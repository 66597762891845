import { css } from 'styled-components'
import { darkColorsTheme, antDarkColorsTheme } from './dark/darkTheme'
import { lightColorsTheme } from './light/lightTheme'
import { ThemeType, colors, hexToRGB } from 'parkway-web-common'

export const themeObject = {
  light: lightColorsTheme,
  dark: darkColorsTheme,
}

export const antThemeObject = {
  light: {},
  dark: antDarkColorsTheme,
}

const getThemeVariables = (theme: ThemeType) => css`
  color-scheme: ${theme};
  --primary-color: ${themeObject[theme].primary};
  --primary1-color: ${themeObject[theme].primary1};
  --primary-gradient-color: ${themeObject[theme].primaryGradient};
  --info-color: var(--primary-color);
  --secondary-color: ${themeObject[theme].secondary};
  --error-color: ${themeObject[theme].error};
  --warning-color: ${themeObject[theme].warning};
  --success-color: ${themeObject[theme].success};
  --background-color: ${themeObject[theme].background};
  --secondary-background-color: ${themeObject[theme].secondaryBackground};
  --secondary-background-selected-color: ${themeObject[theme]
    .secondaryBackgroundSelected};
  --additional-background-color: ${themeObject[theme].additionalBackground};
  --collapse-background-color: ${themeObject[theme].collapseBackground};
  --timeline-background-color: ${themeObject[theme].timelineBackground};
  --spinner-base-color: ${themeObject[theme].spinnerBase};
  --sider-background-color: ${themeObject[theme].siderBackground};
  --shadow-color: ${themeObject[theme].shadow};
  --border-color: ${themeObject[theme].border};
  --border-nft-color: ${themeObject[theme].borderNft};
  --scroll-color: ${themeObject[theme].scroll};

  --primary-rgb-color: ${hexToRGB(themeObject[theme].primary)};
  --info-rgb-color: ${hexToRGB(themeObject[theme].primary)};
  --secondary-rgb-color: ${hexToRGB(themeObject[theme].secondary)};
  --error-rgb-color: ${hexToRGB(themeObject[theme].error)};
  --warning-rgb-color: ${hexToRGB(themeObject[theme].warning)};
  --success-rgb-color: ${hexToRGB(themeObject[theme].success)};
  --background-rgb-color: ${hexToRGB(themeObject[theme].background)};

  --text-main-color: ${themeObject[theme].textMain};
  --text-light-color: ${themeObject[theme].textLight};
  --text-superLight-color: ${themeObject[theme].textSuperLight};
  --text-secondary-color: ${themeObject[theme].textSecondary};
  --text-dark-color: ${themeObject[theme].textDark};
  --text-nft-light-color: ${themeObject[theme].textNftLight};
  --text-sider-primary-color: ${themeObject[theme].textSiderPrimary};
  --text-sider-secondary-color: ${themeObject[theme].textSiderSecondary};
  --subtext-color: ${themeObject[theme].subText};
  --text-primary2-color: ${themeObject[theme].primaryText2};

  --dashboard-map-background-color: ${themeObject[theme]
    .dashboardMapBackground};
  --dashboard-map-circle-color: ${themeObject[theme].dashboardMapCircleColor};
  --dashboard-map-control-disabled-background-color: ${themeObject[theme]
    .dashboardMapControlDisabledBackground};

  --chart-tooltip-label-color: ${themeObject[theme].chartTooltipLabel};
  --chart-color1: ${themeObject[theme].chartColor1};
  --chart-rgb-color1: ${hexToRGB(themeObject[theme].chartColor1)};
  --chart-color1-tint: ${themeObject[theme].chartColor1Tint};
  --chart-color2: ${themeObject[theme].chartColor2};
  --chart-color2-tint: ${themeObject[theme].chartColor2Tint};
  --chart-color3: ${themeObject[theme].chartColor3};
  --chart-color3-tint: ${themeObject[theme].chartColor3Tint};
  --chart-color4: ${themeObject[theme].chartColor4};
  --chart-color4-tint: ${themeObject[theme].chartColor4Tint};
  --chart-color5: ${themeObject[theme].chartColor5};
  --chart-rgb-color5: ${hexToRGB(themeObject[theme].chartColor5)};
  --chart-color5-tint: ${themeObject[theme].chartColor5Tint};

  --notification-success-color: ${themeObject[theme].notificationSuccess};
  --notification-primary-color: ${themeObject[theme].notificationPrimary};
  --notification-warning-color: ${themeObject[theme].notificationWarning};
  --notification-error-color: ${themeObject[theme].notificationError};

  --icon-color: ${themeObject[theme].icon};
  --icon-hover-color: ${themeObject[theme].iconHover};
  --box-shadow: ${themeObject[theme].boxShadow};
  --box-shadow-hover: ${themeObject[theme].boxShadowHover};
  --box-shadow-nft-color: ${themeObject[theme].boxShadowNft};
  --box-shadow-nft-secondary-color: ${themeObject[theme].boxShadowNftSecondary};

  --heading-color: ${themeObject[theme].heading};
  --item-hover-bg: ${themeObject[theme].itemHoverBg};
  --background-base-color: ${themeObject[theme].backgroundColorBase};
  --border-base-color: ${themeObject[theme].borderBase};
  --disabled-color: ${themeObject[theme].disable};
  --disabled-bg-color: ${themeObject[theme].disabledBg};
  --layout-body-bg-color: ${themeObject[theme].layoutBodyBg};
  --layout-header-bg-color: ${themeObject[theme].layoutHeaderBg};
  --layout-sider-bg-color: ${themeObject[theme].layoutSiderBg};
  --input-placeholder-color: ${themeObject[theme].inputPlaceholder};
  --avatar-bg: ${themeObject[theme].avatarBg};
  --alert-text-color: ${themeObject[theme].alertTextColor};
  --breadcrumb-color: ${themeObject[theme].breadcrumb};
  --checkbox-border-color: ${themeObject[theme].checkBoxBorder};
  --primary-gradient-button-color: ${themeObject[theme].primaryGradientButton};
  --primary-gradient-button-color-hover: ${themeObject[theme]
    .primaryGradientButtonHover};
  --revenue-dashboard-border-color: ${themeObject[theme]
    .revenueDashboardBorder};
  --states-green-color: ${themeObject[theme].statesGreenColor};
  --states-green-light-color: ${themeObject[theme].statesGreenLightColor};
  --states-red-color: ${themeObject[theme].statesRedColor};
  --states-red-light-color: ${themeObject[theme].statesRedLightColor};
  --states-orange-light-b-color: ${themeObject[theme].statusOrangeLightB};
  --states-orange-light-color: ${themeObject[theme].statusOrangeLight};
  --states-blue-light-color: ${themeObject[theme].statesBlueLightColor};
  --other-blue-color: ${themeObject[theme].otherBlue};
  --states-orange-color: ${themeObject[theme].statesOrange};
  --main-color-main-optional-primary-3-color: ${themeObject[theme]
    .mainColorMainOptionalPrimary3};
  --neutral-black-9-color: ${themeObject[theme].neutralBlack9};
  --neutral-black-16-color: ${themeObject[theme].neutralBlack16};
  --neutral-black-2-color: ${themeObject[theme].neutralBlack2};
  --neutral-black-1-color: ${themeObject[theme].neutralBlack1};
  --neutral-black-color: ${themeObject[theme].neutralBlack};
  --main-2-color: ${themeObject[theme].mainColor2};
`

export const lightThemeVariables = css`
  ${getThemeVariables('light')}
`

export const darkThemeVariables = css`
  ${getThemeVariables('dark')}
  --ant-success-color-deprecated-bg: ${antThemeObject['dark']
    .successBg} !important;
  --ant-success-color-deprecated-border: ${antThemeObject['dark']
    .successBorder} !important;
`

export const commonThemeVariables = css`
  color-scheme: light dark;
  --white: ${colors.white};
  --black: ${colors.black};
  --green: ${colors.green};
  --orange: ${colors.orange};
  --gray: ${colors.gray};
  --lightgrey: ${colors.lightgrey};
  --violet: ${colors.violet};
  --lightgreen: ${colors.lightgreen};
  --pink: ${colors.pink};
  --blue: ${colors.blue};
  --skyblue: ${colors.skyblue};
  --red: ${colors.red};
`

export const antOverrideCssVariables = css`
  --ant-primary-1: var(--primary1-color) !important;
`

export const convertedVariables = {
  colorScheme: "var(--color-scheme)",
  primaryColor: "var(--primary-color)",
  primary1Color: "var(--primary1-color)",
  primaryGradientColor: "var(--primary-gradient-color)",
  infoColor: "var(--info-color)",
  secondaryColor: "var(--secondary-color)",
  errorColor: "var(--error-color)",
  warningColor: "var(--warning-color)",
  successColor: "var(--success-color)",
  backgroundColor: "var(--background-color)",
  secondaryBackgroundColor: "var(--secondary-background-color)",
  secondaryBackgroundSelectedColor: "var(--secondary-background-selected-color)",
  additionalBackgroundColor: "var(--additional-background-color)",
  collapseBackgroundColor: "var(--collapse-background-color)",
  timelineBackgroundColor: "var(--timeline-background-color)",
  spinnerBaseColor: "var(--spinner-base-color)",
  siderBackgroundColor: "var(--sider-background-color)",
  shadowColor: "var(--shadow-color)",
  borderColor: "var(--border-color)",
  borderNftColor: "var(--border-nft-color)",
  scrollColor: "var(--scroll-color)",
  primaryRgbColor: "var(--primary-rgb-color)",
  infoRgbColor: "var(--info-rgb-color)",
  secondaryRgbColor: "var(--secondary-rgb-color)",
  errorRgbColor: "var(--error-rgb-color)",
  warningRgbColor: "var(--warning-rgb-color)",
  successRgbColor: "var(--success-rgb-color)",
  backgroundRgbColor: "var(--background-rgb-color)",
  textMainColor: "var(--text-main-color)",
  textLightColor: "var(--text-light-color)",
  textSuperLightColor: "var(--text-superLight-color)",
  textSecondaryColor: "var(--text-secondary-color)",
  textDarkColor: "var(--text-dark-color)",
  textNftLightColor: "var(--text-nft-light-color)",
  textSiderPrimaryColor: "var(--text-sider-primary-color)",
  textSiderSecondaryColor: "var(--text-sider-secondary-color)",
  subtextColor: "var(--subtext-color)",
  textPrimary2Color: "var(--text-primary2-color)",
  dashboardMapBackgroundColor: "var(--dashboard-map-background-color)",
  dashboardMapCircleColor: "var(--dashboard-map-circle-color)",
  dashboardMapControlDisabledBackgroundColor: "var(--dashboard-map-control-disabled-background-color)",
  chartTooltipLabelColor: "var(--chart-tooltip-label-color)",
  chartColor1: "var(--chart-color1)",
  chartRgbColor1: "var(--chart-rgb-color1)",
  chartColor1Tint: "var(--chart-color1-tint)",
  chartColor2: "var(--chart-color2)",
  chartColor2Tint: "var(--chart-color2-tint)",
  chartColor3: "var(--chart-color3)",
  chartColor3Tint: "var(--chart-color3-tint)",
  chartColor4: "var(--chart-color4)",
  chartColor4Tint: "var(--chart-color4-tint)",
  chartColor5: "var(--chart-color5)",
  chartRgbColor5: "var(--chart-rgb-color5)",
  chartColor5Tint: "var(--chart-color5-tint)",
  notificationSuccessColor: "var(--notification-success-color)",
  notificationPrimaryColor: "var(--notification-primary-color)",
  notificationWarningColor: "var(--notification-warning-color)",
  notificationErrorColor: "var(--notification-error-color)",
  iconColor: "var(--icon-color)",
  iconHoverColor: "var(--icon-hover-color)",
  boxShadow: "var(--box-shadow)",
  boxShadowHover: "var(--box-shadow-hover)",
  boxShadowNftColor: "var(--box-shadow-nft-color)",
  boxShadowNftSecondaryColor: "var(--box-shadow-nft-secondary-color)",
  headingColor: "var(--heading-color)",
  itemHoverBgColor: "var(--item-hover-bg-color)",
  backgroundBaseColor: "var(--background-base-color)",
  borderBaseColor: "var(--border-base-color)",
  disabledColor: "var(--disabled-color)",
  disabledBgColor: "var(--disabled-bg-color)",
  layoutBodyBgColor: "var(--layout-body-bg-color)",
  layoutHeaderBgColor: "var(--layout-header-bg-color)",
  layoutSiderBgColor: "var(--layout-sider-bg-color)",
  inputPlaceholderColor: "var(--input-placeholder-color)",
  avatarBgColor: "var(--avatar-bg-color)",
  alertTextColor: "var(--alert-text-color)",
  breadcrumbColor: "var(--breadcrumb-color)",
  checkboxBorderColor: "var(--checkbox-border-color)",
  primaryGradientButtonColor: "var(--primary-gradient-button-color)",
  primaryGradientButtonColorHover: "var(--primary-gradient-button-color-hover)",
  revenueDashboardBorderColor: "var(--revenue-dashboard-border-color)",
  statesGreenColor: "var(--states-green-color)",
  statesGreenLightColor: "var(--states-green-light-color)",
  statesRedColor: "var(--states-red-color)",
  statesRedLightColor: "var(--states-red-light-color)",
  statesOrangeLightBColor: "var(--states-orange-light-b-color)",
  statesOrangeLightColor: "var(--states-orange-light-color)",
  statesBlueLightColor: "var(--states-blue-light-color)",
  otherBlueColor: "var(--other-blue-color)",
  statesOrangeColor: "var(--states-orange-color)",
  mainColorMainOptionalPrimary3Color: "var(--main-color-main-optional-primary-3-color)",
  neutralBlack9Color: "var(--neutral-black-9-color)",
  neutralBlack16Color: "var(--neutral-black-16-color)",
  neutralBlack2Color: "var(--neutral-black-2-color)",
  neutralBlack1Color: "var(--neutral-black-1-color)",
  neutralBlackColor: "var(--neutral-black-color)",
  main2Color: "var(--main-2-color)"
};
