import { useUpdateEventContext } from '../../context'

export const useHook = () => {
  const { handleSubmit, form, eventType, onChangeEventType} = useUpdateEventContext()

  return {
    form,
    handleSubmit,
    eventType,
    onChangeEventType
  }
}
