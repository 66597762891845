import { TypeEventEnum } from 'app/api/event/model'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { ActivityContent, CareContent, TrainingContent } from './contents'
import { useHook } from './hook'
import { IProps } from './type'

export function ExpandedRowRender(props: IProps) {
  const { data, isLoading, tumData, downloadQRCode } = useHook(props)

  return (
    <BaseForm>
      {(() => {
        switch (props.event.type) {
          case TypeEventEnum.ACTIVITY:
            return (
              <ActivityContent
                data={data}
                downloadQRCode={downloadQRCode}
                isLoading={isLoading}
              />
            )
          case TypeEventEnum.TRAINING:
            return (
              <TrainingContent
                tumData={tumData}
                event={props.event}
                isLoading={isLoading}
                downloadQRCode={downloadQRCode}
              />
            )
          case TypeEventEnum.CARE:
            return (
              <CareContent
                tumData={tumData}
                event={props.event}
                isLoading={isLoading}
              />
            )
          default:
            return null
        }
      })()}
    </BaseForm>
  )
}
