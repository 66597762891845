import { ENV_CONFIG } from 'app/common/config'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import {
  BaseModalComponent,
  IRefModal,
} from 'app/components/common/ModalComponent'
import { useGetDoanVien } from 'app/react-query/hooks-old/useGetUser'
import axios from 'axios'
import React from 'react'
import toast from 'react-hot-toast'
import { DoanVien } from '../../type'

export const ModalConfirmChangeStatus = ({
  doanVien,
}: {
  doanVien?: DoanVien
}) => {
  const { refetch } = useGetDoanVien()
  const modalUpdateRef = React.useRef<IRefModal>(null)
  const [loading, setLoading] = React.useState<boolean>(false)

  async function handleChangeStatus() {
    if (!doanVien?.maso_doanvien) return

    try {
      setLoading(true)
      await axios.put(
        `${ENV_CONFIG.API_ENDPOINT}/api/v1/doanvien/update/trangthai/${doanVien?.maso_doanvien}`,
      )
      refetch?.()
      modalUpdateRef?.current?.hide?.()
      toast.success('Thay đổi trạng thái thành công')
    } catch (error) {
      toast.error('Đã Có Lỗi Xảy Ra! Hãy Thử Lại')
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <BaseModalComponent
      titleModal={`${
        !doanVien?.trangthai ? 'Khởi động lại' : 'Ngưng hoạt động'
      } đoàn viên "${doanVien?.hovaten ?? ''}"`}
      handleSubmit={handleChangeStatus}
      isLoadingConfirm={loading}
      ref={modalUpdateRef}
      titleOpenModal={
        !doanVien?.trangthai ? 'Khởi động lại' : 'Ngưng hoạt động'
      }
      renderContent={
        <BaseSpace size={4}>
          <BaseText
            children={`Bạn có thực sự muốn ${
              !doanVien?.trangthai ? 'khởi động lại' : 'ngưng hoạt động'
            } của đoàn viên này không?`}
            fontWeight="medium"
            fontSize="xs"
          />

          <BaseText
            children={
              '*Bạn phải thật cẩn thận khi thay đổi trạng thái của công đoàn viên, điều này có thể làm thay đổi dữ liệu và ảnh hưởng tới hệ thống.'
            }
            colorText="statesRedColor"
            opacity="0.8"
            fontWeight="medium"
          />
        </BaseSpace>
      }
    />
  )
}
