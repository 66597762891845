import { PageTitle } from 'app/components/common/PageTitle'
import { TumManagementContainer } from 'app/containers/TumManagement'
import { isEqual } from 'lodash'
import { memo } from 'react'

const TumManagementCpn = () => {
  return (
    <>
      <PageTitle>{'Quản lý công đoàn viên'}</PageTitle>
      <TumManagementContainer />
    </>
  )
}
export const TumManagementPage = memo(TumManagementCpn, isEqual)
