import { BaseRow } from 'app/components/common/BaseRow'
import * as S from './styles'
import BaseText from 'app/components/common/BaseText'

export interface IRevenueDashboard {
  label?: string
  color: string
  value?: string | number
}

const RevenueItem = ({ label = '', color, value }: IRevenueDashboard) => {
  return (
    <S.RevenueItemWrapper color={color}>
      <BaseRow align={'top'} gutter={5} wrap={false}>
        <S.Title>{label}</S.Title>
      </BaseRow>
      <BaseText
        children={value}
        style={{
          color,
        }}
        fontWeight="medium"
        fontSize="xs"
      />
    </S.RevenueItemWrapper>
  )
}

export default RevenueItem
