/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { cover_bg, khcn_logo , cdvn_logo } from 'app/assets';
import axios from 'axios';
import Cookies from 'js-cookie';
import { ENV_CONFIG } from 'app/common/config';

export default function Login(){
  const [email,setEmail] = useState("")
  const [password,setPassword]= useState("")
  const [isSubmit ,setIsSubmit] = useState(false)
  const navigate = useNavigate();


  useEffect(()=> {
    const cookie = Cookies.get("access_token")
    if(cookie) {
      navigate('/')
    } 
  }, [])


  async function handleSubmit (e: any){
    setIsSubmit(true)
    e.preventDefault();
    try {
      const { data } = await axios.post(`${ENV_CONFIG.API_ENDPOINT}/api/auth/login`
      , {
        email: email,
        password: password
      })

      console.log(data)
      // localStorage.setItem("userData",JSON.stringify(data))
      // localStorage.setItem("_auth",JSON.stringify(data.token))
      localStorage.setItem("msdv",data?.maso_doanvien)
      Cookies.set("access_token",data?.token)
      navigate('/')
      toast.success(data.message)

    } catch (error: any) {
      setIsSubmit(false)
      console.log(error?.response?.data?.message)
      if(error?.response?.data?.message.includes("không tồn tại")) {
        toast.error("Thông tin tài khoản không đúng")
      } else {
        toast.error("Lỗi khi đăng nhập")
      }
    }
  }

  return (
    <div className='flex flex-shrink items-center justify-center gap-12 min-h-full mt-20'>
      <div className= 'flex items-center justify-center h-[80vh] ' >
        <img src= {cover_bg}  />
      </div>
      <div className='w-1/3 py-20 flex flex-col item-center'>
        <div className='flex gap-3 flex-shrink items-center justify-center'>
          <img src= {cdvn_logo} width= "15%" />
          <img src= {khcn_logo} width= "15%" />
        </div>
        <h1 className='text-center mb-6 mt-3 text-3xl font-bold'>Đăng nhập</h1>
        <form onSubmit={handleSubmit} className='flex flex-col gap-4 justify-center w-full items-center'>
          <input value={email} onChange={(e)=>setEmail(e.target.value)}
          className='p-3 bg-gray-50 border border-gray-200 text-gray-900 text-sm rounded-lg focus:ring-3 focus:ring-sky-500 focus:border-sky-500 outline-none  w-[60%]' type='text' name='email' placeholder='Email' />
          <input value={password} onChange={(e)=>setPassword(e.target.value)} 
          className='p-3 bg-gray-50 border border-gray-200 text-gray-900 text-sm rounded-lg focus:ring-3 focus:ring-sky-500 focus:border-sky-500 w-[60%] outline-none' type='password' name='password' placeholder='Password' />
          <button type="submit" disabled={isSubmit} className=' bg-sky-500 rounded-lg text-white py-3 w-1/3 hover:scale-105 duration-300'>{isSubmit? "Logging in": "Log In "}</button>
        </form>
      </div>
    </div>
  )
}
