import { RoleEnum } from 'app/common/enum'

export enum JobTitleEnum {
  HEAD = 'HEAD',
  DEPUTY_HEAD = 'DEPUTY_HEAD',
  STAFF = 'STAFF',
  CHAIRMAN_OF_THE_TRADE_UNION = 'CHAIRMAN_OF_THE_TRADE_UNION',
  VICE_CHAIRMAN_OF_THE_TRADE_UNION = 'VICE_CHAIRMAN_OF_THE_TRADE_UNION',
  EXECUTIVE_COMMITTEE_MEMBER = 'EXECUTIVE_COMMITTEE_MEMBER',
  EXECUTIVE_COMMITTEE_MEMBER_OF_TRADE_UNION_DEPARTMENT = 'EXECUTIVE_COMMITTEE_MEMBER_OF_TRADE_UNION_DEPARTMENT',
  CHAIRMAN_OF_TRADE_UNION_INSPECTION_COMMITTEE = 'CHAIRMAN_OF_TRADE_UNION_INSPECTION_COMMITTEE',
  MEMBER_OF_TRADE_UNION_INSPECTION_COMMITTEE = 'MEMBER_OF_TRADE_UNION_INSPECTION_COMMITTEE',
  PRESIDENT_OF_CSTV_TRADE_UNION = 'PRESIDENT_OF_CSTV_TRADE_UNION',
  VICE_PRESIDENT_OF_CSTV_TRADE_UNION = 'VICE_PRESIDENT_OF_CSTV_TRADE_UNION',
  MEMBER_OF_EXECUTIVE_BOARD_OF_CSTV_TRADE_UNION = 'MEMBER_OF_EXECUTIVE_BOARD_OF_CSTV_TRADE_UNION',
  EXECUTIVE_COMMITTEE_MEMBER_OF_CSTV_TRADE_UNION = 'EXECUTIVE_COMMITTEE_MEMBER_OF_CSTV_TRADE_UNION',
  TRADE_UNION_MEMBER = 'TRADE_UNION_MEMBER',
}

export const DefineJobTitleEnum = {
  [JobTitleEnum.HEAD]: 'Trưởng đơn vị',
  [JobTitleEnum.DEPUTY_HEAD]: 'Phó đơn vị',
  [JobTitleEnum.STAFF]: 'Công đoàn viên',
  [JobTitleEnum.CHAIRMAN_OF_THE_TRADE_UNION]: 'Chủ tịch công đoàn',
  [JobTitleEnum.VICE_CHAIRMAN_OF_THE_TRADE_UNION]: 'Phó chủ tịch công đoàn',
  [JobTitleEnum.EXECUTIVE_COMMITTEE_MEMBER]: 'Ủy viên Ban Thường vụ Công đoàn Sở',
  [JobTitleEnum.EXECUTIVE_COMMITTEE_MEMBER_OF_TRADE_UNION_DEPARTMENT]:
    'Ủy viên Ban chấp hành Công đoàn Sở',
  [JobTitleEnum.CHAIRMAN_OF_TRADE_UNION_INSPECTION_COMMITTEE]:
    'Chủ nhiệm Ủy ban Kiểm tra Công đoàn Sở',
  [JobTitleEnum.MEMBER_OF_TRADE_UNION_INSPECTION_COMMITTEE]:
    'Ủy viên Ủy ban Kiểm tra Công đoàn Sở',
  [JobTitleEnum.PRESIDENT_OF_CSTV_TRADE_UNION]: 'Chủ tịch Công đoàn CSTV',
  [JobTitleEnum.VICE_PRESIDENT_OF_CSTV_TRADE_UNION]:
    'Phó Chủ tịch Công đoàn CSTV',
  [JobTitleEnum.MEMBER_OF_EXECUTIVE_BOARD_OF_CSTV_TRADE_UNION]:
    'Ủy viên Ban Thường vụ Công đoàn CSTV',
  [JobTitleEnum.EXECUTIVE_COMMITTEE_MEMBER_OF_CSTV_TRADE_UNION]:
    'Ủy viên Ban chấp hành Công đoàn CSTV',
  [JobTitleEnum.TRADE_UNION_MEMBER]: 'Công đoàn viên',
}

export interface DataItem {
  maso_doanvien?: string
  'Họ và tên': string
  Tỉnh: string
  'Ngày vào công đoàn': string
  'Quê quán': string
  'Giới tính': string
  'Ngày sinh': string
  'Tên Đơn Vị': string
  'Trạng Thái': string
}

export interface DoanVien {
  id?: number
  maso_doanvien: string
  hovaten: string
  gioitinh: string
  ngaysinh: string
  quequan: string
  tinh: string
  cmnd: string
  donvi: string
  trangthai: boolean
  email: string
  role?: RoleEnum
  ngayvao_congdoan: string
  chucdanh?: string

  chucvu?: string
}

export const OptionSelectJobTitle = [
  {
    label: DefineJobTitleEnum[JobTitleEnum.CHAIRMAN_OF_THE_TRADE_UNION],
    value: JobTitleEnum.CHAIRMAN_OF_THE_TRADE_UNION,
  },
  {
    label: DefineJobTitleEnum[JobTitleEnum.VICE_CHAIRMAN_OF_THE_TRADE_UNION],
    value: JobTitleEnum.VICE_CHAIRMAN_OF_THE_TRADE_UNION,
  },
  {
    label: DefineJobTitleEnum[JobTitleEnum.EXECUTIVE_COMMITTEE_MEMBER],
    value: JobTitleEnum.EXECUTIVE_COMMITTEE_MEMBER,
  },
  {
    label:
      DefineJobTitleEnum[
        JobTitleEnum.EXECUTIVE_COMMITTEE_MEMBER_OF_TRADE_UNION_DEPARTMENT
      ],
    value: JobTitleEnum.EXECUTIVE_COMMITTEE_MEMBER_OF_TRADE_UNION_DEPARTMENT,
  },
  {
    label:
      DefineJobTitleEnum[
        JobTitleEnum.CHAIRMAN_OF_TRADE_UNION_INSPECTION_COMMITTEE
      ],
    value: JobTitleEnum.CHAIRMAN_OF_TRADE_UNION_INSPECTION_COMMITTEE,
  },
  {
    label:
      DefineJobTitleEnum[
        JobTitleEnum.MEMBER_OF_TRADE_UNION_INSPECTION_COMMITTEE
      ],
    value: JobTitleEnum.MEMBER_OF_TRADE_UNION_INSPECTION_COMMITTEE,
  },
  {
    label: DefineJobTitleEnum[JobTitleEnum.PRESIDENT_OF_CSTV_TRADE_UNION],
    value: JobTitleEnum.PRESIDENT_OF_CSTV_TRADE_UNION,
  },
  {
    label: DefineJobTitleEnum[JobTitleEnum.VICE_PRESIDENT_OF_CSTV_TRADE_UNION],
    value: JobTitleEnum.VICE_PRESIDENT_OF_CSTV_TRADE_UNION,
  },
  {
    label:
      DefineJobTitleEnum[
        JobTitleEnum.MEMBER_OF_EXECUTIVE_BOARD_OF_CSTV_TRADE_UNION
      ],
    value: JobTitleEnum.MEMBER_OF_EXECUTIVE_BOARD_OF_CSTV_TRADE_UNION,
  },
  {
    label:
      DefineJobTitleEnum[
        JobTitleEnum.EXECUTIVE_COMMITTEE_MEMBER_OF_CSTV_TRADE_UNION
      ],
    value: JobTitleEnum.EXECUTIVE_COMMITTEE_MEMBER_OF_CSTV_TRADE_UNION,
  },
  {
    label: DefineJobTitleEnum[JobTitleEnum.TRADE_UNION_MEMBER],
    value: JobTitleEnum.TRADE_UNION_MEMBER,
  },
]
