import { PageTitle } from 'app/components/common/PageTitle'
import {CreateEventContainer} from 'app/containers/EventManagement/CreateEvent'
import { isEqual } from 'lodash'
import { memo } from 'react'

const CreateEventCpn = () => {
  return (
    <>
      <PageTitle>{'Tạo mới sự kiện'}</PageTitle>
      <CreateEventContainer />
    </>
  )
}
export const CreateEventPage = memo(CreateEventCpn, isEqual)
