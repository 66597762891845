import { SearchOutlined } from '@ant-design/icons'
import { requestGetMemberPrizeByEventId } from 'app/api/event'
import { IActionEvent, IEvent, IPrizeActionEvent } from 'app/api/event/model'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { DoanVien } from 'app/containers/TumManagement/type'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { isEmpty } from 'lodash'
import { useEffect, useMemo, useState } from 'react'

interface IMemberPrizeInEvent {
  // {
  //     "id": 5,
  //     "actionEventId": 3,
  //     "maso_doanvien": "5371054",
  //     "action": {
  //         "id": 3,
  //         "name": "daddd",
  //         "eventId": 4,
  //         "action": "ACTIVE",
  //         "createdAt": "2024-06-18T16:45:12.000Z",
  //         "updatedAt": "2024-06-18T16:45:12.000Z"
  //     },
  //     "checkedIn": false,
  //     "valueCare": "0",
  //     "createdAt": "2024-06-18T16:45:14.000Z",
  //     "updatedAt": "2024-06-18T16:45:14.000Z",
  //     "info": {
  //         "maso_doanvien": "5371054",
  //         "hovaten": "Hiếu Bùi",
  //         "chucdanh": "HEAD",
  //         "quequan": "Thanh Hoá",
  //         "cmnd": "12345678912",
  //         "ngayvao_congdoan": "01-01-2022",
  //         "donvi": "Đơn vị E-1 (Hợp nhất AC-1,2 và E)",
  //         "trangthai": true,
  //         "gioitinh": "Nam",
  //         "ngaysinh": "01-10-1990",
  //         "email": "hieubui@gmail.com",
  //         "password": "3697dd0aaea2c0b60d305736b525d8b06e509cc2dc570a044c69078abea1ba25",
  //         "salt": "bIQEAtPmxQ2xTANTbiXzRXhaY7DYupocxq+eSL+q/dJOOFC1zVp6Y351ujlOovSp0RUJ7Y7STC5gxD6XPlEpoVq7D4K958JCFmg89pnKSRwEkwV8kUqozI5s93SCK4UdFxggKc++KCcTUuR7v3wX1aRGyZDqduoYS2Viujg8l4o=",
  //         "token": "",
  //         "role": "admin",
  //         "createdAt": "2024-06-16T07:41:09.000Z",
  //         "updatedAt": "2024-06-19T14:14:43.000Z"
  //     },
  //     "prize": {
  //         "id": 1,
  //         "name": "addd",
  //         "description": "addd",
  //         "gift": "adđ",
  //         "countPerReward": 1,
  //         "actionEventId": 3,
  //         "type_prize": "INDIVIDUAL",
  //         "action": "ACTIVE",
  //         "createdAt": "2024-06-18T16:45:12.000Z",
  //         "updatedAt": "2024-06-18T16:45:12.000Z"
  //     }
  // },

  id?: number
  actionEventId?: number
  maso_doanvien?: string
  action: IActionEvent
  info?: DoanVien
  prize?: IPrizeActionEvent
}

export const ListMemberPrizeLayout = ({ event }: { event?: IEvent }) => {
  const [data, setData] = useState<IMemberPrizeInEvent[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')

  const getData = async () => {
    if (!event?.id) return
    setLoading(true)
    try {
      const res: IMemberPrizeInEvent[] = await requestGetMemberPrizeByEventId({
        id: event.id,
      })

      setData(res)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      getData()
    }, 1000)

    return () => clearTimeout(timer)
  }, [event])

  const dataSource = useMemo(() => {
    return data
      ?.filter(itm => {
        if (isEmpty(search)) return true

        const searchLowerCase = search.toLowerCase()

        if (itm?.info?.hovaten?.toLowerCase().includes(searchLowerCase))
          return true

        if (itm?.info?.donvi?.toLowerCase().includes(searchLowerCase))
          return true

        if (itm?.prize?.name?.toLowerCase().includes(searchLowerCase))
          return true
      })
      ?.map((item, index) => {
        return {
          ...item,
          key: index + 1,
          index: index + 1,
        }
      })
  }, [data, search])

  return (
    <BaseSpace>
      <BaseText
        children={'Danh sách đoàn viên đoạt giải'}
        fontWeight="semibold"
        fontSize="xs"
      />

      <BaseRow>
        <BaseCol xl={8}>
          <BaseForm>
            <BaseForm.Item hiddenLabel style={{
                marginBottom: 0
            }}>
              <BaseInput
                onChange={e => setSearch(e?.target?.value)}
                value={search}
                placeholder="Nhập tên đoàn viên, tên đơn vị, giải ..."
                allowClear
                suffix={<SearchOutlined rev={undefined} />}
              />
            </BaseForm.Item>
          </BaseForm>
        </BaseCol>
      </BaseRow>

      <BaseTableManagement
        dataSource={dataSource}
        loading={loading}
        pagination={{
          pageSize: 5,
        }}
        columns={[
          convertColumnTable({
            key: 'index',
            title: 'STT',
            classNameWidthColumnOverwrite: 'number-column',
            fixed: 'left',
            render: (value: number) => {
              return (
                <BaseText children={value} opacity="0.7" fontWeight="medium" />
              )
            },
          }),
          convertColumnTable<IMemberPrizeInEvent>({
            key: 'info',
            title: 'Tên đoàn viên',
            fixed: 'left',
            classNameWidthColumnOverwrite: 'big-column',
            render: (_, record?: IMemberPrizeInEvent) => {
              return (
                <BaseText
                  children={record?.info?.hovaten ?? '-'}
                  opacity="0.7"
                  fontWeight="medium"
                />
              )
            },
          }),
          convertColumnTable<IMemberPrizeInEvent>({
            key: 'info',
            title: 'Email',
            render: (_, record?: IMemberPrizeInEvent) => {
              return (
                <BaseText
                  children={record?.info?.email ?? '-'}
                  opacity="0.7"
                  fontWeight="medium"
                />
              )
            },
          }),
          convertColumnTable<IMemberPrizeInEvent>({
            key: 'info',
            title: 'CCCD/CMND',
            render: (_, record?: IMemberPrizeInEvent) => {
              return (
                <BaseText
                  children={record?.info?.cmnd ?? '-'}
                  opacity="0.7"
                  fontWeight="medium"
                />
              )
            },
          }),
          convertColumnTable<IMemberPrizeInEvent>({
            key: 'info',
            title: 'Đơn vị',
            render: (_, record?: IMemberPrizeInEvent) => {
              return (
                <BaseText
                  children={record?.info?.donvi ?? '-'}
                  opacity="0.7"
                  fontWeight="medium"
                />
              )
            },
          }),
          convertColumnTable<IMemberPrizeInEvent>({
            key: 'prize',
            title: 'Đạt giải',
            render: (_, record?: IMemberPrizeInEvent) => {
              return (
                <BaseText
                  children={record?.prize?.name ?? '-'}
                  opacity="0.7"
                  fontWeight="medium"
                />
              )
            },
          }),
        ]}
        className="row-overwrite-style"
        scroll={{
          x: true,
        }}
      />
    </BaseSpace>
  )
}
