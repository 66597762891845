import { PageTitle } from 'app/components/common/PageTitle'
import { DetailTumContainer } from 'app/containers/TumManagement/Detail'
import { isEqual } from 'lodash'
import { memo } from 'react'

const DetailTumCpn = () => {
  return (
    <>
      <PageTitle>{'Chi tiết công đoàn viên'}</PageTitle>
      <DetailTumContainer />
    </>
  )
}
export const DetailTumPage = memo(DetailTumCpn, isEqual)
