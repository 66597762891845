/* eslint-disable @typescript-eslint/no-explicit-any */
import { PlusOutlined } from '@ant-design/icons'
import { IMemberActionEvent, IPrizeActionEvent } from 'app/api/event/model'
import { ENV_CONFIG } from 'app/common/config'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCheckbox } from 'app/components/common/BaseCheckbox'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import {
  BaseModalComponent,
  IRefModal,
} from 'app/components/common/ModalComponent'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { RewardTypeEnum } from 'app/containers/EventManagement/CreateEvent/type'
import { useGetDoanVienByActionId } from 'app/react-query/hooks-old/useGetUser'
import axios from 'axios'
import { isEqual } from 'lodash'
import { useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'

interface IProps {
  data?: IMemberActionEvent[]
  refetch?: () => void
  actionId?: number
  reward?: IPrizeActionEvent
}

export const ModalSelectTum = ({ data, actionId, reward, refetch }: IProps) => {
  const modalRef = useRef<IRefModal>({})
  const [dataSelected, setDataSelected] = useState<IMemberActionEvent[]>([])
  const [search, setSearch] = useState<string>('')
  const [isLoadingSubmit, setIsLoading] = useState<boolean>(false)

  const { data: listTums, isLoading } = useGetDoanVienByActionId(actionId)

  const onSelected = (record: IMemberActionEvent) => {
    if (isEqual(reward?.type, RewardTypeEnum.SINGLE)) {
      const isSelected = dataSelected.some(
        item => item?.maso_doanvien === record?.maso_doanvien,
      )

      if (isSelected) {
        setDataSelected([])
      } else {
        setDataSelected([record])
      }

      return
      
    }

    const isSelected = dataSelected.some(
      item => item?.maso_doanvien === record?.maso_doanvien,
    )

    if (isSelected) {
      setDataSelected(
        dataSelected.filter(
          item => item?.maso_doanvien !== record?.maso_doanvien,
        ),
      )
    } else {
      setDataSelected([...dataSelected, record])
    }
  }

  const openModal = () => {
    modalRef?.current?.open?.()
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setDataSelected(data ?? [])
    }, 1000)

    return () => clearTimeout(timer)
  }, [data])

  const dataTable = useMemo(() => {
    return (listTums as IMemberActionEvent[])?.filter(
      item =>
        item?.info?.hovaten?.toLowerCase().includes(search.toLowerCase()) ||
        item?.info?.email?.toLowerCase().includes(search.toLowerCase()),
    )
  }, [search, listTums])

  const onSubmit = async (data: IMemberActionEvent[]) => {
    setIsLoading(true)
    try {
      await axios.post(
        `${ENV_CONFIG.API_ENDPOINT}/api/v1/action/prize/member/create`,
        {
          prizeId: reward?.id,
          listMember: data.map(item => item?.id),
        },
      )

      // refresh data
      refetch?.()
      modalRef?.current?.hide?.()
      setDataSelected([])
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleSubmit = () => {
    onSubmit?.(dataSelected)
  }

  return (
    <BaseModalComponent
      titleModal={'Danh sách đoàn viên tham gia'}
      ref={modalRef}
      buttonOpenModal={
        <BaseButton
          icon={data?.length ? null : <PlusOutlined rev={undefined} />}
          onClick={openModal}
        >
          {'Thay đổi danh sách giải thưởng'}
        </BaseButton>
      }
      widthModal={800}
      handleSubmit={handleSubmit}
      isLoadingConfirm={isLoadingSubmit}
      renderContent={
        <BaseSpace>
          <BaseFormItem label={'Tìm kiếm'}>
            <BaseInput
              value={search}
              onChange={e => setSearch(e?.target?.value)}
              placeholder="Tìm kiếm theo tên, email"
            />
          </BaseFormItem>

          <BaseText children={'Danh sách'} fontWeight="medium" fontSize="xs" />
          <BaseTableManagement
            columns={[
              convertColumnTable<IMemberActionEvent>({
                key: 'info',
                title: 'Họ và tên',
                render: (_, record) => {
                  return (
                    <BaseText
                      children={`${record?.info?.hovaten} ${
                        record?.info?.trangthai ? '' : '(Dừng công tác)'
                      }`}
                      fontWeight="medium"
                      colorText={
                        record?.info?.trangthai
                          ? 'primaryColor'
                          : 'statesRedColor'
                      }
                    />
                  )
                },
              }),
              convertColumnTable<IMemberActionEvent>({
                key: 'info',
                title: 'Email',
                render: (_, record) => {
                  return (
                    <BaseText
                      children={record?.info?.email}
                      fontWeight="medium"
                      colorText={
                        record?.info?.trangthai
                          ? 'primaryColor'
                          : 'statesRedColor'
                      }
                    />
                  )
                },
              }),
              {
                key: 'maso_doanvien',
                title: '',
                width: '10%',

                dataIndex: 'maso_doanvien',
                render: (_, record: IMemberActionEvent) => {
                  const isSelected = dataSelected.some(
                    item => item?.maso_doanvien === record?.maso_doanvien,
                  )
                  return (
                    <BaseCheckbox
                      value={isSelected}
                      checked={isSelected}
                      onClick={() => onSelected(record)}
                      disabled={!record?.info?.trangthai}
                    />
                  )
                },
              },
            ]}
            dataSource={dataTable}
            className="row-overwrite-style"
            scroll={{ x: true }}
            loading={isLoading}
            pagination={{
              pageSize: 5,
            }}
          />
        </BaseSpace>
      }
    />
  )
}

const BaseFormItem = styled(BaseForm.Item)`
  margin-bottom: 0px;
`
