import R from 'app/assets/R'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseModalComponent } from 'app/components/common/ModalComponent'
import { useModalHook } from './hook'
import * as S from './styles'
import { BaseButton } from 'app/components/common/BaseButton'
import { LockOutlined } from '@ant-design/icons'

export const ModalChangePassword = () => {
  const {
    form,
    t,
    isLoading,
    handleSubmit,
    handleCancel,
    rules,
    modalRef,
    openModal,
  } = useModalHook()

  return (
    <BaseModalComponent
      titleModal="Thay đổi mật khẩu"
      handleCancel={handleCancel}
      isLoadingConfirm={isLoading}
      handleSubmit={form?.submit}
      ref={modalRef}
      buttonOpenModal={
        <BaseButton
          icon={<LockOutlined rev={undefined} />}
          onClick={openModal}
          type="text"
        >
          {t(R.strings.change_password)}
        </BaseButton>
      }
      renderContent={
        <BaseForm
          layout="vertical"
          onFinish={handleSubmit}
          requiredMark="optional"
          initialValues={{}}
          form={form}
        >
          <BaseSpace size={4}>
            <S.FormItem
              name={'oldPassword'}
              label={t(R.strings.old_password)}
              rules={rules.oldPassword}
              required
            >
              <S.FormInputPassword
                placeholder={t(R.strings.enter_old_password)}
              />
            </S.FormItem>
            <S.FormItem
              name={'newPassword'}
              label={t(R.strings.new_password)}
              rules={rules.newPassword}
              required
            >
              <S.FormInputPassword
                placeholder={t(R.strings.enter_new_password)}
              />
            </S.FormItem>

            <S.FormItem
              name={'confirmNewPassword'}
              label={t(R.strings.confirm_new_password)}
              rules={rules.confirmNewPassword}
              required
            >
              <S.FormInputPassword
                placeholder={t(R.strings.enter_confirm_new_password)}
              />
            </S.FormItem>
          </BaseSpace>
        </BaseForm>
      }
    />
  )
}
