// Authentication
export const AUTH_PATH = '/auth'
export const LOGOUT_PATH = '/logout'
export const LOGIN_PATH = 'login'
export const AUTH_LOGIN_PATH = '/auth/login'

// Main
export const DASHBOARD_PATH = '/'
export const EVENT_MANAGEMENT_PATH = '/event-management'
export const DETAIL_EVENT_PATH = '/event-management/event-detail'
export const CREATE_EVENT_PATH = '/event-management/create-event'
export const EDIT_EVENT_PATH = '/event-management/update-event'
export const CHECK_IN_ACTION_PATH = '/check-in-action/:id'
export const CHECK_IN_ACTION_PATH_WITHOUT_ID = '/check-in-action'

export const EVENT_MANAGEMENT_BY_UNIT_PATH = '/event-management-by-unit'
export const DETAIL_EVENT_BY_UNIT_PATH =
  '/event-management-by-unit/event-detail'

// old path
export const LIST_UNIT_PATH = '/danhsachdonvi'
export const REGISTERED_MEMBERS_PATH = '/doanviendangky'
export const LIST_MEMBERS_PATH = '/danh-sach-doan-vien'
export const UNIT_DETAILS_PATH = '/danhsachdonvi/:donviId'
export const MEMBER_DETAILS_PATH_2 = '/danh-sach-doan-vien/chi-tiet-doan-vien'
export const LIST_TRADE_UNION_MEMBERS_PATH = '/danhsachcongdoanvien'
export const EVENT_INFO_PATH = '/thongtinsukien'
export const PERSONAL_INFO_PATH = '/thongtincanhan'
