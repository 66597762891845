import {
  IParamsGetActionByEvent,
  IParamsGetActionRegisteredByMember,
  IParamsGetEvents,
  IParamsGetMemberByAction,
} from 'app/api/event/model'

export const EventKeys = {
  allEvents: ['AllEvents'] as const,
  listEvents: () => [...EventKeys.allEvents, 'listEvents'] as const,
  listEvent: (filter?: IParamsGetEvents) =>
    [...EventKeys.listEvents(), { filter }] as const,

  allEventStatus: ['AllEventStatus'] as const,
  listEventStatus: (filter?: IParamsGetEvents) =>
    [EventKeys.allEventStatus, { filter }] as const,

  allTradeUnionMembers: ['TradeUnionMembers'] as const,
  listTradeUnionMembers: () =>
    [...EventKeys.allTradeUnionMembers, 'listTradeUnionMembers'] as const,
  listTradeUnionMember: (filter?: IParamsGetEvents) =>
    [...EventKeys.listTradeUnionMembers(), { filter }] as const,

  allActionsByEvent: ['ActionsByEvent'] as const,
  listActionsByEvent: () =>
    [...EventKeys.allActionsByEvent, 'listActionsByEvent'] as const,
  listActionEvent: (filter?: IParamsGetActionByEvent) =>
    [...EventKeys.listActionsByEvent(), { filter }] as const,

  allDetailEvent: ['DetailEvent'] as const,
  infoDetailEvent: (id?: string) =>
    [...EventKeys.allDetailEvent, 'infoDetailEvent', id] as const,

  allActionsNotJoinByEvent: ['ActionsByEvent'] as const,
  listActionsNotJoinByEvent: () =>
    [
      ...EventKeys.allActionsNotJoinByEvent,
      'listActionsNotJoinByEvent',
    ] as const,
  listActionNotJoinEvent: (filter?: IParamsGetActionByEvent) =>
    [...EventKeys.listActionsNotJoinByEvent(), { filter }] as const,

  allListMemberOfReward: ['ListMemberOfReward'] as const,
  listListMemberOfReward: () =>
    [...EventKeys.allListMemberOfReward, 'listListMemberOfReward'] as const,
  listListMemberOfRewards: (filter?: IParamsGetActionByEvent) =>
    [...EventKeys.listListMemberOfReward(), { filter }] as const,

  allListActionNotJoinByMember: ['ListActionNotJoinByMember'] as const,
  listListActionNotJoinByMember: () =>
    [
      ...EventKeys.allListActionNotJoinByMember,
      'listListActionNotJoinByMember',
    ] as const,
  listListActionNotJoinByMembers: (id?: string) =>
    [...EventKeys.listListActionNotJoinByMember(), { id }] as const,

  allListActionRegisteredByMember: ['ListActionRegisteredByMember'] as const,
  listListActionRegisteredByMember: () =>
    [
      ...EventKeys.allListActionRegisteredByMember,
      'listListActionRegisteredByMember',
    ] as const,
  listListActionRegisteredByMembers: (
    filter?: IParamsGetActionRegisteredByMember,
  ) => [...EventKeys.listListActionRegisteredByMember(), { filter }] as const,

  allMemberRegisterByAction: ['MemberRegisterByAction'] as const,
  listMemberRegisterByAction: () =>
    [
      ...EventKeys.allMemberRegisterByAction,
      'listMemberRegisterByAction',
    ] as const,
  listMemberRegisterByActions: (
    filter?: IParamsGetMemberByAction,
  ) => [...EventKeys.listMemberRegisterByAction(), { filter }] as const,
}
