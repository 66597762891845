/* eslint-disable @typescript-eslint/no-explicit-any */
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { useMounted } from 'app/hook'
import {
  Pagination,
  formatMoney,
  initialPagination,
  normalizePhone,
} from 'parkway-web-common'
import { DefaultRecordType, Key } from 'rc-table/lib/interface'
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { IBaseTableCommon, IDataBaseTable } from '../common-table/model'
import { ActionsDropdown } from './ActionDropdown'
import * as S from './styles'
interface Props {
  getDataTable: (
    pagination: Pagination,
    params?: any,
  ) => Promise<IDataBaseTable<any>>
}

export const useHookTableManagement = ({ getDataTable }: Props) => {
  const [tableData, setTableData] = useState<{
    data: IBaseTableCommon[]
    pagination: Pagination
    loading: boolean
  }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  })
  const [selectRowsTable, setSelectRowsTable] = useState<{
    selectedRowKeys: Key[]
    selectedRows: DefaultRecordType[]
  }>({
    selectedRowKeys: [],
    selectedRows: [],
  })
  const { isMounted } = useMounted()

  const fetchLoading = (isLoading: boolean) => {
    setTableData(prev => ({
      ...prev,
      loading: isLoading,
    }))
  }

  const fetch = useCallback(
    (pagination?: Pagination, params?: any) => {
      setTableData(tableData => {
        return { ...tableData, loading: true }
      })
      getDataTable(pagination ?? initialPagination, params).then(res => {
        if (isMounted.current) {
          setTableData({
            data: res?.data,
            pagination: res?.pagination,
            loading: false,
          })
        }
      })
    },
    [isMounted],
  )

  useEffect(() => {
    fetch(initialPagination)
  }, [fetch])

  const handleTableChange = (pagination: Pagination, params?: any) => {
    fetch(pagination, params)
  }

  const rowSelection = {
    onChange: (selectedRowKeys: Key[], selectedRows: DefaultRecordType[]) => {
      setSelectRowsTable({ selectedRowKeys, selectedRows })
    },
  }

  return {
    tableData,
    handleTableChange,
    fetch,
    rowSelection,
    selectRowsTable,
    fetchLoading,
  }
}

export enum ValueEnum {
  Number = 'Number',
  Money = 'Money',
  Status = 'Status',
  Main = 'Main',
  Phone = 'Phone',
  Base = 'Base',
  ThreeDot = 'ThreeDot',
  Link = 'Link',
}

export enum StatusTableEnum {
  active = 'active',
  deleted = 'deleted',
  error = 'error',
  inactive = 'inactive',
}

export const StatusEnumNumber = {
  active: 1,
  deleted: 4,
  error: 3,
  inactive: 2,
}

export type StatusTableType = keyof typeof StatusTableEnum

export const RenderValueTable = ({
  type,
  value = '',
  prefix,
  actionComponent,
  iconLeft,
  onClick,
  showStatusWrapper = true,
  isNotShowThreeDot = false,
  style,
  styleText,
}: {
  type: keyof typeof ValueEnum
  value?: string | number | StatusTableType
  prefix?: string
  actionComponent?: ReactNode
  iconLeft?: ReactNode
  onClick?: () => void
  showStatusWrapper?: boolean
  isNotShowThreeDot?: boolean
  style?: React.CSSProperties
  styleText?: React.CSSProperties
}) => {
  const { t } = useTranslation()

  const renderStatus = useMemo(() => {
    switch (value) {
      case 'active': {
        return t(R.strings.active)
      }
      case 'deleted': {
        return t(R.strings.deleted)
      }
      case 'error': {
        return t(R.strings.error)
      }
      case 'inactive': {
        return t(R.strings.inactive)
      }
      default:
        return t(R.strings.error)
    }
  }, [value, t])

  switch (type) {
    case 'Main':
      return (
        <S.ValueTableWrapper style={style}>
          <S.TextMain style={styleText}>{value}</S.TextMain>
        </S.ValueTableWrapper>
      )
    case 'Link':
      return (
        <S.ValueTableWrapper style={style}>
          <S.TextLink style={styleText} onClick={onClick}>
            {value}
          </S.TextLink>
        </S.ValueTableWrapper>
      )
    case 'Money':
      return (
        <S.ValueTableWrapper style={style}>
          <S.BaseText style={styleText}>
            {formatMoney(value, prefix)}
          </S.BaseText>
        </S.ValueTableWrapper>
      )
    case 'Number':
      return (
        <S.ValueTableWrapper style={style}>
          <S.BaseText style={styleText}>{formatMoney(value, '')}</S.BaseText>
        </S.ValueTableWrapper>
      )

    case 'Status':
      return (
        <S.ValueTableWrapper
          justify={'end'}
          align={'middle'}
          gutter={[10, 10]}
          wrap={false}
          style={style}
        >
          {showStatusWrapper && (
            <BaseCol>
              <S.StatusWrapper status={value}>{renderStatus}</S.StatusWrapper>
            </BaseCol>
          )}
          {!isNotShowThreeDot ? (
            <BaseCol>
              <ActionsDropdown actionComponent={actionComponent} />
            </BaseCol>
          ) : null}
        </S.ValueTableWrapper>
      )

    case 'Phone':
      return (
        <S.ValueTableWrapper onClick={onClick && onClick}>
          <S.BaseText>
            {iconLeft && <S.BaseIcon>{iconLeft}</S.BaseIcon>}
            {normalizePhone(`${value}`)}
          </S.BaseText>
        </S.ValueTableWrapper>
      )

    case 'Base':
      return (
        <S.ValueTableWrapper>
          <S.BaseText>{value}</S.BaseText>
        </S.ValueTableWrapper>
      )

    case 'ThreeDot':
      return (
        <S.ValueTableWrapper>
          <ActionsDropdown actionComponent={actionComponent} />
        </S.ValueTableWrapper>
      )
  }
}
