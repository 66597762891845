import { TypeEventEnum } from 'app/api/event/model'
import { SvgDeleteDesignIcon } from 'app/assets/svg-assets'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseCollapse } from 'app/components/common/BaseCollapse'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { SectionBase } from 'app/components/common/SectionBase'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { isEmpty, isEqual } from 'lodash'
import { BORDER_RADIUS, PADDING } from 'parkway-web-common'
import { useMemo } from 'react'
import styled from 'styled-components'
import { useCreateEventContext } from '../../context'
import {
  ModalAddActivity,
  ModalAddEditReward,
  ModalEditActivity,
  ModalSelectTum,
} from '../../modals'
import { ColumnTumActivity, RewardLayout, TumTable } from './layouts'

export const ActionEvent = () => {
  const {
    activities,
    removeActivityByIndex,
    updateTitleInActiveByIndex,
    updateDataTumInActivityByIndex,
    eventType,
    updateValueCareInActiveByIndex,
  } = useCreateEventContext()

  const textMemberDisplay = useMemo(() => {
    switch (eventType) {
      case TypeEventEnum.CARE:
        return 'Danh sách đối tượng được chăm lo'
      default:
        return 'Danh sách đối tượng tham gia'
    }
  }, [eventType])

  const titleDisplay = useMemo(() => {
    switch (eventType) {
      case TypeEventEnum.CARE:
        return 'Danh sách hoạt động Chăm lo'
      case TypeEventEnum.TRAINING:
        return 'Danh sách Hội thảo, Tập huấn'
      case TypeEventEnum.ACTIVITY:
        return 'Danh sách hoạt động Thể thao - Văn hóa'
      default:
        return 'Danh sách hoạt động'
    }
  }, [eventType])

  return (
    <RootWrapper>
      <BaseRow gutter={[16, 8]} align={'middle'} justify={'space-between'}>
        <BaseCol>
          <BaseText children={titleDisplay} fontWeight="medium" fontSize="xs" />
        </BaseCol>

        <BaseCol>
          <ModalAddActivity />
        </BaseCol>
      </BaseRow>

      <ContentWrapper>
        {activities?.length ? (
          activities?.map((item, index) => {
            return (
              <SectionBase key={index}>
                <BaseCollapse
                  items={[
                    {
                      key: index,
                      label: (
                        <BaseText
                          children={
                            isEmpty(item?.title)
                              ? 'Chưa có tên hoạt động'
                              : item?.title
                          }
                          fontWeight="semibold"
                        />
                      ),
                      children: (
                        <BaseSpace>
                          <BaseFormItem label={'Tên hoạt động'} required>
                            <BaseInput
                              value={item?.title}
                              placeholder="Nhập tên hoạt động"
                              onChange={e =>
                                updateTitleInActiveByIndex?.(
                                  index,
                                  e.target.value,
                                )
                              }
                            />
                          </BaseFormItem>

                          {isEqual(eventType, TypeEventEnum.CARE) ? (
                            <BaseFormItem label={'Phần quà chăm lo'} required>
                              <BaseInput
                                value={item?.valueCare}
                                placeholder="Nhập phần quà"
                                onChange={e =>
                                  updateValueCareInActiveByIndex?.(
                                    index,
                                    e.target.value,
                                  )
                                }
                              />
                            </BaseFormItem>
                          ) : null}

                          <BaseRow
                            align={'middle'}
                            justify={'space-between'}
                            gutter={[16, 8]}
                          >
                            <BaseCol>
                              <BaseText
                                children={textMemberDisplay}
                                fontSize="xs"
                                fontWeight="medium"
                              />
                            </BaseCol>
                            <BaseCol>
                              <ModalSelectTum
                                data={item?.data}
                                onSubmit={data =>
                                  updateDataTumInActivityByIndex?.(index, data)
                                }
                              />
                            </BaseCol>
                          </BaseRow>

                          {(() => {
                            switch (eventType) {
                              case TypeEventEnum.ACTIVITY:
                                return (
                                  <>
                                    <ColumnTumActivity
                                      index={index}
                                      activity={item}
                                    />
                                    <BaseRow
                                      gutter={[16, 8]}
                                      align={'middle'}
                                      justify={'space-between'}
                                    >
                                      <BaseCol>
                                        <BaseText
                                          children={'Danh sách giải thưởng'}
                                          fontWeight="medium"
                                          fontSize="xs"
                                        />
                                      </BaseCol>

                                      <BaseCol>
                                        <ModalAddEditReward index={index} />
                                      </BaseCol>
                                    </BaseRow>

                                    <RewardLayout
                                      activity={item}
                                      index={index}
                                    />
                                  </>
                                )
                              case TypeEventEnum.CARE:
                              case TypeEventEnum.TRAINING:
                                return <TumTable activity={item} />
                            }
                          })()}
                        </BaseSpace>
                      ),
                      extra: (
                        <div onClick={e => e?.stopPropagation()}>
                          <BaseRow align={'middle'} gutter={8}>
                            <BaseCol>
                              <ModalEditActivity
                                activityIndex={index}
                                activity={item}
                              />
                            </BaseCol>
                            <BaseCol>
                              <BaseButton
                                icon={<SvgDeleteDesignIcon />}
                                onClick={() => removeActivityByIndex?.(index)}
                                type="text"
                              />
                            </BaseCol>
                          </BaseRow>
                        </div>
                      ),
                      style: {
                        alignItems: 'center',
                      },
                    },
                  ]}
                />
              </SectionBase>
            )
          })
        ) : (
          <BaseText children={'Danh sách trống'} fontWeight="medium" />
        )}
      </ContentWrapper>
    </RootWrapper>
  )
}

const ContentWrapper = styled(BaseSpace)`
  background-color: ${convertedVariables.neutralBlack9Color};
  padding: ${PADDING.md};
  border-radius: ${BORDER_RADIUS};
`

const BaseFormItem = styled(BaseForm.Item)`
  margin-bottom: 0px;
`

const RootWrapper = styled(BaseSpace)``
