import { ExportOutlined } from '@ant-design/icons'
import { Button, Modal } from 'antd'
import { ENV_CONFIG } from 'app/common/config'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import HeaderPage from 'app/components/header/components/HeaderPage'
import {
  DefineJobTitleEnum,
  DoanVien,
  JobTitleEnum,
} from 'app/containers/TumManagement/type'
import { DonVi } from 'app/containers/UnitManagement'
import {
  useCanDeleteDonVi,
  useGetDonVi,
} from 'app/react-query/hooks-old/useDonVi'
import { useGetDoanVienByUnitName } from 'app/react-query/hooks-old/useGetUser'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import axios from 'axios'
import { isEmpty } from 'lodash'
import { BORDER_RADIUS, PADDING } from 'parkway-web-common'
import React, { useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useQueryClient } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import {
  HistoryUnitLayout,
  TradeUnionMemberLayout,
  UpdateUnitModal,
} from './layouts'

type ButtonConfirmProps = {
  onClick: () => void
  disabled?: boolean
}
type ButtonCancelProps = {
  onClick: () => void
}

const ModalConfirmButton = ({ onClick, disabled }: ButtonConfirmProps) => {
  return (
    <Button
      key="submit"
      disabled={disabled}
      type="default"
      onClick={onClick}
      className="border-sky-500 text-sky-500 hover:text-white hover:bg-sky-500 transition ease-in-out"
    >
      Xác Nhận
    </Button>
  )
}
const ModalCancelButton = ({ onClick }: ButtonCancelProps) => {
  return (
    <Button
      key="submit"
      type="default"
      danger={true}
      ghost={false}
      onClick={onClick}
      className=" border-red-500 text-red-500 hover:text-white hover:bg-red-500 transition ease-in-out"
    >
      Hủy Bỏ
    </Button>
  )
}

export const DetailUnitContainer: React.FC = () => {
  const navigate = useNavigate()
  const query = useQueryClient()

  const locationData = useLocation()

  const donviId = useMemo(() => {
    return locationData?.state?.donviId
  }, [locationData])

  useEffect(() => {
    if (!donviId) {
      navigate(-1)
    }
  }, [donviId])

  const { data: donviApi, isLoading: isLoadingDonvi } = useGetDonVi(
    donviId ?? '',
  )

  const canDelete = useCanDeleteDonVi(donviId ?? '')

  const donviDetail: DonVi = useMemo(() => {
    return donviApi
  }, [donviApi])
  const [reconfirmDelete, setReconfirmDelete] = useState(false)

  const {
    data: doanvienData,
    isLoading,
    refetch: refetchDoanVien,
  } = useGetDoanVienByUnitName(donviDetail?.ten)

  async function deleteDonVi() {
    try {
      await axios.delete(`${ENV_CONFIG.API_ENDPOINT}/api/v1/donvi/${donviId}`)
      toast.success('Xóa Đơn Vị Thành Công')
      navigate('/danhsachdonvi')
    } catch (error) {
      toast.error('Đã Có Lỗi Xảy Ra! Hãy Thử Lại')
      console.log(error)
    }
  }

  const managementData = useMemo(() => {
    const PRESIDENT_OF_CSTV_TRADE_UNION: DoanVien[] = doanvienData?.filter((dv: DoanVien) => {
      return dv?.chucdanh
        ?.split('&')
        ?.includes(JobTitleEnum.PRESIDENT_OF_CSTV_TRADE_UNION)
    })

    const VICE_PRESIDENT_OF_CSTV_TRADE_UNION: DoanVien[] = doanvienData?.filter(
      (dv: DoanVien) => {
        return dv?.chucdanh
          ?.split('&')
          ?.includes(JobTitleEnum.VICE_PRESIDENT_OF_CSTV_TRADE_UNION)
      },
    )

    const MEMBER_OF_EXECUTIVE_BOARD_OF_CSTV_TRADE_UNION: DoanVien[] = doanvienData?.filter(
      (dv: DoanVien) => {
        return dv?.chucdanh
          ?.split('&')
          ?.includes(JobTitleEnum.MEMBER_OF_EXECUTIVE_BOARD_OF_CSTV_TRADE_UNION)
      },
    )

    const EXECUTIVE_COMMITTEE_MEMBER_OF_CSTV_TRADE_UNION: DoanVien[] = doanvienData?.filter(
      (dv: DoanVien) => {
        return dv?.chucdanh
          ?.split('&')
          ?.includes(
            JobTitleEnum.EXECUTIVE_COMMITTEE_MEMBER_OF_CSTV_TRADE_UNION,
          )
      },
    )

    return {
      PRESIDENT_OF_CSTV_TRADE_UNION,
      VICE_PRESIDENT_OF_CSTV_TRADE_UNION,
      MEMBER_OF_EXECUTIVE_BOARD_OF_CSTV_TRADE_UNION,
      EXECUTIVE_COMMITTEE_MEMBER_OF_CSTV_TRADE_UNION,
    }
  }, [doanvienData])

  const dataTable = useMemo(() => {
    return doanvienData?.map((dv: DoanVien, index: number) => {
      return {
        index: index + 1,
        key: dv?.maso_doanvien,
        ...dv,
      }
    })
  }, [doanvienData])

  const _refetchAll = () => {
    refetchDoanVien?.()

    query.invalidateQueries('doanvienbyunitname')
    query.invalidateQueries('donvi')

    window?.location.reload()
  }

  return (
    <>
      {isLoadingDonvi ? (
        <></>
      ) : (
        <BaseSpace>
          <HeaderPage
            title={`Chi tiết đơn vị "${donviDetail?.ten}"`}
            rightComponent={
              <div className="flex gap-4">
                <Button
                  onClick={() => setReconfirmDelete(true)}
                  danger
                  className="hover:bg-red-500 hover:text-white hover:border hover:border-gray-400"
                >
                  Xóa đơn vị
                </Button>
                <UpdateUnitModal
                  donviDetail={donviDetail}
                  refetch={_refetchAll}
                />
              </div>
            }
          />
          <BaseForm>
            <BaseSpace>
              <ContentWrapper>
                <BaseText
                  children={'Thông tin cơ bản'}
                  fontSize="xs"
                  fontWeight="semibold"
                />

                <BaseRow align={'top'} gutter={[16, 8]} justify={'start'}>
                  <BaseCol xl={8}>
                    <BaseSpace size={8}>
                      <BaseText
                        children={'Tên'}
                        fontWeight="medium"
                        fontSize="xs"
                        opacity="0.7"
                      />
                      <BaseText
                        children={donviDetail?.ten ?? ''}
                        fontWeight="semibold"
                        fontSize="xs"
                      />
                    </BaseSpace>
                  </BaseCol>
                  <BaseCol xl={8}>
                    <BaseSpace size={8}>
                      <BaseText
                        children={'Địa chỉ'}
                        fontWeight="medium"
                        fontSize="xs"
                        opacity="0.7"
                      />
                      <BaseText
                        children={donviDetail?.diachi ?? ''}
                        fontWeight="semibold"
                        fontSize="xs"
                      />
                    </BaseSpace>
                  </BaseCol>

                  <BaseCol xl={8}>
                    <BaseSpace size={8}>
                      <BaseText
                        children={'Ngày thành lập'}
                        fontWeight="medium"
                        fontSize="xs"
                        opacity="0.7"
                      />
                      <BaseText
                        children={donviDetail?.ngaythanhlap ?? ''}
                        fontWeight="semibold"
                        fontSize="xs"
                      />
                    </BaseSpace>
                  </BaseCol>
                </BaseRow>
                <BaseRow gutter={[16, 8]} align={'top'}>
                  <BaseCol xl={8}>
                    <BaseSpace size={8}>
                      <BaseText
                        children={
                          DefineJobTitleEnum[
                            JobTitleEnum.PRESIDENT_OF_CSTV_TRADE_UNION
                          ]
                        }
                        fontWeight="medium"
                        fontSize="xs"
                        opacity="0.7"
                      />
                      <BaseRow gutter={[16, 8]} align={'middle'}>
                        {managementData?.PRESIDENT_OF_CSTV_TRADE_UNION?.length ? (
                          managementData?.PRESIDENT_OF_CSTV_TRADE_UNION?.map(
                            (dv: DoanVien, index: number) => (
                              <>
                                <BaseCol key={index}>
                                  <BaseText
                                    children={dv?.hovaten ?? '-'}
                                    fontWeight="semibold"
                                    fontSize="xs"
                                  />
                                </BaseCol>

                                {index < managementData?.PRESIDENT_OF_CSTV_TRADE_UNION.length - 1 ? (
                                  <BaseCol>{'|'}</BaseCol>
                                ) : null}
                              </>
                            ),
                          )
                        ) : (
                          <BaseCol>
                            <BaseText
                              children={'-'}
                              fontWeight="semibold"
                              fontSize="xs"
                            />
                          </BaseCol>
                        )}
                      </BaseRow>
                    </BaseSpace>
                  </BaseCol>
                  <BaseCol xl={8}>
                    <BaseSpace size={8}>
                      <BaseText
                        children={
                          DefineJobTitleEnum[
                            JobTitleEnum.VICE_PRESIDENT_OF_CSTV_TRADE_UNION
                          ]
                        }
                        fontWeight="medium"
                        fontSize="xs"
                        opacity="0.7"
                      />
                      <BaseRow gutter={[16, 8]} align={'middle'}>
                        {managementData?.VICE_PRESIDENT_OF_CSTV_TRADE_UNION?.length ? (
                          managementData?.VICE_PRESIDENT_OF_CSTV_TRADE_UNION?.map(
                            (dv: DoanVien, index: number) => (
                              <>
                                <BaseCol key={index}>
                                  <BaseText
                                    children={dv?.hovaten ?? '-'}
                                    fontWeight="semibold"
                                    fontSize="xs"
                                  />
                                </BaseCol>

                                {index <
                                managementData?.VICE_PRESIDENT_OF_CSTV_TRADE_UNION.length - 1 ? (
                                  <BaseCol>{'|'}</BaseCol>
                                ) : null}
                              </>
                            ),
                          )
                        ) : (
                          <BaseCol>
                            <BaseText
                              children={'-'}
                              fontWeight="semibold"
                              fontSize="xs"
                            />
                          </BaseCol>
                        )}
                      </BaseRow>
                    </BaseSpace>
                  </BaseCol>
                  <BaseCol xl={8}>
                    <BaseSpace size={8}>
                      <BaseText
                        children={
                          DefineJobTitleEnum[
                            JobTitleEnum
                              .MEMBER_OF_EXECUTIVE_BOARD_OF_CSTV_TRADE_UNION
                          ]
                        }
                        fontWeight="medium"
                        fontSize="xs"
                        opacity="0.7"
                      />
                      <BaseRow gutter={[16, 8]} align={'middle'}>
                        {managementData?.MEMBER_OF_EXECUTIVE_BOARD_OF_CSTV_TRADE_UNION?.length ? (
                          managementData?.MEMBER_OF_EXECUTIVE_BOARD_OF_CSTV_TRADE_UNION?.map(
                            (dv: DoanVien, index: number) => (
                              <>
                                <BaseCol key={index}>
                                  <BaseText
                                    children={dv?.hovaten ?? '-'}
                                    fontWeight="semibold"
                                    fontSize="xs"
                                  />
                                </BaseCol>

                                {index < managementData?.MEMBER_OF_EXECUTIVE_BOARD_OF_CSTV_TRADE_UNION.length - 1 ? (
                                  <BaseCol>{'|'}</BaseCol>
                                ) : null}
                              </>
                            ),
                          )
                        ) : (
                          <BaseCol>
                            <BaseText
                              children={'-'}
                              fontWeight="semibold"
                              fontSize="xs"
                            />
                          </BaseCol>
                        )}
                      </BaseRow>
                    </BaseSpace>
                  </BaseCol>
                </BaseRow>

                <BaseRow gutter={[16, 8]} align={'top'}>
                  <BaseCol xl={8}>
                    <BaseSpace size={8}>
                      <BaseText
                        children={
                          DefineJobTitleEnum[
                            JobTitleEnum
                              .EXECUTIVE_COMMITTEE_MEMBER_OF_CSTV_TRADE_UNION
                          ]
                        }
                        fontWeight="medium"
                        fontSize="xs"
                        opacity="0.7"
                      />
                      <BaseRow gutter={[16, 8]} align={'middle'}>
                        {managementData?.EXECUTIVE_COMMITTEE_MEMBER_OF_CSTV_TRADE_UNION?.length ? (
                          managementData?.EXECUTIVE_COMMITTEE_MEMBER_OF_CSTV_TRADE_UNION?.map(
                            (dv: DoanVien, index: number) => (
                              <>
                                <BaseCol key={index}>
                                  <BaseText
                                    children={dv?.hovaten ?? '-'}
                                    fontWeight="semibold"
                                    fontSize="xs"
                                  />
                                </BaseCol>

                                {index <
                                managementData?.EXECUTIVE_COMMITTEE_MEMBER_OF_CSTV_TRADE_UNION.length - 1 ? (
                                  <BaseCol>{'|'}</BaseCol>
                                ) : null}
                              </>
                            ),
                          )
                        ) : (
                          <BaseCol>
                            <BaseText
                              children={'-'}
                              fontWeight="semibold"
                              fontSize="xs"
                            />
                          </BaseCol>
                        )}
                      </BaseRow>
                    </BaseSpace>
                  </BaseCol>
                </BaseRow>

                <BaseRow align={'middle'}>
                  <BaseCol xl={24}>
                    <BaseSpace size={8}>
                      <BaseText
                        children={'Thông tin đơn vị'}
                        fontWeight="medium"
                        fontSize="xs"
                        opacity="0.7"
                      />
                      <BaseText
                        children={
                          !isEmpty(donviDetail?.thongtindonvi)
                            ? donviDetail?.thongtindonvi
                            : '-'
                        }
                        fontWeight="semibold"
                        fontSize="xs"
                      />
                    </BaseSpace>
                  </BaseCol>
                </BaseRow>
              </ContentWrapper>

              <ContentWrapper>
                <BaseRow
                  gutter={[16, 16]}
                  align={'middle'}
                  justify={'space-between'}
                >
                  <BaseCol>
                    <BaseText
                      children={'Danh sách công đoàn viên trực thuộc'}
                      fontWeight="semibold"
                      fontSize="xs"
                    />
                  </BaseCol>
                  <BaseCol>
                    <BaseButton icon={<ExportOutlined rev={undefined} />}>
                      {'Export excel'}
                    </BaseButton>
                  </BaseCol>
                </BaseRow>
                <TradeUnionMemberLayout
                  dataTable={dataTable}
                  isLoadingDoanVien={isLoading}
                  refetch={_refetchAll}
                />
              </ContentWrapper>

              <ContentWrapper size={20}>
                <BaseText
                  children={'Lịch sử đơn vị'}
                  fontWeight="semibold"
                  fontSize="xs"
                />
                <HistoryUnitLayout id={donviId} />
              </ContentWrapper>
            </BaseSpace>
          </BaseForm>

          <Modal
            title={
              canDelete.data
                ? 'Không Thể Xóa Đơn Vị Này'
                : 'Bạn Có Muốn Xóa Đơn Vị Này?'
            }
            onCancel={() => setReconfirmDelete(false)}
            open={reconfirmDelete}
            footer={[
              <ModalCancelButton onClick={() => setReconfirmDelete(false)} />,
              <ModalConfirmButton
                disabled={canDelete?.data}
                onClick={deleteDonVi}
              />,
            ]}
          >
            {canDelete.data ? (
              <p>Hãy chuyển tất cả đoàn viên sang đơn vị khác trước khi xóa </p>
            ) : (
              <p>
                Danh sách đoàn viên đang ở với đơn vị này sẽ không tồn mất đơn
                vị, bạn cần chuyển đơn vị cho danh sách đoàn viên
              </p>
            )}
          </Modal>
        </BaseSpace>
      )}
    </>
  )
}

const ContentWrapper = styled(BaseSpace)`
  padding: ${PADDING.md};
  border-radius: ${BORDER_RADIUS};
  background-color: ${convertedVariables.backgroundColor};
`
