import { IActionEvent, IEvent } from 'app/api/event/model'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseEmpty } from 'app/components/common/BaseEmpty'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { Loading } from 'app/components/common/Loading'
import { SectionBase } from 'app/components/common/SectionBase'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { BORDER_RADIUS } from 'parkway-web-common'
import { useMemo } from 'react'
import styled from 'styled-components'

interface IData extends IActionEvent {
  key: number
  event?: IEvent
}

export const MyActionLayout = ({
  listAction,
  isLoading,
}: {
  listAction: IActionEvent[]
  isLoading?: boolean
}) => {
  const data: IData[] = useMemo(() => {
    return listAction?.map((item, index) => {
      return {
        ...item,
        key: index + 1,
      }
    })
  }, [listAction])

  return (
    <SectionBase title="Danh sách hoạt động đã tham gia">
      <BaseSpace size={8}>
        <HeaderWrapper align={'middle'} justify={'space-between'}>
          <BaseCol xl={2}>
            <BaseText children={'STT'} fontWeight="semibold" opacity="0.7" />
          </BaseCol>
          <BaseCol xl={18}>
            <BaseText
              children={'Tên hoạt động'}
              fontWeight="semibold"
              opacity="0.7"
            />
          </BaseCol>
          <BaseCol xl={4}>
            <BaseText
              children={'Sự kiện'}
              fontWeight="semibold"
              opacity="0.7"
            />
          </BaseCol>
        </HeaderWrapper>
        <ContentWrapper size={4}>
          {data?.length ? (
            data?.map((item, index) => {
              return (
                <ItemWrapper
                  key={index}
                  align={'middle'}
                  justify={'space-between'}
                >
                  <BaseCol xl={2}>
                    <BaseText children={item?.key} fontWeight="semibold" />
                  </BaseCol>
                  <BaseCol xl={18}>
                    <BaseSpace size={4}>
                      <BaseText
                        children={item?.name}
                        fontWeight="semibold"
                        fontSize="xs"
                      />
                    </BaseSpace>
                  </BaseCol>
                  <BaseCol xl={4}>
                    <BaseSpace size={4}>
                      <BaseText
                        children={item?.event?.name ?? ''}
                        fontWeight="semibold"
                        fontSize="xs"
                      />
                    </BaseSpace>
                  </BaseCol>
                </ItemWrapper>
              )
            })
          ) : (
            <BaseEmpty />
          )}
        </ContentWrapper>
      </BaseSpace>
      {isLoading ? <Loading isAbsolute size="small" /> : null}
    </SectionBase>
  )
}

const HeaderWrapper = styled(BaseRow)`
  width: 100%;
  padding: ${BORDER_RADIUS};
  background-color: ${convertedVariables.backgroundColor};
`

const ItemWrapper = styled(BaseRow)`
  width: 100%;
  padding: ${BORDER_RADIUS};
  background-color: ${convertedVariables.backgroundColor};

  border-radius: ${BORDER_RADIUS};
  box-shadow: 0px 0.5px 2px rgba(0, 0, 0, 0.1);
`

const ContentWrapper = styled(BaseSpace)`
  height: 200px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`
