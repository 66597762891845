import {
  CalendarOutlined,
  FileOutlined,
  HomeOutlined,
  UserOutlined
} from '@ant-design/icons'
// import 'antd/dist/antd.css'
import {
  DASHBOARD_PATH,
  EVENT_MANAGEMENT_PATH,
  LIST_MEMBERS_PATH,
  LIST_UNIT_PATH,
  PERSONAL_INFO_PATH
} from 'app/components/router/route-path'
import React from 'react'
import { getItem, MenuItem, SiderCommon } from './components'

const items: MenuItem[] = [
  getItem('Trang chủ', '100', <HomeOutlined rev={undefined} />, DASHBOARD_PATH),
  getItem(
    'Quản lý sự kiện',
    2,
    <CalendarOutlined rev={undefined} />,
    EVENT_MANAGEMENT_PATH,
  ),

  getItem(
    'Quản lý công đoàn viên',
    '5',
    <UserOutlined rev={undefined} />,
    LIST_MEMBERS_PATH,
  ),
  getItem('Quản lý đơn vị', '6', <FileOutlined rev={undefined} />, LIST_UNIT_PATH),
  // getItem(
  //   'Đăng kí hoạt động/sự kiện',
  //   '9',
  //   <FlagOutlined rev={undefined} />,
  //   EVENT_INFO_PATH,
  // ),
  getItem(
    'Thông tin cá nhân',
    '3',
    <UserOutlined rev={undefined} />,
    PERSONAL_INFO_PATH,
  ),
]

const Sidebar: React.FC = () => {
  return <SiderCommon items={items} />
}

export default Sidebar
