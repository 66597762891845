/* eslint-disable @typescript-eslint/no-explicit-any */
import { RoleEnum } from 'app/common/enum'
import Layout from 'app/components/old/layout/Layout'
import AdminRoute from 'app/components/old/layout/routes/AdminRoute'
import PrivateRoute from 'app/components/old/layout/routes/PrivateRoute'
import Login from 'app/page/authentication/login'
import { DashboardPage } from 'app/page/menu/dashboard'
import { EventManagementPage } from 'app/page/menu/event'
import { CheckInActionPage } from 'app/page/menu/event/check-in-action'
import { DetailEventPage } from 'app/page/menu/event/detail'
import EventRegisterForMemberPage from 'app/page/menu/event/register-activity-event'
import { ProfilePage } from 'app/page/menu/profile'
import { TumManagementPage } from 'app/page/menu/tum'
import { DetailTumPage } from 'app/page/menu/tum/detail'
import { UnitManagementPage } from 'app/page/menu/unit'
import { DetailUnitPage } from 'app/page/menu/unit/detail'
import { useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import {
  CHECK_IN_ACTION_PATH,
  CREATE_EVENT_PATH,
  DASHBOARD_PATH,
  DETAIL_EVENT_BY_UNIT_PATH,
  DETAIL_EVENT_PATH,
  EDIT_EVENT_PATH,
  EVENT_INFO_PATH,
  EVENT_MANAGEMENT_BY_UNIT_PATH,
  EVENT_MANAGEMENT_PATH,
  LIST_MEMBERS_PATH,
  LIST_TRADE_UNION_MEMBERS_PATH,
  LIST_UNIT_PATH,
  MEMBER_DETAILS_PATH_2,
  PERSONAL_INFO_PATH,
  UNIT_DETAILS_PATH,
} from './route-path'
import { CreateEventPage } from 'app/page/menu/event/create-event'
import { UpdateEventPage } from 'app/page/menu/event/update-event'
import { TumManagementByUnitPage } from 'app/page/menu/tum/by0unit'
import { EventManagementByUnitPage } from 'app/page/menu/event/by-unit'

interface User {
  cmnd: string
  donvi: string
  email: string
  gioitinh: string
  hovaten: string
  maso_doanvien: string
  ngaysinh: string
  role?: RoleEnum
  tinh: string
  trangthai: boolean
}

export const AppRouterConverted = () => {
  const [userData, setUserData] = useState<User>({
    cmnd: '',
    donvi: '',
    email: '',
    gioitinh: '',
    hovaten: '',
    maso_doanvien: '',
    ngaysinh: '',
    tinh: '',
    trangthai: true,
  })

  const handleUserData = (data: any) => {
    setUserData(data)
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PrivateRoute handleData={handleUserData} />}>
          <Route
            path={DASHBOARD_PATH}
            element={
              <Layout role={userData.role} fullName={userData.hovaten} />
            }
          >
            <Route index element={<DashboardPage />} />
            <Route element={<AdminRoute role={userData.role} />}>
              <Route path={LIST_UNIT_PATH} element={<UnitManagementPage />} />

              <Route path={UNIT_DETAILS_PATH} element={<DetailUnitPage />} />

              <Route path={LIST_MEMBERS_PATH} element={<TumManagementPage />} />
            </Route>

            <Route path={PERSONAL_INFO_PATH} element={<ProfilePage />} />
            <Route path={MEMBER_DETAILS_PATH_2} element={<DetailTumPage />} />
            <Route
              path={EVENT_INFO_PATH}
              element={<EventRegisterForMemberPage />}
            />
            <Route
              path={EVENT_MANAGEMENT_PATH}
              element={<EventManagementPage />}
            />
            <Route path={DETAIL_EVENT_PATH} element={<DetailEventPage />} />
            <Route path={CREATE_EVENT_PATH} element={<CreateEventPage />} />
            <Route path={EDIT_EVENT_PATH} element={<UpdateEventPage />} />

            <Route
              path={LIST_TRADE_UNION_MEMBERS_PATH}
              element={<TumManagementByUnitPage />}
            />

            <Route
              path={EVENT_MANAGEMENT_BY_UNIT_PATH}
              element={<EventManagementByUnitPage />}
            />
            <Route
              path={DETAIL_EVENT_BY_UNIT_PATH}
              element={<DetailEventPage />}
            />
          </Route>
        </Route>
        <Route path="/login" element={<Login />} />

        <Route path={CHECK_IN_ACTION_PATH} element={<CheckInActionPage />} />
      </Routes>
    </BrowserRouter>
  )
}
