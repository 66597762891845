import { random } from 'lodash'
import { IHistoryUnit, INewHistoryUnit } from './type'
import { ActionTypeEnum } from 'app/containers/UnitManagement/layouts/ModalActionUnit'

export function convertToNewHistory(
  units: IHistoryUnit[] = [],
): INewHistoryUnit[] {
  const groupedUnits: { [key: string]: IHistoryUnit[] } = {}

  units.forEach(unit => {
    // unit?.numberMatchKey == null
    if (!unit?.numberMatchKey) {
      const key = `${random(0, 100000)}-${unit.status}`
      if (!groupedUnits[key]) {
        groupedUnits[key] = []
      }
      groupedUnits[key].push(unit)
    }

    if (unit?.numberMatchKey && unit?.status) {
      const key = `${unit.numberMatchKey}-${unit.status}`
      if (!groupedUnits[key]) {
        groupedUnits[key] = []
      }
      groupedUnits[key].push(unit)
    }
  })

  // console.log({ groupedUnits })

  return Object.keys(groupedUnits).map(key => ({
    status: groupedUnits[key][0].status as ActionTypeEnum,
    oldUnit: groupedUnits[key],
  }))
}
