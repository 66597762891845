import React from 'react'
import styled from 'styled-components'
import { BaseSpin } from '../BaseSpin'

interface LoadingProps {
  size?: 'small' | 'default' | 'large'
  color?: string
  isAbsolute?: boolean
}

export const Loading: React.FC<LoadingProps> = ({
  size = 'large',
  isAbsolute,
}) => {
  return (
    <SpinnerContainer $isAbsolute={isAbsolute}>
      <BaseSpin size={size} />
    </SpinnerContainer>
  )
}

const SpinnerContainer = styled.div<{ $isAbsolute?: boolean }>`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: ${props => (props?.$isAbsolute ? 'absolute' : 'unset')};
  top: 0;
`
