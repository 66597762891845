import { Timeline } from 'antd'
import { DefineRoleEnum } from 'app/common/enum'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { Loading } from 'app/components/common/Loading'
import { SectionBase } from 'app/components/common/SectionBase'
import HeaderPage from 'app/components/header/components/HeaderPage'
import { FORMAT_DD_MM_YYYY_HH_MM } from 'app/constant'
import { MyActionLayout, MyPrizeLayout } from 'app/containers/Profile/layouts'
import { useGetDashboardEmployee } from 'app/react-query/hook/dashboard'
import {
  useGetDoanvienByMSD,
  useGetDoanvienHistory,
} from 'app/react-query/hooks-old/useGetUser'
import { DateUtil } from 'parkway-web-common'
import { useLocation, useNavigate } from 'react-router-dom'
import { UpdateDoanVien } from '../layouts'
import { DefineJobTitleEnum, JobTitleEnum } from '../type'
import { useEffect, useMemo } from 'react'

export interface IDoanVienInHistory {
  maso_doanvien?: string
  hovaten?: string
  chucdanh?: string
  quequan?: string
  cmnd?: string
  ngayvao_congdoan?: string
  donvi?: {
    id?: number
    ten?: string
    diachi?: string
    ngaythanhlap?: string
    thongtindonvi?: string
    numberMatchKey?: number
    status?: string
    action?: string
    createdAt?: string
    updatedAt?: string
  }
  trangthai?: boolean
  gioitinh?: string
  ngaysinh?: string
  email?: string
  password?: string
  salt?: string
  token?: string
  role?: string
  createdAt?: string
  updatedAt?: string
}

export interface IHistoryDoanVien {
  thoigian?: string
  thong_tin_doan_vien?: IDoanVienInHistory
}

export const DetailTumContainer = () => {
  const { state } = useLocation()
  const navigate = useNavigate()

  const tumId = useMemo(() => {
    return state?.tumId
  }, [state?.tumId])

  useEffect(() => {
    if (!tumId) {
      navigate(-1)
    }
  }, [tumId])

  const { data: doanvien, isLoading, refetch } = useGetDoanvienByMSD(tumId)

  const { data: history, isLoading: isLoadingHistory } =
    useGetDoanvienHistory(tumId)

  const { data: dataDashboard, isLoading: isLoadingDashboard } =
    useGetDashboardEmployee(tumId)

  return (
    <>
      {isLoading ? (
        <></>
      ) : (
        <BaseSpace>
          <HeaderPage
            title="Chi tiết đoàn viên"
            rightComponent={
              <UpdateDoanVien
                isHaveBorder
                doanVien={doanvien}
                titleOpenModal={'Cập nhật đoàn viên'}
                refetchOther={refetch}
              />
            }
          />

          <BaseSpace>
            <SectionBase title="Thông tin công đoàn viên">
              <BaseSpace>
                <BaseRow align={'top'} gutter={[16, 16]} justify={'start'}>
                  <BaseCol xl={6}>
                    <BaseSpace size={8}>
                      <BaseText
                        children={'Họ và tên'}
                        fontWeight="medium"
                        fontSize="xs"
                        opacity="0.7"
                      />
                      <BaseText
                        children={doanvien?.hovaten ?? '-'}
                        fontWeight="semibold"
                        fontSize="xs"
                      />
                    </BaseSpace>
                  </BaseCol>

                  <BaseCol xl={6}>
                    <BaseSpace size={8}>
                      <BaseText
                        children={'Ngày sinh'}
                        fontWeight="medium"
                        fontSize="xs"
                        opacity="0.7"
                      />
                      <BaseText
                        children={doanvien?.ngaysinh}
                        fontWeight="semibold"
                        fontSize="xs"
                      />
                    </BaseSpace>
                  </BaseCol>

                  <BaseCol xl={6}>
                    <BaseSpace size={8}>
                      <BaseText
                        children={'Giới tính'}
                        fontWeight="medium"
                        fontSize="xs"
                        opacity="0.7"
                      />
                      <BaseText
                        children={doanvien?.gioitinh ?? '-'}
                        fontWeight="semibold"
                        fontSize="xs"
                      />
                    </BaseSpace>
                  </BaseCol>

                  <BaseCol xl={6}>
                    <BaseSpace size={8}>
                      <BaseText
                        children={'Quê quán'}
                        fontWeight="medium"
                        fontSize="xs"
                        opacity="0.7"
                      />
                      <BaseText
                        children={doanvien?.quequan}
                        fontWeight="semibold"
                        fontSize="xs"
                      />
                    </BaseSpace>
                  </BaseCol>

                  <BaseCol xl={6}>
                    <BaseSpace size={8}>
                      <BaseText
                        children={'Email'}
                        fontWeight="medium"
                        fontSize="xs"
                        opacity="0.7"
                      />
                      <BaseText
                        children={doanvien?.email ?? '-'}
                        fontWeight="semibold"
                        fontSize="xs"
                      />
                    </BaseSpace>
                  </BaseCol>

                  <BaseCol xl={6}>
                    <BaseSpace size={8}>
                      <BaseText
                        children={'CMND/CCCD'}
                        fontWeight="medium"
                        fontSize="xs"
                        opacity="0.7"
                      />
                      <BaseText
                        children={doanvien?.cmnd ?? '-'}
                        fontWeight="semibold"
                        fontSize="xs"
                      />
                    </BaseSpace>
                  </BaseCol>

                  <BaseCol xl={6}>
                    <BaseSpace size={8}>
                      <BaseText
                        children={'Ngày vào công đoàn'}
                        fontWeight="medium"
                        fontSize="xs"
                        opacity="0.7"
                      />
                      <BaseText
                        children={doanvien?.ngayvao_congdoan ?? '-'}
                        fontWeight="semibold"
                        fontSize="xs"
                      />
                    </BaseSpace>
                  </BaseCol>

                  <BaseCol xl={6}>
                    <BaseSpace size={8}>
                      <BaseText
                        children={'Quyền hệ thống'}
                        fontWeight="medium"
                        fontSize="xs"
                        opacity="0.7"
                      />
                      <BaseText
                        children={
                          doanvien?.role ? DefineRoleEnum[doanvien?.role] : '-'
                        }
                        fontWeight="semibold"
                        fontSize="xs"
                      />
                    </BaseSpace>
                  </BaseCol>

                  <BaseCol xl={6}>
                    <BaseSpace size={8}>
                      <BaseText
                        children={'Chức vụ công đoàn'}
                        fontWeight="medium"
                        fontSize="xs"
                        opacity="0.7"
                      />
                      <BaseText
                        children={
                          doanvien?.chucdanh
                            ?.split('&')
                            ?.map(
                              item => DefineJobTitleEnum[item as JobTitleEnum],
                            )
                            .join(' & ') ?? ''
                        }
                        fontWeight="semibold"
                        fontSize="xs"
                      />
                    </BaseSpace>
                  </BaseCol>

                  <BaseCol xl={6}>
                    <BaseSpace size={8}>
                      <BaseText
                        children={'Đơn vị công tác'}
                        fontWeight="medium"
                        fontSize="xs"
                        opacity="0.7"
                      />
                      <BaseText
                        children={doanvien?.donvi ?? '-'}
                        fontWeight="semibold"
                        fontSize="xs"
                      />
                    </BaseSpace>
                  </BaseCol>

                  <BaseCol xl={6}>
                    <BaseSpace size={8}>
                      <BaseText
                        children={'Trạng thái'}
                        fontWeight="medium"
                        fontSize="xs"
                        opacity="0.7"
                      />
                      <BaseText
                        colorText={
                          doanvien?.trangthai
                            ? 'statesGreenColor'
                            : 'statesRedColor'
                        }
                        children={
                          doanvien?.trangthai
                            ? 'Đang hoạt động'
                            : 'Ngưng hoạt động'
                        }
                        fontWeight="semibold"
                        fontSize="xs"
                      />
                    </BaseSpace>
                  </BaseCol>
                </BaseRow>

                <BaseRow>
                  <BaseCol xl={24}>
                    <BaseSpace size={8}>
                      <BaseText
                        children={'Chức vụ'}
                        fontWeight="medium"
                        fontSize="xs"
                        opacity="0.7"
                      />
                      <BaseText
                        children={doanvien?.chucvu ?? '-'}
                        fontWeight="semibold"
                        fontSize="xs"
                      />
                    </BaseSpace>
                  </BaseCol>
                </BaseRow>
              </BaseSpace>
            </SectionBase>

            <SectionBase title="Lịch sử công tác">
              <BaseSpace>
                <Timeline>
                  <Timeline.Item
                    children={
                      <BaseSpace>
                        <BaseText
                          children={`Công tác tại đơn vị: ${
                            doanvien?.donvi ?? ''
                          }`}
                          opacity="0.7"
                          fontWeight="semibold"
                        />
                        <BaseText
                          children={`Chức vụ: ${
                            doanvien?.chucdanh
                              ?.split('&')
                              ?.map(
                                item =>
                                  DefineJobTitleEnum[item as JobTitleEnum],
                              )
                              .join(' & ') ?? ''
                          }`}
                          opacity="0.7"
                          fontWeight="semibold"
                        />
                      </BaseSpace>
                    }
                  />

                  {((history as IHistoryDoanVien[]) ?? [])?.map(
                    (item, index) => {
                      return (
                        <Timeline.Item
                          key={index}
                          children={
                            <BaseSpace>
                              <BaseRow gutter={[10, 10]} align={'middle'}>
                                <BaseCol>
                                  <BaseText
                                    children={DateUtil.formatDate(
                                      item?.thoigian ?? '',
                                      FORMAT_DD_MM_YYYY_HH_MM,
                                    )}
                                    opacity="0.7"
                                    fontWeight="semibold"
                                  />
                                </BaseCol>
                              </BaseRow>

                              <BaseText
                                children={`Công tác tại đơn vị: ${
                                  item?.thong_tin_doan_vien?.donvi?.ten ?? '-'
                                }`}
                                opacity="0.7"
                                fontWeight="semibold"
                              />
                              <BaseText
                                children={`Chức vụ: ${
                                  item?.thong_tin_doan_vien?.chucdanh
                                    ?.split('&')
                                    ?.map(
                                      item =>
                                        DefineJobTitleEnum[
                                          item as JobTitleEnum
                                        ],
                                    )
                                    .join(' & ') ?? ''
                                }`}
                                opacity="0.7"
                                fontWeight="semibold"
                              />
                            </BaseSpace>
                          }
                        />
                      )
                    },
                  )}
                </Timeline>

                {isLoadingHistory ? <Loading isAbsolute size="small" /> : null}
              </BaseSpace>
            </SectionBase>

            <MyPrizeLayout
              listPrize={dataDashboard?.myPrize ?? []}
              isLoading={isLoadingDashboard}
              inProfile
              isCanChangeOtherReward={false}
            />

            <MyActionLayout
              listAction={dataDashboard?.myAction ?? []}
              isLoading={isLoadingDashboard}
            />
          </BaseSpace>
        </BaseSpace>
      )}
    </>
  )
}
