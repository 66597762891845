import { IEvent } from 'app/api/event/model'
import ContentLayout from './content'
import { UpdateEventProvider } from './context'

export const UpdateModalLayout = ({ event }: { event?: IEvent }) => {
  return (
    <UpdateEventProvider event={event}>
      <ContentLayout />
    </UpdateEventProvider>
  )
}
