import { DashOutlined } from '@ant-design/icons'
import {
  DefineGroupEventEnum,
  DefineTypeEventEnum,
} from 'app/api/event/constant'
import {
  GroupEventEnum,
  IEvent,
  ResourceEventEnum,
  TypeEventEnum,
} from 'app/api/event/model'
import R from 'app/assets/R'
import { BaseButton } from 'app/components/common/BaseButton'
import { BasePopover } from 'app/components/common/BasePopover'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { DETAIL_EVENT_BY_UNIT_PATH } from 'app/components/router/route-path'
import { RenderValueTable } from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { FORMAT_DD_MM_YYYY_HH_MM } from 'app/constant'
import { useGetEventList } from 'app/react-query/hook/event'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { isEqual } from 'lodash'
import {
  BORDER_RADIUS,
  DateUtil,
  PADDING,
  Pagination,
} from 'parkway-web-common'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import styled from 'styled-components'
import {
  DefineEventStatus,
  EventStatusEnum,
  IEventTableData,
  IFilterEventList,
} from '../../type'

export const useHook = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [filter, setFilter] = useState<IFilterEventList>({})
  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([])

  const { data: dataApi, isLoading } = useGetEventList({
    status: filter?.status,
  })

  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)

  const data: IEventTableData[] = useMemo(() => {
    return (
      dataApi?.data
        ?.filter(itm => {
          return (
            itm?.name
              ?.toLowerCase()
              .includes((filter?.search ?? '')?.toLowerCase()) ||
            itm?.desc
              ?.toLowerCase()
              .includes((filter?.search ?? '')?.toLowerCase())
          )
        })
        ?.filter(itm => {
          if (!itm?.status || !filter?.status?.length) return true

          return filter?.status?.includes(itm?.status)
        })
        ?.filter(itm => {
          if (!itm?.group || !filter?.group?.length) return true

          return filter?.group?.includes(itm?.group)
        })
        ?.filter(itm => {
          if (!itm?.type || !filter?.type?.length) return true

          return filter?.type?.includes(itm?.type)
        })
        ?.filter(itm => {
          return isEqual(itm?.resource, ResourceEventEnum.ADMIN)
        })
        ?.map((item, index) => {
          return {
            key: index + 1,
            ...item,
          }
        }) ?? []
    )
  }, [dataApi, filter])

  const onNavigateDetail = (event: IEvent) => {
    navigate(DETAIL_EVENT_BY_UNIT_PATH, {
      state: {
        event,
      },
    })
  }

  const columns = useMemo(() => {
    return [
      convertColumnTable<IEventTableData>({
        key: 'name',
        title: t(R.strings.event_name),
        classNameWidthColumnOverwrite: 'very-big-column',
        fixed: 'left',
        render: (_, record) => {
          return (
            <RenderValueTable
              value={record?.name}
              type="Link"
              onClick={() => onNavigateDetail(record)}
            />
          )
        },
      }),
      convertColumnTable<IEventTableData>({
        key: 'desc',
        title: t(R.strings.description),
        classNameWidthColumnOverwrite: 'very-big-column',
        render: (_, record) => {
          return (
            <BaseText
              fontWeight="medium"
              opacity="0.7"
              children={record?.desc ?? '-'}
              style={{
                display: '-webkit-box',
                WebkitLineClamp: 3,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
              }}
            />
          )
        },
      }),

      convertColumnTable<IEventTableData>({
        key: 'date_started',
        title: t(R.strings.start_date_event),
        render: (_, record) => {
          return (
            <BaseText
              fontWeight="medium"
              opacity="0.7"
              children={
                record?.date_started
                  ? DateUtil?.formatDate(
                      record?.date_started ?? '',
                      FORMAT_DD_MM_YYYY_HH_MM,
                    )
                  : '-'
              }
            />
          )
        },
      }),

      convertColumnTable<IEventTableData>({
        key: 'date_end',
        title: t(R.strings.end_date_event),
        render: (_, record) => {
          return (
            <BaseText
              fontWeight="medium"
              opacity="0.7"
              children={
                record?.date_end
                  ? DateUtil?.formatDate(
                      record?.date_end ?? '',
                      FORMAT_DD_MM_YYYY_HH_MM,
                    )
                  : '-'
              }
            />
          )
        },
      }),

      convertColumnTable<IEvent>({
        key: 'group',
        title: 'Nhóm sự kiện',
        render: (group: GroupEventEnum) => {
          return (
            <BaseText
              children={DefineGroupEventEnum[group]?.text ?? '-'}
              fontWeight="semibold"
              colorText="collapseBackgroundColor"
            />
          )
        },
      }),

      convertColumnTable<IEvent>({
        key: 'type',
        title: 'Loại sự kiện',
        render: (type: TypeEventEnum) => {
          return (
            <BaseText
              children={DefineTypeEventEnum[type]?.text ?? '-'}
              fontWeight="semibold"
              colorText="statesGreenColor"
            />
          )
        },
      }),

      convertColumnTable<IEventTableData>({
        key: 'status',
        title: t(R.strings.status),
        fixed: 'right',
        render: (_, record) => {
          if (!record?.status) return null

          return (
            <StatusEventWrapper $status={record?.status}>
              <BaseText
                children={t(DefineEventStatus[record?.status]?.keyI18n)}
                colorText={(() => {
                  switch (record?.status) {
                    case EventStatusEnum.NOT_OPEN:
                      return 'otherBlueColor'
                    case EventStatusEnum.REGISTRATION:
                      return 'otherBlueColor'
                    case EventStatusEnum.REGISTRATION_COMPLETED:
                      return 'statesOrangeColor'
                    case EventStatusEnum.IN_PROGRESS:
                      return 'statesGreenColor'
                    case EventStatusEnum.FINISHED:
                      return 'statesRedColor'
                  }
                })()}
                fontWeight="medium"
              />
            </StatusEventWrapper>
          )
        },
      }),

      convertColumnTable<IEventTableData>({
        key: 'key',
        title: '',
        fixed: 'right',
        classNameWidthColumnOverwrite: 'number-column',
        render: (_, record) => {
          return (
            <BasePopover
              trigger="click"
              content={
                <BaseSpace>
                  <BaseButton
                    type="text"
                    onClick={() => onNavigateDetail(record)}
                  >
                    <BaseText
                      children={'Xem chi tiết'}
                      fontWeight="medium"
                      fontSize="xs"
                    />
                  </BaseButton>
                </BaseSpace>
              }
            >
              <DashOutlined rev={undefined} rotate={90} />
            </BasePopover>
          )
        },
      }),
    ]
  }, [t, expandedRowKeys])

  const onChangeFilter = (newFilter: IFilterEventList) => {
    setFilter(newFilter)
  }

  const handleExpand = (expanded: boolean, newKey?: number) => {
    if (!newKey) return
    if (expanded) {
      setExpandedRowKeys([...expandedRowKeys, newKey])
    } else {
      setExpandedRowKeys(expandedRowKeys.filter(key => key !== newKey))
    }
  }

  const handleChangeTable = (pagination?: Pagination) => {
    setPage(pagination?.current ?? 1)
    setPageSize(pagination?.pageSize ?? 10)
  }

  return {
    isLoading,
    columns,
    data,
    onChangeFilter,
    filter,
    handleExpand,
    expandedRowKeys,
    page,
    pageSize,
    total: data?.length,
    handleChangeTable,
  }
}

export const StatusEventWrapper = styled.div<{ $status?: EventStatusEnum }>`
  padding: 3px ${PADDING.xxs};
  border: 1px solid
    ${props => {
      switch (props?.$status) {
        case EventStatusEnum.NOT_OPEN:
          return convertedVariables.otherBlueColor
        case EventStatusEnum.REGISTRATION:
          return convertedVariables.otherBlueColor
        case EventStatusEnum.REGISTRATION_COMPLETED:
          return convertedVariables.statesOrangeColor
        case EventStatusEnum.IN_PROGRESS:
          return convertedVariables.statesGreenColor
        case EventStatusEnum.FINISHED:
          return convertedVariables.statesRedColor
      }
    }};

  background-color: ${props => {
    switch (props?.$status) {
      case EventStatusEnum.NOT_OPEN:
        return convertedVariables.statesBlueLightColor
      case EventStatusEnum.REGISTRATION:
        return convertedVariables.statesBlueLightColor
      case EventStatusEnum.REGISTRATION_COMPLETED:
        return convertedVariables.statesOrangeLightColor
      case EventStatusEnum.IN_PROGRESS:
        return convertedVariables.statesGreenLightColor
      case EventStatusEnum.FINISHED:
        return convertedVariables.statesRedLightColor
    }
  }};

  border-radius: ${BORDER_RADIUS};
  width: fit-content;
`
