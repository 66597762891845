import { ENV_CONFIG } from 'app/common/config'
import { BASE_URL_LOCAL } from 'parkway-web-common'
import { ApiServiceClient } from './api-service-handle'

const ApiClient = ApiServiceClient({
  baseUrl: ENV_CONFIG.API_ENDPOINT,
})

const ApiClientHandle = ({
  type = 'api',
  localUrl,
}: {
  type?: 'local' | 'api'
  localUrl?: string
}) => {
  switch (type) {
    case 'local':
      return ApiServiceClient({
        baseUrl: localUrl ?? ENV_CONFIG.BASE_URL_LOCAL,
      })
    case 'api':
      return ApiClient
  }
}

const getBaseUrl = (url?: string) => {
  return url || process.env.REACT_APP_API_ENDPOINT || BASE_URL_LOCAL
}

export {
  ApiClient,
  ApiClientHandle,
  getBaseUrl
}

