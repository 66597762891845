import { ENV_CONFIG } from 'app/common/config'
import axios from 'axios'
import { useQuery } from 'react-query'

export const useGetAllDonVi = () => {
  const allDonViQuery = useQuery({
    queryKey: ['donvi'],
    queryFn: async () => {
      try {
        const { data } = await axios.get(
          `${ENV_CONFIG.API_ENDPOINT}/api/v1/donvi`,
        )
        return data
      } catch (error) {
        console.log(error)
      }
    },
  })
  return allDonViQuery
}

export const useGetDonVi = (id: string) => {
  return useQuery(
    ['donvi', id],
    async () => {
      try {
        const { data } = await axios.get(
          `${ENV_CONFIG.API_ENDPOINT}/api/v1/donvi/get/${id}`,
        )
        return data
      } catch (error) {
        console.log(error)
      }
    },
    {
      enabled: !!id,
      cacheTime: 1000,
    },
  )
}
export const useCanDeleteDonVi = (id: string) => {
  const canDelete = useQuery({
    queryKey: ['donvi', 'canDelete', id],
    queryFn: async () => {
      try {
        const { data } = await axios.get(
          `${ENV_CONFIG.API_ENDPOINT}/api/v1/donvi/eon/${id}`,
        )
        return data
      } catch (error) {
        console.log(error)
      }
    },
  })
  return canDelete
}

export const useGetHistoryDonviById = (id?: string) => {
  const getDonViQuery = useQuery({
    queryKey: ['history-donvi', id],
    queryFn: async () => {
      try {
        if (!id) return []
        const { data } = await axios.get(
          `${ENV_CONFIG.API_ENDPOINT}/api/v1/donvi/history/${id}`,
        )
        return data?.dataSort ?? []
      } catch (error) {
        console.log(error)
      }
    },
  })
  return getDonViQuery
}
