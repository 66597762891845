/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormInstance } from 'antd'
import {
  IEvent,
  IPayloadActionEvent,
  IPayloadCreateActionEvent,
  IPayloadUpdateEvent,
  ResourceEventEnum,
  TypeEventEnum,
} from 'app/api/event/model'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { notificationController } from 'app/controllers/notification-controller'
import {
  useGetActionByEvent,
  useGetEventById,
  useUpdateActionEvent,
  useUpdateEvent,
} from 'app/react-query/hook/event'
import dayjs from 'dayjs'
import { isEqual } from 'lodash'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { useActivityHook } from './layouts/ActionEvent/hook'
import {
  IActivityEvent,
  IColumnActivity,
  IDataTumActivity,
  IFormCreateEvent,
  IRewardActivity,
  TypeColumn,
} from './type'

interface ICreateEventContext {
  form?: FormInstance<IFormCreateEvent>

  handleSubmit?: (values: IFormCreateEvent) => void

  isLoading?: boolean

  activities?: IActivityEvent[]
  addActivity?: (activity: IActivityEvent) => void
  updateTitleInActiveByIndex?: (index: number, title: string) => void
  updateValueCareInActiveByIndex?: (index: number, title: string) => void
  removeActivityByIndex?: (index: number) => void
  addColumnInActivityByIndex?: (index: number, column: IColumnActivity) => void
  removeColumnInActivityByIndex?: (
    activityIndex: number,
    columnIndex: number,
  ) => void
  updateColumnNameInActivityByIndex?: (
    activityIndex: number,
    columnIndex: number,
    name: string,
  ) => void
  updateColumnTypeInActivityByIndex?: (
    activityIndex: number,
    columnIndex: number,
    type: TypeColumn,
  ) => void
  updateDataTumInActivityByIndex?: (
    activityIndex: number,
    data: IDataTumActivity[],
  ) => void
  updateActivityByIndex?: (index: number, activity: IActivityEvent) => void

  updateRewardInActivityByIndex?: (
    activityIndex: number,
    rewardIndex: number,
    reward: IRewardActivity,
  ) => void

  deleteRewardInActivityByIndex?: (
    activityIndex: number,
    rewardIndex: number,
  ) => void

  addRewardInActivityByIndex?: (
    activityIndex: number,
    reward: IRewardActivity,
  ) => void

  isLoadingSubmit?: boolean

  eventType?: TypeEventEnum
  onChangeEventType?: (type: TypeEventEnum) => void
}
export const CreateEventContext = createContext<ICreateEventContext>({})

export const CreateEventProvider = ({ children }) => {
  const { state } = useLocation()
  const navigate = useNavigate()
  const [form] = BaseForm.useForm<IFormCreateEvent>()
  const [eventType, setEventType] = useState<TypeEventEnum | undefined>(
    undefined,
  )

  const { mutateAsync: mutateAsyncUpdateEvent, isLoading: isLoadingSubmit } =
    useUpdateEvent()

  const {
    mutateAsync: mutateAsyncUpdateActionEvent,
    isLoading: isLoadingAction,
  } = useUpdateActionEvent()

  const [isLoading, setIsLoading] = useState(false)

  const activityHook = useActivityHook()

  const event: IEvent = useMemo(() => {
    return state?.event
  }, [state])

  const { data: dataApi, refetch } = useGetActionByEvent({
    id: event?.id ?? 0,
  })

  const { refetch: refetchDetailEvent } = useGetEventById(event?.id?.toString())

  const dataActivity = useMemo(() => {
    const activityEvents: IActivityEvent[] =
      dataApi?.data?.map((item, index) => {
        const columns = item?.listCategory?.map(column => {
          return {
            id: column?.id,
            name: column?.name,
            keyOfMainData: column?.keyOfMainData,
            type: column?.type,
          } as IColumnActivity
        })

        const rewards = item?.listPrize?.map(reward => {
          return {
            name: reward?.name,
            countPerReward: reward?.countPerReward,
            description: reward?.description,
            gift: reward?.gift,
            type: reward?.type_prize,
          } as IRewardActivity
        })

        const dataR = item?.listMember?.map(member => {
          let data: IDataTumActivity = {
            ...member?.info,
          }

          columns?.forEach(column => {
            data = {
              ...data,
              [column?.name ?? '']: member?.listMemberCategory?.find(
                category => {
                  return (
                    (category?.idCategory &&
                      column?.keyOfMainData &&
                      isEqual(category?.idCategory, column?.keyOfMainData)) ||
                    (category?.idCategory === column?.id &&
                      category.idRegisterMember === member?.id)
                  )
                },
              )?.value,
            }
          })

          return data
        })

        return {
          title: item?.name,
          valueCare: item?.valueCare,
          columns,
          data: dataR,
          rewards,
          key: index + 1,
        }
      }) ?? []

    return activityEvents
  }, [dataApi])

  useEffect(() => {
    if (!event) {
      notificationController?.error?.({
        message: 'Không tìm thấy sự kiện',
      })
      navigate?.(-1)
      return
    }

    if (event) {
      form?.setFieldsValue({
        name: event?.name,
        description: event?.desc,
        startDate: event?.date_started ? dayjs(event?.date_started) : undefined,
        endDate: event?.date_end ? dayjs(event?.date_end) : undefined,
        startDateRegister: event?.register_started
          ? dayjs(event?.register_started)
          : undefined,
        endDateRegister: event?.register_end
          ? dayjs(event?.register_end)
          : undefined,

        link: event?.link,
        type: event?.type,

        group: event?.group,
      })

      setEventType(event?.type)
      activityHook?.handleInitActivity?.(dataActivity)
    }
  }, [event, dataActivity])

  const refetchAndGoBack = () => {
    refetch?.()
    refetchDetailEvent?.()
    form?.resetFields?.()
    activityHook?.resetAllData()
    navigate?.(-1)
  }

  const handleSubmit = async (infoPayload?: IFormCreateEvent) => {
    if (!infoPayload) return

    setIsLoading(true)

    try {
      const payload: IPayloadUpdateEvent = {
        name: infoPayload.name,
        date_end: infoPayload.endDate?.toISOString(),
        date_started: infoPayload.startDate?.toISOString(),
        desc: infoPayload?.description ?? '-',
        register_end: infoPayload.endDateRegister?.toISOString(),
        register_started: infoPayload.startDateRegister?.toISOString(),
        type: infoPayload?.type,
        link: infoPayload?.link,
        resource: ResourceEventEnum.ADMIN,
        id: event?.id,

        group: infoPayload?.group,
      }

      const res = await mutateAsyncUpdateEvent?.(payload)

      if (event?.id) {
        if (isEqual(infoPayload?.type, TypeEventEnum.ACTIVITY)) {
          const payloadCreateAction: IPayloadCreateActionEvent = {
            event_id: event?.id,
            actions:
              activityHook?.activities?.map(item => {
                return {
                  action: {
                    event_id: event?.id,
                    name: item?.title ?? '',
                  },
                  columns:
                    item?.columns?.map(column => {
                      return {
                        type: column?.type,
                        name: column?.name,
                        keyOfMainData: column?.keyOfMainData,
                      }
                    }) ?? [],
                  memberCategory:
                    item?.data?.map(member => ({
                      maso_doanvien: member?.maso_doanvien,
                      columns: item?.columns?.map(col => {
                        const key = col?.keyOfMainData ?? col?.name ?? ''

                        const valueExist = member?.[key]

                        return {
                          name: key,
                          value: valueExist ?? '',
                        }
                      }),
                    })) ?? [],
                  prize:
                    item?.rewards?.map(reward => ({
                      name: reward?.name,
                      type_prize: reward?.type,
                      countPerReward: reward?.countPerReward ?? 0,
                      description: reward?.description ?? '',
                      gift: reward?.gift ?? '',
                    })) ?? [],
                } as IPayloadActionEvent
              }) ?? [],
          }

          const resUpdate = await mutateAsyncUpdateActionEvent?.(
            payloadCreateAction,
          )

          if (resUpdate) {
            refetchAndGoBack()
            return
          }
        } else if (isEqual(infoPayload?.type, TypeEventEnum.NEWS)) {
          // do something
        } else {
          const payloadCreateAction: IPayloadCreateActionEvent = {
            event_id: event?.id,
            actions:
              activityHook?.activities?.map(item => {
                return {
                  action: {
                    event_id: event?.id,
                    name: item?.title ?? '',
                    valueCare: item?.valueCare,
                  },
                  columns: [],
                  memberCategory: item?.data?.map(member => ({
                    maso_doanvien: member?.maso_doanvien,
                    valueCare: item?.valueCare,
                    columns: [],
                  })),
                  prize: [],
                } as IPayloadActionEvent
              }) ?? [],
          }

          const resUpdate = await mutateAsyncUpdateActionEvent?.(
            payloadCreateAction,
          )

          if (resUpdate) {
            refetchAndGoBack()
            return
          }
        }
      }

      if (res) {
        refetchAndGoBack()
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const onChangeEventType = (type?: TypeEventEnum) => {
    setEventType(type)

    form?.setFieldsValue({
      link: undefined,
    })

    activityHook?.resetAllData()
  }

  return (
    <CreateEventContext.Provider
      value={{
        form,
        handleSubmit,
        isLoading,
        isLoadingSubmit: isLoadingAction || isLoadingSubmit || isLoading,
        onChangeEventType,
        eventType,
        ...activityHook,
      }}
    >
      {children}
    </CreateEventContext.Provider>
  )
}

export function useCreateEventContext() {
  const context = useContext(CreateEventContext)

  if (context === undefined) {
    throw new Error('useCreateEvent must be used within a CreateEventProvider')
  }
  return context
}
