/* eslint-disable @typescript-eslint/no-explicit-any */
import { ENV_CONFIG } from 'app/common/config'
import { DoanVien } from 'app/containers/TumManagement/type'
import { ApiClient } from 'app/service/api-service'
// import { useRouter } from "next/navigation";
import axios from 'axios'
import { useQuery } from 'react-query'

export const useGetDoanVien = (params?: any) => {
  const allDoanVienQuery = useQuery({
    queryKey: ['doanvien', params],
    queryFn: async () => {
      try {
        const data = await ApiClient.Get({
          url: `${ENV_CONFIG.API_ENDPOINT}/api/v1/doanvien`,
          params: { params },
        })
        return data
      } catch (error) {
        console.log(error)
      }
    },
  })
  return allDoanVienQuery
}

export const useGetDoanVienByUnitName = (unitName?: string) => {
  return useQuery(
    ['doanvienbyunitname', unitName],
    async () => {
      try {
        if (!unitName) return []
        const { data } = await axios.get(
          `${ENV_CONFIG.API_ENDPOINT}/api/v1/doanvien/donvi`,
          {
            params: {
              donvi: unitName,
            },
          },
        )
        return data
      } catch (error) {
        return []
      }
    },
    {
      enabled: !!unitName,
    },
  )
}

export const useGetDoanVienByActionId = (id?: number) => {
  const allDoanVienQuery = useQuery({
    queryKey: ['doanvienbyactionid', id],
    queryFn: async () => {
      try {
        if (!id) return []
        const { data } = await axios.get(
          `${ENV_CONFIG.API_ENDPOINT}/api/v1/action/:id/register-member`?.replace(
            ':id',
            id.toString(),
          ),
          {
            params: {
              id,
            },
          },
        )
        return data
      } catch (error) {
        console.log(error)
      }
    },
  })
  return allDoanVienQuery
}

export const useGetDoanvienByMSD = (masodoanvien?: string) => {
  const doanVienQuery = useQuery({
    queryKey: ['doanvien', masodoanvien],
    queryFn: async () => {
      try {
        const { data } = await axios.get(
          `${ENV_CONFIG.API_ENDPOINT}/api/v1/doanvien/${masodoanvien}`,
        )
        return data as DoanVien
      } catch (error) {
        console.log(error)
      }
    },
  })
  return doanVienQuery
}

export const useGetDoanvienHistory = (masodoanvien?: string) => {
  const doanVienQuery = useQuery({
    queryKey: ['doanvien-history', masodoanvien],
    queryFn: async () => {
      try {
        const { data } = await axios.get(
          `${ENV_CONFIG.API_ENDPOINT}/api/v1/doanvienhistory/${masodoanvien}`,
        )
        return data
      } catch (error) {
        console.log(error)
      }
    },
  })
  return doanVienQuery
}
