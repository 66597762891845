/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  DeleteFilled,
  DownloadOutlined,
  ExportOutlined,
  ImportOutlined,
} from '@ant-design/icons'
import R from 'app/assets/R'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseModal } from 'app/components/common/BaseModal'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseUpload } from 'app/components/common/BaseUpload'
import { isNull } from 'lodash'
import { EXAMPLE_URL_EXCEL_FILE, normFile } from 'parkway-web-common'
import { ReactNode, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as S from './styles'

interface Props {
  title?: string
  titleI18nKey?: string
  isExport?: boolean
  isImport?: boolean
  onPressExport?: () => void
  onPressImport?: (file?: any) => void
  rightComponent?: ReactNode
  urlExample?: string
  nameFileExample?: string
}

const HeaderPage = ({
  title,
  isExport,
  isImport,
  onPressExport,
  onPressImport,
  rightComponent,
  titleI18nKey,
  urlExample,
}: Props) => {
  const { t } = useTranslation()
  const [openImportArea, setOpenImportArea] = useState(false)
  const [file, setFile] = useState<File | null>(null)

  const onCloseModal = () => {
    setOpenImportArea(false)
  }

  const onOpenModal = () => {
    setOpenImportArea(true)
  }

  const onRemoveFile = () => {
    setFile(null)
  }

  const onConfirmModal = () => {
    if (file) {
      onPressImport?.(file)
      onRemoveFile()
      onCloseModal()
    }
  }

  const onChangeFile = values => {
    setTimeout(() => {
      const { file } = values
      const { lastModified, name, type, originFileObj } = file
      const newFile = new File([originFileObj], name, { type, lastModified })
      setFile(newFile)
    }, 500)
  }

  const actionComponent = useCallback(() => {
    return (
      <BaseRow gutter={10} style={{ marginRight: 0 }}>
        {rightComponent && rightComponent}

        {isExport && (
          <BaseCol>
            <S.ButtonAction
              onClick={onPressExport}
              icon={<ExportOutlined rev={undefined} />}
            >
              {t(R.strings.export_excel)}
            </S.ButtonAction>
          </BaseCol>
        )}
        {isImport && (
          <>
            <BaseCol>
              <a href={urlExample ?? EXAMPLE_URL_EXCEL_FILE}>
                <S.ButtonAction>
                  <BaseRow gutter={8}>
                    <BaseCol>
                      <DownloadOutlined rev={undefined} />
                    </BaseCol>
                    <BaseCol>
                      {t(R.strings.download_example_import_file)}
                    </BaseCol>
                  </BaseRow>
                </S.ButtonAction>
              </a>
            </BaseCol>
            <BaseCol>
              <S.ButtonAction onClick={onOpenModal}>
                <BaseRow gutter={8}>
                  <BaseCol>
                    <ImportOutlined rev={undefined} />
                  </BaseCol>
                  <BaseCol>{t(R.strings.import)}</BaseCol>
                </BaseRow>
              </S.ButtonAction>
            </BaseCol>
          </>
        )}
      </BaseRow>
    )
  }, [isExport, isImport, onPressImport, onPressExport, onOpenModal])()

  return (
    <>
      <S.RootWrapper align={'middle'} justify={'space-between'}>
        <BaseCol>
          <S.HeadingTitlePage>
            {titleI18nKey ? t(titleI18nKey) : title ?? ''}
          </S.HeadingTitlePage>
        </BaseCol>
        {actionComponent}
      </S.RootWrapper>
      {openImportArea && (
        <BaseModal
          title={t(R.strings.import_file)}
          open={openImportArea}
          onOk={onConfirmModal}
          okButtonProps={{ disabled: isNull(file) }}
          onCancel={onCloseModal}
          okText={t(R.strings.import)}
          cancelText={t(R.strings.cancel)}
        >
          <S.DraggerInput
            name="dragger"
            valuePropName="file"
            getValueFromEvent={normFile}
            noStyle
          >
            <BaseUpload.Dragger
              name="files"
              onChange={onChangeFile}
              customRequest={() => undefined}
              showUploadList={false}
            >
              <S.DraggerWrapper>
                <S.IconMailBox rev={undefined} size={24} />
              </S.DraggerWrapper>
            </BaseUpload.Dragger>
          </S.DraggerInput>
          {file && (
            <S.FileNameDisplayWrapper
              gutter={20}
              justify={'start'}
              align={'middle'}
            >
              <BaseCol>
                <span>{file?.name}</span>
              </BaseCol>
              <BaseCol>
                <BaseButton
                  icon={<DeleteFilled rev={undefined} onClick={onRemoveFile} />}
                />
              </BaseCol>
            </S.FileNameDisplayWrapper>
          )}
        </BaseModal>
      )}
    </>
  )
}

export default HeaderPage
