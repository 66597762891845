/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  requestChangeStatusMemberRegister,
  requestCheckInAction,
  requestCreateActionByEvent,
  requestCreateEvent,
  requestDeleteEvent,
  requestGetActionByEvent,
  requestGetActionNotJoinByMemberId,
  requestGetActionRegisteredByMember,
  requestGetEventById,
  requestGetEventList,
  requestGetEventStatusList,
  requestGetMemberOfPrize,
  requestGetMemberRegisterByActionId,
  requestRegisterActionByMember,
  requestUpdateActionByEvent,
  requestUpdateEvent,
} from 'app/api/event'
import {
  IActionEvent,
  IDeleteEvent,
  IEvent,
  IMemberActionEvent,
  IParamsGetActionByEvent,
  IParamsGetActionRegisteredByMember,
  IParamsGetEvents,
  IParamsGetMemberByAction,
  IPayloadChangeStatusMemberRegister,
  IPayloadCheckInActionByMember,
  IPayloadCreateActionEvent,
  IPayloadCreateEvent,
  IPayloadRegisterActionByMember,
  IPayloadUpdateEvent,
} from 'app/api/event/model'
import { useAppSelector } from 'app/redux/hooks'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { IResponseQueryList } from '../model/common'
import { EventKeys } from '../query-key/event'

export function useGetEventList(params: IParamsGetEvents) {
  return useQuery<IResponseQueryList<IEvent[]>, undefined>(
    EventKeys.listEvent(params),
    async () => {
      const data: IEvent[] = await requestGetEventList(params)
      return {
        data,
        total: data?.length ?? 0,
      }
    },
  )
}

export function useGetEventById(id?: string) {
  return useQuery<IEvent, undefined>(
    EventKeys.infoDetailEvent(id),
    async () => {
      const data: IEvent = await requestGetEventById(id)
      return data
    },
  )
}

export function useGetActionByEvent(params: IParamsGetActionByEvent) {
  return useQuery<IResponseQueryList<IActionEvent[]>, undefined>(
    EventKeys.listActionEvent(params),
    async () => {
      if (!params.id) {
        return {
          data: [],
          total: 0,
        }
      }

      const data: IActionEvent[] = await requestGetActionByEvent(params)
      return {
        data,
        total: data?.length ?? 0,
      }
    },
  )
}

export const useCheckInActionByMember = () => {
  const query = useQueryClient()
  return useMutation<unknown, unknown, IPayloadCheckInActionByMember, unknown>(
    async (body: IPayloadCheckInActionByMember) => {
      await requestCheckInAction(body)
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: EventKeys.allActionsByEvent,
        })
      },
    },
  )
}

export function useGetActionNotJoinByEvent(params: IParamsGetActionByEvent) {
  return useQuery<IResponseQueryList<IActionEvent[]>, undefined>(
    EventKeys.listActionEvent(params),
    async () => {
      if (!params.id) {
        return {
          data: [],
          total: 0,
        }
      }

      const data: IActionEvent[] = await requestGetActionByEvent(params)
      return {
        data,
        total: data?.length ?? 0,
      }
    },
  )
}

export function useGetMemberOfPrize(params: IParamsGetActionByEvent) {
  return useQuery<IMemberActionEvent[], undefined>(
    EventKeys.listListMemberOfRewards(params),
    async () => {
      try {
        if (!params.id) {
          return []
        }

        const data: IMemberActionEvent[] = await requestGetMemberOfPrize(params)
        return data
      } catch (error) {
        return []
      }
    },
  )
}

export function useGetEventByStatusList(params: IParamsGetEvents) {
  return useQuery<IResponseQueryList<IEvent[]>, undefined>(
    EventKeys.listEvent(params),
    async () => {
      const data: IEvent[] = await requestGetEventStatusList(params)
      return {
        data,
        total: data?.length ?? 0,
      }
    },
  )
}

export const useDeleteEvent = () => {
  const query = useQueryClient()
  return useMutation<unknown, unknown, IDeleteEvent, unknown>(
    async (body: IDeleteEvent) => {
      await requestDeleteEvent(body)
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: EventKeys.allEvents,
        })
      },
    },
  )
}

export const useUpdateEvent = () => {
  const query = useQueryClient()
  return useMutation<unknown, unknown, IPayloadUpdateEvent, unknown>(
    async (body: IPayloadUpdateEvent) => {
      return await requestUpdateEvent(body)
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: EventKeys.allEvents,
        })
        query.invalidateQueries({
          queryKey: EventKeys.allActionsByEvent,
        })
      },
    },
  )
}

export const useCreateEvent = () => {
  const query = useQueryClient()
  return useMutation<IEvent | undefined, unknown, IPayloadCreateEvent, unknown>(
    async (body?: IPayloadCreateEvent) => {
      const res: { body: IEvent } = await requestCreateEvent(body)
      return res?.body
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: EventKeys.allEvents,
        })
        query.invalidateQueries({
          queryKey: EventKeys.allActionsByEvent,
        })
      },
    },
  )
}

export const useCreateActionEvent = () => {
  const query = useQueryClient()
  return useMutation<unknown, unknown, IPayloadCreateActionEvent, unknown>(
    async (body?: IPayloadCreateActionEvent) => {
      return await requestCreateActionByEvent(body)
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: EventKeys.allEvents,
        })
        query.invalidateQueries({
          queryKey: EventKeys.allActionsByEvent,
        })
      },
    },
  )
}

export const useUpdateActionEvent = () => {
  const query = useQueryClient()
  return useMutation<unknown, unknown, IPayloadCreateActionEvent, unknown>(
    async (body?: IPayloadCreateActionEvent) => {
      return await requestUpdateActionByEvent(body)
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: EventKeys.allEvents,
        })
        query.invalidateQueries({
          queryKey: EventKeys.allActionsByEvent,
        })
      },
    },
  )
}

export function useGetActionNotJoinByMember() {
  const user = useAppSelector(state => state?.userOld)?.profile
  return useQuery<IResponseQueryList<IActionEvent[]>, undefined>(
    EventKeys.listListActionNotJoinByMembers(user?.maso_doanvien),
    async () => {
      if (!user?.maso_doanvien) {
        return {
          data: [],
          total: 0,
        }
      }

      const data: IActionEvent[] = await requestGetActionNotJoinByMemberId(
        user?.maso_doanvien,
      )
      return {
        data,
        total: data?.length ?? 0,
      }
    },
  )
}

export function useGetActionRegisteredByMember(
  params: IParamsGetActionRegisteredByMember,
) {
  return useQuery<IResponseQueryList<IActionEvent[]>, undefined>(
    EventKeys.listListActionRegisteredByMembers(params),
    async () => {
      const data: IActionEvent[] = await requestGetActionRegisteredByMember(
        params,
      )
      return {
        data,
        total: data?.length ?? 0,
      }
    },
  )
}

export function useGetMemberRegisteredByAction(
  params: IParamsGetMemberByAction,
) {
  return useQuery<IResponseQueryList<IActionEvent[]>, undefined>(
    EventKeys.listMemberRegisterByActions(params),
    async () => {
      const data: IActionEvent[] = await requestGetMemberRegisterByActionId(
        params,
      )
      return {
        data,
        total: data?.length ?? 0,
      }
    },
  )
}

// requestChangeStatusMemberRegister
export const useChangeStatusMemberRegister = () => {
  const query = useQueryClient()
  return useMutation<
    unknown,
    unknown,
    IPayloadChangeStatusMemberRegister,
    unknown
  >(
    async (body?: IPayloadChangeStatusMemberRegister) => {
      return await requestChangeStatusMemberRegister(body)
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: EventKeys.allMemberRegisterByAction,
        })
      },
    },
  )
}

// requestChangeStatusMemberRegister
export const useRegisterActionByMember = () => {
  const query = useQueryClient()
  return useMutation<unknown, unknown, IPayloadRegisterActionByMember, unknown>(
    async (body: IPayloadRegisterActionByMember) => {
      return await requestRegisterActionByMember(body)
    },
    {
      onSuccess: () => {
        query.invalidateQueries({
          queryKey: EventKeys.allActionsNotJoinByEvent,
        })
        query.invalidateQueries({
          queryKey: EventKeys.allListActionRegisteredByMember,
        })
      },
    },
  )
}
