import { PageTitle } from 'app/components/common/PageTitle'
import { UpdateEventContainer } from 'app/containers/EventManagement/UpdateEvent'
import { isEqual } from 'lodash'
import { memo } from 'react'

const UpdateEventCpn = () => {
  return (
    <>
      <PageTitle>{'Cập nhật sự kiện'}</PageTitle>
      <UpdateEventContainer />
    </>
  )
}
export const UpdateEventPage = memo(UpdateEventCpn, isEqual)
