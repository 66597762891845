import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ENV_CONFIG } from 'app/common/config'
import { DoanVien } from 'app/containers/TumManagement/type'
import axios from 'axios'
import Cookies from 'js-cookie'

export interface UserState {
  profile?: DoanVien
}

const initialState: UserState = {
  profile: undefined,
}

export const getUserInfoOld = createAsyncThunk('user/info-old', async () => {
  console.log("get user info");
  
  try {
    const checkAccessTokenInCookies = () => {
      const accessToken = Cookies.get('access_token') // Use your cookie name
      return !!accessToken 
    }

    if (checkAccessTokenInCookies()) {
      const res = await axios.get(
        `${ENV_CONFIG.API_ENDPOINT}/api/v1/doanvien/info`,
        {
          headers: {
            token: Cookies.get('access_token') ?? '',
          },
        },
      )

      const userData = res?.data
      
      return userData
    }
  } catch (error) {
    return undefined
  }
})

export const userOldSlice = createSlice({
  name: 'user/info-old',
  initialState,
  reducers: {
    changeProfile: (state, action) => {
      state.profile = action.payload
    },
    resetProfile: state => {
      state.profile = undefined
    },
  },
  extraReducers: builder => {
    builder.addCase(getUserInfoOld.fulfilled, (state, action) => {
      state.profile = action.payload
    })
  },
})

export const { resetProfile, changeProfile } = userOldSlice.actions

export default userOldSlice.reducer
