import { PageTitle } from 'app/components/common/PageTitle'
import { DetailUnitContainer } from 'app/containers/UnitManagement/DetailUnit'
import { isEqual } from 'lodash'
import { memo } from 'react'

const DetailUnitCpn = () => {
  return (
    <>
      <PageTitle>{'Chi tiết đơn vị'}</PageTitle>
      <DetailUnitContainer />
    </>
  )
}
export const DetailUnitPage = memo(DetailUnitCpn, isEqual)
