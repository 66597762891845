import ContentLayout from './content'
import { CreateEventProvider } from './context'

export const UpdateEventContainer = () => {
  return (
    <CreateEventProvider>
      <ContentLayout />
    </CreateEventProvider>
  )
}
