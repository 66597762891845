import { StatusMemberRegisterWaiting } from 'app/api/event/model'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import {
  BaseModalComponent,
  IRefModal,
} from 'app/components/common/ModalComponent'
import { notificationController } from 'app/controllers/notification-controller'
import {
  useChangeStatusMemberRegister,
  useGetActionByEvent,
  useGetMemberRegisteredByAction,
} from 'app/react-query/hook/event'
import { useRef } from 'react'
import { useLocation } from 'react-router'

export const ModalApproveMember = ({ id }: { id?: number }) => {
  const { state } = useLocation()
  const refModal = useRef<IRefModal>({})

  const { mutateAsync: mutateAsyncUpdate, isLoading } =
    useChangeStatusMemberRegister()

  const { refetch } = useGetMemberRegisteredByAction({
    id,
    status: StatusMemberRegisterWaiting.WAITING,
  })

  const { refetch: refetchAction } = useGetActionByEvent({
    id: state?.event?.id ?? 0,
  })

  const handleSubmit = async () => {
    if (!id) return
    const res = await mutateAsyncUpdate({
      id,
      status: StatusMemberRegisterWaiting.ACCEPTED,
    })
    if (res) {
      refModal?.current?.hide?.()
      notificationController?.success({
        message: 'Phê duyệt đăng kí thành công',
      })

      refetch?.()
      refetchAction?.()
    }
  }

  return (
    <BaseModalComponent
      ref={refModal}
      titleModal="Phê duyệt đăng kí"
      buttonOpenModal={
        <BaseButton
          children="Phê duyệt"
          onClick={() => refModal.current?.open?.()}
          type="text"
        />
      }
      handleSubmit={handleSubmit}
      isLoadingConfirm={isLoading}
      renderContent={
        <BaseSpace size={8}>
          <BaseText
            children={`Bạn có chắc chắn phê duyệt đơn đăng kí này?`}
            fontWeight="medium"
            fontSize="xs"
          />
          <BaseText
            children={`Lưu ý: Sau khi phê duyệt, bạn có thể thay đổi trạng thái của đoàn viên trong hoạt động`}
            colorText="statesOrangeColor"
            opacity="0.8"
          />
        </BaseSpace>
      }
    />
  )
}
