import { Layout } from 'antd'
import { Outlet } from 'react-router-dom'
import EmployeeSideBar from './EmployeeSideBar'
import UIHeader from './Header'
import Sidebar from './Sidebar'
import UserSideBar from './UserSideBar'
import { RoleEnum } from 'app/common/enum'
import { convertedVariables } from 'app/styles/themes/themeVariables'

const { Header, Content } = Layout

interface CustomLayoutProps {
  role?: RoleEnum
  fullName: string
}

const RenderSideBar = (role?: RoleEnum) => {
  switch (role) {
    case RoleEnum.ADMIN:
      return <Sidebar />
    case RoleEnum.USER:
      return <UserSideBar />
    case RoleEnum.EMPLOYEE:
      return <EmployeeSideBar />

    default:
      break
  }
}

const CustomLayout = ({ role, fullName }: CustomLayoutProps) => {
  return (
    <Layout style={{ minHeight: '100vh' }} className="overflow-x-hidden w-full">
      {RenderSideBar(role)}
      <Layout>
        <Header
          style={{ background: convertedVariables.backgroundColor, padding: 0 }}
        >
          <UIHeader name={fullName} role={role} />
        </Header>
        <Content style={{ margin: '16px' }} className="w-full pr-10">
          {<Outlet />}
        </Content>
      </Layout>
    </Layout>
  )
}

export default CustomLayout
