import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { Loading } from 'app/components/common/Loading'
import { SectionBase } from 'app/components/common/SectionBase'
import { DonVi } from 'app/containers/UnitManagement'

export const InfoUnitLayout = ({
  unit,
  isLoading,
}: {
  unit?: DonVi
  isLoading?: boolean
}) => {
  return (
    <SectionBase title="Thông tin đơn vị">
      <BaseSpace>
        <BaseText children={`Tên: ${unit?.ten ?? ''}`} fontWeight="semibold" />
        <BaseText
          children={`Địa chỉ: ${unit?.diachi ?? ''}`}
          fontWeight="medium"
          opacity="0.7"
        />
        <BaseText
          children={`Thành lập từ: ${unit?.ngaythanhlap ?? ''}`}
          fontWeight="medium"
          opacity="0.7"
        />
        {isLoading ? <Loading isAbsolute size="small" /> : null}
      </BaseSpace>
    </SectionBase>
  )
}
