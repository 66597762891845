import { BASE_URL_LOCAL } from 'parkway-web-common'

const TIMESLOT_STEP = 15

const NOTE_MAX_LENGTH = 300

const ENV_CONFIG = {
  BASE_URL_LOCAL: process.env.REACT_APP_BASE_URL_LOCAL ?? BASE_URL_LOCAL,
  TINY_KEY: process.env.REACT_APP_TINY_KEY ?? '',
  API_ENDPOINT: process.env.REACT_APP_API_ENDPOINT ?? '',
  OLD_WEBSITE_ENDPOINT: process.env.REACT_APP_OLD_WEBSITE_ENDPOINT ?? '',
  HR_API_ENDPOINT: process.env.REACT_APP_HR_API_ENDPOINT,
  ACCOUNT_API_ENDPOINT: process.env.REACT_APP_ACCOUNT_API_ENDPOINT,
  CRM_API_ENDPOINT: process.env.REACT_APP_CRM_API_ENDPOINT,
  FIREBASE_API_KEY: process.env.REACT_APP_FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  FIREBASE_PROJECT_ID: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUDGET: process.env.REACT_APP_FIREBASE_STORAGE_BUDGET,
  FIREBASE_MESSAGE_SENDER_ID: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
  FIREBASE_APP_ID: process.env.REACT_APP_FIREBASE_APP_ID,
  FIREBASE_MEASUREMENT_ID: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  UEH_STORAGE: process.env.REACT_APP_UEH_STORAGE,
  URL_WEB: process.env.REACT_APP_URL_WEB ?? 'https://cdv.khcn.cyberdx.tech',
}

export {
  ENV_CONFIG,
  NOTE_MAX_LENGTH,
  TIMESLOT_STEP,
}
