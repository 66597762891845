/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormInstance } from 'antd'
import {
  IPayloadCreateEvent,
  ResourceEventEnum,
  TypeEventEnum,
} from 'app/api/event/model'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { IRefModal } from 'app/components/common/ModalComponent'
import { useCreateEvent } from 'app/react-query/hook/event'
import {
  createContext,
  MutableRefObject,
  useContext,
  useRef,
  useState,
} from 'react'
import { IFormCreateEvent } from './type'
import { useAppSelector } from 'app/redux/hooks'

interface ICreateEventContext {
  form?: FormInstance<IFormCreateEvent>
  modalRef?: MutableRefObject<IRefModal>

  openModal?: () => void
  closeModal?: () => void
  handleSubmit?: () => void

  isLoading?: boolean
  isLoadingSubmit?: boolean
  eventType?: TypeEventEnum
  onChangeEventType?: (type: TypeEventEnum) => void
}
export const CreateEventContext = createContext<ICreateEventContext>({})

export const CreateEventProvider = ({ children }) => {
  const userInfo = useAppSelector(state => state.userOld).profile
  const [form] = BaseForm.useForm<IFormCreateEvent>()
  const modalRef = useRef<IRefModal>({})
  const [info, setInfo] = useState<IFormCreateEvent>({})
  const [eventType, setEventType] = useState<TypeEventEnum | undefined>(
    undefined,
  )

  const { mutateAsync: mutateAsyncCreateEvent, isLoading: isLoadingSubmit } =
    useCreateEvent()

  const [isLoading, setIsLoading] = useState(false)

  const openModal = () => {
    modalRef.current.open?.()
  }

  const closeModal = () => {
    modalRef.current.hide?.()
  }

  const handleSubmit = async (infoData?: IFormCreateEvent) => {
    if (!infoData) return
    setIsLoading(true)

    const infoPayload = infoData ?? info
    try {
      const payload: IPayloadCreateEvent = {
        name: infoPayload.name,
        date_end: infoPayload.endDate?.toISOString(),
        date_started: infoPayload.startDate?.toISOString(),
        desc: infoPayload?.description ?? '-',
        register_end: undefined,
        register_started: undefined,
        type: infoPayload?.type,
        link: infoPayload?.link,
        resource: ResourceEventEnum.UNIT,
        group: infoPayload?.group,
        unit: userInfo?.donvi
      }

      await mutateAsyncCreateEvent?.(payload)

      form?.resetFields?.()
      setInfo({})
      closeModal?.()
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const onChangeEventType = (type?: TypeEventEnum) => {
    setEventType(type)

    form?.setFieldsValue({
      link: undefined,
    })
  }

  return (
    <CreateEventContext.Provider
      value={{
        form,
        modalRef,
        openModal,
        closeModal,
        handleSubmit,
        isLoading,
        isLoadingSubmit: isLoadingSubmit || isLoading,
        onChangeEventType,
        eventType,
      }}
    >
      {children}
    </CreateEventContext.Provider>
  )
}

export function useCreateEventContext() {
  const context = useContext(CreateEventContext)

  if (context === undefined) {
    throw new Error('useCreateEvent must be used within a CreateEventProvider')
  }
  return context
}
