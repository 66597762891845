/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  requestGetDashboardInfo,
  requestGetEventByGroup,
  requestGetEventByType,
  requestGetMemberByUnit,
  requestGetMyAction,
  requestGetMyActionRegister,
  requestGetMyPrize,
  requestGetTop10MemberJoinEvent,
  requestGetTop10MemberJoinEventByUnit,
  requestGetTop10MemberPrize,
  requestGetTop10MemberPrizeByUnit,
  requestGetTop10UnitJoinEvent,
  requestGetTop10UnitPrize,
  requestGetUnitByMember,
} from 'app/api/dashboard'
import {
  IDashboardInfo,
  IDataDashboard,
  IDataDashboardEmployee,
  IDataDashboardUser,
  IDataTop10MemberJoinEvent,
  IDataTop10MemberPrize,
  IDataTop10UnitJoinEvent,
  IDataTop10UnitPrize,
} from 'app/api/dashboard/model'
import { IActionEvent, IPrizeActionEvent } from 'app/api/event/model'
import { useAppSelector } from 'app/redux/hooks'
import { useQuery } from 'react-query'
import { DashboardInfoKeys } from '../query-key/dashboard'

export function useGetDashboardInfo(params?: {
  startDate?: string
  endDate?: string
}) {
  const user = useAppSelector(state => state?.userOld)?.profile
  return useQuery<IDashboardInfo, undefined>(
    DashboardInfoKeys.dashboardInfo(user?.maso_doanvien, params),
    async () => {
      const resPromiseAll = await Promise.all([
        requestGetDashboardInfo(params),
        requestGetTop10MemberPrize(params),
        requestGetTop10MemberJoinEvent(params),
        requestGetTop10UnitPrize(params),
        requestGetTop10UnitJoinEvent(params),
        requestGetEventByType(params),
        requestGetEventByGroup(params),
      ])

      const data: IDashboardInfo = {
        info: (resPromiseAll?.[0] as IDataDashboard) ?? {},
        top10MemberPrize: resPromiseAll?.[1] ?? ([] as IDataTop10MemberPrize[]),
        top10MemberJoinEvent:
          resPromiseAll?.[2] ?? ([] as IDataTop10MemberJoinEvent[]),

        top10UnitPrize: resPromiseAll?.[3] ?? ([] as IDataTop10UnitPrize[]),
        top10UnitJoinEvent:
          resPromiseAll?.[4] ?? ([] as IDataTop10UnitJoinEvent[]),

        event: resPromiseAll?.[5] ?? {
          activity: [],
          care: [],
          news: [],
          training: [],
        },

        eventByGroup: resPromiseAll?.[6] ?? {
          artistic: [],
          check: [],
          community: [],
          femaleWorker: [],
          organization: [],
          propaganda: [],
          reward: []
        },
      }

      return data
    },
    {
      cacheTime: 5000,
    },
  )
}

export function useGetDashboardUser() {
  const user = useAppSelector(state => state?.userOld)?.profile
  return useQuery<IDataDashboardUser, undefined>(
    DashboardInfoKeys.dashboardUser(user?.donvi),
    async () => {
      if (!user?.donvi) {
        return {
          listDoanVien: [],
          top10Prize: [],
          top10Action: [],
        }
      }

      const resPromiseAll = await Promise.all([
        await requestGetMemberByUnit(user?.donvi),
        await requestGetTop10MemberPrizeByUnit(user?.donvi),
        await requestGetTop10MemberJoinEventByUnit(user?.donvi),
      ])

      const data: IDataDashboardUser = {
        listDoanVien: resPromiseAll?.[0] ?? ([] as IDataTop10MemberPrize[]),
        top10Prize: resPromiseAll?.[1] ?? ([] as IDataTop10MemberPrize[]),
        top10Action: resPromiseAll?.[2] ?? ([] as IDataTop10MemberJoinEvent[]),
      }

      return data
    },
    {
      cacheTime: 5000,
    },
  )
}

export function useGetDashboardEmployee(tumId?: string) {
  const user = useAppSelector(state => state?.userOld)?.profile
  const maso_doanvien = tumId ?? user?.maso_doanvien
  return useQuery<IDataDashboardEmployee, undefined>(
    DashboardInfoKeys.dashboardEmployee(maso_doanvien),
    async () => {
      if (!maso_doanvien) {
        return {
          donvi: {},
          myAction: [],
          myPrize: [],
          myActionRegister: [],
        }
      }

      const resPromiseAll = await Promise.all([
        await requestGetUnitByMember(maso_doanvien),
        await requestGetMyPrize(maso_doanvien),
        await requestGetMyAction(maso_doanvien),
        await requestGetMyActionRegister(maso_doanvien),
      ])

      const data: IDataDashboardEmployee = {
        donvi: resPromiseAll?.[0] ?? {},
        myPrize: resPromiseAll?.[1] ?? ([] as IPrizeActionEvent[]),
        myAction: resPromiseAll?.[2] ?? ([] as IActionEvent[]),
        myActionRegister: resPromiseAll?.[3] ?? ([] as IActionEvent[]),
      }

      return data
    },
    {
      cacheTime: 5000,
    },
  )
}
