import { DownloadOutlined, ExportOutlined } from '@ant-design/icons'
import { StatusMemberRegisterWaiting } from 'app/api/event/model'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseCollapse } from 'app/components/common/BaseCollapse'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseTabs } from 'app/components/common/BaseTabs'
import BaseText from 'app/components/common/BaseText'
import { Loading } from 'app/components/common/Loading'
import { SectionBase } from 'app/components/common/SectionBase'
import QRCode from 'qrcode.react'
import { IActivityEvent } from '../../../../CreateEvent/type'
import {
    ActionRegisteredByStatusLayout,
    ColumnTumActivity,
    RewardLayout,
} from '../../layouts'

interface IProps {
  data: IActivityEvent[]
  isLoading: boolean
  downloadQRCode: () => void
}

export const ActivityContent = ({
  data,
  downloadQRCode,
  isLoading,
}: IProps) => {
  return (
    <SectionBase>
      <BaseSpace>
        <BaseRow align={'middle'} justify={'space-between'} gutter={[16, 16]}>
          <BaseCol>
            <BaseText
              children={'Danh sách hoạt động'}
              fontWeight="semibold"
              fontSize="md"
            />
          </BaseCol>
          <BaseCol>
            <BaseButton icon={<ExportOutlined rev={undefined} />}>
              {'Export excel'}
            </BaseButton>
          </BaseCol>
        </BaseRow>
        {data?.length ? (
          data?.map((item, index) => {
            return (
              <BaseCollapse
                items={[
                  {
                    key: index,
                    label: (
                      <BaseText
                        children={item?.title ?? ''}
                        fontWeight="semibold"
                        fontSize="xs"
                      />
                    ),
                    children: (
                      <BaseSpace>
                        <BaseRow
                          align={'middle'}
                          justify={'center'}
                          gutter={16}
                        >
                          <BaseCol>
                            <QRCode
                              id="qrCodeEl"
                              size={200}
                              style={{
                                height: 'auto',
                                maxWidth: '200px',
                                width: '200px',
                              }}
                              value={`https://cdv.khcn.cyberdx.tech/check-in-action/${item?.id}`}
                              viewBox={`0 0 200 200`}
                            />
                          </BaseCol>
                          <BaseCol>
                            <BaseButton
                              type="text"
                              icon={<DownloadOutlined rev={undefined} />}
                              onClick={downloadQRCode}
                            />
                          </BaseCol>
                        </BaseRow>

                        <BaseRow
                          align={'middle'}
                          justify={'space-between'}
                          gutter={[16, 8]}
                        >
                          <BaseCol>
                            <BaseText
                              children={'Danh sách đối tượng tham gia'}
                              fontSize="xs"
                              fontWeight="medium"
                            />
                          </BaseCol>
                          <BaseCol>
                            <BaseButton
                              icon={<ExportOutlined rev={undefined} />}
                            >
                              {'Export excel'}
                            </BaseButton>
                          </BaseCol>
                        </BaseRow>
                        <ColumnTumActivity activity={item} />

                        <BaseText
                          children={'Danh sách chờ phê duyệt'}
                          fontWeight="semibold"
                          fontSize="xs"
                        />

                        <BaseTabs
                          items={[
                            {
                              key: '1',
                              label: 'Đang chờ',
                              children: (
                                <ActionRegisteredByStatusLayout
                                  status={StatusMemberRegisterWaiting.WAITING}
                                  actionId={item?.id}
                                />
                              ),
                            },
                            {
                              key: '2',
                              label: 'Đã phê duyệt',
                              children: (
                                <ActionRegisteredByStatusLayout
                                  status={StatusMemberRegisterWaiting.ACCEPTED}
                                  actionId={item?.id}
                                />
                              ),
                            },
                            {
                              key: '3',
                              label: 'Từ chối',
                              children: (
                                <ActionRegisteredByStatusLayout
                                  status={StatusMemberRegisterWaiting.REJECTED}
                                  actionId={item?.id}
                                />
                              ),
                            },
                          ]}
                        />

                        <BaseRow
                          gutter={[16, 8]}
                          align={'middle'}
                          justify={'space-between'}
                        >
                          <BaseCol>
                            <BaseText
                              children={'Danh sách giải thưởng'}
                              fontWeight="medium"
                              fontSize="xs"
                            />
                          </BaseCol>
                          <BaseCol>
                            <BaseButton
                              icon={<ExportOutlined rev={undefined} />}
                            >
                              {'Export excel'}
                            </BaseButton>
                          </BaseCol>
                        </BaseRow>

                        <RewardLayout activity={item} />
                      </BaseSpace>
                    ),

                    style: {
                      alignItems: 'center',
                    },
                  },
                ]}
                defaultActiveKey={index}
                expandIconPosition="right"
              />
            )
          })
        ) : (
          <BaseText
            children={'Danh sách hoạt động trống'}
            fontWeight="medium"
          />
        )}
      </BaseSpace>
      {isLoading ? <Loading isAbsolute size="small" /> : null}
    </SectionBase>
  )
}
