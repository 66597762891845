import { UploadOutlined } from '@ant-design/icons'
import TextArea from 'antd/es/input/TextArea'
import { ENV_CONFIG } from 'app/common/config'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseSpace } from 'app/components/common/BaseSpace'
import {
  BaseModalComponent,
  IRefModal,
} from 'app/components/common/ModalComponent'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { BaseDatePicker } from 'app/components/common/pickers/BaseDatePicker'
import { DonVi } from 'app/containers/UnitManagement'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import axios from 'axios'
import dayjs, { Dayjs } from 'dayjs'
import { BORDER_RADIUS, moment } from 'parkway-web-common'
import React, { useEffect } from 'react'
import toast from 'react-hot-toast'

interface IFormPayloadUpdateUnit {
  ten?: string
  diachi?: string
  chucdanh?: string
  ngaythanhlap?: Dayjs
  thongtindonvi?: string
}

export const UpdateUnitModal = ({
  donviDetail,
  refetch,
}: {
  donviDetail?: DonVi
  refetch?: () => void
}) => {
  const [form] = BaseForm.useForm<IFormPayloadUpdateUnit>()
  const modalUpdateRef = React.useRef<IRefModal>(null)

  const [loading, setLoading] = React.useState<boolean>(false)

  useEffect(() => {
    form.setFieldsValue({
      ten: donviDetail?.ten ?? '',
      diachi: donviDetail?.diachi ?? '',
      ngaythanhlap: donviDetail?.ngaythanhlap
        ? dayjs(moment(donviDetail?.ngaythanhlap, 'DD-MM-YYYY').toDate())
        : undefined,
      thongtindonvi: donviDetail?.thongtindonvi ?? '',
    })
  }, [donviDetail])

  async function updateDonVi(values?: IFormPayloadUpdateUnit) {
    if (!donviDetail?.id) return
    try {
      setLoading(true)
      const res = await axios.put(
        `${ENV_CONFIG.API_ENDPOINT}/api/v1/donvi/update/${donviDetail?.id}`,
        {
          ten: values?.ten,
          diachi: values?.diachi,
          ngaythanhlap: values?.ngaythanhlap
            ? values?.ngaythanhlap?.format('DD-MM-YYYY')
            : donviDetail?.ngaythanhlap,
          thongtindonvi: values?.thongtindonvi ?? '',
        },
      )

      if (res) {
        form.resetFields()
        refetch?.()
        modalUpdateRef?.current?.hide?.()
        toast.success('Cập nhật đơn vị thành công')
      }
    } catch (error) {
      toast.error('Đã Có Lỗi Xảy Ra! Hãy Thử Lại')
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <BaseModalComponent
      buttonOpenModal={
        <BaseButton
          onClick={() => {
            modalUpdateRef?.current?.open?.()
          }}
        >
          Cập nhật đơn vị
        </BaseButton>
      }
      titleModal="Cập nhật đơn vị"
      titleOpenModal="Cập nhật đơn vị"
      handleSubmit={form.submit}
      isLoadingConfirm={loading}
      ref={modalUpdateRef}
      borderButtonOpen={`1px solid ${convertedVariables.neutralBlack2Color}`}
      borderRadiusButtonOpen={BORDER_RADIUS}
      renderContent={
        <BaseForm form={form} onFinish={updateDonVi}>
          <BaseSpace size={4}>
            <BaseForm.Item
              name={'ten'}
              label={'Tên đơn vị'}
              required
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập tên đơn vị',
                },
              ]}
            >
              <BaseInput placeholder="Nhập tên đơn vị" />
            </BaseForm.Item>
            <BaseForm.Item
              name={'diachi'}
              label={'Địa chỉ đơn vị'}
              required
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập địa chỉ',
                },
              ]}
            >
              <BaseInput placeholder="Nhập địa chỉ" />
            </BaseForm.Item>

            <BaseForm.Item
              name={'ngaythanhlap'}
              label={'Ngày thành lập đơn vị'}
              required
              rules={[
                {
                  required: true,
                  message: 'Vui lòng chọn ngày thành lập',
                },
              ]}
            >
              <BaseDatePicker
                placeholder="Chọn ngày thành lập"
                style={{ width: '100%' }}
                format={'DD-MM-YYYY'}
              />
            </BaseForm.Item>

            <BaseForm.Item name={'thongtindonvi'} label={'Thông tin đơn vị'}>
              <TextArea placeholder="Nhập thông tin đơn vị" rows={5} />
            </BaseForm.Item>

            <BaseButton icon={<UploadOutlined rev={undefined} />}>
              {'Upload file'}
            </BaseButton>
          </BaseSpace>
        </BaseForm>
      }
    />
  )
}
