/* eslint-disable @typescript-eslint/no-explicit-any */
import R from 'app/assets/R'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { IRefModal } from 'app/components/common/ModalComponent'
import { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IFormChangePassword, IRuleChangePassword } from './type'
import axios from 'axios'
import { ENV_CONFIG } from 'app/common/config'
import { useAppSelector } from 'app/redux/hooks'
import { message } from 'antd'

export const useModalHook = () => {
  const { t } = useTranslation()
  const userInfo = useAppSelector(state => state.userOld.profile)
  const modalRef = useRef<IRefModal>({})
  const [form] = BaseForm.useForm<IFormChangePassword>()
  const [isLoading, setIsLoading] = useState(false)
  const [module, setModule] = useState<string | undefined>()

  const handleCancel = () => {
    form.resetFields()
  }

  const handleSubmit = async (values: IFormChangePassword) => {
    if (!userInfo?.maso_doanvien) {
      return
    }

    setIsLoading(true)

    try {
      await axios.put(
        `${ENV_CONFIG.API_ENDPOINT}/api/v1/doanvien/update/password`,
        {
          password: values.newPassword,
          oldPassword: values.oldPassword,
          maso_doanvien: userInfo?.maso_doanvien,
        },
      )

      message.success(t(R.strings.change_password_success))
      form.resetFields()
      hideModal()
    } catch (error: any) {
      message.error(error?.response?.data?.message)
      setIsLoading(false)
    } finally {
      setIsLoading(false)
    }
  }

  const onChangeModule = (value: string) => {
    setModule(value)
  }

  const rules: IRuleChangePassword = useMemo(() => {
    return {
      oldPassword: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.old_password),
          }),
        },
      ],
      newPassword: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.new_password),
          }),
        },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue('oldPassword') !== `${value}`) {
              return Promise.resolve()
            }
            return Promise.reject(
              new Error(t(R.strings.new_password_need_different_old_password)),
            )
          },
        }),
      ],
      confirmNewPassword: [
        {
          required: true,
          message: t(R.strings.require_field, {
            field: t(R.strings.confirm_new_password),
          }),
        },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue('newPassword') === `${value}`) {
              return Promise.resolve()
            }
            return Promise.reject(
              new Error(
                t(R.strings.the_new_password_that_you_entered_do_not_match),
              ),
            )
          },
        }),
      ],
    }
  }, [t])

  const openModal = () => {
    modalRef.current?.open?.()
  }

  const hideModal = () => {
    modalRef.current?.hide?.()
  }

  return {
    form,
    t,
    isLoading,
    handleSubmit,
    handleCancel,
    rules,
    onChangeModule,
    module,
    openModal,
    modalRef,
    hideModal,
  }
}
