/* eslint-disable @typescript-eslint/no-unused-vars */
import { PlusOutlined } from '@ant-design/icons'
import { DefineRoleEnum, RoleEnum } from 'app/common/enum'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import {
  BaseModalComponent,
  IRefModal,
} from 'app/components/common/ModalComponent'
import { BaseDatePicker } from 'app/components/common/pickers/BaseDatePicker'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { useAppSelector } from 'app/redux/hooks'
import { Dayjs } from 'dayjs'
import { REG_EMAIL } from 'parkway-web-common'
import React, { useState } from 'react'
import styled from 'styled-components'
import {
  DefineJobTitleEnum,
  JobTitleEnum,
  OptionSelectJobTitle,
} from '../../type'
import axios from 'axios'
import { ENV_CONFIG } from 'app/common/config'
import { ApiClient, ApiClientHandle } from 'app/service/api-service'
import { useGetDoanVien } from 'app/react-query/hooks-old/useGetUser'
import TextArea from 'antd/es/input/TextArea'
import { useGetAllDonVi } from 'app/react-query/hooks-old/useDonVi'
import { DonVi } from 'app/containers/UnitManagement'

interface IFormData {
  chucdanh?: JobTitleEnum[]
  hovaten?: string
  quequan?: string
  cmnd?: string
  gioitinh?: string
  ngaysinh?: Dayjs
  email?: string
  role?: RoleEnum
  donvi?: string
  ngayvao_congdoan?: Dayjs
  tinh?: string
  maso_doanvien?: string

  chucvu?: string
}

export const CreateTumModal = () => {
  const user = useAppSelector(state => state?.userOld)?.profile
  const [form] = BaseForm.useForm<IFormData>()
  const modalUpdateRef = React.useRef<IRefModal>(null)
  const [loading, setLoading] = useState(false)

  const { data: unit, isLoading: loadingUnit } = useGetAllDonVi()

  const { refetch } = useGetDoanVien()

  async function updateCongDoan(values?: IFormData) {
    setLoading(true)
    try {
      const newRow = {
        hovaten: values?.hovaten ?? '',
        quequan: values?.quequan ?? '',
        gioitinh: values?.gioitinh ?? '',
        ngaysinh: values?.ngaysinh?.format('DD-MM-YYYY') ?? '',
        tinh: values?.tinh ?? '',
        cmnd: values?.cmnd ?? '',
        donvi: values?.donvi ?? user?.donvi ?? '',
        email: values?.email ?? '',
        ngayvao_congdoan: values?.ngayvao_congdoan
          ? values?.ngayvao_congdoan?.format('DD-MM-YYYY')
          : '',
        maso_doanvien: values?.maso_doanvien ?? '',
        role: values?.role,
        chucdanh: values?.chucdanh?.join('&') ?? '',
        chucvu: values?.chucvu ?? '',
      }

      const res = await ApiClient.Post({
        url: `${ENV_CONFIG.API_ENDPOINT}/api/v1/doanvien/create`,
        body: newRow,
      })

      if (res) {
        modalUpdateRef?.current?.hide?.()
        refetch?.()
        form.resetFields()
      }
    } catch (error) {
      console.log('error', error)
    } finally {
      setLoading(false)
    }
  }

  const buttonStyle = {
    backgroundColor: '#A3D2CA',
    color: 'white',
    border: 'none',
  }

  return (
    <BaseModalComponent
      titleModal={`Thêm công đoàn viên`}
      handleSubmit={form.submit}
      ref={modalUpdateRef}
      widthModal={900}
      isLoadingConfirm={loading}
      buttonOpenModal={
        <BaseButton
          onClick={() => modalUpdateRef?.current?.open?.()}
          icon={<PlusOutlined rev={undefined} />}
        >
          Thêm đoàn viên
        </BaseButton>
      }
      renderContent={
        <BaseForm form={form} onFinish={updateCongDoan}>
          <BaseSpace size={4}>
            <BaseForm.Item
              label={'Mã số đoàn viên'}
              required
              name={'maso_doanvien'}
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập mã số đoàn viên',
                },
              ]}
            >
              <BaseInput placeholder="Nhập mã số đoàn viên" />
            </BaseForm.Item>
            <BaseForm.Item
              label={'Tên công đoàn viên'}
              required
              name={'hovaten'}
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập tên công đoàn viên',
                },
              ]}
            >
              <BaseInput placeholder="Nhập tên đoàn viên" />
            </BaseForm.Item>

            <BaseRow align={'top'} gutter={[16, 8]} justify={'space-between'}>
              <BaseCol xl={12}>
                <BaseForm.Item
                  label={'CMND/CCCD'}
                  required
                  name={'cmnd'}
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng nhập CMND/CCCD',
                    },
                    {
                      pattern: new RegExp('^[0-9]{9}$|^[0-9]{12}$'),
                      message: 'CMND phải có 9 hoặc 12 kí tự',
                    },
                  ]}
                >
                  <BaseInput placeholder="Vui lòng nhập CMND/CCCD" />
                </BaseForm.Item>
              </BaseCol>
              <BaseCol xl={12}>
                <BaseForm.Item
                  label={'Email'}
                  required
                  name={'email'}
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng nhập email',
                    },
                    {
                      pattern: REG_EMAIL,
                      message: 'Email không hợp lệ',
                    },
                  ]}
                >
                  <BaseInput placeholder="Nhập email" />
                </BaseForm.Item>
              </BaseCol>
            </BaseRow>

            <BaseRow
              align={'middle'}
              gutter={[16, 8]}
              justify={'space-between'}
            >
              <BaseCol xl={12}>
                <BaseForm.Item
                  label={'Quê quán (theo CCCD)'}
                  required
                  name={'quequan'}
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng nhập quê quán',
                    },
                  ]}
                >
                  <BaseInput placeholder="Vui lòng nhập quê quán" />
                </BaseForm.Item>
              </BaseCol>

              <BaseCol xl={12}>
                <BaseForm.Item
                  label={'Giới tính'}
                  required
                  name={'gioitinh'}
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng chọn giới tính',
                    },
                  ]}
                >
                  <BaseSelect
                    placeholder="Chọn giới tính"
                    options={[
                      {
                        label: 'Nam',
                        value: 'Nam',
                      },
                      {
                        label: 'Nữ',
                        value: 'Nữ',
                      },
                      {
                        label: 'Khác',
                        value: 'Khác',
                      },
                    ]}
                  />
                </BaseForm.Item>
              </BaseCol>
            </BaseRow>

            <BaseRow align={'top'} gutter={[16, 8]} justify={'space-between'}>
              <BaseCol xl={12}>
                <BaseForm.Item
                  label={'Ngày sinh'}
                  required
                  name={'ngaysinh'}
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng chọn ngày sinh',
                    },
                  ]}
                >
                  <BaseDatePickerStyled
                    format={'DD-MM-YYYY'}
                    placeholder="Chọn ngày sinh"
                    allowClear={false}
                  />
                </BaseForm.Item>
              </BaseCol>

              <BaseCol xl={12}>
                <BaseForm.Item
                  label={'Ngày vào công đoàn'}
                  name={'ngayvao_congdoan'}
                >
                  <BaseDatePickerStyled
                    format={'DD-MM-YYYY'}
                    placeholder="Chọn ngày vào công đoàn"
                    allowClear={true}
                  />
                </BaseForm.Item>
              </BaseCol>
            </BaseRow>

            <BaseRow align={'top'} gutter={[16, 8]} justify={'space-between'}>
              <BaseCol xl={12}>
                <BaseForm.Item
                  name={'chucdanh'}
                  label={'Chức vụ công đoàn'}
                  required
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng chọn chức vụ công đoàn',
                    },
                  ]}
                >
                  <BaseSelect
                    placeholder="Chọn chức vụ công đoàn"
                    mode="multiple"
                    maxTagCount={2}
                    options={OptionSelectJobTitle}
                  />
                </BaseForm.Item>
              </BaseCol>

              <BaseCol xl={12}>
                <BaseForm.Item
                  name={'role'}
                  label={'Quyền'}
                  required
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng chọn quyền',
                    },
                  ]}
                >
                  <BaseSelect
                    placeholder="Chọn quyền"
                    options={[
                      {
                        label: DefineRoleEnum[RoleEnum.ADMIN],
                        value: RoleEnum.ADMIN,
                      },
                      {
                        label: DefineRoleEnum[RoleEnum.USER],
                        value: RoleEnum.USER,
                      },
                      {
                        label: DefineRoleEnum[RoleEnum.EMPLOYEE],
                        value: RoleEnum.EMPLOYEE,
                      },
                    ]}
                  />
                </BaseForm.Item>
              </BaseCol>
            </BaseRow>
            <BaseForm.Item label={'Chức vụ'} name={'chucvu'}>
              <TextArea placeholder="Nhập chức vụ" rows={5} />
            </BaseForm.Item>
            <BaseForm.Item
              label={'Đơn vị'}
              name={'donvi'}
              required
              rules={[
                {
                  required: true,
                  message: 'Vui lòng chọn đơn vị',
                },
              ]}
            >
              <BaseSelect
                options={(unit as DonVi[] ?? [])?.map(item => ({
                  value: item.ten,
                  label: item.ten,
                }))}
                placeholder="Chọn đơn vị"
                loading={loadingUnit}
              />
            </BaseForm.Item>
          </BaseSpace>
        </BaseForm>
      }
    />
  )
}

const BaseDatePickerStyled = styled(BaseDatePicker)`
  width: 100%;
`
