import { MoreOutlined } from '@ant-design/icons'
import { Timeline } from 'antd'
import { DefineRoleEnum } from 'app/common/enum'
import { BaseAvatar } from 'app/components/common/BaseAvatar'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BasePopover } from 'app/components/common/BasePopover'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { Loading } from 'app/components/common/Loading'
import { SectionBase } from 'app/components/common/SectionBase'
import HeaderPage from 'app/components/header/components/HeaderPage'
import { StatusWrapper } from 'app/components/tables/BaseTableManagement/styles'
import { useGetDashboardEmployee } from 'app/react-query/hook/dashboard'
import { useGetDoanvienHistory } from 'app/react-query/hooks-old/useGetUser'
import { useAppDispatch, useAppSelector } from 'app/redux/hooks'
import { getUserInfoOld } from 'app/redux/slices/userOldSlice'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { FONT_SIZE } from 'parkway-web-common'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { IHistoryDoanVien } from '../TumManagement/Detail'
import { DefineJobTitleEnum, JobTitleEnum } from '../TumManagement/type'
import {
  ModalChangePassword,
  MyActionLayout,
  MyPrizeLayout,
  UpdateProfile,
} from './layouts'

export const ProfileContainer: React.FC = () => {
  const dispatch = useAppDispatch()

  const userInfo = useAppSelector(state => state.userOld.profile)

  const { data, isLoading } = useGetDashboardEmployee()

  const { data: history, isLoading: isLoadingHistory } = useGetDoanvienHistory(
    userInfo?.maso_doanvien,
  )

  useEffect(() => {
    dispatch(getUserInfoOld())
  }, [])

  return (
    <BaseSpace>
      <HeaderPage
        title="Thông tin cá nhân"
        rightComponent={
          <BasePopover
            trigger={'click'}
            content={
              <BaseSpace size={8}>
                <UpdateProfile doanVien={userInfo} />

                <ModalChangePassword />
              </BaseSpace>
            }
          >
            <BaseButton type="text" icon={<MoreOutlined rev={undefined} />} />
          </BasePopover>
        }
      />

      <SectionBase>
        <BaseSpace>
          <BaseRow gutter={[16, 8]} align={'middle'}>
            <BaseCol>
              <BaseAvatar
                alt={userInfo?.hovaten}
                size={120}
                style={{
                  fontSize: FONT_SIZE.xxxxl,
                }}
              />
            </BaseCol>
            <BaseCol>
              <BaseSpace size={10}>
                <BaseText
                  children={userInfo?.hovaten}
                  fontWeight="semibold"
                  fontSize="xl"
                />

                <BaseText
                  children={
                    userInfo?.chucdanh
                      ?.split('&')
                      ?.map(item => DefineJobTitleEnum[item as JobTitleEnum])
                      .join(' & ') ?? ''
                  }
                  fontWeight="medium"
                  fontSize="xs"
                />

                <StatusWrapper
                  status={userInfo?.trangthai ? 'active' : 'inactive'}
                >
                  {userInfo?.trangthai ? 'Đang hoạt động' : 'Ngưng hoạt động'}
                </StatusWrapper>
              </BaseSpace>
            </BaseCol>
          </BaseRow>
          <Line />

          <BaseRow align={'top'} gutter={[16, 16]} justify={'start'}>
            <BaseCol xl={6}>
              <BaseSpace size={8}>
                <BaseText
                  children={'Ngày sinh'}
                  fontWeight="medium"
                  fontSize="xs"
                  opacity="0.7"
                />
                <BaseText
                  children={userInfo?.ngaysinh}
                  fontWeight="semibold"
                  fontSize="xs"
                />
              </BaseSpace>
            </BaseCol>

            <BaseCol xl={6}>
              <BaseSpace size={8}>
                <BaseText
                  children={'Giới tính'}
                  fontWeight="medium"
                  fontSize="xs"
                  opacity="0.7"
                />
                <BaseText
                  children={userInfo?.gioitinh ?? '-'}
                  fontWeight="semibold"
                  fontSize="xs"
                />
              </BaseSpace>
            </BaseCol>

            <BaseCol xl={6}>
              <BaseSpace size={8}>
                <BaseText
                  children={'Quê quán'}
                  fontWeight="medium"
                  fontSize="xs"
                  opacity="0.7"
                />
                <BaseText
                  children={userInfo?.quequan}
                  fontWeight="semibold"
                  fontSize="xs"
                />
              </BaseSpace>
            </BaseCol>

            <BaseCol xl={6}>
              <BaseSpace size={8}>
                <BaseText
                  children={'Email'}
                  fontWeight="medium"
                  fontSize="xs"
                  opacity="0.7"
                />
                <BaseText
                  children={userInfo?.email ?? '-'}
                  fontWeight="semibold"
                  fontSize="xs"
                />
              </BaseSpace>
            </BaseCol>

            <BaseCol xl={6}>
              <BaseSpace size={8}>
                <BaseText
                  children={'CMND/CCCD'}
                  fontWeight="medium"
                  fontSize="xs"
                  opacity="0.7"
                />
                <BaseText
                  children={userInfo?.cmnd ?? '-'}
                  fontWeight="semibold"
                  fontSize="xs"
                />
              </BaseSpace>
            </BaseCol>

            <BaseCol xl={6}>
              <BaseSpace size={8}>
                <BaseText
                  children={'Ngày vào công đoàn'}
                  fontWeight="medium"
                  fontSize="xs"
                  opacity="0.7"
                />
                <BaseText
                  children={userInfo?.ngayvao_congdoan ?? '-'}
                  fontWeight="semibold"
                  fontSize="xs"
                />
              </BaseSpace>
            </BaseCol>

            <BaseCol xl={6}>
              <BaseSpace size={8}>
                <BaseText
                  children={'Quyền hệ thống'}
                  fontWeight="medium"
                  fontSize="xs"
                  opacity="0.7"
                />
                <BaseText
                  children={
                    userInfo?.role ? DefineRoleEnum[userInfo?.role] : '-'
                  }
                  fontWeight="semibold"
                  fontSize="xs"
                />
              </BaseSpace>
            </BaseCol>

            <BaseCol xl={6}>
              <BaseSpace size={8}>
                <BaseText
                  children={'Đơn vị công tác'}
                  fontWeight="medium"
                  fontSize="xs"
                  opacity="0.7"
                />
                <BaseText
                  children={userInfo?.donvi ?? '-'}
                  fontWeight="semibold"
                  fontSize="xs"
                />
              </BaseSpace>
            </BaseCol>

            <BaseCol xl={6}>
              <BaseSpace size={8}>
                <BaseText
                  children={'Chức vụ công đoàn'}
                  fontWeight="medium"
                  fontSize="xs"
                  opacity="0.7"
                />
                <BaseText
                  children={
                    userInfo?.chucdanh
                      ?.split('&')
                      ?.map(item => DefineJobTitleEnum[item as JobTitleEnum])
                      .join(' & ') ?? ''
                  }
                  fontWeight="semibold"
                  fontSize="xs"
                />
              </BaseSpace>
            </BaseCol>
          </BaseRow>

          <BaseRow>
            <BaseCol xl={24}>
              <BaseSpace size={8}>
                <BaseText
                  children={'Chức vụ'}
                  fontWeight="medium"
                  fontSize="xs"
                  opacity="0.7"
                />
                <BaseText
                  children={userInfo?.chucvu ?? '-'}
                  fontWeight="semibold"
                  fontSize="xs"
                />
              </BaseSpace>
            </BaseCol>
          </BaseRow>
        </BaseSpace>
      </SectionBase>

      <SectionBase title="Lịch sử công tác">
        <BaseSpace>
          <Timeline>
            <Timeline.Item
              children={
                <BaseSpace>
                  <BaseText
                    children={`Công tác tại đơn vị: ${userInfo?.donvi ?? ''}`}
                    opacity="0.7"
                    fontWeight="semibold"
                  />
                  <BaseText
                    children={`Chức vụ: ${
                      userInfo?.chucdanh
                        ?.split('&')
                        ?.map(item => DefineJobTitleEnum[item as JobTitleEnum])
                        .join(' & ') ?? ''
                    }`}
                    opacity="0.7"
                    fontWeight="semibold"
                  />
                </BaseSpace>
              }
            />

            {((history as IHistoryDoanVien[]) ?? [])?.map((item, index) => {
              return (
                <Timeline.Item
                  key={index}
                  children={
                    <BaseSpace>
                      <BaseText
                        children={`Công tác tại đơn vị: ${
                          item?.thong_tin_doan_vien?.donvi?.ten ?? '-'
                        }`}
                        opacity="0.7"
                        fontWeight="semibold"
                      />
                      <BaseText
                        children={`Chức vụ: ${
                          item?.thong_tin_doan_vien?.chucdanh
                            ?.split('&')
                            ?.map(
                              item => DefineJobTitleEnum[item as JobTitleEnum],
                            )
                            .join(' & ') ?? ''
                        }`}
                        opacity="0.7"
                        fontWeight="semibold"
                      />
                    </BaseSpace>
                  }
                />
              )
            })}
          </Timeline>

          {isLoadingHistory ? <Loading isAbsolute size="small" /> : null}
        </BaseSpace>
      </SectionBase>

      <MyPrizeLayout
        listPrize={data?.myPrize ?? []}
        isLoading={isLoading}
        inProfile
      />

      <MyActionLayout listAction={data?.myAction ?? []} isLoading={isLoading} />
    </BaseSpace>
  )
}

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${convertedVariables.neutralBlack9Color};
`
