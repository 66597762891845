/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormInstance } from 'antd'
import {
  IPayloadActionEvent,
  IPayloadCreateActionEvent,
  IPayloadCreateEvent,
  ResourceEventEnum,
  TypeEventEnum,
} from 'app/api/event/model'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import {
  useCreateActionEvent,
  useCreateEvent,
  useGetEventList,
} from 'app/react-query/hook/event'
import { isEqual } from 'lodash'
import { createContext, useContext, useState } from 'react'
import { useNavigate } from 'react-router'
import { useActivityHook } from './layouts/ActionEvent/hook'
import {
  IActivityEvent,
  IColumnActivity,
  IDataTumActivity,
  IFormCreateEvent,
  IRewardActivity,
  TypeColumn,
} from './type'

interface ICreateEventContext {
  form?: FormInstance<IFormCreateEvent>

  handleSubmit?: (values: IFormCreateEvent) => void

  isLoading?: boolean

  activities?: IActivityEvent[]
  addActivity?: (activity: IActivityEvent) => void
  updateTitleInActiveByIndex?: (index: number, title: string) => void
  updateValueCareInActiveByIndex?: (index: number, title: string) => void
  removeActivityByIndex?: (index: number) => void
  addColumnInActivityByIndex?: (index: number, column: IColumnActivity) => void
  removeColumnInActivityByIndex?: (
    activityIndex: number,
    columnIndex: number,
  ) => void
  updateColumnNameInActivityByIndex?: (
    activityIndex: number,
    columnIndex: number,
    name: string,
  ) => void
  updateColumnTypeInActivityByIndex?: (
    activityIndex: number,
    columnIndex: number,
    type: TypeColumn,
  ) => void
  updateDataTumInActivityByIndex?: (
    activityIndex: number,
    data: IDataTumActivity[],
  ) => void
  updateActivityByIndex?: (index: number, activity: IActivityEvent) => void

  updateRewardInActivityByIndex?: (
    activityIndex: number,
    rewardIndex: number,
    reward: IRewardActivity,
  ) => void

  deleteRewardInActivityByIndex?: (
    activityIndex: number,
    rewardIndex: number,
  ) => void

  addRewardInActivityByIndex?: (
    activityIndex: number,
    reward: IRewardActivity,
  ) => void

  isLoadingSubmit?: boolean

  eventType?: TypeEventEnum
  onChangeEventType?: (type: TypeEventEnum) => void
}
export const CreateEventContext = createContext<ICreateEventContext>({})

export const CreateEventProvider = ({ children }) => {
  const navigate = useNavigate()
  const [form] = BaseForm.useForm<IFormCreateEvent>()
  const [eventType, setEventType] = useState<TypeEventEnum | undefined>(
    undefined,
  )

  const { mutateAsync: mutateAsyncCreateEvent, isLoading: isLoadingSubmit } =
    useCreateEvent()

  const { mutateAsync: mutateAsyncCreateActionEvent } = useCreateActionEvent()

  const { refetch } = useGetEventList({})

  const [isLoading, setIsLoading] = useState(false)

  const activityHook = useActivityHook()

  const onRefetchAndGoBack = () => {
    refetch()

    form?.resetFields?.()
    activityHook?.resetAllData()
    navigate?.(-1)
  }

  const handleSubmit = async (infoPayload?: IFormCreateEvent) => {
    if (!infoPayload) return

    setIsLoading(true)

    try {
      const payload: IPayloadCreateEvent = {
        name: infoPayload.name,
        date_end: infoPayload.endDate?.toISOString(),
        date_started: infoPayload.startDate?.toISOString(),
        desc: infoPayload?.description ?? '-',
        register_end: infoPayload.endDateRegister?.toISOString(),
        register_started: infoPayload.startDateRegister?.toISOString(),
        type: infoPayload?.type,
        link: infoPayload?.link,
        resource: ResourceEventEnum.ADMIN,
        group: infoPayload?.group,
      }

      const res = await mutateAsyncCreateEvent?.(payload)

      if (res?.id) {
        if (isEqual(infoPayload?.type, TypeEventEnum.ACTIVITY)) {
          const payloadCreateAction: IPayloadCreateActionEvent = {
            event_id: res?.id,
            actions:
              activityHook?.activities?.map(item => {
                return {
                  action: {
                    event_id: res?.id,
                    name: item?.title ?? '',
                  },
                  columns:
                    item?.columns?.map(column => {
                      return {
                        type: column?.type,
                        name: column?.name,
                        keyOfMainData: column?.keyOfMainData,
                      }
                    }) ?? [],
                  memberCategory:
                    item?.data?.map(member => ({
                      maso_doanvien: member?.maso_doanvien,
                      columns: item?.columns?.map(col => {
                        const key = col?.keyOfMainData ?? col?.name ?? ''

                        const valueExist = member?.[key]

                        return {
                          name: key,
                          value: valueExist ?? '',
                        }
                      }),
                    })) ?? [],
                  prize:
                    item?.rewards?.map(reward => ({
                      name: reward?.name,
                      type_prize: reward?.type,
                      countPerReward: reward?.countPerReward,
                      description: reward?.description,
                      gift: reward?.gift,
                    })) ?? [],
                } as IPayloadActionEvent
              }) ?? [],
          }

          const resAction = await mutateAsyncCreateActionEvent?.(
            payloadCreateAction,
          )

          if (resAction) {
            onRefetchAndGoBack()
            return
          }
        } else if (isEqual(infoPayload?.type, TypeEventEnum.NEWS)) {
          // do something
        } else {
          const payloadCreateAction: IPayloadCreateActionEvent = {
            event_id: res?.id,
            actions:
              activityHook?.activities?.map(item => {
                return {
                  action: {
                    event_id: res?.id,
                    name: item?.title ?? '',
                    valueCare: item?.valueCare,
                  },
                  columns: [],
                  memberCategory: item?.data?.map(member => ({
                    maso_doanvien: member?.maso_doanvien,
                    valueCare: item?.valueCare,
                    columns: [],
                  })),
                  prize: [],
                } as IPayloadActionEvent
              }) ?? [],
          }

          const resAction = await mutateAsyncCreateActionEvent?.(
            payloadCreateAction,
          )

          if (resAction) {
            onRefetchAndGoBack()
            return
          }
        }
      }

      if (res) {
        onRefetchAndGoBack()
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const onChangeEventType = (type?: TypeEventEnum) => {
    setEventType(type)

    form?.setFieldsValue({
      link: undefined,
    })

    activityHook?.resetAllData()
  }

  return (
    <CreateEventContext.Provider
      value={{
        form,
        handleSubmit,
        isLoading,
        isLoadingSubmit: isLoadingSubmit ?? isLoading,
        onChangeEventType,
        eventType,
        ...activityHook,
      }}
    >
      {children}
    </CreateEventContext.Provider>
  )
}

export function useCreateEventContext() {
  const context = useContext(CreateEventContext)

  if (context === undefined) {
    throw new Error('useCreateEvent must be used within a CreateEventProvider')
  }
  return context
}
