import type { MenuProps } from 'antd'
import { Layout, Menu } from 'antd'
import { cdvn_logo, khcn_logo } from 'app/assets'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const { Sider } = Layout

export type MenuItem = Required<MenuProps>['items'][number]

export function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon: React.ReactNode | undefined,
  path: string | undefined,
  children?: MenuItem[] | undefined,
): MenuItem {
  return {
    key,
    icon,
    children,
    label: path ? <Link to={path}>{label}</Link> : label,
  } as MenuItem
}

export const SiderCommon = ({ items }: { items: MenuItem[] }) => {
  const [collapsed, setCollapsed] = useState(false)

  return (
    <>
      <Sider
        width={280}
        collapsible
        collapsed={collapsed}
        onCollapse={value => setCollapsed(value)}
        style={{ background: 'light' }}
        theme="light"
      >
        {collapsed ? (
          <>
            <div className="flex gap-3 flex-col items-center my-7 justify-center w-full">
              <img
                src={cdvn_logo}
                alt="congdong_logo"
                width={35}
                className="rounded-full"
              />
              <img
                src={khcn_logo}
                alt="congdong_logo"
                width={35}
                className="rounded-full"
              />
            </div>
            <div className="border border-1 border-gray-600 mx-[16px] "></div>
          </>
        ) : (
          <>
            <BaseRow
              gutter={8}
              align={'middle'}
              justify={'center'}
              className="my-4"
            >
              <BaseCol xl={4}>
                <BaseRow justify={'center'}>
                  <img
                    src={cdvn_logo}
                    alt="congdong_logo"
                    className="rounded-full"
                  />
                </BaseRow>
              </BaseCol>
              <BaseCol xl={4}>
                <img
                  src={khcn_logo}
                  alt="congdong_logo"
                  className="rounded-full"
                />
              </BaseCol>
              <BaseCol xl={12}>
                <BaseSpace size={4}>
                  <BaseText
                    children={'Công đoàn cơ sở'}
                    fontWeight="semibold"
                    fontSize="xs"
                  />
                  <BaseText children={'Sở KH&CN'} fontWeight="semibold" />
                </BaseSpace>
              </BaseCol>
            </BaseRow>
          </>
        )}
        <Menu
          theme="light"
          mode="inline"
          items={items}
          style={{ backgroundColor: 'transparent' }}
        ></Menu>
      </Sider>
    </>
  )
}
