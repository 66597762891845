import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  DefineEventStatus,
  EventStatusEnum,
  IFilterEventList,
} from '../../type'
import {
  GroupEventEnum,
  ResourceEventEnum,
  TypeEventEnum,
} from 'app/api/event/model'
import {
  DefineGroupEventEnum,
  DefineTypeEventEnum,
} from 'app/api/event/constant'

interface IProps {
  onChangeFilter?: (filter: IFilterEventList) => void
  filter?: IFilterEventList
}

export const FilterLayout: React.FC<IProps> = ({ filter, onChangeFilter }) => {
  const { t } = useTranslation()

  const onChangeStatus = (value?: EventStatusEnum[]) => {
    onChangeFilter?.({ ...filter, status: value })
  }

  const onChangeType = (value?: TypeEventEnum[]) => {
    onChangeFilter?.({ ...filter, type: value })
  }

  const onChangeResource = (value?: ResourceEventEnum) => {
    onChangeFilter?.({ ...filter, resource: value })
  }

  const onChangeSearch = (value: string) => {
    onChangeFilter?.({ ...filter, search: value })
  }

  const onChangeEventGroup = (group?: GroupEventEnum[]) => {
    onChangeFilter?.({ ...filter, group: group })
  }

  return (
    <BaseForm>
      <BaseRow gutter={[16, 0]}>
        <BaseCol xl={6}>
          <BaseForm.Item hiddenLabel>
            <BaseInput
              placeholder={t(R.strings.search)}
              value={filter?.search}
              onChange={e => onChangeSearch?.(e?.target?.value)}
              allowClear
            />
          </BaseForm.Item>
        </BaseCol>

        <BaseCol xl={6}>
          <BaseForm.Item hiddenLabel>
            <BaseSelect
              placeholder={'Tất cả nhóm'}
              mode="multiple"
              allowClear
              options={[
                {
                  label: DefineGroupEventEnum[GroupEventEnum.ORGANIZATION].text,
                  value: GroupEventEnum.ORGANIZATION,
                },
                {
                  label: DefineGroupEventEnum[GroupEventEnum.ARTISTIC].text,
                  value: GroupEventEnum.ARTISTIC,
                },
                {
                  label: DefineGroupEventEnum[GroupEventEnum.CHECK].text,
                  value: GroupEventEnum.CHECK,
                },
                {
                  label: DefineGroupEventEnum[GroupEventEnum.COMMUNITY].text,
                  value: GroupEventEnum.COMMUNITY,
                },
                {
                  label:
                    DefineGroupEventEnum[GroupEventEnum.FEMALE_WORKER].text,
                  value: GroupEventEnum.FEMALE_WORKER,
                },
                {
                  label: DefineGroupEventEnum[GroupEventEnum.PROPAGANDA].text,
                  value: GroupEventEnum.PROPAGANDA,
                },
                {
                  label: DefineGroupEventEnum[GroupEventEnum.REWARD].text,
                  value: GroupEventEnum.REWARD,
                },
              ]}
              onChange={value =>
                onChangeEventGroup?.(value as GroupEventEnum[])
              }
              maxTagCount={1}
              value={filter?.group}
            />
          </BaseForm.Item>
        </BaseCol>

        <BaseCol xl={6}>
          <BaseForm.Item hiddenLabel>
            <BaseSelect
              allowClear
              placeholder={t(R.strings.all_status)}
              onChange={value => onChangeStatus?.(value as EventStatusEnum[])}
              value={filter?.status}
              maxTagCount={1}
              mode="multiple"
              options={[
                {
                  label: t(DefineEventStatus[EventStatusEnum.NOT_OPEN].keyI18n),
                  value: EventStatusEnum.NOT_OPEN,
                },
                {
                  label: t(
                    DefineEventStatus[EventStatusEnum.REGISTRATION].keyI18n,
                  ),
                  value: EventStatusEnum.REGISTRATION,
                },
                {
                  label: t(
                    DefineEventStatus[EventStatusEnum.REGISTRATION_COMPLETED]
                      .keyI18n,
                  ),
                  value: EventStatusEnum.REGISTRATION_COMPLETED,
                },
                {
                  label: t(
                    DefineEventStatus[EventStatusEnum.IN_PROGRESS].keyI18n,
                  ),
                  value: EventStatusEnum.IN_PROGRESS,
                },
                {
                  label: t(DefineEventStatus[EventStatusEnum.FINISHED].keyI18n),
                  value: EventStatusEnum.FINISHED,
                },
              ]}
            />
          </BaseForm.Item>
        </BaseCol>

        <BaseCol xl={6}>
          <BaseForm.Item hiddenLabel>
            <BaseSelect
              allowClear
              placeholder={'Tất cả loại sự kiện'}
              onChange={value => onChangeType?.(value as TypeEventEnum[])}
              value={filter?.type}
              maxTagCount={1}
              mode="multiple"
              options={[
                {
                  label: DefineTypeEventEnum[TypeEventEnum.ACTIVITY].text,
                  value: TypeEventEnum.ACTIVITY,
                },
                {
                  label: DefineTypeEventEnum[TypeEventEnum.CARE].text,
                  value: TypeEventEnum.CARE,
                },
                {
                  label: DefineTypeEventEnum[TypeEventEnum.NEWS].text,
                  value: TypeEventEnum.NEWS,
                },
                {
                  label: DefineTypeEventEnum[TypeEventEnum.TRAINING].text,
                  value: TypeEventEnum.TRAINING,
                },
              ]}
            />
          </BaseForm.Item>
        </BaseCol>

        <BaseCol xl={6}>
          <BaseForm.Item hiddenLabel>
            <BaseSelect
              allowClear
              placeholder={'Tất cả nguồn'}
              onChange={value => onChangeResource?.(value as ResourceEventEnum)}
              value={filter?.resource}
              options={[
                {
                  label: 'Từ đơn vị',
                  value: ResourceEventEnum.UNIT,
                },
                {
                  label: 'Từ sở',
                  value: ResourceEventEnum.ADMIN,
                },
              ]}
            />
          </BaseForm.Item>
        </BaseCol>
      </BaseRow>
    </BaseForm>
  )
}
