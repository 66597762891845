import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { SectionBase } from 'app/components/common/SectionBase'
import HeaderPage from 'app/components/header/components/HeaderPage'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import React from 'react'
import { useHook } from './hook'
import { FilterLayout } from './layouts'
import { CreateModalLayout } from './layouts/CreateWithUnitModal'
import OtherEventLayout from './layouts/OtherEvent'
import { BaseForm } from 'app/components/common/forms/BaseForm'

const EventManagementByUnitContainer: React.FC = () => {
  const {
    columns,
    data,
    isLoading,
    filter,
    onChangeFilter,
    handleChangeTable,
  } = useHook()

  return (
    <BaseForm>
      <BaseSpace>
        <HeaderPage
          titleI18nKey={R.strings.event_management}
          rightComponent={
            <BaseCol>
              <CreateModalLayout />
            </BaseCol>
          }
          isExport
        />

        <SectionBase>
          <BaseSpace>
            <FilterLayout filter={filter} onChangeFilter={onChangeFilter} />
            <BaseTableManagement
              columns={columns}
              dataSource={data}
              loading={isLoading}
              pagination={{
                pageSize: 10,
              }}
              rowClassName="row-overwrite-style"
              onChange={handleChangeTable}
            />
          </BaseSpace>
        </SectionBase>

        <OtherEventLayout />
      </BaseSpace>
    </BaseForm>
  )
}

export default EventManagementByUnitContainer
