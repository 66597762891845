import { GroupEventEnum, TypeEventEnum } from 'app/api/event/model'
import { DoanVien } from 'app/containers/TumManagement/type'
import { Dayjs } from 'dayjs'


export interface IFormCreateEvent {
  name?: string
  startDate?: Dayjs
  endDate?: Dayjs
  description?: string
  startDateRegister?: Dayjs
  endDateRegister?: Dayjs
  type?: TypeEventEnum
  link?: string

  group?: GroupEventEnum
  
}

export enum TypeColumn {
  STRING = 'string',
  DATE = 'date',
  NUMBER = 'number',
  OTHER = 'other',
}

export enum RewardTypeEnum {
  SINGLE = 'INDIVIDUAL',
  GROUP = 'COLLECTIVE',
}

export const DefineRewardType = {
  [RewardTypeEnum.SINGLE]: 'Cá nhân',
  [RewardTypeEnum.GROUP]: 'Tập thể',
}

export interface IColumnActivity {
  id?: number
  name?: string
  type?: TypeColumn
  keyOfMainData?: keyof DoanVien
}

export interface IDataTumActivity extends DoanVien {
  [key: string]: string | number | Date | undefined | null | boolean
}

export interface IRewardActivity {
  id?: number
  name?: string
  description?: string
  gift?: string
  type?: TypeColumn
  countPerReward?: number
  key?: number
}

export interface IActivityEvent {
  id?: number
  title?: string
  valueCare?: string
  columns?: IColumnActivity[]
  data?: IDataTumActivity[]
  rewards?: IRewardActivity[]
}
