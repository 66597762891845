import R from 'app/assets/R'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseModalComponent } from 'app/components/common/ModalComponent'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { BORDER_RADIUS } from 'parkway-web-common'
import { useUpdateEventContext } from './context'
import { InfoEvent } from './layouts'

const ContentLayout = () => {
  const { form, modalRef, isLoadingSubmit} = useUpdateEventContext()

  return (
    <BaseModalComponent
      titleModal={R.strings.update_event}
      titleOpenModal={R.strings.update_event}
      ref={modalRef}
      borderRadiusButtonOpen={BORDER_RADIUS}
      borderButtonOpen={`1px solid ${convertedVariables.neutralBlack9Color}`}
      styleButtonOpen={{
        backgroundColor: convertedVariables?.backgroundColor,
      }}
      widthModal={1280}
      isShowLineBottom={false}
      showConfirmButton={false}
      showCancelButton={false}
      isLoadingConfirm={isLoadingSubmit}
      renderContent={
        <BaseForm form={form}>
          <BaseSpace>
            <InfoEvent />
          </BaseSpace>
        </BaseForm>
      }
    />
  )
}

export default ContentLayout
