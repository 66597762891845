import { IDataDashboard } from 'app/api/dashboard/model'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import RevenueItem from 'app/containers/Dashboard/RevenueItem'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { formatMoney } from 'parkway-web-common'

export const InfoData = ({ info }: { info?: IDataDashboard }) => {
  return (
    <BaseRow align={'top'} justify={'space-between'} gutter={[16, 16]}>
      <BaseCol xl={6} style={{ flex: 1 }}>
        <RevenueItem
          color={convertedVariables.otherBlueColor}
          label="Tổng số sự kiện"
          value={formatMoney(info?.totalEvent ?? 0, '')}
        />
      </BaseCol>
      <BaseCol xl={6} style={{ flex: 1 }}>
        <RevenueItem
          color={convertedVariables.statesGreenColor}
          label="Tổng số sự kiện đang diễn ra"
          value={formatMoney(info?.totalEventInEvent ?? 0, '')}
        />
      </BaseCol>
      <BaseCol xl={6} style={{ flex: 1 }}>
        <RevenueItem
          color={convertedVariables.statesOrangeColor}
          label="Tổng số đơn vị"
          value={formatMoney(info?.totalUnit ?? 0, '')}
        />
      </BaseCol>
      <BaseCol xl={6} style={{ flex: 1 }}>
        <RevenueItem
          color={convertedVariables.statesRedColor}
          label="Tổng số đoàn viên dang hoạt động"
          value={formatMoney(info?.totalTradeUnionMember ?? 0, '')}
        />
      </BaseCol>
    </BaseRow>
  )
}
