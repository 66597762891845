import TextArea from 'antd/es/input/TextArea'
import { ENV_CONFIG } from 'app/common/config'
import { DefineRoleEnum, RoleEnum } from 'app/common/enum'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import {
  BaseModalComponent,
  IRefModal,
} from 'app/components/common/ModalComponent'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { BaseDatePicker } from 'app/components/common/pickers/BaseDatePicker'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import {
  DoanVien,
  JobTitleEnum,
  OptionSelectJobTitle
} from 'app/containers/TumManagement/type'
import { DonVi } from 'app/containers/UnitManagement'
import { useGetAllDonVi } from 'app/react-query/hooks-old/useDonVi'
import { useGetDoanVien } from 'app/react-query/hooks-old/useGetUser'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import axios from 'axios'
import dayjs, { Dayjs } from 'dayjs'
import { moment, REG_EMAIL } from 'parkway-web-common'
import React, { useEffect } from 'react'
import toast from 'react-hot-toast'
import styled from 'styled-components'

interface IFormData {
  chucdanh?: JobTitleEnum[]
  hovaten?: string
  quequan?: string
  cmnd?: string
  gioitinh?: string
  ngaysinh?: Dayjs
  email?: string
  role?: RoleEnum
  donvi?: string
  ngayvao_congdoan?: Dayjs
  chucvu?: string
}

export const UpdateDoanVien = ({
  doanVien,
  isHaveBorder,
  titleOpenModal = 'Cập nhật',
  refetchOther,
}: {
  doanVien?: DoanVien
  isHaveBorder?: boolean
  titleOpenModal?: string
  refetchOther?: () => void
}) => {
  const { refetch } = useGetDoanVien()

  const { data: donvi } = useGetAllDonVi()

  const [form] = BaseForm.useForm<IFormData>()
  const modalUpdateRef = React.useRef<IRefModal>(null)
  const [loading, setLoading] = React.useState<boolean>(false)

  useEffect(() => {
    form.setFieldsValue({
      chucdanh: doanVien?.chucdanh?.split('&')
      .map(value => value.trim() as JobTitleEnum) ?? [],
      hovaten: doanVien?.hovaten,
      quequan: doanVien?.quequan,
      cmnd: doanVien?.cmnd,
      gioitinh: doanVien?.gioitinh,
      ngaysinh: doanVien?.ngaysinh
        ? dayjs(moment(doanVien?.ngaysinh, 'DD-MM-YYYY').toDate())
        : undefined,
      email: doanVien?.email,
      donvi: doanVien?.donvi,
      ngayvao_congdoan: doanVien?.ngayvao_congdoan
        ? dayjs(moment(doanVien?.ngayvao_congdoan, 'DD-MM-YYYY').toDate())
        : undefined,
      role: doanVien?.role,
      chucvu: doanVien?.chucvu,
    })
  }, [doanVien])

  async function updateCongDoan(values?: IFormData) {
    if (!doanVien?.maso_doanvien) return

    try {
      setLoading(true)
      await axios.put(
        `${ENV_CONFIG.API_ENDPOINT}/api/v1/doanvien/${doanVien?.maso_doanvien}`,
        {
          hovaten: values?.hovaten,
          tinh: doanVien?.tinh,
          quequan: values?.quequan,
          cmnd: values?.cmnd,
          gioitinh: values?.gioitinh,
          ngaysinh: values?.ngaysinh
            ? values?.ngaysinh.format('DD-MM-YYYY')
            : undefined,
          donvi: values?.donvi,
          trangthai: doanVien?.trangthai,
          email: values?.email,
          role: values?.role,
          ngayvao_congdoan: values?.ngayvao_congdoan
            ? values?.ngayvao_congdoan.format('DD-MM-YYYY')
            : undefined,
          chucdanh: values?.chucdanh?.join('&') ?? '',
          chucvu: values?.chucvu,
        },
      )
      form.resetFields()
      refetch?.()

      refetchOther?.()

      modalUpdateRef?.current?.hide?.()
      toast.success('Cập nhật thành công')

      window.location.reload()
    } catch (error) {
      toast.error('Đã Có Lỗi Xảy Ra! Hãy Thử Lại')
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <BaseModalComponent
      titleModal={`Cập nhật công đoàn viên`}
      handleSubmit={form.submit}
      isLoadingConfirm={loading}
      ref={modalUpdateRef}
      titleOpenModal="Cập nhật"
      widthModal={900}
      buttonOpenModal={
        <BaseButton
          onClick={() => modalUpdateRef?.current?.open?.()}
          type={isHaveBorder ? 'default' : 'text'}
          style={{
            width: '100%',
            justifyContent: 'flex-start',
            backgroundColor: convertedVariables.backgroundColor,
          }}
        >
          {titleOpenModal}
        </BaseButton>
      }
      renderContent={
        <BaseForm form={form} onFinish={updateCongDoan}>
          <BaseSpace size={4}>
            <BaseForm.Item
              label={'Tên công đoàn viên'}
              required
              name={'hovaten'}
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập tên công đoàn viên',
                },
              ]}
            >
              <BaseInput
                value={doanVien?.hovaten ?? ''}
                placeholder="Nhập tên đoàn viên"
              />
            </BaseForm.Item>

            <BaseRow
              align={'middle'}
              gutter={[16, 8]}
              justify={'space-between'}
            >
              <BaseCol xl={12}>
                <BaseForm.Item
                  label={'Quê quán (theo CCCD)'}
                  required
                  name={'quequan'}
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng nhập quê quán',
                    },
                  ]}
                >
                  <BaseInput
                    value={doanVien?.quequan ?? ''}
                    placeholder="Vui lòng nhập quê quán"
                  />
                </BaseForm.Item>
              </BaseCol>

              <BaseCol xl={12}>
                <BaseForm.Item
                  label={'Giới tính'}
                  required
                  name={'gioitinh'}
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng chọn giới tính',
                    },
                  ]}
                >
                  <BaseSelect
                    placeholder="Chọn giới tính"
                    options={[
                      {
                        label: 'Nam',
                        value: 'Nam',
                      },
                      {
                        label: 'Nữ',
                        value: 'Nữ',
                      },
                      {
                        label: 'Khác',
                        value: 'Khác',
                      },
                    ]}
                  />
                </BaseForm.Item>
              </BaseCol>
            </BaseRow>

            <BaseRow align={'top'} gutter={[16, 8]} justify={'space-between'}>
              <BaseCol xl={12}>
                <BaseForm.Item
                  label={'CMND/CCCD'}
                  required
                  name={'cmnd'}
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng nhập CMND/CCCD',
                    },
                    {
                      pattern: new RegExp('^[0-9]{9}$|^[0-9]{12}$'),
                      message: 'CMND phải có 9 hoặc 12 kí tự',
                    },
                  ]}
                >
                  <BaseInput
                    value={doanVien?.cmnd ?? ''}
                    placeholder="Vui lòng nhập CMND/CCCD"
                  />
                </BaseForm.Item>
              </BaseCol>
              <BaseCol xl={12}>
                <BaseForm.Item
                  label={'Email'}
                  required
                  name={'email'}
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng nhập email',
                    },
                    {
                      pattern: REG_EMAIL,
                      message: 'Email không hợp lệ',
                    },
                  ]}
                >
                  <BaseInput
                    value={doanVien?.email ?? ''}
                    placeholder="Nhập email"
                    disabled
                  />
                </BaseForm.Item>
              </BaseCol>
            </BaseRow>

            <BaseRow align={'top'} gutter={[16, 8]} justify={'space-between'}>
              <BaseCol xl={12}>
                <BaseForm.Item
                  label={'Ngày sinh'}
                  required
                  name={'ngaysinh'}
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng chọn ngày sinh',
                    },
                  ]}
                >
                  <BaseDatePickerStyled
                    format={'DD-MM-YYYY'}
                    placeholder="Chọn ngày sinh"
                    allowClear={false}
                  />
                </BaseForm.Item>
              </BaseCol>

              <BaseCol xl={12}>
                <BaseForm.Item
                  label={'Ngày vào công đoàn'}
                  name={'ngayvao_congdoan'}
                >
                  <BaseDatePickerStyled
                    format={'DD-MM-YYYY'}
                    placeholder="Chọn ngày vào công đoàn"
                    allowClear={false}
                  />
                </BaseForm.Item>
              </BaseCol>
            </BaseRow>

            <BaseRow align={'top'} gutter={[16, 8]} justify={'space-between'}>
              <BaseCol xl={12}>
                <BaseForm.Item
                  name={'chucdanh'}
                  label={'Chức vụ công đoàn'}
                  required
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng chọn chức vụ công đoàn',
                    },
                  ]}
                >
                  <BaseSelect
                    placeholder="Chọn chức vụ công đoàn"
                    options={OptionSelectJobTitle}
                  />
                </BaseForm.Item>
              </BaseCol>

              <BaseCol xl={12}>
                <BaseForm.Item
                  label={'Đơn vị'}
                  required
                  name={'donvi'}
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng nhập đơn vị',
                    },
                  ]}
                >
                  <BaseSelect
                    options={
                      (donvi as DonVi[])?.map(value => ({
                        label: value.ten,
                        value: value.ten,
                      })) ?? []
                    }
                    showSearch
                    placeholder="Chọn đơn vị"
                  />
                </BaseForm.Item>
              </BaseCol>
            </BaseRow>

            <BaseForm.Item
              name={'role'}
              label={'Quyền'}
              required
              rules={[
                {
                  required: true,
                  message: 'Vui lòng chọn quyền',
                },
              ]}
            >
              <BaseSelect
                placeholder="Chọn quyền"
                options={[
                  {
                    label: DefineRoleEnum[RoleEnum.ADMIN],
                    value: RoleEnum.ADMIN,
                  },
                  {
                    label: DefineRoleEnum[RoleEnum.USER],
                    value: RoleEnum.USER,
                  },
                  {
                    label: DefineRoleEnum[RoleEnum.EMPLOYEE],
                    value: RoleEnum.EMPLOYEE,
                  },
                ]}
              />
            </BaseForm.Item>

            <BaseForm.Item label={'Chức vụ'} name={'chucvu'}>
              <TextArea
                value={doanVien?.chucvu ?? ''}
                placeholder="Vui lòng nhập chức vụ"
                rows={5}
              />
            </BaseForm.Item>
          </BaseSpace>
        </BaseForm>
      }
    />
  )
}

const BaseDatePickerStyled = styled(BaseDatePicker)`
  width: 100%;
`
