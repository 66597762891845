import { DownloadOutlined, ExportOutlined } from '@ant-design/icons'
import { StatusMemberRegisterWaiting, TypeEventEnum } from 'app/api/event/model'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseCollapse } from 'app/components/common/BaseCollapse'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseTabs } from 'app/components/common/BaseTabs'
import BaseText from 'app/components/common/BaseText'
import { SectionBase } from 'app/components/common/SectionBase'
import { IEventTableData } from 'app/containers/EventManagement/type'
import { DoanVien } from 'app/containers/TumManagement/type'
import QRCode from 'qrcode.react'
import { ActionRegisteredByStatusLayout } from '../../layouts'
import { TumTable } from '../../layouts/TumTable'
import { Loading } from 'app/components/common/Loading'

interface IProps {
  tumData: {
    id?: number
    title?: string
    infoData?: DoanVien[]
  }[]
  downloadQRCode: () => void
  event: IEventTableData
  isLoading?: boolean
}

export const TrainingContent = ({
  downloadQRCode,
  event,
  tumData,
  isLoading,
}: IProps) => {
  return (
    <SectionBase>
      <BaseSpace>
        <BaseRow align={'middle'} justify={'space-between'} gutter={[16, 16]}>
          <BaseCol>
            <BaseText
              children={'Danh sách hoạt động'}
              fontWeight="semibold"
              fontSize="md"
            />
          </BaseCol>
          <BaseCol>
            <BaseButton icon={<ExportOutlined rev={undefined} />}>
              {'Export excel'}
            </BaseButton>
          </BaseCol>
        </BaseRow>
        {tumData?.length ? (
          tumData?.map((item, index) => {
            return (
              <BaseCollapse
                items={[
                  {
                    key: index,
                    label: (
                      <BaseText
                        children={item?.title ?? ''}
                        fontWeight="semibold"
                        fontSize="xs"
                      />
                    ),
                    children: (
                      <BaseSpace>
                        <BaseRow
                          align={'middle'}
                          justify={'center'}
                          gutter={16}
                        >
                          <BaseCol>
                            <QRCode
                              id="qrCodeEl"
                              size={200}
                              style={{
                                height: 'auto',
                                maxWidth: '200px',
                                width: '200px',
                              }}
                              value={`https://cdv.khcn.cyberdx.tech/check-in-action/${item?.id}`}
                              viewBox={`0 0 200 200`}
                            />
                          </BaseCol>
                          <BaseCol>
                            <BaseButton
                              type="text"
                              icon={<DownloadOutlined rev={undefined} />}
                              onClick={downloadQRCode}
                            />
                          </BaseCol>
                        </BaseRow>
                        <BaseRow
                          align={'middle'}
                          justify={'space-between'}
                          gutter={[16, 8]}
                        >
                          <BaseCol>
                            <BaseText
                              children={'Danh sách đối tượng tham gia'}
                              fontSize="xs"
                              fontWeight="medium"
                            />
                          </BaseCol>
                        </BaseRow>
                        <TumTable
                          data={item?.infoData ?? []}
                          eventType={event?.type as TypeEventEnum}
                          activityId={item?.id}
                        />

                        <BaseText
                          children={'Danh sách chờ phê duyệt'}
                          fontWeight="semibold"
                          fontSize="xs"
                        />

                        <BaseTabs
                          items={[
                            {
                              key: '1',
                              label: 'Đang chờ',
                              children: (
                                <ActionRegisteredByStatusLayout
                                  status={StatusMemberRegisterWaiting.WAITING}
                                  actionId={item?.id}
                                />
                              ),
                            },
                            {
                              key: '2',
                              label: 'Đã phê duyệt',
                              children: (
                                <ActionRegisteredByStatusLayout
                                  status={StatusMemberRegisterWaiting.ACCEPTED}
                                  actionId={item?.id}
                                />
                              ),
                            },
                            {
                              key: '3',
                              label: 'Từ chối',
                              children: (
                                <ActionRegisteredByStatusLayout
                                  status={StatusMemberRegisterWaiting.REJECTED}
                                  actionId={item?.id}
                                />
                              ),
                            },
                          ]}
                        />
                      </BaseSpace>
                    ),

                    style: {
                      alignItems: 'center',
                    },
                  },
                ]}
              />
            )
          })
        ) : (
          <BaseText
            children={'Danh sách hoạt động trống'}
            fontWeight="medium"
          />
        )}
      </BaseSpace>

      {isLoading ? <Loading isAbsolute size="small" /> : null}
    </SectionBase>
  )
}
