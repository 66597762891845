import { BaseSpace } from 'app/components/common/BaseSpace'
import { SectionBase } from 'app/components/common/SectionBase'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import HeaderPage from 'app/components/header/components/HeaderPage'
import { useCreateEventContext } from './context'
import { ActionEvent, InfoEvent } from './layouts'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseButton } from 'app/components/common/BaseButton'
import { isEqual } from 'lodash'
import { TypeEventEnum } from 'app/api/event/model'
import { useNavigate } from 'react-router'
import { Loading } from 'app/components/common/Loading'

const ContentLayout = () => {
  const navigate = useNavigate()
  const { form, handleSubmit, eventType, isLoadingSubmit } =
    useCreateEventContext()
  return (
    <BaseForm form={form} onFinish={handleSubmit}>
      <BaseSpace>
        <HeaderPage title="Tạo mới sự kiện" />

        <SectionBase>
          <InfoEvent />
        </SectionBase>

        {!isEqual(eventType, TypeEventEnum.NEWS) ? (
          <SectionBase>
            <ActionEvent />
          </SectionBase>
        ) : null}

        <BaseRow align={'middle'} justify={'end'}>
          <BaseRow justify={'start'} gutter={[16, 8]} align={'middle'}>
            <BaseCol>
              <BaseButton onClick={() => navigate?.(-1)}>{'Huỷ'}</BaseButton>
            </BaseCol>
            <BaseCol>
              <BaseButton onClick={form?.submit} loading={isLoadingSubmit}>
                {'Save'}
              </BaseButton>
            </BaseCol>
          </BaseRow>
        </BaseRow>

        {isLoadingSubmit ? <Loading isAbsolute size="small" /> : null}
      </BaseSpace>
    </BaseForm>
  )
}

export default ContentLayout
