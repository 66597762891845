import { ApiClient } from 'app/service/api-service'
import { EventEndPoint } from './constant'
import {
  IDeleteEvent,
  IParamsGetActionByEvent,
  IParamsGetActionRegisteredByMember,
  IParamsGetEvents,
  IParamsGetMemberByAction,
  IPayloadChangeStatusMemberRegister,
  IPayloadCheckInActionByMember,
  IPayloadCreateActionEvent,
  IPayloadCreateEvent,
  IPayloadRegisterActionByMember,
  IPayloadUpdateEvent,
} from './model'

export const requestGetEventList = (params: IParamsGetEvents) =>
  ApiClient.Get({
    url: EventEndPoint.ADMIN_GET_EVENT,
    params: { params },
  })

export const requestGetEventStatusList = (params: IParamsGetEvents) =>
  ApiClient.Get({
    url: EventEndPoint.ADMIN_GET_EVENT_BY_STATUS,
    params: { params },
  })

export const requestGetEventById = (id?: string) =>
  ApiClient.Get({
    url: `${EventEndPoint.ADMIN_GET_EVENT_BY_ID?.replace(
      ':id',
      id ?? '',
    )}`,
    params: { params: { id } },
  })

export const requestGetActionById = (id: number) =>
  ApiClient.Get({
    url: `${EventEndPoint.GET_ACTION_EVENT_BY_ID?.replace(
      ':id',
      id.toString(),
    )}`,
    params: { id },
  })

export const requestDeleteEvent = (body: IDeleteEvent) =>
  ApiClient.Post({
    url: EventEndPoint.ADMIN_DELETE_EVENT?.replace(
      ':id',
      body?.id?.toString() ?? '',
    ),
    body,
  })

export const requestUpdateEvent = ({ id, ...body }: IPayloadUpdateEvent) =>
  ApiClient.Put({
    url: `${EventEndPoint.ADMIN_UPDATE_EVENT}/${id}`,
    body,
  })

export const requestCreateEvent = (body?: IPayloadCreateEvent) =>
  ApiClient.Post({
    url: EventEndPoint.ADMIN_CREATE_EVENT,
    body,
  })

export const requestGetActionByEvent = (params: IParamsGetActionByEvent) =>
  ApiClient.Get({
    url: `${EventEndPoint.ADMIN_GET_ACTION_BY_EVENT_ID?.replace(
      ':id',
      params?.id.toString(),
    )}`,
    params: { params },
  })

export const requestGetMemberOfPrize = (params: IParamsGetActionByEvent) =>
  ApiClient.Get({
    url: `${EventEndPoint.ADMIN_GET_MEMBER_BY_PRIZE?.replace(
      ':id',
      params?.id.toString(),
    )}`,
    params: { params },
  })

export const requestGetMemberPrizeByEventId = (
  params: IParamsGetActionByEvent,
) =>
  ApiClient.Get({
    url: `${EventEndPoint.ADMIN_GET_MEMBER_PRIZE_BY_EVENT_ID?.replace(
      ':id',
      params?.id.toString(),
    )}`,
    params: { params },
  })

export const requestGetUnitPrizeByEventId = (params: IParamsGetActionByEvent) =>
  ApiClient.Get({
    url: `${EventEndPoint.ADMIN_GET_UNIT_PRIZE_BY_EVENT_ID?.replace(
      ':id',
      params?.id.toString(),
    )}`,
    params: { params },
  })

export const requestCreateActionByEvent = (body?: IPayloadCreateActionEvent) =>
  ApiClient.Post({
    url: EventEndPoint.ADMIN_CREATE_ACTION,
    body,
  })

export const requestUpdateActionByEvent = (body?: IPayloadCreateActionEvent) =>
  ApiClient.Put({
    url: EventEndPoint.ADMIN_UPDATE_ACTION,
    body,
  })

export const requestGetActionNotJoinByMemberId = (id: string) =>
  ApiClient.Get({
    url: `${EventEndPoint.GET_ACTION_NOT_JOIN_BY_MEMBER?.replace(
      ':id',
      id.toString(),
    )}`,
    params: { id },
  })

export const requestGetActionRegisteredByMember = (
  params: IParamsGetActionRegisteredByMember,
) =>
  ApiClient.Get({
    url: EventEndPoint.GET_REGISTER_ACTION_WAITING_BY_MEMBER,
    params: { params },
  })

export const requestGetMemberRegisterByActionId = (
  params: IParamsGetMemberByAction,
) =>
  ApiClient.Get({
    url: EventEndPoint.GET_REGISTER_MEMBER_WAITING_BY_ACTION,
    params: { params },
  })

export const requestChangeStatusMemberRegister = (
  body?: IPayloadChangeStatusMemberRegister,
) =>
  ApiClient.Put({
    url: EventEndPoint.REGISTER_MEMBER_WAITING_CHANGE_STATUS,
    body,
  })

export const requestRegisterActionByMember = (
  body: IPayloadRegisterActionByMember,
) =>
  ApiClient.Post({
    url: EventEndPoint.REGISTER_MEMBER_WITH_ACTION,
    body,
  })

export const requestCheckInAction = (body: IPayloadCheckInActionByMember) =>
  ApiClient.Post({
    url: EventEndPoint.ACTION_CHECK_IN,
    body,
  })
