import { PageTitle } from 'app/components/common/PageTitle'
import EventContainer from 'app/containers/EventManagement/DetailEvent'
import { isEqual } from 'lodash'
import { memo } from 'react'

const DetailEventCpn = () => {
  return (
    <>
      <PageTitle>{'Chi tiết sự kiện'}</PageTitle>
      <EventContainer />
    </>
  )
}
export const DetailEventPage = memo(DetailEventCpn, isEqual)
