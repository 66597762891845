import { ApiClient } from 'app/service/api-service'
import { AuthEndPoint } from './constant'
import {
  IPayloadNewPassword,
  LoginRequest,
  ResetPasswordRequest,
  SecurityCodePayload,
  SignUpRequest,
} from './model'

export const requestPostLogin = (body: LoginRequest) =>
ApiClient.Post({ url: AuthEndPoint.LOGIN, body })

export const requestPostSignUp = (body: SignUpRequest) =>
  ApiClient.Post({ url: AuthEndPoint.SIGN_UP, body })

export const requestPostResetPassword = (body: ResetPasswordRequest) =>
  ApiClient.Post({ url: AuthEndPoint.FORGOT_PASSWORD, body })

export const requestPostVerifySecurityCode = (body: SecurityCodePayload) =>
  ApiClient.Post({
    url: AuthEndPoint.VERITY_CODE,
    body,
  })

export const requestPostSetNewPassword = (body: IPayloadNewPassword) =>
  ApiClient.Post({ url: AuthEndPoint.NEW_PASSWORD, body })
