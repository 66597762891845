import { IDashboardInfo } from 'app/api/dashboard/model'
import { DefineGroupEventEnum } from 'app/api/event/constant'
import { GroupEventEnum, IEvent } from 'app/api/event/model'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseEmpty } from 'app/components/common/BaseEmpty'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { SectionBase } from 'app/components/common/SectionBase'
import { DETAIL_EVENT_PATH } from 'app/components/router/route-path'
import { RenderValueTable } from 'app/components/tables/BaseTableManagement/hook'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { useNavigate } from 'react-router'

const ItemEvent = ({ data, title }: { data?: IEvent[]; title: string }) => {
  const navigate = useNavigate()

  const navigateToDetailEvent = (event: IEvent) => {
    navigate(DETAIL_EVENT_PATH, {
      state: { event },
    })
  }

  return (
    <SectionBase
      title={title}
      style={{
        minHeight: 300,
        maxHeight: 350,
      }}
    >
      <div>
        <BaseSpace>
          <BaseRow gutter={8} align={'middle'}>
            <BaseCol xl={24}>
              <BaseRow
                gutter={[16, 8]}
                align={'middle'}
                justify={'space-between'}
              >
                <BaseCol>
                  <BaseText children={'Sự kiện'} fontWeight="medium" />
                </BaseCol>
                <BaseCol>
                  <div
                    style={{
                      backgroundColor: convertedVariables.statesGreenLightColor,
                      minWidth: 22,
                      height: 22,
                      borderRadius: 15,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <BaseText
                      children={`${data?.length ?? 0}`}
                      fontWeight="semibold"
                      colorText="statesGreenColor"
                    />
                  </div>
                </BaseCol>
              </BaseRow>
            </BaseCol>
          </BaseRow>
          {data?.length ? (
            <BaseSpace
              style={{
                minHeight: 150,
                maxHeight: 150,
                overflowY: 'auto',
              }}
            >
              {data?.map((item, index) => {
                return (
                  <BaseRow
                    gutter={8}
                    align={'middle'}
                    key={index}
                    justify={'space-between'}
                    style={{
                      marginLeft: 0,
                      marginRight: 0,
                    }}
                  >
                    <BaseCol xl={24}>
                      <RenderValueTable
                        value={item?.name}
                        type="Link"
                        onClick={() => navigateToDetailEvent(item)}
                      />
                    </BaseCol>
                  </BaseRow>
                )
              })}
            </BaseSpace>
          ) : (
            <BaseEmpty />
          )}
        </BaseSpace>
      </div>
    </SectionBase>
  )
}

export const EventByGroupLayout = ({ data }: { data?: IDashboardInfo }) => {
  return (
    <BaseRow gutter={16}>
      <BaseCol xl={6} style={{ flex: 1 }}>
        <ItemEvent
          data={data?.eventByGroup?.artistic ?? []}
          title={DefineGroupEventEnum[GroupEventEnum.ARTISTIC].text}
        />
      </BaseCol>
      <BaseCol xl={6} style={{ flex: 1 }}>
        <ItemEvent
          data={data?.eventByGroup?.check ?? []}
          title={DefineGroupEventEnum[GroupEventEnum.CHECK].text}
        />
      </BaseCol>
      <BaseCol xl={6} style={{ flex: 1 }}>
        <ItemEvent
          data={data?.eventByGroup?.community ?? []}
          title={DefineGroupEventEnum[GroupEventEnum.COMMUNITY].text}
        />
      </BaseCol>
      <BaseCol xl={6} style={{ flex: 1 }}>
        <ItemEvent
          data={data?.eventByGroup?.femaleWorker ?? []}
          title={DefineGroupEventEnum[GroupEventEnum.FEMALE_WORKER].text}
        />
      </BaseCol>
      <BaseCol xl={8} style={{ flex: 1 }}>
        <ItemEvent
          data={data?.eventByGroup?.organization ?? []}
          title={DefineGroupEventEnum[GroupEventEnum.ORGANIZATION].text}
        />
      </BaseCol>
      <BaseCol xl={8} style={{ flex: 1 }}>
        <ItemEvent
          data={data?.eventByGroup?.reward ?? []}
          title={DefineGroupEventEnum[GroupEventEnum.REWARD].text}
        />
      </BaseCol>
      <BaseCol xl={8} style={{ flex: 1 }}>
        <ItemEvent
          data={data?.eventByGroup?.propaganda ?? []}
          title={DefineGroupEventEnum[GroupEventEnum.PROPAGANDA].text}
        />
      </BaseCol>
    </BaseRow>
  )
}
