import { message } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { ENV_CONFIG } from 'app/common/config'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import {
  BaseModalComponent,
  IRefModal,
} from 'app/components/common/ModalComponent'
import { BaseDatePicker } from 'app/components/common/pickers/BaseDatePicker'
import { useGetAllDonVi } from 'app/react-query/hooks-old/useDonVi'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import axios from 'axios'
import { Dayjs } from 'dayjs'
import { BORDER_RADIUS } from 'parkway-web-common'
import React from 'react'
interface IPayloadCreateUnit {
  ten?: string
  diachi?: string
  ngaythanhlap?: string
  thongtindonvi?: string
}

interface IFormPayloadCreateUnit {
  ten?: string
  diachi?: string
  ngaythanhlap?: Dayjs
  thongtindonvi?: string
}

const CreateUnitModal = () => {
  const [form] = BaseForm.useForm<IFormPayloadCreateUnit>()
  const donvi = useGetAllDonVi()
  const [loading, setLoading] = React.useState<boolean>(false)

  const handleCreateUnit = async (values: IFormPayloadCreateUnit) => {
    const payload: IPayloadCreateUnit = {
      ten: values.ten,
      diachi: values.diachi,
      ngaythanhlap: values.ngaythanhlap ? values.ngaythanhlap.format("DD-MM-YYYY") : '',
      thongtindonvi: values.thongtindonvi ?? ' ',
    }

    try {
      setLoading(true)
      await axios.post(
        `${ENV_CONFIG.API_ENDPOINT}/api/v1/donvi/create`,
        payload,
      )
      message.success('Tạo đơn vị thành công!')
      form?.resetFields()
      donvi.refetch()
      modalRef.current?.hide?.()
    } catch (error) {
      message.error('Tạo đơn vị thất bại!')
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const modalRef = React.useRef<IRefModal>(null)

  return (
    <BaseModalComponent
      titleModal="Tạo Đơn Vị Mới"
      titleOpenModal="Tạo Đơn Vị Mới"
      ref={modalRef}
      handleSubmit={form.submit}
      isLoadingConfirm={loading}
      borderButtonOpen={`1px solid ${convertedVariables.neutralBlack2Color}`}
      borderRadiusButtonOpen={BORDER_RADIUS}
      styleButtonOpen={{
        backgroundColor: convertedVariables.backgroundColor,
      }}
      renderContent={
        <BaseForm form={form} onFinish={handleCreateUnit}>
          <BaseSpace size={4}>
            <BaseForm.Item
              name={'ten'}
              label={'Tên đơn vị'}
              required
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập tên đơn vị',
                },
              ]}
            >
              <BaseInput placeholder="Nhập tên đơn vị" />
            </BaseForm.Item>
            <BaseForm.Item
              name={'diachi'}
              label={'Địa chỉ đơn vị'}
              required
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập địa chỉ',
                },
              ]}
            >
              <BaseInput placeholder="Nhập địa chỉ" />
            </BaseForm.Item>

            <BaseForm.Item
              name={'ngaythanhlap'}
              label={'Ngày thành lập đơn vị'}
              required
              rules={[
                {
                  required: true,
                  message: 'Vui lòng chọn ngày thành lập',
                },
              ]}
            >
              <BaseDatePicker
                placeholder="Chọn ngày thành lập"
                style={{ width: '100%' }}
                format={"DD-MM-YYYY"}
              />
            </BaseForm.Item>

            <BaseForm.Item name={'thongtindonvi'} label={'Thông tin đơn vị'}>
              <TextArea placeholder="Nhập thông tin đơn vị" rows={5}/>
            </BaseForm.Item>
          </BaseSpace>
        </BaseForm>
      }
    />
  )
}

export default CreateUnitModal
