import { ENV_CONFIG } from 'app/common/config'
import { BaseSpace } from 'app/components/common/BaseSpace'
import {
  BaseModalComponent,
  IRefModal,
} from 'app/components/common/ModalComponent'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import {
  DoanVien,
  JobTitleEnum,
  OptionSelectJobTitle
} from 'app/containers/TumManagement/type'
import axios from 'axios'
import React, { useEffect } from 'react'
import toast from 'react-hot-toast'

interface IForm {
  chucdanh?: JobTitleEnum[]
}

export const UpdateJobTitle = ({
  doanVien,
  refetch,
}: {
  doanVien?: DoanVien
  refetch?: () => void
}) => {
  const [form] = BaseForm.useForm<IForm>()
  const modalUpdateRef = React.useRef<IRefModal>(null)
  const [loading, setLoading] = React.useState<boolean>(false)

  useEffect(() => {
    form.setFieldsValue({
      chucdanh:
        doanVien?.chucdanh?.split('&').map(item => item as JobTitleEnum) ?? [],
    })
  }, [doanVien])

  async function updateDonVi(values?: IForm) {
    if (!doanVien?.maso_doanvien) return

    try {
      setLoading(true)
      const res = await axios.put(
        `${ENV_CONFIG.API_ENDPOINT}/api/v1/doanvien/update/chucdanh`,
        {
          chucdanh: values?.chucdanh?.join('&') ?? '',
          maso_doanvien: doanVien?.maso_doanvien,
        },
      )

      if (res) {
        form.resetFields()
        refetch?.()
        modalUpdateRef?.current?.hide?.()
        toast.success('Cập nhật chức vụ công đoàn thành công')
      }
    } catch (error) {
      toast.error('Đã Có Lỗi Xảy Ra! Hãy Thử Lại')
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <BaseModalComponent
      titleModal={`Cập nhật chức vụ công đoàn`}
      handleSubmit={form.submit}
      isLoadingConfirm={loading}
      ref={modalUpdateRef}
      titleOpenModal="Cập nhật chức vụ công đoàn"
      renderContent={
        <BaseForm form={form} onFinish={updateDonVi}>
          <BaseSpace size={4}>
            <BaseForm.Item label={'Tên công đoàn viên'} required>
              <BaseInput value={doanVien?.hovaten ?? ''} disabled />
            </BaseForm.Item>

            <BaseForm.Item
              name={'chucdanh'}
              label={'Chức vụ công đoàn'}
              required
              rules={[
                {
                  required: true,
                  message: 'Vui lòng chọn chức vụ công đoàn',
                },
              ]}
            >
              <BaseSelect
                placeholder="Chọn chức vụ công đoàn"
                mode="multiple"
                maxTagCount={2}
                options={OptionSelectJobTitle}
              />
            </BaseForm.Item>
          </BaseSpace>
        </BaseForm>
      }
    />
  )
}
