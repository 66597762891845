/* eslint-disable @typescript-eslint/no-explicit-any */
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { useMemo } from 'react'
import { IActivityEvent } from '../../../../CreateEvent/type'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { BaseCheckbox } from 'app/components/common/BaseCheckbox'
import { useCheckInActionByMember } from 'app/react-query/hook/event'

interface IProps {
  activity: IActivityEvent
}

export const ColumnTumActivity = ({ activity }: IProps) => {
  const { mutateAsync: mutateAsyncCheckIn, isLoading} = useCheckInActionByMember()
  const data = useMemo(() => {
    return activity?.data?.map((item, index) => {
      return {
        ...item,
        key: item?.maso_doanvien,
        index: index + 1,
      }
    })
  }, [activity?.data])

  const handleCheckIn = async ({
    value,
    doanvienId,
  }: {
    value: boolean
    doanvienId?: string
  }) => {
    if (!activity?.id) return

    if (!doanvienId) return

    await mutateAsyncCheckIn({
      checkedIn: value,
      actionEventId: activity?.id,
      doanvienId,
    })
  }

  return activity?.columns?.length ? (
    <BaseSpace style={{ display: 'flex', flex: 1, maxWidth: '100%' }}>
      <BaseTableManagement
        columns={[
          ...((activity?.columns?.map((column, index) => {
            return {
              title: column?.name,
              dataIndex: column?.name,
              key: column?.keyOfMainData ?? column?.name,
              fixed: index === 0 ? 'left' : undefined,
              className: 'big-column',
              render: (_: any, record?: any) => {
                return (
                  <BaseText
                    children={
                      record?.[column?.keyOfMainData ?? column?.name ?? ''] ??
                      ''
                    }
                    fontWeight="medium"
                    opacity={column?.keyOfMainData ? '1' : '0.7'}
                  />
                )
              },
            }
          }) ?? []) as any),
          convertColumnTable<any>({
            key: 'checkedIn',
            title: 'Check in',
            fixed: 'right',
            render: (_, record) => {

              return (
                <BaseCheckbox
                  checked={record?.checkedIn}
                  value={record?.checkedIn}
                  onChange={e =>
                    handleCheckIn({
                      value: e?.target?.checked,
                      doanvienId: record?.key,
                    })
                  }
                />
              )
            },
          }),
        ]}
        dataSource={data}
        className="row-overwrite-style"
        scroll={{ x: true }}
        pagination={{
          pageSize: 5,
        }}
        loading={isLoading}
      />
    </BaseSpace>
  ) : null
}
