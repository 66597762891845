import { IDataTop10UnitPrize } from 'app/api/dashboard/model'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseEmpty } from 'app/components/common/BaseEmpty'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { Loading } from 'app/components/common/Loading'
import { SectionBase } from 'app/components/common/SectionBase'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import _ from 'lodash'
import { BORDER_RADIUS, formatMoney } from 'parkway-web-common'
import { useMemo } from 'react'
import styled from 'styled-components'

interface IData extends IDataTop10UnitPrize {
  key: string
  color?: string
}

export const Top10UnitPrizeLayout = ({
  top10UnitPrize,
  isLoading,
}: {
  top10UnitPrize: IDataTop10UnitPrize[]
  isLoading?: boolean
}) => {
  const dataTop10UnitPrize: IData[] = useMemo(() => {
    const top1Value = _.maxBy(top10UnitPrize, 'countPrize')?.countPrize
    const top2Value = _.maxBy(
      top10UnitPrize.filter(item => item.countPrize !== top1Value),
      'countPrize',
    )?.countPrize

    const top3Value = _.maxBy(
      top10UnitPrize.filter(
        item => item.countPrize !== top1Value && item.countPrize !== top2Value,
      ),
      'countPrize',
    )?.countPrize

    return top10UnitPrize?.map((item, index) => {
      let key = `${index + 1}`

      if (
        index > 0 &&
        item?.countPrize === top10UnitPrize[index - 1]?.countPrize
      ) {
        key = '-'
      }

      let color: undefined | string = undefined

      if (item?.countPrize === top1Value) {
        color = convertedVariables.statesGreenColor
      } else if (item?.countPrize === top2Value) {
        color = convertedVariables.otherBlueColor
      } else if (item?.countPrize === top3Value) {
        color = convertedVariables.statesRedColor
      }

      return {
        ...item,
        key,
        color,
      }
    })
  }, [top10UnitPrize])

  return (
    <SectionBase title="Danh sách đơn vị đoạt nhiều giải nhất">
      <BaseSpace size={8}>
        <HeaderWrapper align={'middle'} justify={'space-between'}>
          <BaseCol xl={2}>
            <BaseText children={'STT'} fontWeight="semibold" opacity="0.7" />
          </BaseCol>
          <BaseCol xl={19}>
            <BaseText
              children={'Tên đơn vị'}
              fontWeight="semibold"
              opacity="0.7"
            />
          </BaseCol>
          <BaseCol xl={3}>
            <BaseText
              children={'Số giải'}
              fontWeight="semibold"
              opacity="0.7"
            />
          </BaseCol>
        </HeaderWrapper>
        <ContentWrapper size={4}>
          {dataTop10UnitPrize?.length ? (
            dataTop10UnitPrize?.map((item, index) => {
              return (
                <ItemWrapper
                  key={index}
                  align={'middle'}
                  justify={'space-between'}
                >
                  <BaseCol xl={2}>
                    <BaseText
                      children={item?.key}
                      fontWeight="semibold"
                      style={{
                        color: item?.color,
                      }}
                    />
                  </BaseCol>
                  <BaseCol xl={19}>
                    <BaseText
                      children={item?.donvi}
                      fontWeight="semibold"
                      fontSize="xs"
                      style={{
                        color: item?.color,
                      }}
                    />
                  </BaseCol>
                  <BaseCol xl={3}>
                    <BaseRow
                      justify={'start'}
                      align={'middle'}
                      gutter={8}
                      wrap={false}
                    >
                      <BaseCol>
                        <BaseText
                          children={`${formatMoney(item?.countPrize ?? 0, '')}`}
                          fontWeight="semibold"
                          style={{
                            color: item?.color,
                          }}
                        />
                      </BaseCol>
                    </BaseRow>
                  </BaseCol>
                </ItemWrapper>
              )
            })
          ) : (
            <BaseEmpty />
          )}
        </ContentWrapper>
      </BaseSpace>
      {isLoading ? <Loading isAbsolute size="small" /> : null}
    </SectionBase>
  )
}

const HeaderWrapper = styled(BaseRow)`
  width: 100%;
  padding: ${BORDER_RADIUS};
  background-color: ${convertedVariables.backgroundColor};
`

const ItemWrapper = styled(BaseRow)`
  width: 100%;
  padding: ${BORDER_RADIUS};
  background-color: ${convertedVariables.backgroundColor};

  border-radius: ${BORDER_RADIUS};
  box-shadow: 0px 0.5px 2px rgba(0, 0, 0, 0.1);
`

const ContentWrapper = styled(BaseSpace)`
  height: 200px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`
