import { MoreOutlined, SearchOutlined } from '@ant-design/icons'
import { RoleEnum } from 'app/common/enum'
import { BaseButton } from 'app/components/common/BaseButton'
import { BasePopover } from 'app/components/common/BasePopover'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { SectionBase } from 'app/components/common/SectionBase'
import HeaderPage from 'app/components/header/components/HeaderPage'
import { MEMBER_DETAILS_PATH_2 } from 'app/components/router/route-path'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { RenderValueTable } from 'app/components/tables/BaseTableManagement/hook'
import { StatusWrapper } from 'app/components/tables/BaseTableManagement/styles'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { useGetDoanVienByUnitName } from 'app/react-query/hooks-old/useGetUser'
import { useAppSelector } from 'app/redux/hooks'
import { isEmpty, isEqual } from 'lodash'
import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import {
  CreateTumModal,
  ModalConfirmChangeStatus,
  UpdateDoanVien,
} from './layouts'
import { DoanVien } from './type'
import { DefineJobTitleEnum, JobTitleEnum } from '../TumManagement/type'

export const TumManagementByUnitContainer: React.FC = () => {
  const userData = useAppSelector(state => state.userOld)?.profile
  const navigate = useNavigate()
  const { data: allDoanVien, isLoading: isLoadingDoanVien } =
    useGetDoanVienByUnitName(userData?.donvi)
  const [query, setQuery] = useState<string>('')

  const data = useMemo(() => {
    const doanVienList: DoanVien[] = allDoanVien ?? []
    return doanVienList
      ?.filter(doanVien => {
        return (
          doanVien?.hovaten?.toLowerCase().includes(query?.toLowerCase()) ||
          doanVien?.donvi?.toLowerCase().includes(query?.toLowerCase()) ||
          doanVien?.cmnd?.toLowerCase().includes(query?.toLowerCase())
        )
      })
      ?.map((item, index) => {
        return {
          ...item,
          index: index + 1,
          key: index + 1,
        }
      })
  }, [allDoanVien, query])

  return (
    <BaseSpace>
      <HeaderPage
        title={'Danh sách đoàn viên'}
        isExport
        rightComponent={<CreateTumModal />}
      />
      <SectionBase>
        <BaseSpace>
          <BaseInput
            style={{ width: 300, marginRight: 16 }}
            value={query}
            onChange={e => setQuery(e.target.value)}
            placeholder="Tìm kiếm"
            allowClear
            suffix={<SearchOutlined rev={undefined} />}
          />

          <BaseTableManagement
            dataSource={data}
            pagination={{
              pageSize: 10,
              showSizeChanger: false,
              total: allDoanVien?.length,
            }}
            loading={isLoadingDoanVien}
            columns={[
              convertColumnTable<{ index: number }>({
                key: 'index',
                title: 'STT',
                fixed: 'left',
                classNameWidthColumnOverwrite: 'number-column',
                render: (value: number) => (
                  <BaseText
                    children={value}
                    opacity="0.7"
                    fontWeight="medium"
                  />
                ),
              }),
              convertColumnTable<DoanVien>({
                key: 'hovaten',
                title: 'Họ và tên',
                fixed: 'left',
                classNameWidthColumnOverwrite: 'big-column',
                render: (_, record) => (
                  <RenderValueTable
                    onClick={() =>
                      navigate(MEMBER_DETAILS_PATH_2, {
                        state: {
                          tumId: record?.maso_doanvien,
                        },
                      })
                    }
                    value={record?.hovaten}
                    type="Link"
                  />
                ),
              }),
              convertColumnTable<DoanVien>({
                key: 'donvi',
                title: 'Đơn vị',
                render: (value: string) => (
                  <BaseText
                    children={!isEmpty(value) ? value : userData?.donvi}
                    opacity="0.7"
                    fontWeight="medium"
                  />
                ),
              }),
              convertColumnTable<DoanVien>({
                key: 'chucvu',
                title: 'Chức vụ',
                classNameWidthColumnOverwrite: 'big-column',
                render: (value: string) => (
                  <BaseText
                    children={!isEmpty(value) ? value : '-'}
                    fontWeight="medium"
                  />
                ),
              }),
              convertColumnTable<DoanVien>({
                key: 'chucdanh',
                title: 'Chức vụ công đoàn',
                render: (value: string) => (
                  <BaseText
                    children={
                      value
                        ?.split('&')
                        ?.map(item => DefineJobTitleEnum[item as JobTitleEnum])
                        .join(' & ') ?? ''
                    }
                    fontWeight="medium"
                  />
                ),
              }),
              convertColumnTable<DoanVien>({
                key: 'ngaysinh',
                title: 'Ngày sinh',
                render: (value: string) => (
                  <BaseText
                    children={value}
                    opacity="0.7"
                    fontWeight="medium"
                  />
                ),
              }),
              convertColumnTable<DoanVien>({
                key: 'gioitinh',
                title: 'Giới tính',
                render: (value: string) => (
                  <BaseText
                    children={value}
                    opacity="0.7"
                    fontWeight="medium"
                  />
                ),
              }),
              convertColumnTable<DoanVien>({
                key: 'quequan',
                title: 'Quê quán',
                render: (value: string) => (
                  <BaseText
                    children={value}
                    opacity="0.7"
                    fontWeight="medium"
                  />
                ),
              }),
              convertColumnTable<DoanVien>({
                key: 'ngayvao_congdoan',
                title: 'Ngày vào công đoàn',
                render: (value: string) => (
                  <BaseText
                    children={value}
                    opacity="0.7"
                    fontWeight="medium"
                  />
                ),
              }),

              convertColumnTable<DoanVien>({
                key: 'cmnd',
                title: 'CMND/CCCD',
                render: (value: string) => (
                  <BaseText
                    children={value}
                    opacity="0.7"
                    fontWeight="medium"
                  />
                ),
              }),

              // trạng thái
              convertColumnTable<DoanVien>({
                key: 'trangthai',
                title: 'Trạng thái',
                fixed: 'right',
                render: (value: string) => (
                  <StatusWrapper
                    children={value ? 'Đang hoạt động' : 'Ngừng hoạt động'}
                    status={value ? 'active' : 'deleted'}
                  />
                ),
              }),

              convertColumnTable<DoanVien>({
                key: 'trangthai',
                title: '',
                fixed: 'right',
                classNameWidthColumnOverwrite: 'number-column',
                render: (_, record) => (
                  <BasePopover
                    content={
                      <BaseSpace>
                        {isEqual(userData?.role, RoleEnum.USER) ? (
                          <UpdateDoanVien doanVien={record} />
                        ) : null}

                        <ModalConfirmChangeStatus doanVien={record} />
                      </BaseSpace>
                    }
                    trigger={'click'}
                  >
                    <BaseButton
                      icon={<MoreOutlined rev={undefined} />}
                      type="text"
                    />
                  </BasePopover>
                ),
              }),
            ]}
            className="row-overwrite-style"
          />
        </BaseSpace>
      </SectionBase>
    </BaseSpace>
  )
}
