import { TypeEventEnum } from 'app/api/event/model'
import { BaseCheckbox } from 'app/components/common/BaseCheckbox'
import BaseText from 'app/components/common/BaseText'
import { MEMBER_DETAILS_PATH_2 } from 'app/components/router/route-path'

import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { RenderValueTable } from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import {
  DefineJobTitleEnum,
  DoanVien,
  JobTitleEnum,
} from 'app/containers/TumManagement/type'
import { useCheckInActionByMember } from 'app/react-query/hook/event'
import { isEqual } from 'lodash'
import { useNavigate } from 'react-router'
export const TumTable = ({
  data,
  eventType,
  activityId,
}: {
  data: DoanVien[]
  eventType?: TypeEventEnum
  activityId?: number
}) => {
  const navigate = useNavigate()

  const { mutateAsync: mutateAsyncCheckIn, isLoading } =
    useCheckInActionByMember()

  const handleCheckIn = async ({
    value,
    doanvienId,
  }: {
    value: boolean
    doanvienId?: string
  }) => {
    if (!activityId) return

    if (!doanvienId) return

    await mutateAsyncCheckIn({
      checkedIn: value,
      actionEventId: activityId,
      doanvienId,
    })
  }

  return (
    <BaseTableManagement
      dataSource={data?.map((item, index) => ({
        ...item,
        index: index + 1,
        key: index + 1,
      }))}
      pagination={{
        pageSize: 10,
      }}
      columns={[
        convertColumnTable<{ index: number }>({
          key: 'index',
          title: 'STT',
          fixed: 'left',
          classNameWidthColumnOverwrite: 'number-column',
          render: (value: number) => (
            <BaseText children={value} opacity="0.7" fontWeight="medium" />
          ),
        }),

        convertColumnTable<DoanVien>({
          key: 'hovaten',
          title: 'Họ và tên',
          fixed: 'left',
          classNameWidthColumnOverwrite: 'big-column',
          render: (_, record) => (
            <RenderValueTable
              onClick={() =>
                navigate(MEMBER_DETAILS_PATH_2, {
                  state: {
                    tumId: record?.maso_doanvien,
                  },
                })
              }
              value={record?.hovaten}
              type="Link"
            />
          ),
        }),
        convertColumnTable<DoanVien>({
          key: 'chucdanh',
          title: 'Chức vụ công đoàn',
          render: (value: string) => (
            <BaseText
              children={
                value
                  ?.split('&')
                  ?.map(item => DefineJobTitleEnum[item as JobTitleEnum])
                  .join(' & ') ?? ''
              }
              fontWeight="medium"
            />
          ),
        }),
        convertColumnTable<DoanVien>({
          key: 'ngaysinh',
          title: 'Ngày sinh',
          render: (value: string) => (
            <BaseText children={value} opacity="0.7" fontWeight="medium" />
          ),
        }),
        convertColumnTable<DoanVien>({
          key: 'gioitinh',
          title: 'Giới tính',
          render: (value: string) => (
            <BaseText children={value} opacity="0.7" fontWeight="medium" />
          ),
        }),
        convertColumnTable<DoanVien>({
          key: 'quequan',
          title: 'Quê quán',
          render: (value: string) => (
            <BaseText children={value} opacity="0.7" fontWeight="medium" />
          ),
        }),
        convertColumnTable<DoanVien>({
          key: 'ngayvao_congdoan',
          title: 'Ngày vào công đoàn',
          render: (value: string) => (
            <BaseText children={value} opacity="0.7" fontWeight="medium" />
          ),
        }),
        convertColumnTable<DoanVien>({
          key: 'cmnd',
          title: 'CMND/CCCD',
          render: (value: string) => (
            <BaseText children={value} opacity="0.7" fontWeight="medium" />
          ),
        }),
        ...(isEqual(eventType, TypeEventEnum.CARE)
          ? [
              convertColumnTable<{ valueCare?: string }>({
                key: 'valueCare',
                title: 'Phần quà',
                fixed: 'right',
                render: (value?: string) => (
                  <BaseText
                    children={value ?? '-'}
                    opacity="0.7"
                    fontWeight="medium"
                  />
                ),
              }),
            ]
          : [
              convertColumnTable<{ key?: string; checkedIn?: boolean }>({
                key: 'checkedIn',
                title: 'Check in',
                fixed: 'right',
                render: (_, record) => {
                  return (
                    <BaseCheckbox
                      checked={record?.checkedIn}
                      value={record?.checkedIn}
                      onChange={e =>
                        handleCheckIn({
                          value: e?.target?.checked,
                          doanvienId: record?.key,
                        })
                      }
                    />
                  )
                },
              }),
            ]),
      ]}
      loading={isLoading}
      scroll={{
        x: true,
      }}
    />
  )
}
