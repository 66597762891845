import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { SectionBase } from 'app/components/common/SectionBase'
import {
  DefineJobTitleEnum,
  DoanVien,
  JobTitleEnum,
} from 'app/containers/TumManagement/type'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'

interface IProps {
  listMember: DoanVien[]
  isLoading?: boolean
}

export const ListMemberUnitLayout = ({ listMember, isLoading }: IProps) => {
  return (
    <SectionBase title="Danh sách đoàn viên của đơn vị">
      <BaseSpace>
        <BaseTableManagement
          dataSource={listMember}
          pagination={{
            pageSize: 5,
            showSizeChanger: false,
            total: listMember?.length,
          }}
          loading={isLoading}
          columns={[
            convertColumnTable<{ index: number }>({
              key: 'index',
              title: 'STT',
              fixed: 'left',
              classNameWidthColumnOverwrite: 'number-column',
              render: (value: number) => (
                <BaseText children={value} opacity="0.7" fontWeight="medium" />
              ),
            }),

            convertColumnTable<DoanVien>({
              key: 'hovaten',
              title: 'Họ và tên',
              fixed: 'left',
              classNameWidthColumnOverwrite: 'big-column',
              render: (value: string) => (
                <BaseText children={value} fontWeight="medium" />
              ),
            }),
            convertColumnTable<DoanVien>({
              key: 'chucdanh',
              title: 'Chức vụ công đoàn',
              render: (value: string) => (
                <BaseText
                  children={
                    value
                      ?.split('&')
                      ?.map(item => DefineJobTitleEnum[item as JobTitleEnum])
                      .join(' & ') ?? ''
                  }
                  fontWeight="medium"
                />
              ),
            }),
            convertColumnTable<DoanVien>({
              key: 'ngaysinh',
              title: 'Ngày sinh',
              render: (value: string) => (
                <BaseText children={value} opacity="0.7" fontWeight="medium" />
              ),
            }),
            convertColumnTable<DoanVien>({
              key: 'gioitinh',
              title: 'Giới tính',
              render: (value: string) => (
                <BaseText children={value} opacity="0.7" fontWeight="medium" />
              ),
            }),
            convertColumnTable<DoanVien>({
              key: 'quequan',
              title: 'Quê quán',
              render: (value: string) => (
                <BaseText children={value} opacity="0.7" fontWeight="medium" />
              ),
            }),

            convertColumnTable<DoanVien>({
              key: 'cmnd',
              title: 'CMND/CCCD',
              render: (value: string) => (
                <BaseText children={value} opacity="0.7" fontWeight="medium" />
              ),
            }),
          ]}
          className="row-overwrite-style"
          scroll={{ x: true }}
        />
      </BaseSpace>
    </SectionBase>
  )
}
