import R from 'app/assets/R'
import { RoleEnum } from 'app/common/enum'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { Loading } from 'app/components/common/Loading'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import HeaderPage from 'app/components/header/components/HeaderPage'
import { useAppDispatch, useAppSelector } from 'app/redux/hooks'
import { getUserInfoOld } from 'app/redux/slices/userOldSlice'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  DashboardAdminLayout,
  DashboardMemberLayout,
  DashboardUserLayout,
} from './layouts'

export enum FilterAreaEnum {
  // this year
  THIS_YEAR = 'THIS_YEAR',
  // last year
  LAST_YEAR = 'LAST_YEAR',

  // this month
  THIS_MONTH = 'THIS_MONTH',
  // last month
  LAST_MONTH = 'LAST_MONTH',

  // this week
  THIS_WEEK = 'THIS_WEEK',
  // last week
  LAST_WEEK = 'LAST_WEEK',
}

export const convertFilterAreaEnumToTime = (filter: FilterAreaEnum) => {
  switch (filter) {
    case FilterAreaEnum.THIS_YEAR:
      return {
        start: new Date(new Date().getFullYear(), 0, 1),
        end: new Date(new Date().getFullYear(), 11, 31),
      }
    case FilterAreaEnum.LAST_YEAR:
      return {
        start: new Date(new Date().getFullYear() - 1, 0, 1),
        end: new Date(new Date().getFullYear() - 1, 11, 31),
      }
    case FilterAreaEnum.THIS_MONTH:
      return {
        start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
      }
    case FilterAreaEnum.LAST_MONTH:
      return {
        start: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
        end: new Date(new Date().getFullYear(), new Date().getMonth(), 0),
      }
    case FilterAreaEnum.THIS_WEEK:
      return {
        start: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() - new Date().getDay(),
        ),
        end: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() + (6 - new Date().getDay()),
        ),
      }
    case FilterAreaEnum.LAST_WEEK:
      return {
        start: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() - new Date().getDay() - 7,
        ),
        end: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() + (6 - new Date().getDay() - 7),
        ),
      }
    default:
      return {
        start: new Date(new Date().getFullYear(), 0, 1),
        end: new Date(new Date().getFullYear(), 11, 31),
      }
  }
}

const DashboardContainer: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const userData = useAppSelector(state => state.userOld)?.profile

  const [filter, setFilter] = useState<FilterAreaEnum>(FilterAreaEnum.THIS_YEAR)

  const params = useMemo(() => {
    const res = convertFilterAreaEnumToTime(filter)
    return {
      startDate: res.start?.toISOString(),
      endDate: res.end?.toISOString(),
    }
  }, [filter])

  const renderLayout = useCallback(() => {
    switch (userData?.role) {
      case RoleEnum.ADMIN:
      case RoleEnum.SUB_ADMIN:
        return <DashboardAdminLayout {...params} />
      case RoleEnum.USER:
        return <DashboardUserLayout />
      case RoleEnum.EMPLOYEE:
        return <DashboardMemberLayout />
      default:
        return <Loading size="small" />
    }
  }, [userData?.role, params])()

  useEffect(() => {
    dispatch(getUserInfoOld())
  }, [])

  return (
    <RootWrapper>
      <HeaderPage
        title={t(R.strings.dashboard)}
        rightComponent={(() => {
          switch (userData?.role) {
            case RoleEnum.EMPLOYEE:
            case RoleEnum.USER:
              return null
            case RoleEnum.ADMIN:
              return (
                <BaseSelect
                  value={filter}
                  onChange={value => setFilter(value as FilterAreaEnum)}
                  options={[
                    {
                      label: 'Năm nay',
                      value: FilterAreaEnum.THIS_YEAR,
                    },
                    {
                      label: 'Năm trước',
                      value: FilterAreaEnum.LAST_YEAR,
                    },
                    {
                      label: 'Tháng này',
                      value: FilterAreaEnum.THIS_MONTH,
                    },
                    {
                      label: 'Tháng trước',
                      value: FilterAreaEnum.LAST_MONTH,
                    },
                    {
                      label: 'Tuần này',
                      value: FilterAreaEnum.THIS_WEEK,
                    },
                    {
                      label: 'Tuần trước',
                      value: FilterAreaEnum.LAST_WEEK,
                    },
                  ]}
                />
              )
            default:
              return null
          }
        })()}
      />

      {renderLayout}
    </RootWrapper>
  )
}

export default DashboardContainer

const RootWrapper = styled(BaseSpace)`
  min-width: 800px;
`
