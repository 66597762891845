import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { useGetDashboardUser } from 'app/react-query/hook/dashboard'
import {
  ListMemberUnitLayout,
  Top10MemberJoinEventByUnitLayout,
  Top10MemberPrizeByUnitLayout,
} from './layouts'

export const DashboardUserLayout = () => {
  const { data, isLoading } = useGetDashboardUser()
  return (
    <BaseSpace>
      <BaseRow gutter={[16, 8]} align={'middle'} justify={'space-between'}>
        <BaseCol xl={12}>
          <Top10MemberPrizeByUnitLayout
            top10MemberPrizeByUnit={data?.top10Prize ?? []}
            isLoading={isLoading}
          />
        </BaseCol>
        <BaseCol xl={12}>
          <Top10MemberJoinEventByUnitLayout
            top10MemberJoinEventByUnit={data?.top10Action ?? []}
            isLoading={isLoading}
          />
        </BaseCol>
      </BaseRow>
      <ListMemberUnitLayout
        listMember={data?.listDoanVien ?? []}
        isLoading={isLoading}
      />
    </BaseSpace>
  )
}
