import { SearchOutlined } from '@ant-design/icons'
import { requestGetUnitPrizeByEventId } from 'app/api/event'
import { IEvent } from 'app/api/event/model'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { DoanVien } from 'app/containers/TumManagement/type'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { isEmpty } from 'lodash'
import { useEffect, useMemo, useState } from 'react'

interface IUnitPrizeInEvent {
  donvi?: string
  members?: DoanVien[]
  countPrize?: number
}

export const ListUnitPrizeLayout = ({ event }: { event?: IEvent }) => {
  const [data, setData] = useState<IUnitPrizeInEvent[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')

  const getData = async () => {
    if (!event?.id) return
    setLoading(true)
    try {
      const res: IUnitPrizeInEvent[] = await requestGetUnitPrizeByEventId({
        id: event.id,
      })

      setData(res)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      getData()
    }, 1000)

    return () => clearTimeout(timer)
  }, [event])

  const dataSource = useMemo(() => {
    return data
      ?.filter(itm => {
        if (isEmpty(search)) return true

        const searchLowerCase = search.toLowerCase()

        if (itm?.donvi?.toLowerCase().includes(searchLowerCase)) return true
      })
      ?.map((item, index) => {
        return {
          ...item,
          key: index + 1,
          index: index + 1,
        }
      })
  }, [data, search])

  return (
    <BaseSpace>
      <BaseText
        children={'Danh sách đơn vị đoạt giải'}
        fontWeight="semibold"
        fontSize="xs"
      />

      <BaseRow>
        <BaseCol xl={8}>
          <BaseForm>
            <BaseForm.Item
              hiddenLabel
              style={{
                marginBottom: 0,
              }}
            >
              <BaseInput
                onChange={e => setSearch(e?.target?.value)}
                value={search}
                placeholder="Nhập tên đơn vị"
                allowClear
                suffix={<SearchOutlined rev={undefined} />}
              />
            </BaseForm.Item>
          </BaseForm>
        </BaseCol>
      </BaseRow>

      <BaseTableManagement
        dataSource={dataSource}
        loading={loading}
        pagination={{
          pageSize: 5,
        }}
        columns={[
          convertColumnTable({
            key: 'index',
            title: 'STT',
            classNameWidthColumnOverwrite: 'number-column',
            render: (value: number) => {
              return (
                <BaseText children={value} opacity="0.7" fontWeight="medium" />
              )
            },
          }),
          convertColumnTable<IUnitPrizeInEvent>({
            key: 'donvi',
            title: 'Tên đơn vị',
            render: (_, record?: IUnitPrizeInEvent) => {
              return (
                <BaseText
                  children={record?.donvi ?? '-'}
                  opacity="0.7"
                  fontWeight="medium"
                />
              )
            },
          }),
          convertColumnTable<IUnitPrizeInEvent>({
            key: 'countPrize',
            title: 'Số lượng giải',
            render: (_, record?: IUnitPrizeInEvent) => {
              return (
                <BaseText
                  children={record?.countPrize ?? '0'}
                  opacity="0.7"
                  fontWeight="medium"
                />
              )
            },
          }),
          convertColumnTable<IUnitPrizeInEvent>({
            key: 'members',
            title: 'Số đoàn viên đoạt giải',
            render: (_, record?: IUnitPrizeInEvent) => {
              return (
                <BaseText
                  children={record?.members?.length ?? '0'}
                  opacity="0.7"
                  fontWeight="medium"
                />
              )
            },
          }),
        ]}
        className="row-overwrite-style"
        scroll={{
          x: true,
        }}
      />
    </BaseSpace>
  )
}
