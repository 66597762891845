import { memo } from 'react'
import { isEqual } from 'lodash'
import EventContainer from 'app/containers/EventManagementByUnit'
import { PageTitle } from 'app/components/common/PageTitle'
import { useTranslation } from 'react-i18next'
import R from 'app/assets/R'

const EventManagementByUnitCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.event_management_by_unit)}</PageTitle>
      <EventContainer />
    </>
  )
}
export const EventManagementByUnitPage = memo(EventManagementByUnitCpn, isEqual)
