import { IActionEvent, IEvent } from 'app/api/event/model'
import BaseText from 'app/components/common/BaseText'
import { SectionBase } from 'app/components/common/SectionBase'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { useMemo } from 'react'

interface IProps {
  listActionRegister?: IActionEvent[]
  isLoading?: boolean
}

interface IDataTable extends IActionEvent {
  key?: number
  event?: IEvent
}

export const MyActionRegisterLayout = ({
  listActionRegister,
  isLoading,
}: IProps) => {
  const data = useMemo(() => {
    return listActionRegister?.map((item, index) => {
      return {
        ...item,
        key: index + 1,
      } as IDataTable
    })
  }, [listActionRegister])
  return (
    <SectionBase title="Hoạt động bạn đã đăng kí">
      <BaseTableManagement
        columns={[
          convertColumnTable<IDataTable>({
            title: 'STT',
            key: 'key',
            classNameWidthColumnOverwrite: 'number-column',
            fixed: 'left',
            render: (key: number) => {
              return (
                <BaseText children={key} fontWeight="medium" opacity="0.7" />
              )
            },
          }),
          convertColumnTable<IDataTable>({
            title: 'Tên hoạt động',
            key: 'name',
            fixed: 'left',

            classNameWidthColumnOverwrite: 'big-column',
            render: (value: string) => {
              return <BaseText children={value} fontWeight="semibold" />
            },
          }),
          convertColumnTable<IDataTable>({
            title: 'Sự kiện',
            key: 'event',
            classNameWidthColumnOverwrite: 'big-column',
            render: (_, record) => {
              return (
                <BaseText
                  children={record?.event?.name ?? ''}
                  fontWeight="medium"
                  opacity="0.7"
                />
              )
            },
          }),
          convertColumnTable<IDataTable>({
            title: 'Thời gian bắt đầu',
            key: 'event',
            render: (_, record) => {
              return (
                <BaseText
                  children={record?.event?.date_started ?? ''}
                  fontWeight="medium"
                  colorText="statesGreenColor"
                />
              )
            },
          }),
          convertColumnTable<IDataTable>({
            title: 'Thời gian kết thúc',
            key: 'event',
            render: (_, record) => {
              return (
                <BaseText
                  children={record?.event?.date_started ?? ''}
                  fontWeight="medium"
                  colorText="statesGreenColor"
                />
              )
            },
          }),
        ]}
        dataSource={data}
        scroll={{
          x: true,
        }}
        loading={isLoading}
      />
    </SectionBase>
  )
}
