import { LogoutOutlined } from '@ant-design/icons'
import { userIcon } from 'app/assets'
import R from 'app/assets/R'
import { RoleEnum } from 'app/common/enum'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseImage } from 'app/components/common/BaseImage'
import { BasePopover } from 'app/components/common/BasePopover'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { DASHBOARD_PATH } from 'app/components/router/route-path'
import Cookies from 'js-cookie'
import { isEmpty, isEqual } from 'lodash'
import { FONT_SIZE, FONT_WEIGHT } from 'parkway-web-common'
import { useMemo } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

type Props = {
  name: string
  role?: RoleEnum
}

export const SPACE_PATH = ' | '

export interface ThongBao {
  id: string
  noidung: string
  createdBy: string
  type: string
  role?: RoleEnum
  createdAt: string
  updatedAt: string
  // group_id?: string[]
}

export default function Header({ name }: Props) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { pathname } = useLocation()

  const path = useMemo(() => {
    if (isEqual(pathname, DASHBOARD_PATH)) {
      return {
        lastPath: t(R.strings.dashboard),
        middlePathName: '',
        middlePath: '',
      }
    }

    const convertedPath = pathname.replace(/-/g, '_')
    const pathComponents = convertedPath
      .split('/')
      .filter(component => component !== '')

    if (pathComponents.length > 1) {
      const splitIndex = pathComponents.length - 1
      const middleArray = pathComponents.slice(0, splitIndex)
      const lastArray = pathComponents.slice(splitIndex)
      return {
        lastPath: lastArray
          .map(component => `${t(`${component}`)}`)
          .join(SPACE_PATH),
        middlePathName: middleArray
          .map(component => `${t(`${component}`)}`)
          .join(SPACE_PATH),
        middleArray,
        middlePath: middleArray.map(component => component).join(SPACE_PATH),
      }
    }

    const translatedPath = pathComponents
      .map(component => `${t(`${component}`)}`)
      .join(SPACE_PATH)

    return {
      lastPath: translatedPath,
      middlePathName: '',
      middlePath: '',
    }
  }, [pathname, t])

  const renderPath = (() => {
    let prefix = ''
    return (
      <CurrentPath>
        <HomeText onClick={() => navigate('/')}>{`Home${SPACE_PATH}`}</HomeText>
        {!isEmpty(path?.middleArray) &&
          path?.middleArray?.map((pathChild, index) => {
            prefix += `/${pathChild.replace(/_/g, '-')}`
            const navigationPath = prefix

            return (
              <HomeText
                key={index}
                onClick={() => navigate(navigationPath)}
              >{`${t(pathChild)}${SPACE_PATH}`}</HomeText>
            )
          })}
        {path.lastPath}
      </CurrentPath>
    )
  })()

  function handleSignOut() {
    Cookies.remove('access_token')
    toast.success('Thành công đăng xuất!')
    navigate('/login')
  }

  return (
    <RootWrapper align={'middle'} gutter={[16, 16]} justify={'space-between'}>
      <BaseCol>{renderPath}</BaseCol>
      <BaseCol>
        <BaseRow align={'middle'} gutter={20}>
          <BaseCol>
            <BaseRow gutter={8} align={'middle'}>
              <BaseCol>
                <BasePopover
                  content={
                    <BaseSpace size={4}>
                      <BaseButton
                        onClick={handleSignOut}
                        icon={<LogoutOutlined rev={undefined} />}
                        type="text"
                      >
                        Đăng xuất
                      </BaseButton>
                    </BaseSpace>
                  }
                  trigger={'click'}
                >
                  <BaseButton
                    icon={
                      <BaseImage
                        preview={false}
                        src={userIcon}
                        alt="user"
                        height={40}
                        width={40}
                      />
                    }
                    type="text"
                  />
                </BasePopover>
              </BaseCol>
              <BaseCol>
                <div className="hidden lg:flex cursor-pointer">
                  {name ? name : 'Tên Đoàn Viên'}
                </div>
              </BaseCol>
            </BaseRow>
          </BaseCol>
        </BaseRow>
      </BaseCol>
    </RootWrapper>
  )
}

export const CurrentPath = styled.span`
  color: var(--primary-color);
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.semibold};
`

export const HomeText = styled.span`
  color: var(--main-color-main-optional-primary-3-color);
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.regular};
  cursor: pointer;
`

const RootWrapper = styled(BaseRow)`
  padding: 0 16px;
`
