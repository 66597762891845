import { PageTitle } from 'app/components/common/PageTitle'
import { UnitManagementContainer } from 'app/containers/UnitManagement'
import { isEqual } from 'lodash'
import { memo } from 'react'

const UnitManagementCpn = () => {
  return (
    <>
      <PageTitle>{'Quản lý đơn vị'}</PageTitle>
      <UnitManagementContainer />
    </>
  )
}
export const UnitManagementPage = memo(UnitManagementCpn, isEqual)
