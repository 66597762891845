import { useCreateEventContext } from '../../context'

export const useHook = () => {
  const { form, eventType, onChangeEventType } = useCreateEventContext()

  return {
    form,
    eventType,
    onChangeEventType,
  }
}
