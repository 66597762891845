import {
  CalendarOutlined,
  FlagOutlined,
  HomeOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons'
import {
  DASHBOARD_PATH,
  EVENT_INFO_PATH,
  EVENT_MANAGEMENT_BY_UNIT_PATH,
  LIST_TRADE_UNION_MEMBERS_PATH,
  PERSONAL_INFO_PATH,
} from 'app/components/router/route-path'
import React from 'react'
import { getItem, MenuItem, SiderCommon } from './components'

const items: MenuItem[] = [
  getItem('Trang chủ', '5', <HomeOutlined rev={undefined} />, DASHBOARD_PATH),

  getItem(
    'Danh sách công đoàn viên',
    '4',
    <TeamOutlined rev={undefined} />,
    LIST_TRADE_UNION_MEMBERS_PATH,
  ),
  getItem(
    'Danh sách sự kiện',
    '7',
    <CalendarOutlined rev={undefined} />,
    EVENT_MANAGEMENT_BY_UNIT_PATH,
  ),
  getItem(
    'Đăng kí hoạt động/sự kiện',
    '6',
    <FlagOutlined rev={undefined} />,
    EVENT_INFO_PATH,
  ),
  getItem(
    'Thông tin cá nhân',
    '3',
    <UserOutlined rev={undefined} />,
    PERSONAL_INFO_PATH,
  ),
]

const Sidebar: React.FC = () => {
  return <SiderCommon items={items} />
}

export default Sidebar
