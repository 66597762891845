import { BaseSpace } from 'app/components/common/BaseSpace'
import { SectionBase } from 'app/components/common/SectionBase'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import React from 'react'
import { useHook } from './hook'
import { FilterLayout } from '..'

const OtherEventLayout: React.FC = () => {
  const {
    columns,
    data,
    isLoading,
    filter,
    onChangeFilter,
    handleChangeTable,
  } = useHook()

  return (
    <SectionBase title="Danh sách sự kiện cấp sở">
      <BaseSpace>
        <FilterLayout filter={filter} onChangeFilter={onChangeFilter} />
        <BaseTableManagement
          columns={columns}
          dataSource={data}
          loading={isLoading}
          pagination={{
            pageSize: 10,
          }}
          rowClassName="row-overwrite-style"
          onChange={handleChangeTable}
        />
      </BaseSpace>
    </SectionBase>
  )
}

export default OtherEventLayout
