import { BaseSpace } from 'app/components/common/BaseSpace'
import { PageTitle } from 'app/components/common/PageTitle'
import { SectionBase } from 'app/components/common/SectionBase'
import HeaderPage from 'app/components/header/components/HeaderPage'
import RegisterActionForMemberContainer from 'app/containers/RegisterForMember'

const EventRegisterForMemberPage = () => {
  return (
    <>
      <PageTitle>{'Đăng kí hoạt động/sự kiện'}</PageTitle>
      <BaseSpace>
        <HeaderPage title="Đăng kí hoạt động/sự kiện" />
        <SectionBase>
          <RegisterActionForMemberContainer />
        </SectionBase>
      </BaseSpace>
    </>
  )
}

export default EventRegisterForMemberPage
