// svg import auto

export { ReactComponent as EditIcon} from './svg/edit.svg';
export { ReactComponent as SeletecdIconIcon} from './svg/seletecdIcon.svg';
export { ReactComponent as SvgAccountingIcon} from './svg/svg_accounting.svg';
export { ReactComponent as SvgAccountingFocusIcon} from './svg/svg_accounting_focus.svg';
export { ReactComponent as SvgArrowBackUpIcon} from './svg/svg_arrow_back_up.svg';
export { ReactComponent as SvgArrowTrendingDownIcon} from './svg/svg_arrow_trending_down.svg';
export { ReactComponent as SvgArrowTrendingUpIcon} from './svg/svg_arrow_trending_up.svg';
export { ReactComponent as SvgAttendanceRecordIcon} from './svg/svg_attendance_record.svg';
export { ReactComponent as SvgAttendanceRecordFocusIcon} from './svg/svg_attendance_record_focus.svg';
export { ReactComponent as SvgBlockItemIcon} from './svg/svg_block_item.svg';
export { ReactComponent as SvgCalendarDesignIcon} from './svg/svg_calendar_design.svg';
export { ReactComponent as SvgClinicIcon} from './svg/svg_clinic.svg';
export { ReactComponent as SvgClinicManagementIcon} from './svg/svg_clinic_management.svg';
export { ReactComponent as SvgClinicManagementFocusIcon} from './svg/svg_clinic_management_focus.svg';
export { ReactComponent as SvgClipboardPlusIcon} from './svg/svg_clipboard_plus.svg';
export { ReactComponent as SvgCompanyIcon} from './svg/svg_company.svg';
export { ReactComponent as SvgCompanyFocusIcon} from './svg/svg_company_focus.svg';
export { ReactComponent as SvgCopyIcon} from './svg/svg_copy.svg';
export { ReactComponent as SvgCustomerCareIcon} from './svg/svg_customer_care.svg';
export { ReactComponent as SvgCustomerCareFocusIcon} from './svg/svg_customer_care_focus.svg';
export { ReactComponent as SvgDashboardIcon} from './svg/svg_dashboard.svg';
export { ReactComponent as SvgDashboardFocusIcon} from './svg/svg_dashboard_focus.svg';
export { ReactComponent as SvgDefaultPrefixIcon} from './svg/svg_default_prefix.svg';
export { ReactComponent as SvgDeleteDesignIcon} from './svg/svg_delete_design.svg';
export { ReactComponent as SvgDocPrefixIcon} from './svg/svg_doc_prefix.svg';
export { ReactComponent as SvgExcelPrefixIcon} from './svg/svg_excel_prefix.svg';
export { ReactComponent as SvgFinanceIcon} from './svg/svg_finance.svg';
export { ReactComponent as SvgFoldersIcon} from './svg/svg_folders.svg';
export { ReactComponent as SvgHumanResourcesIcon} from './svg/svg_human_resources.svg';
export { ReactComponent as SvgHumanResourcesFocusIcon} from './svg/svg_human_resources_focus.svg';
export { ReactComponent as SvgIconCollapsedIcon} from './svg/svg_icon_collapsed.svg';
export { ReactComponent as SvgImageExampleIcon} from './svg/svg_image_example.svg';
export { ReactComponent as SvgImageFolderIcon} from './svg/svg_image_folder.svg';
export { ReactComponent as SvgInformationCircleOrangeIcon} from './svg/svg_information_circle_orange.svg';
export { ReactComponent as SvgInventoryManagementIcon} from './svg/svg_inventory_management.svg';
export { ReactComponent as SvgInventoryManagementFocusIcon} from './svg/svg_inventory_management_focus.svg';
export { ReactComponent as SvgMailBoxIcon} from './svg/svg_mail_box.svg';
export { ReactComponent as SvgMailBoxFocusIcon} from './svg/svg_mail_box_focus.svg';
export { ReactComponent as SvgMarketingIcon} from './svg/svg_marketing.svg';
export { ReactComponent as SvgMarketingFocusIcon} from './svg/svg_marketing_focus.svg';
export { ReactComponent as SvgMedicalExaminationIcon} from './svg/svg_medical_examination.svg';
export { ReactComponent as SvgMedicalExaminationFocusIcon} from './svg/svg_medical_examination_focus.svg';
export { ReactComponent as SvgMyIncomeIcon} from './svg/svg_my_income.svg';
export { ReactComponent as SvgMyIncomeFocusIcon} from './svg/svg_my_income_focus.svg';
export { ReactComponent as SvgMyRequestIcon} from './svg/svg_my_request.svg';
export { ReactComponent as SvgMyRequestFocusIcon} from './svg/svg_my_request_focus.svg';
export { ReactComponent as SvgMyTaskIcon} from './svg/svg_my_task.svg';
export { ReactComponent as SvgMyTasksFocusIcon} from './svg/svg_my_tasks_focus.svg';
export { ReactComponent as SvgPdfPrefixIcon} from './svg/svg_pdf_prefix.svg';
export { ReactComponent as SvgPriceManagementAddIcon} from './svg/svg_price_management_add.svg';
export { ReactComponent as SvgPrinterIcon} from './svg/svg_printer.svg';
export { ReactComponent as SvgPurchasingManagementIcon} from './svg/svg_purchasing_management.svg';
export { ReactComponent as SvgPurchasingManagementFocusIcon} from './svg/svg_purchasing_management_focus.svg';
export { ReactComponent as SvgRefundAmountIcon} from './svg/svg_refund_amount.svg';
export { ReactComponent as SvgReportIcon} from './svg/svg_report.svg';
export { ReactComponent as SvgReportFocusIcon} from './svg/svg_report_focus.svg';
export { ReactComponent as SvgSelectBlockIcon} from './svg/svg_select_block.svg';
export { ReactComponent as SvgSelectListIcon} from './svg/svg_select_list.svg';
export { ReactComponent as SvgTableCloseIcon} from './svg/svg_table_close.svg';
export { ReactComponent as SvgTableOpenIcon} from './svg/svg_table_open.svg';
export { ReactComponent as SvgUtilitiesIcon} from './svg/svg_utilities.svg';
export { ReactComponent as SvgUtilitiesFocusIcon} from './svg/svg_utilities_focus.svg';
export { ReactComponent as SvgWorkScheduleIcon} from './svg/svg_work_schedule.svg';
export { ReactComponent as SvgWorkScheduleFocusIcon} from './svg/svg_work_schedule_focus.svg';
export { ReactComponent as TrashIcon} from './svg/trash.svg';
        