import { PlusOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { SectionBase } from 'app/components/common/SectionBase'
import HeaderPage from 'app/components/header/components/HeaderPage'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import React from 'react'
import { useHook } from './hook'
import { FilterLayout } from './layouts'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseForm } from 'app/components/common/forms/BaseForm'

const EventManagementContainer: React.FC = () => {
  const {
    columns,
    data,
    isLoading,
    filter,
    onChangeFilter,
    handleChangeTable,
    navigateToCreate,
  } = useHook()

  return (
    <BaseForm>
      <BaseSpace>
        <HeaderPage
          titleI18nKey={R.strings.event_management}
          rightComponent={
            <BaseCol>
              <BaseButton
                icon={<PlusOutlined rev={undefined} />}
                onClick={navigateToCreate}
              >
                {'Tạo mới sự kiện'}
              </BaseButton>
            </BaseCol>
          }
          isExport
        />

        <SectionBase>
          <BaseSpace>
            <FilterLayout filter={filter} onChangeFilter={onChangeFilter} />
            <BaseTableManagement
              columns={columns}
              dataSource={data}
              loading={isLoading}
              pagination={{
                pageSize: 10,
              }}
              rowClassName="row-overwrite-style"
              onChange={handleChangeTable}
            />
          </BaseSpace>
        </SectionBase>
      </BaseSpace>
    </BaseForm>
  )
}

export default EventManagementContainer
