import { DoanVien } from 'app/containers/TumManagement/type'
import {
  IActivityEvent,
  TypeColumn,
} from 'app/containers/EventManagement/CreateEvent/type'
import { EventStatusEnum } from 'app/containers/EventManagement/type'
import { ResponseType } from 'parkway-web-common'

export interface IParamsGetEvents {
  status?: EventStatusEnum[]
  resource?: ResourceEventEnum
}

export enum ResourceEventEnum {
  UNIT = 'unit',
  ADMIN = 'admin',
}

export interface IParamsGetTradeUnionMemberByEventId extends IParamsGetEvents {
  event_id?: number
}


export enum GroupEventEnum {
  //   1. Công tác tổ chức
  // 2. Tuyên giáo
  // 3. Nữ công
  // 4. Văn Thể Mỹ
  // 5. Kiểm tra
  // 6. Công tác cộng đồng
  // 7. Thi đua khen thưởng

  ORGANIZATION = 'ORGANIZATION',
  PROPAGANDA = "PROPAGANDA",
  FEMALE_WORKER = "FEMALE_WORKER",
  ARTISTIC = "ARTISTIC",
  CHECK = "CHECK",
  COMMUNITY = "COMMUNITY",
  REWARD = "REWARD",
}

export enum TypeEventEnum {
  TRAINING = 'training', // Hội thảo, tập huấn
  CARE = 'care', // Chăm lo
  ACTIVITY = 'activity', // Hoạt động
  NEWS = 'news', // Tin tức
}

export interface IEvent {
  id?: number
  name?: string
  desc?: string
  date_started?: string
  date_end?: string
  register_started?: string
  register_end?: string
  status?: EventStatusEnum

  link?: string
  type?: TypeEventEnum

  activities?: IActivityEvent[]

  resource?: ResourceEventEnum
  group?: GroupEventEnum

  unit?: string
}

export type IResponseEventList = ResponseType<{
  rows: IEvent[]
  count: number
}>

export interface IPayloadCreateEvent {
  name?: string
  desc?: string
  date_started?: string
  date_end?: string
  register_started?: string
  register_end?: string
  type?: TypeEventEnum
  link?: string

  actions?: IPayloadActionEvent[]

  resource?: ResourceEventEnum

  group?: GroupEventEnum

  unit?: string
}

export interface IPayloadUpdateEvent {
  id?: number
  name?: string
  desc?: string
  date_started?: string
  date_end?: string
  register_started?: string
  register_end?: string
  type?: TypeEventEnum
  link?: string
  resource?: ResourceEventEnum

  group?: GroupEventEnum
}

export interface IDeleteEvent {
  id?: number
}

// action event
export interface IParamsGetActionByEvent {
  id: number
}

export interface IActionEvent {
  id?: number
  name?: string
  valueCare?: string
  eventId?: number
  action?: string
  createdAt?: string
  updatedAt?: string
  listMember?: IMemberActionEvent[]
  listPrize?: IPrizeActionEvent[]
  listCategory?: ICategoryActionEvent[]

  event?: IEvent
}

export interface IMemberActionEvent {
  id?: number
  actionEventId?: number
  maso_doanvien?: string
  action?: string
  checkedIn?: boolean
  createdAt?: string
  updatedAt?: string
  listMemberCategory?: IMemberCategoryActionEvent[]
  info: DoanVien
  valueCare?: string
}

export interface IMemberCategoryActionEvent {
  id?: number
  idRegisterMember?: number
  idCategory?: number
  value?: string
  action?: string
  createdAt?: string
  updatedAt?: string
}

export interface IPrizeActionEvent {
  id?: number
  name?: string
  description?: string
  gift?: string
  countPerReward?: number
  actionEventId?: number
  type_prize?: string
  action?: string
  createdAt?: string
  updatedAt?: string
  type?: string

  actionEvent?: IActionEvent
}

export interface ICategoryActionEvent {
  id?: number
  name?: string
  actionEventId?: number
  action?: string
  keyOfMainData?: string
  type?: TypeColumn
  createdAt?: string
  updatedAt?: string
}

export interface IPayloadActionEvent {
  action: {
    name?: string
    event_id?: number
    valueCare?: string
  }
  prize: {
    name: string
    type_prize: string
    description?: string
    gift?: string
    countPerReward?: number
  }[]
  columns?: {
    name?: string
    keyOfMainData?: string
    type?: string
  }[]
  memberCategory: {
    maso_doanvien: string
    columns: {
      name: string
      value: string
    }[]
  }[]
}
export interface IPayloadCreateActionEvent {
  event_id: number
  actions: IPayloadActionEvent[]
}

export enum StatusMemberRegisterWaiting {
  WAITING = 'WAITING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}

export interface IParamsGetActionRegisteredByMember {
  id?: string
  status?: StatusMemberRegisterWaiting
}

export interface IParamsGetMemberByAction {
  id?: number
  status?: StatusMemberRegisterWaiting
}

export interface IPayloadChangeStatusMemberRegister {
  id?: number
  status: StatusMemberRegisterWaiting
  reason?: string
}

export interface IPayloadRegisterActionByMember {
  listMember: {
    action_event_id: number
    maso_doanvien: string
  }[]
}

export interface IPayloadCheckInActionByMember {
  actionEventId?: number
  doanvienId?: string
  checkedIn?: boolean
}
