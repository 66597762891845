import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseEmpty } from 'app/components/common/BaseEmpty'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { Loading } from 'app/components/common/Loading'
import HeaderPage from 'app/components/header/components/HeaderPage'
import ModalActionUnit from 'app/containers/UnitManagement/layouts/ModalActionUnit'
import ModalCreateUnit from 'app/containers/UnitManagement/layouts/ModalCreateUnit'
import { useGetAllDonVi } from 'app/react-query/hooks-old/useDonVi'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { t } from 'i18next'
import { BORDER_RADIUS, formatMoney, PADDING } from 'parkway-web-common'
import React from 'react'
import { useNavigate } from 'react-router'
import styled from 'styled-components'

export interface DonVi {
  id?: number
  ten?: string
  diachi?: string
  chucdanh?: string
  status?: string
  action?: string
  ngaythanhlap?: string
  thongtindonvi?: string
  updatedAt?: string
  createdAt?: string

  doanvienCount?: number
}

export const UnitManagementContainer: React.FC = () => {
  const unitData = useGetAllDonVi()
  const navigate = useNavigate()

  const onNavigate = (donviId?: number) => {
    if (!donviId) return

    navigate(`/danhsachdonvi/chitietdonvi`, {
      state: {
        donviId,
      },
    })
  }

  return (
    <BaseSpace>
      <HeaderPage
        title="Danh sách đơn vị"
        rightComponent={
          <>
            <BaseCol>
              <ModalCreateUnit />
            </BaseCol>
            <BaseCol>
              <ModalActionUnit />
            </BaseCol>
          </>
        }
        isExport
      />

      <BaseRow align={'top'} gutter={[16, 16]}>
        {unitData?.data?.length ? (
          unitData.data?.map((unit: DonVi, index: number) => {
            return (
              <BaseCol key={index} xl={8}>
                <ItemUnit onClick={() => onNavigate(unit?.id)}>
                  <BaseRow
                    align={'middle'}
                    gutter={8}
                    justify={'space-between'}
                  >
                    <BaseCol>
                      <BaseText
                        children={unit?.ten}
                        fontWeight="semibold"
                        fontSize="xl"
                      />
                    </BaseCol>
                  </BaseRow>
                  <BaseRow
                    gutter={8}
                    align={'middle'}
                    justify={'space-between'}
                  >
                    <BaseCol>
                      <BaseText
                        children={`${t(R.strings.address)}:`}
                        fontWeight="medium"
                        opacity="0.6"
                      />
                    </BaseCol>
                    <BaseCol>
                      <BaseText children={unit?.diachi} fontWeight="medium" />
                    </BaseCol>
                  </BaseRow>
                  <BaseRow
                    gutter={8}
                    align={'middle'}
                    justify={'space-between'}
                  >
                    <BaseCol>
                      <BaseText
                        children={`Ngày thành lập:`}
                        fontWeight="medium"
                        opacity="0.6"
                      />
                    </BaseCol>
                    <BaseCol>
                      <BaseText
                        children={unit?.ngaythanhlap ?? '-'}
                        fontWeight="medium"
                      />
                    </BaseCol>
                  </BaseRow>

                  <BaseRow
                    gutter={8}
                    align={'middle'}
                    justify={'space-between'}
                  >
                    <BaseCol>
                      <BaseText
                        children={`Số lượng đoàn viên`}
                        fontWeight="medium"
                        opacity="0.6"
                      />
                    </BaseCol>
                    <BaseCol>
                      <BaseText
                        children={formatMoney(unit?.doanvienCount ?? 0, '')}
                        fontWeight="semibold"
                        colorText="statesGreenColor"
                      />
                    </BaseCol>
                  </BaseRow>
                </ItemUnit>
              </BaseCol>
            )
          })
        ) : (
          <BaseEmpty />
        )}
      </BaseRow>
      {unitData?.isLoading ? <Loading isAbsolute size="default" /> : null}
    </BaseSpace>
  )
}

const ItemUnit = styled(BaseSpace)`
  cursor: pointer;
  padding: ${PADDING.md};
  border-radius: ${BORDER_RADIUS};
  background-color: ${convertedVariables.backgroundColor};
  width: 100%;

  .description {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  min-height: 200px;
`
