import { RoleEnum } from "app/common/enum";
import toast from "react-hot-toast";
import { Outlet, Navigate } from "react-router-dom";

interface IProps {
  role?: RoleEnum;
}

const AdminRoute = ({ role }: IProps) => {
  return role === RoleEnum.ADMIN ? (
    <Outlet />
  ) : (
    <>
      {toast.error("Please login as Admin to access this page!")}
      <Navigate to={"/"} />
    </>
  );
};
export default AdminRoute;
