import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseTabs } from 'app/components/common/BaseTabs'
import {
  ActionOpenRegisterLayout,
  ActionRegisteredByStatusLayout,
} from './layouts'
import { StatusMemberRegisterWaiting } from 'app/api/event/model'

const RegisterActionForMemberContainer = () => {
  return (
    <BaseSpace>
      <BaseTabs
        tabPosition={'top'}
        items={[
          {
            label: 'Hoạt động đang mở đăng kí',
            children: <ActionOpenRegisterLayout />,
            key: '1',
          },
          {
            label: 'Hoạt động/sự kiện đã đăng kí',
            children: (
              <ActionRegisteredByStatusLayout
                status={StatusMemberRegisterWaiting.WAITING}
              />
            ),
            key: '2',
          },
          {
            label: 'Hoạt động/sự kiện đã được duyệt',
            children: (
              <ActionRegisteredByStatusLayout
                status={StatusMemberRegisterWaiting.ACCEPTED}
              />
            ),
            key: '3',
          },
          {
            label: 'Hoạt động/sự kiện đã bị từ chối',
            children: (
              <ActionRegisteredByStatusLayout
                status={StatusMemberRegisterWaiting.REJECTED}
              />
            ),
            key: '4',
          },
        ]}
      />
    </BaseSpace>
  )
}

export default RegisterActionForMemberContainer
