import { GroupEventEnum, TypeEventEnum } from "./model"

// Endpoint
export const EventEndPoint = {
  ADMIN_GET_EVENT: 'api/v1/event',
  ADMIN_GET_EVENT_BY_STATUS: 'api/v1/event/status',
  ADMIN_GET_EVENT_BY_ID: 'api/v1/event/:id',
  ADMIN_CREATE_EVENT: 'api/v1/event/create',
  ADMIN_UPDATE_EVENT: 'api/v1/event/update',
  ADMIN_DELETE_EVENT: 'api/v1/event/delete/:id',

  // get action by event id
  ADMIN_GET_ACTION_BY_EVENT_ID: 'api/v1/action/event/:id',
  ADMIN_CREATE_ACTION: 'api/v1/action/create',
  ADMIN_UPDATE_ACTION: 'api/v1/action/update',

  // prize
  ADMIN_GET_MEMBER_BY_PRIZE: 'api/v1/action/prize/member/:id',
  ADMIN_GET_MEMBER_PRIZE_BY_EVENT_ID: 'api/v1/event/:id/member-prize',
  ADMIN_GET_UNIT_PRIZE_BY_EVENT_ID: 'api/v1/event/:id/unit/prize',

  // member register
  //action/event/not-join/:id
  GET_ACTION_NOT_JOIN_BY_MEMBER: 'api/v1/action/event/not-join/:id',
  GET_REGISTER_MEMBER_WAITING_BY_ACTION: 'api/v1/register/member/waiting/by-action',
  GET_REGISTER_ACTION_WAITING_BY_MEMBER: 'api/v1/register/member/waiting/by-member',
  GET_REGISTER_MEMBER_BY_ACTION: 'api/v1/register/member/by-action',
  REGISTER_MEMBER_WAITING_CHANGE_STATUS: 'api/v1/register/member/waiting/change-status',
  
  REGISTER_MEMBER_WITH_ACTION: 'api/v1/register/member/waiting/create',

  // action check in
  ACTION_CHECK_IN: 'api/v1/action/check-in',

  // getActionEventById
  GET_ACTION_EVENT_BY_ID: 'api/v1/action/:id',
}


export const DefineTypeEventEnum = {
  [TypeEventEnum.ACTIVITY]: {
    text: "Nhóm hội thi",
  },
  [TypeEventEnum.CARE]: {
    text:"Hoạt động chăm lo"
  },
  [TypeEventEnum.NEWS]: {
    text: "Nhóm thông tin"
  },
  [TypeEventEnum.TRAINING]: {
    text: "Nhóm Tập huấn - Hội thảo - Đào tạo"
  }
}

//   1. Công tác tổ chức
  // 2. Tuyên giáo
  // 3. Nữ công
  // 4. Văn Thể Mỹ
  // 5. Kiểm tra
  // 6. Công tác cộng đồng
  // 7. Thi đua khen thưởng

export const DefineGroupEventEnum = {
  [GroupEventEnum.ORGANIZATION]: {
    text: 'Công tác tổ chức',
  },
  [GroupEventEnum.PROPAGANDA]: {
    text: 'Tuyên giáo'
  },
  [GroupEventEnum.ARTISTIC]: {
    text: 'Văn thể mỹ'
  },
  [GroupEventEnum.CHECK]: {
    text: 'Kiểm tra'
  },
  [GroupEventEnum.COMMUNITY]: {
    text: 'Công tác cộng đồng'
  },
  [GroupEventEnum.FEMALE_WORKER]: {
    text: 'Nữ công'
  },
  [GroupEventEnum.REWARD]: {
    text: 'Thi đua khen thưởng'
  },

}