import { IDataTop10MemberJoinEvent } from 'app/api/dashboard/model'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseEmpty } from 'app/components/common/BaseEmpty'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { Loading } from 'app/components/common/Loading'
import { SectionBase } from 'app/components/common/SectionBase'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import _ from 'lodash'
import { BORDER_RADIUS, formatMoney } from 'parkway-web-common'
import { useMemo } from 'react'
import styled from 'styled-components'
interface IData extends IDataTop10MemberJoinEvent {
  key: string
  color?: string
}
export const Top10MemberJoinEventLayout = ({
  top10MemberJoinEvent,
  isLoading,
}: {
  top10MemberJoinEvent: IDataTop10MemberJoinEvent[]
  isLoading?: boolean
}) => {
  const dataTop10MemberJoinEvent: IData[] = useMemo(() => {
    const top1Value = _.maxBy(top10MemberJoinEvent, 'count')?.count
    const top2Value = _.maxBy(
      top10MemberJoinEvent.filter(item => item.count !== top1Value),
      'count',
    )?.count

    const top3Value = _.maxBy(
      top10MemberJoinEvent.filter(
        item => item.count !== top1Value && item.count !== top2Value,
      ),
      'count',
    )?.count

    return top10MemberJoinEvent?.map((item, index) => {
      let key = `${index + 1}`

      if (index > 0 && item?.count === top10MemberJoinEvent[index - 1]?.count) {
        key = '-'
      }

      let color: undefined | string = undefined

      if (item?.count === top1Value) {
        color = convertedVariables.statesGreenColor
      } else if (item?.count === top2Value) {
        color = convertedVariables.otherBlueColor
      } else if (item?.count === top3Value) {
        color = convertedVariables.statesRedColor
      }

      return {
        ...item,
        key,
        color,
      }
    })
  }, [top10MemberJoinEvent])

  return (
    <SectionBase title="Danh sách đoàn viên tích cực nhất">
      <BaseSpace size={8}>
        <HeaderWrapper align={'middle'} justify={'space-between'}>
          <BaseCol xl={2}>
            <BaseText children={'STT'} fontWeight="semibold" opacity="0.7" />
          </BaseCol>
          <BaseCol xl={17}>
            <BaseText
              children={'Tên đoàn viên'}
              fontWeight="semibold"
              opacity="0.7"
            />
          </BaseCol>
          <BaseCol xl={5}>
            <BaseText
              children={'Số lượt tham gia'}
              fontWeight="semibold"
              opacity="0.7"
            />
          </BaseCol>
        </HeaderWrapper>
        <ContentWrapper size={4}>
          {dataTop10MemberJoinEvent?.length ? (
            dataTop10MemberJoinEvent?.map((item, index) => {
              return (
                <ItemWrapper
                  key={index}
                  align={'middle'}
                  justify={'space-between'}
                >
                  <BaseCol xl={2}>
                    <BaseText
                      children={item?.key}
                      fontWeight="semibold"
                      style={{
                        color: item?.color,
                      }}
                    />
                  </BaseCol>
                  <BaseCol xl={17}>
                    <BaseSpace size={4}>
                      <BaseText
                        children={item?.info?.hovaten}
                        fontWeight="semibold"
                        fontSize="xs"
                        style={{
                          color: item?.color,
                        }}
                      />
                      <BaseText
                        children={item?.info?.donvi}
                        fontWeight="medium"
                        fontSize="xxxs"
                      />
                    </BaseSpace>
                  </BaseCol>
                  <BaseCol xl={5}>
                    <BaseRow
                      justify={'start'}
                      align={'middle'}
                      gutter={8}
                      wrap={false}
                    >
                      <BaseCol>
                        <BaseText
                          children={`${formatMoney(item?.count ?? 0, '')}`}
                          fontWeight="semibold"
                          style={{
                            color: item?.color,
                          }}
                        />
                      </BaseCol>
                    </BaseRow>
                  </BaseCol>
                </ItemWrapper>
              )
            })
          ) : (
            <BaseEmpty />
          )}
        </ContentWrapper>
      </BaseSpace>
      {isLoading ? <Loading isAbsolute size="small" /> : null}
    </SectionBase>
  )
}

const HeaderWrapper = styled(BaseRow)`
  width: 100%;
  padding: ${BORDER_RADIUS};
  background-color: ${convertedVariables.backgroundColor};
`

const ItemWrapper = styled(BaseRow)`
  width: 100%;
  padding: ${BORDER_RADIUS};
  background-color: ${convertedVariables.backgroundColor};
  border-radius: ${BORDER_RADIUS};
  box-shadow: 0px 0.5px 2px rgba(0, 0, 0, 0.1);
`

const ContentWrapper = styled(BaseSpace)`
  height: 200px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`
