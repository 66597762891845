/* eslint-disable @typescript-eslint/no-explicit-any */
import { AvatarProps } from 'antd'
import {
  backgroundColorAvatarMap,
  colorAvatarMap,
} from 'app/components/common/BaseAvatar/contant'
import { BaseBadgeProps } from 'app/components/common/BaseBadge'
import { NotificationType } from 'app/components/common/BaseNotification'
import {
  getGetStartEndDateOfWeek,
  getStartEndDateOfMonth,
} from 'app/components/common/BaseWeekPicker'
import { IResponseFilter } from 'app/components/tables/BaseTableReport/FilterReport/type'
import { flatten } from 'lodash'
import { FORMAT_CENTER_YYYY_MM_DD, Severity, moment } from 'parkway-web-common'
import { MutableRefObject, useEffect, useMemo, useRef } from 'react'
import { InfiniteData } from 'react-query'

interface NotificationSeverity {
  id: number
  name: NotificationType
}

export const defineColorBySeverity = (
  severity: NotificationType | undefined,
  rgb = false,
): string => {
  const postfix = rgb ? 'rgb-color' : 'color'
  switch (severity) {
    case 'error':
    case 'warning':
    case 'success':
      return `var(--${severity}-${postfix})`
    case 'info':
    default:
      return `var(--primary-${postfix})`
  }
}

export const notificationsSeverities: NotificationSeverity[] = [
  { id: 0, name: 'info' },
  { id: 1, name: 'success' },
  { id: 2, name: 'warning' },
  { id: 3, name: 'error' },
  { id: 4, name: 'mention' },
]

export const normalizeProp = (
  prop: string | number | [number, number],
): string =>
  typeof prop === 'number'
    ? `${prop}px`
    : (Array.isArray(prop) && `${prop[0]}px ${prop[1]}px`) || prop.toString()

export const mapBadgeStatus = (status: BaseBadgeProps['status']): Severity => {
  if (!status || status === 'default' || status === 'processing') {
    return 'info'
  }

  return status
}

interface UseMountedReturnVal {
  isMounted: MutableRefObject<boolean>
}

export const useMounted = (): UseMountedReturnVal => {
  const isMounted = useRef(false)

  useEffect(() => {
    isMounted.current = true

    return () => {
      isMounted.current = false
    }
  }, [])

  return { isMounted }
}

export const usePagination = () => {
  function mapDataResponse<T>(data: InfiniteData<T>): T {
    return data?.pages?.[0]
  }

  const flattenData = (data?: InfiniteData<any>) => {
    if (!data) {
      return []
    }

    return flatten(data?.pages?.map(page => page))?.filter(
      (obj: any) => obj !== undefined,
    )
  }

  const flattenDataList = (data?: InfiniteData<any>) => {
    if (!data) {
      return []
    }

    return data?.pages?.[0]
  }

  return {
    mapDataResponse,
    flattenData,
    flattenDataList,
  }
}

export const initFilterTable = (): IResponseFilter => {
  const minMaxWeed = getStartEndDateOfMonth({
    month: moment().month() + 1,
    year: moment().year(),
  })

  const { startOfWeek, endOfWeek } = getGetStartEndDateOfWeek(
    moment().format(FORMAT_CENTER_YYYY_MM_DD),
    minMaxWeed.minDayOfMonth,
    minMaxWeed.maxDayOfMonth,
  )

  return {
    year: `${moment().year()}`,
    month: `${moment().month() + 1}`,
    week: {
      startDate: startOfWeek,
      endDate: endOfWeek,
    },
  }
}

export function convertToTitleCase(input: string): string {
  return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase()
}

export const mapColorWithFirstCharacter = (firstCharacter: string) => {
  const character = firstCharacter.toUpperCase()
  const backgroundColor = backgroundColorAvatarMap[character]
  const color = colorAvatarMap[character]
  return { backgroundColor, color }
}

export const useBaseImage = ({ alt, src }: AvatarProps) => {
  const firstCharacterOfName = useMemo(() => {
    if (src) return 'P'

    if (!alt) return 'P'

    const nameSplit = alt?.split(' ') ?? []
    const name = nameSplit?.[0] ?? ''
    return name.charAt(0)
  }, [alt, src])

  const color = useMemo(() => {
    if (src) return ''
    return mapColorWithFirstCharacter(firstCharacterOfName).color
  }, [firstCharacterOfName, src])

  const backgroundColor = useMemo(() => {
    if (src) return ''
    return mapColorWithFirstCharacter(firstCharacterOfName).backgroundColor
  }, [firstCharacterOfName, src])

  return { color, backgroundColor, firstCharacterOfName }
}

export const getBase64 = (file: Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file as Blob)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = error => reject(error)
  })

export function compareArrays(arr1: string[], arr2: string[]): boolean {
  if (arr1.length !== arr2.length) {
    return false
  }
  arr1 = arr1.sort()
  arr2 = arr2.sort()
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false
    }
  }
  return true
}
