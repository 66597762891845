/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormInstance } from 'antd'
import {
  IEvent,
  IPayloadUpdateEvent,
  ResourceEventEnum,
  TypeEventEnum,
} from 'app/api/event/model'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { IRefModal } from 'app/components/common/ModalComponent'
import { useUpdateEvent } from 'app/react-query/hook/event'
import {
  createContext,
  MutableRefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { IFormUpdateEvent } from './type'
import dayjs from 'dayjs'

interface IUpdateEventContext {
  form?: FormInstance<IFormUpdateEvent>
  modalRef?: MutableRefObject<IRefModal>

  openModal?: () => void
  closeModal?: () => void
  handleSubmit?: () => void

  isLoading?: boolean
  isLoadingSubmit?: boolean
  eventType?: TypeEventEnum
  onChangeEventType?: (type: TypeEventEnum) => void
}
export const UpdateEventContext = createContext<IUpdateEventContext>({})

export const UpdateEventProvider = ({
  children,
  event,
}: {
  children: any
  event?: IEvent
}) => {
  const [form] = BaseForm.useForm<IFormUpdateEvent>()
  const modalRef = useRef<IRefModal>({})
  const [eventType, setEventType] = useState<TypeEventEnum | undefined>(
    undefined,
  )

  const { mutateAsync: mutateAsyncUpdateEvent, isLoading: isLoadingSubmit } =
    useUpdateEvent()

  const [isLoading, setIsLoading] = useState(false)

  const openModal = () => {
    modalRef.current.open?.()
  }

  const closeModal = () => {
    modalRef.current.hide?.()
  }

  const handleSubmit = async (infoPayload?: IFormUpdateEvent) => {
    if (!infoPayload) return
    setIsLoading(true)
    try {
      const payload: IPayloadUpdateEvent = {
        name: infoPayload.name,
        date_end: infoPayload.endDate?.toISOString(),
        date_started: infoPayload.startDate?.toISOString(),
        desc: infoPayload?.description ?? '-',
        register_end: undefined,
        register_started: undefined,
        type: infoPayload?.type,
        link: infoPayload?.link,
        resource: ResourceEventEnum.UNIT,
        id: event?.id,
        group: event?.group,
      }

      const res = await mutateAsyncUpdateEvent?.(payload)

      if (res) {
        form?.resetFields?.()
        closeModal?.()
        window.location.reload()
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const onChangeEventType = (type?: TypeEventEnum) => {
    setEventType(type)

    form?.setFieldsValue({
      link: undefined,
    })
  }

  useEffect(() => {
    form.setFieldsValue({
      name: event?.name,
      startDate: event?.date_started ? dayjs(event?.date_started) : undefined,
      endDate: event?.date_end ? dayjs(event?.date_end) : undefined,
      description: event?.desc,
      type: event?.type,
      link: event?.link,
      group: event?.group,
    })

    setEventType(event?.type)
  }, [event])
  return (
    <UpdateEventContext.Provider
      value={{
        form,
        modalRef,
        openModal,
        closeModal,
        handleSubmit,
        isLoading,
        isLoadingSubmit: isLoadingSubmit || isLoading,
        onChangeEventType,
        eventType,
      }}
    >
      {children}
    </UpdateEventContext.Provider>
  )
}

export function useUpdateEventContext() {
  const context = useContext(UpdateEventContext)

  if (context === undefined) {
    throw new Error('useUpdateEvent must be used within a UpdateEventProvider')
  }
  return context
}
