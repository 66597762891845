import { Timeline } from 'antd'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseEmpty } from 'app/components/common/BaseEmpty'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { Loading } from 'app/components/common/Loading'
import { SectionBase } from 'app/components/common/SectionBase'
import { DefineActionTypeEnum } from 'app/containers/UnitManagement/layouts/ModalActionUnit'
import { useGetHistoryDonviById } from 'app/react-query/hooks-old/useDonVi'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { BORDER_RADIUS, DateUtil, PADDING } from 'parkway-web-common'
import { useMemo } from 'react'
import styled from 'styled-components'
import { convertToNewHistory } from './hook'
import { IHistoryUnit, INewHistoryUnit } from './type'

export const HistoryUnitLayout = ({ id }: { id?: string }) => {
  const { data: historyData, isLoading } = useGetHistoryDonviById(id)

  const timeLineHistory: INewHistoryUnit[] = useMemo(() => {
    return convertToNewHistory(historyData as IHistoryUnit[])
  }, [historyData])

  return (
    <BaseSpace>
      {timeLineHistory?.length ? (
        <Timeline>
          {timeLineHistory.map((item, index) => {
            return (
              <Timeline.Item
                key={index}
                children={
                  <BaseSpace>
                    <SectionBase>
                      <BaseSpace>
                        <BaseRow gutter={[16, 8]} align={'middle'}>
                          <BaseCol>
                            <BaseText
                              children={'Hoạt động: '}
                              fontWeight="medium"
                              opacity="0.7"
                              fontSize="xs"
                            />
                          </BaseCol>
                          <BaseCol>
                            <StatusWrapper
                              $backgroundColor={
                                DefineActionTypeEnum?.[item?.status]
                                  .backgroundColor
                              }
                            >
                              <BaseText
                                children={
                                  DefineActionTypeEnum[item?.status]?.title
                                }
                                style={{
                                  color:
                                    DefineActionTypeEnum?.[item?.status]?.color,
                                }}
                                fontWeight="semibold"
                              />
                            </StatusWrapper>
                          </BaseCol>
                        </BaseRow>

                        <BaseText
                          children={'Đơn vị cũ'}
                          fontSize="xs"
                          fontWeight="semibold"
                          opacity="0.7"
                        />

                        <BaseRow
                          align={'top'}
                          gutter={[16, 16]}
                          justify={'start'}
                        >
                          {item?.oldUnit?.map((itm, idx) => {
                            return (
                              <BaseCol xl={8} key={idx}>
                                <OldUnitItemWrapper size={8}>
                                  <BaseText
                                    children={itm?.ten}
                                    fontWeight="medium"
                                    fontSize="xs"
                                  />
                                  <BaseText
                                    children={`${itm?.diachi}`}
                                    fontWeight="medium"
                                    opacity="0.7"
                                  />
                                  <BaseText
                                    children={`Ngày thành lập: ${DateUtil.formatDate(
                                      itm?.ngaythanhlap,
                                      'DD-MM-YYYY',
                                    )}`}
                                    fontWeight="medium"
                                    opacity="0.7"
                                  />
                                </OldUnitItemWrapper>
                              </BaseCol>
                            )
                          })}
                        </BaseRow>
                      </BaseSpace>
                    </SectionBase>
                  </BaseSpace>
                }
              />
            )
          })}
        </Timeline>
      ) : (
        <BaseEmpty />
      )}
      {isLoading ? <Loading isAbsolute size="small" /> : null}
    </BaseSpace>
  )
}

const OldUnitItemWrapper = styled(BaseSpace)`
  padding: ${PADDING.xs};
  border-radius: ${BORDER_RADIUS};
  border: 1px solid ${convertedVariables.neutralBlack2Color};
  background-color: ${convertedVariables.backgroundColor};
`

const StatusWrapper = styled.div<{
  $backgroundColor?: string
}>`
  background-color: ${({ $backgroundColor }) =>
    $backgroundColor ?? convertedVariables.backgroundColor};
  padding: 4px ${PADDING.xs};
  border-radius: ${BORDER_RADIUS};
`
