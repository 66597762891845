/* eslint-disable @typescript-eslint/no-explicit-any */
import { useGetActionByEvent } from 'app/react-query/hook/event'
import { useMemo } from 'react'
import {
  IActivityEvent,
  IColumnActivity,
  IDataTumActivity,
  IRewardActivity,
} from '../../CreateEvent/type'
import { IProps } from './type'
import { DoanVien } from 'app/containers/TumManagement/type'
import { isEqual } from 'lodash'

export const useHook = (props: IProps) => {
  const { data: dataApi, isLoading } = useGetActionByEvent({
    id: props?.event?.id ?? 0,
  })

  const data = useMemo(() => {
    const activityEvents: IActivityEvent[] =
      dataApi?.data?.map((item, index) => {
        const columns = item?.listCategory?.map(column => {
          return {
            id: column?.id,
            name: column?.name,
            keyOfMainData: column?.keyOfMainData,
            type: column?.type,
          } as IColumnActivity
        })

        const rewards = item?.listPrize?.map(reward => {
          return {
            id: reward?.id,
            name: reward?.name,
            countPerReward: reward?.countPerReward,
            description: reward?.description,
            gift: reward?.gift,
            type: reward?.type_prize,
          } as IRewardActivity
        })

        const dataR = item?.listMember?.map(member => {
          let data: IDataTumActivity = {
            ...member?.info,
            valueCare: member?.valueCare,
            checkedIn: member?.checkedIn,
          }

          columns?.forEach(column => {
            data = {
              ...data,
              [column?.name ?? '']: member?.listMemberCategory?.find(
                category => {
                  console.log({ category, member, column })

                  return (
                    (category?.idCategory &&
                      column?.keyOfMainData &&
                      isEqual(category?.idCategory, column?.keyOfMainData)) ||
                    (category?.idCategory === column?.id &&
                      category.idRegisterMember === member?.id)
                  )
                },
              )?.value,
            }
          })

          return data
        })

        return {
          id: item?.id,
          title: item?.name,
          columns,
          data: dataR,
          rewards,
          key: index + 1,
        }
      }) ?? []

    return activityEvents
  }, [dataApi])

  const tumData = useMemo(() => {
    const activityEvents: {
      id?: number
      title?: string
      infoData?: DoanVien[]
    }[] =
      dataApi?.data?.map(item => {
        return {
          id: item?.id,
          title: item?.name,
          infoData: item?.listMember?.map(member => ({
            ...member?.info,
            valueCare: member?.valueCare,
            checkedIn: member?.checkedIn,
          })),
        }
      }) ?? []

    return activityEvents
  }, [dataApi])

  const downloadQRCode = () => {
    const getElementById = document?.getElementById('qrCodeEl') as any
    console.log({ getElementById }, 'type', typeof getElementById)

    if (getElementById) {
      const qrCodeURL = getElementById
        .toDataURL('image/png')
        .replace('image/png', 'image/octet-stream')
      console.log(qrCodeURL)
      const aEl = document.createElement('a')
      aEl.href = qrCodeURL
      aEl.download = 'QR_Code.png'
      document.body.appendChild(aEl)
      aEl.click()
      document.body.removeChild(aEl)
    }
  }

  return {
    data,
    isLoading,
    tumData,
    downloadQRCode,
    event: props?.event,
  }
}
