export default {
  yes: 'Có',
  no: 'Không',
  fill_barcode: 'Nhập mã',
  see_more: 'Xem thêm',
  choose: 'Chọn',
  find: 'Tìm kiếm',
  address: 'Địa chỉ',
  language: 'Ngôn ngữ',
  vietnamese: 'Tiếng việt',
  english: 'English',
  foreign_language: 'Ngoại ngữ',
  home: 'Home',
  phone: 'Số điện thoại',
  email: 'Email',
  send: 'Gửi',
  next: 'Tiếp theo',
  back: 'Quay lại',
  minute: 'phút',
  hour: 'giờ',
  send_code: 'Gửi mã',
  login: 'Đăng nhập',
  user_name: 'Tên đăng nhập',
  password: 'Mật khẩu',
  re_password: 'Nhập lại mật khẩu',
  re_password_not_match: 'Mật khẩu không trùng khớp',
  change_password: 'Đổi mật khẩu',
  change_password_success: 'Đổi mật khẩu thành công',
  login_success: 'Đăng nhập thành công',
  forgot_password: 'Quên mật khẩu',
  require_field: 'Vui lòng nhập "{{field}}", đây là trường bắt buộc',
  required: 'Bắt buộc',
  minimum_field: 'Vui lòng nhập tối thiểu {{count}} kí tự',
  maximum_field: 'Vui lòng nhập tối đa {{count}} kí tự',
  register: 'Đăng kí',
  confirm_password: 'Xác nhận mật khẩu',
  verify_by_phone: 'Xác thực bằng số điện thoại',
  verify_by_email: 'Xác thực bằng số email',
  code_verify: 'Mã xác thực',
  message_verify_phone:
    'Bạn vui lòng kiểm tra trong tin nhắn Zalo của số điện thoại {{phone}} để lấy mã xác thực.',
  message_verify_email:
    'Bạn vui lòng kiểm tra trong hộp thư (bao gồm cả thư rác) của email "{{email}}" để lấy mã xác thực.',
  re_send_verify_code: 'Gửi lại mã xác thực',
  resend: 'Gửi lại',
  time_resend_verify_code: 'Gửi lại sau {{timer}} giây',
  verify: 'Xác thực',
  logout: 'Đăng xuất',
  description: 'Mô tả',
  full_name: 'Họ và tên',
  regex_name: 'Tên cần đúng định dạng',
  regex_user_name: 'User name cần đúng định dạng (Không bao gồm dấu "cách")',
  using_by: 'Được sử dụng bởi',
  require_email: 'Vui lòng nhập email',
  require_contact_name: 'Vui lòng nhập tên người liên hệ',
  regex_email: 'Vui lòng nhập đúng định dạng email',
  regex_phone: 'Vui lòng nhập đúng định dạng số điện thoại',
  send_contact_success:
    'Đã gửi thông tin liên hệ thành công, cảm ơn quý khách.',
  please_enter_correct_format: 'Vui lòng  định dạng',
  medical_examination: 'Khám bệnh',
  hr: 'Nhân sự',
  finance: 'Tài chính',
  inventory_management: 'Quản lý kho',
  purchasing_management: 'Quản lý mua hàng',
  customer_care: 'Chăm sóc khách hàng',
  clinic_management: 'Quản lý phòng khám',
  management: 'Quản lý',
  doctor: 'Bác sĩ',
  company: 'Công ty',
  organizational_chart: 'Sơ đồ tổ chức',
  announcement: 'Thông báo',
  internal_news: 'Tin nội bộ',
  onboarding: 'OnBoard',
  report: 'Báo cáo',
  marketing: 'Marketing',
  accounting: 'Kế toán',
  work_schedule: 'Lịch làm việc',
  attendance_record: 'Chấm công',
  mailbox: 'Hộp thư',
  my_requests: 'Yêu cầu của tôi',
  my_tasks: 'Công việc của tôi',
  my_income: 'Thu nhập của tôi',
  dashboard: 'Trang chủ',
  profile: 'Thông tin cá nhân',
  read_all: 'Đánh dấu đọc tất cả',
  view_all: 'Xem tất cả',
  remember_me: 'Ghi nhớ',
  parkway_dental: 'Nha khoa Parkway',
  description_parkway_auth:
    'Nha khoa Parkway là hệ thống nha khoa toàn diện và chuyên nghiệp, đặc biệt về chỉnh nha và implant, đáp ứng trọn vẹn nhu cầu nha khoa của khách hàng.',
  development: 'Đang phát triển',
  customers: 'Khách hàng',
  region: 'Khu vực',
  revenue: 'Doanh thu',
  total_revenue: 'Tổng doanh thu',
  pending_revenue: 'Doanh thu ước đoán',
  actual_revenue: 'Thực thu',
  compared_to_last_month: 'So với tháng trước',
  today_revenue: 'Doanh thu hôm nay',
  branch_name: 'Tên chi nhánh',
  overall_today_summary: 'Tổng quan hôm nay',
  overview: 'Tổng quan',
  area: 'Khu vực',
  clinic: 'Phòng khám',
  department: 'Phòng ban',
  ordinal_number: 'STT',
  notification: 'Thông báo',
  not_done: 'Chưa làm',
  done: 'Hoàn thành',
  in_progress: 'Đang làm',
  see_detail: 'Xem chi tiết',
  mail: 'Thư',
  new_mail: 'Thư mới',
  important: 'Quan trọng',
  show_less: 'Đọc tiếp',
  collapse: 'Thu gọn',
  last_time_update: 'Cập nhật gần nhất',
  service: 'Dịch vụ',
  report_001: 'Report 001 - Đo lường chuyển đổi từ số lượng Lead sang Check in',
  report_002: 'Report 002 - Đo lường hiệu quả tư vấn của CSKH mới (Team Call)',
  report_003: 'Report 003 - Đo lường hiệu quả tư vấn của CSKH cũ (Team Call)',
  report_004:
    'Report 004 - Đo lường hiệu quả chốt của khu vực/phòng khám/bác sĩ',
  report_004_doctor:
    'Report 004 - Đo lường hiệu quả chốt của phòng khám theo bác sĩ',
  report_004_area:
    'Report 004 - Đo lường hiệu quả chốt của phòng khám theo khu vực',
  report_005:
    'Report 005 - Báo cáo doanh thu từng chi nhánh theo ngày-tuần-tháng',
  report_006:
    'Report 006 - Báo cáo doanh thu từng chi nhánh theo nhóm sản phẩm',
  report_007: 'Report 007 - Báo cáo doanh thu theo nguồn khách hàng',
  report_008: 'Report 008 - Top 10 sản phẩm cả hệ thống',
  report_009: 'Report 009 - Báo cáo nhóm người dùng theo sản phẩm',
  report_010: 'Report 010 - Báo cáo nhóm người dùng theo khu vực',
  report_011: 'Report 011 - Top người dùng chi tiêu nhiều',
  report_012: 'Report 012 - P&L',

  report_001_description:
    'Báo cáo đo lường chuyển đổi từ số lượng Lead sang Check in',
  report_002_description:
    'Báo cáo đo lường hiệu quả tư vấn của CSKH mới (Team Call)',
  report_003_description:
    'Báo cáo đo lường hiệu quả tư vấn của CSKH cũ (Team Call)',
  report_004_description:
    'Báo cáo đo lường hiệu quả chốt của khu vực/phòng khám/bác sĩ',
  report_004_area_description:
    'Báo cáo đo lường hiệu quả chốt của phòng khám theo khu vực',
  report_004_doctor_description:
    'Báo cáo đo lường hiệu quả chốt của phòng khám theo bác sĩ',
  report_005_description:
    'Báo cáo doanh thu từng chi nhánh theo ngày-tuần-tháng',
  report_006_description: 'Báo cáo doanh thu từng chi nhánh theo nhóm sản phẩm',
  report_007_description: 'Báo cáo doanh thu theo nguồn khách hàng',
  report_008_description: 'Báo cáo Top 10 sản phẩm cả hệ thống',
  report_009_description: 'Báo cáo nhóm người dùng theo sản phẩm',
  report_010_description: 'Báo cáo nhóm người dùng theo khu vực',
  report_011_description: 'Báo cáo Top người dùng chi tiêu nhiều',
  report_012_description: 'Báo cáo P&L',

  budget: 'Ngân sách',
  spent_actual: 'Thực chi',
  percent_budget_using: '% Đã chi',
  planing: 'Kế hoạch',
  actuals: 'Thực đạt',
  cpql: 'CPQL',
  price: 'Giá',
  percent_complete_target_api: '% Đạt KPI',
  number_checkin: 'Số check in',
  percent_achievement: '% Đạt',
  number_q_lead: 'Số Qlead',
  see_with: 'Xem theo',
  week: 'Tuần',
  choose_date: 'Chọn ngày',
  choose_week: 'Chọn tuần',
  document: 'Tài liệu tập tin',
  name_employee: 'Tên nhân viên',
  number_date_re_cared: 'Số data chăm sóc lại',
  number_off_appointments_scheduled: 'Số đặt lịch hẹn',
  number_check_in_achieved: 'Số check in thực đạt',
  crv_1: 'CVR1',
  crv_1_period: 'CRV1 cùng kì',
  crv_2: 'CVR2',
  crv_2_period: 'CRV2 cùng kì',
  crv_1_growth_in_period: 'Tỉ lệ tăng trưởng CVR1 so với cùng kỳ',
  crv_2_growth_in_period: 'Tỉ lệ tăng trưởng CVR2 so với cùng kỳ',
  number_of_cases_closed: 'Số ca chốt',
  crv3: 'CVR3 hiện tại',
  crv3_with_period: 'CVR3 cùng kỳ',
  percent_crv3_growth_in_period: 'Tỉ lệ tăng trưởng CVR3 so với cùng kỳ',
  number_of_check_ins: 'Số lượt check in',
  week1: 'Tuần 1',
  week2: 'Tuần 2',
  week3: 'Tuần 3',
  week4: 'Tuần 4',
  week_no: 'Tuần {{week}}',
  total_revenue_month: 'Tổng doanh thu tháng {{month}}',
  total_revenue_target: 'Tổng doanh thu kế hoạch',
  percent_of_target_complete: '% Đạt được',
  month1: 'Tháng 1',
  month2: 'Tháng 2',
  month3: 'Tháng 3',
  month4: 'Tháng 4',
  month5: 'Tháng 5',
  month6: 'Tháng 6',
  month7: 'Tháng 7',
  month8: 'Tháng 8',
  month9: 'Tháng 9',
  month10: 'Tháng 10',
  month11: 'Tháng 11',
  month12: 'Tháng 12',
  month_no: 'Tháng {{month}}',
  total_revenue_year: 'Tổng doanh thu năm {{year}}',
  group_product: 'Nhóm sản phẩm',
  a1_implant: 'A1 - Implant',
  a2_implant: 'A2 - Implant',
  a3_implant: 'A3 - Implant',
  total_revenue_actual: 'Tổng doanh thu thực tế',
  total_revenue_projected: 'Tổng doanh thu ước đoán',
  total_revenue_planned: 'Tổng doanh thu kế hoạch',
  total_revenue_achieved: 'Tổng doanh thu thực hiện được',
  group_customer: 'Nhóm khách hàng',
  revenue_week: 'Doanh thu tuần {{week}}',
  hcm_area: 'Khu vực Hồ Chí Minh',
  hanoi_area: 'Khu vực Hà Nội',
  digital_ad: 'Quảng cáo digital',
  percentage_of_total_revenue: '% Trên tổng doanh thu',
  system_wide: 'Cả hệ thống',
  product: 'Sản phẩm',
  quarter_in_year: 'Quý trong năm',
  gender: 'Giới tính',
  age: 'Độ tuổi',
  cases_in_product_group: 'Số ca thuộc nhóm sản phẩm',
  fixed_braces: 'Chỉnh nha cố định',
  other_products: 'Sản phẩm khác',
  revenue_of_product_group: 'Doanh thu nhóm sản phẩm',
  cases_in_area: 'Số ca thuộc khu vực',
  revenue_of_area: 'Doanh thu thuộc khu vực',
  province: 'Tỉnh',
  telesales_cared: 'Telesales chăm sóc',
  other_customer: 'Khách hàng khác',
  old_customer: 'Khách hàng cũ',
  import_budget_week: 'Import ngân sách theo tuần',
  import_budget_month: 'Import ngân sách theo tháng',
  import_qlead_actual_week: 'Import Số QLead Actuals theo tuần',
  import_qlead_actual_month: 'Import Số QLead Actuals theo tháng',
  import_cpql_numbers_week: 'Import số CPQL theo tuần',
  import_cpql_numbers_month: 'Import số CPQL theo tháng',
  import_weekly_appointment_schedule: 'Import Số lịch hẹn planning theo tuần',
  appointment_schedule: 'Số lịch hẹn kế hoạch',
  check_in_schedule: 'Số kế hoạch check in',
  import_monthly_appointment_schedule: 'Import Số lịch hẹn planning theo tháng',
  import_cost_of_goods_sold_and_selling_expenses_weekly:
    'Giá vốn hàng bán và chi phí bán hàng theo tuần',
  cost_of_goods_sold_and_selling_expenses:
    'Giá vốn hàng bán và chi phí bán hàng',
  marketing_plan: 'Kế hoạch Marketing',
  import_cost_of_goods_sold_and_selling_expenses_monthly:
    'Import giá vốn hàng bán và chi phí bán hàng theo tháng',
  import_weekly_revenue: 'Kế hoạch doanh thu theo tuần',
  import_monthly_revenue: 'Kế hoạch doanh thu theo tháng',
  import: 'Import',
  from_date: 'Từ ngày',
  to_date: 'Đến ngày',
  start_date: 'Ngày bắt đầu',
  end_date: 'Ngày kết thúc',
  number_planned: 'Số Plan',
  number_actual: 'Số Actual',
  export_excel: 'Xuất Excel',
  download_example_import_file: 'Tải mẫu file import',
  upload: 'Tải lên',
  cp_nvl_live: 'CP NVL Trực tiếp',
  doctor_department: 'Bộ phận bác sĩ',
  nursing_department: 'Bộ phận y tá',
  advisory_department: 'Bộ phận tư vấn',
  x_ray_technical_department: 'Bộ phận X-ray technical',
  reception_department: 'Bộ phận lễ tân',
  security_and_housekeeping_department: 'Bộ phận tạp vụ bảo vệ',
  initial_construction: 'Xây dựng ban đầu',
  machinery_and_equipment: 'Máy móc thiết bị',
  rental_expenses: 'Chi phí tiền thuê',
  location_rental_tax_expenses: 'Chi phí thuế thuê địa điểm',
  other_operating_expenses: 'Các chi phí vận hành khác',
  card_swipe_installment_fees: 'Phí quẹt thẻ - trả góp',
  others_cosmetics: 'Khác (thẩm mỹ)',
  plan_number: 'Số plan',
  performance: 'Performance',
  branch: 'Chi nhánh',
  call_center: 'Call center',
  ccdc: 'CCDC',
  location_rental_expenses: 'Chi phí thuê địa điểm',
  depreciation_expenses: 'Chi phí khấu hao TSCĐ/CCDC',
  salary_expenses_allowances: 'Chi phí lương, phụ cấp...',
  cost_of_goods_sold: 'Giá vốn hàng bán',
  selling_expenses: 'Chi phí bán hàng',
  download_successful: 'Download thành công',
  import_file_successful: 'Import file thành công',
  import_file_error: 'Import file lỗi',
  month: 'Tháng',
  import_file: 'Import file',
  created: 'Mới',
  requested: 'Đã giao',
  recall: 'Gọi lại',
  confirm_customer: 'Xác nhận khách hàng',
  confirmed: 'Đã xác nhận',
  cancel: 'Hủy',
  orthodontic: 'Mắc cài',
  general_treatment: 'Điều trị tổng quát',
  treatments: 'Dịch vụ',
  orthodontic_correction: 'Chỉnh nha',
  invisalign: 'Invisalign',
  implant: 'Implant',
  number_of_cases_in_the_region: 'Số ca thuộc khu vực',
  revenue_in_the_region: 'Doanh số thuộc khu vực',
  hochiminh: 'Hồ Chí Minh',
  hanoi: 'Hà Nội',
  annual_expenditure_amount: 'Số tiền chi tiêu trong năm',
  number_of_purchases: 'Số lần mua hàng',
  average_order_value: 'Giá trị trung bình đơn hàng',
  groupA1: 'Nhóm A1',
  groupA2: 'Nhóm A2',
  groupA3: 'Nhóm A3',
  groupA4: 'Nhóm A4',
  number_of_service_uses: 'Số lần sử dụng dịch vụ',
  number_new_lead: 'Số lead mới',
  compared_to_last_day: 'So với hôm qua',
  year: 'Năm',
  from_then_util_now: 'Từ trước đến nay',
  quarter: 'Quý',
  quarter_num: 'Quý {{quarter}}',
  male: 'Nam',
  female: 'Nữ',
  other: 'Khác',
  all_system: 'Toàn hệ thống',
  porcelain_cosmetic_dentistry: 'Răng sứ thẩm mỹ',
  customer_care_plan: 'Kế hoạch chăm sóc khách hàng',
  number_customer_re_care: 'Số khách hàng chăm sóc lại',
  number_qlead_actual: 'Số QLead Actuals',
  account_management: 'Quản lý tài khoản',
  employee_id: 'Mã nhân viên',
  account_name: 'Tên tài khoản',
  status: 'Trạng thái',
  active: 'Active',
  deleted: 'Deleted',
  error: 'Error',
  search: 'Tìm kiếm',
  all: 'Tất cả',
  delete: 'Xoá',
  done_button: 'Đã xong',
  update: 'Cập nhật',
  edit: 'Sửa',
  id_property: 'Mẫu mã',
  barcode: 'Barcode',
  add_account: 'Thêm tài khoản',
  first_name: 'Họ',
  last_name: 'Tên',
  enter_field: 'Nhập {{field}}',
  please_select_status: 'Vui lòng chọn trạng thái',
  inactive: 'Inactive',
  update_account: 'Cập nhật tài khoản',
  action: 'Hành động',
  update_account_id_success: 'Cập nhật tài khoản {{account}} thành công',
  delete_account: 'Xoá tài khoản',
  are_you_want_delete_account: `Bạn xác nhận xoá tài khoản`,
  profile_title: 'Profile',
  please_select_profile: 'Chọn profile',
  once_the_account_is_locked_it_cannot_be_recovered:
    'Tài khoản sau khi khoá sẽ không thể khôi phục lại',
  once_the_field_is_locked_it_cannot_be_recovered:
    '{{field}} sau khi khoá sẽ không thể khôi phục lại',
  please_select_role: 'Vui lòng chọn cấp bậc',
  job_title: 'Quản lý chức danh',
  update_time: 'Thời gian cập nhật',
  days_of_week: {
    monday: 'T2',
    tuesday: 'T3',
    wednesday: 'T4',
    thursday: 'T5',
    friday: 'T6',
    saturday: 'T7',
    sunday: 'CN',
  },
  job_title_content: {
    management: 'Quản lý chức danh',
    description: 'Mô tả',
    name: 'Tên',
    group: 'Nhóm chức danh',
    add_job_title: 'Thêm chức danh',
    job_title_management: 'Quản lý trực tiếp',
    hasTimekeeping: 'Có chấm công',
    dayOffType: 'Loại ngày nghỉ',
    dayOfDynamic: 'Linh hoạt',
    dayOfFixed: 'Cố định',
    id: 'ID Chức danh',
    name_create: 'Tên chức danh',
    please_select_job_title_management: 'Chọn quản lý',
    are_you_want_delete_job_title: 'Bạn xác nhận xoá chức danh',
    once_the_job_title_is_locked_it_cannot_be_recovered:
      'Chức danh sau khi khoá sẽ không thể khôi phục lại',
    please_select_dayOffDynamic: 'Số ngày nghỉ',
    please_select_job_title_group: 'Chọn nhóm chức danh',
    please_select_dayOffFixed: 'Số ngày nghỉ cố định',
  },
  job_title_field: {
    job_title_string_id: 'code',
    name: 'name',
    description: 'description',
    status: 'status',
    role: 'role',
    job_title_management: 'jobTitleManagement',
  },
  job_title_level: 'Quản lý cấp bậc',
  job_title_level_content: {
    title: 'Cấp bậc',
    management: 'Quản lý cấp bậc',
    description: 'Mô tả',
    name: 'Tên',
    add: 'Thêm cấp bậc',
    id: 'ID Cấp bậc',
    name_create: 'Tên cấp bậc',
    are_you_want_delete: 'Bạn xác nhận xoá cấp bậc',
    once_the_job_title_level_is_locked_it_cannot_be_recovered:
      'Cấp bậc sau khi khoá sẽ không thể khôi phục lại',
  },
  job_title_level_field: {
    job_title_level_string_id: 'code',
    name: 'name',
    description: 'description',
    status: 'status',
    job_title_level_id: 'jobTitleLevelId',
  },
  unit: 'Quản lý phòng ban',
  unit_content: {
    title: 'phòng ban',
    create_unit_child: 'Tạo phòng ban con',
    unit_parent: 'Phòng ban trực thuộc',
    management: 'Quản lý phòng ban',
    unit_department: 'Khối',
    job_title_management: 'Quản lý trực tiếp',
    unitTypeId: 'Loại phòng ban',
    description: 'Mô tả',
    name: 'Tên',
    add: 'Thêm phòng ban',
    _id: 'ID',
    id: 'Mã Phòng ban',
    please_select_unit_department: 'Chọn khối',
    please_select_unitTypeId: 'Chọn loại phòng ban',
    please_select_unit_job_title_management: 'Chọn quản lý',
    name_create: 'Tên phòng ban',
    are_you_want_delete: 'Bạn xác nhận xoá phòng ban',
    once_the_unit_is_locked_it_cannot_be_recovered:
      'Phòng ban sau khi khoá sẽ không thể khôi phục lại',
  },
  unit_field: {
    name: 'Tên phòng ban',
    description: 'Mô tả',
    status: 'Trạng thái',
    unit_string_id: 'Mã',
    unit_department_string_id: 'Khối',
    unitTypeId: 'Loại',
    job_title_management: 'Quản lý trực tiếp',
  },
  account: 'Tài khoản',
  create_profile: 'Thêm nhân viên',
  update_profile: 'Cập nhật nhân viên',
  profile_content: {
    title: 'nhân viên',
    management: 'Quản lý nhân viên',
    profile_department: 'Khối',
    job_title: 'Chức danh',
    description: 'Mô tả',
    firstname: 'Tên',
    lastname: 'Họ',
    email: 'Email',
    phone: 'Số điện thoại',
    address: 'Địa chỉ',
    add: 'Thêm nhân viên',
    name: 'Tên',
    id: 'ID nhân viên',
    mobile: 'Số điện thoại',
    employeeid: 'Mã nhân viên',
    employee_old_id: 'Mã nhân viên cũ',
    job_title_main_column: 'Chức danh chính',
    job_title_secondary_column: 'Chức danh kiêm nhiệm',
    job_title_main: '1. Chức danh chính',
    job_title_secondary: '2. Chức danh kiêm nhiệm',
    job_title_secondary_child: 'Chức danh kiêm nhiệm',
    please_select_department: 'Chọn phòng ban',
    please_select_job_title: 'Chọn chức danh',
    please_select_account: 'Chọn tài khoản',
    name_create: 'Tên nhân viên',
    info_section: 'Thông tin nhân viên',
    job_title_section: 'Chức danh nhân viên',
    are_you_want_delete: 'Bạn xác nhận xoá nhân viên',
    create_profile_success: 'Thêm nhân viên thành công',
    once_the_profile_is_locked_it_cannot_be_recovered:
      'Nhân viên sau khi khoá sẽ không thể khôi phục lại',
    add_work_info_main: 'Thêm chức danh chính',
    add_work_info_aka: 'Thêm chức danh kiêm nhiệm',
    option_create: 'Tạo tài khoản',
    option_select_id: 'Đã có tài khoản',
    option_non: 'Không tạo tài khoản',
    skill: 'Kỹ năng nhân viên',
    add_skill: 'Thêm kỹ năng',
    please_select_skill: 'Chọn kỹ năng',
    please_fill_skill: 'Nhập kỹ năng',
    industry: 'Loại',
    add_industry: 'Thêm loại',
    please_select_industry: 'Chọn loại',
    please_fill_industry: 'Nhập loại',
    level: 'Cấp độ',
    add_level: 'Thêm cấp độ',
    please_select_level: 'Chọn cấp độ',
    please_fill_level: 'Nhập cấp độ',
    degree: 'Bằng cấp nhân viên',
    add_degree: 'Thêm bằng cấp',
    please_select_degree: 'Chọn bắng cấp',
    degree_classification: 'Xếp loại bằng cấp',
    add_degree_classification: 'Thêm xếp loại bằng cấp',
    please_select_degree_classification: 'Chọn xếp loại',
    area: 'Khu vực',
    clinic: 'Chi nhánh',
    department: 'Bộ phận',
  },
  profile_field: {
    name: 'name',
    email: 'email',
    mobile: 'mobile',
    status: 'status',
    account: 'accountid',
    last_name: 'lastname',
    first_name: 'firstname',
    employeeid: 'employeeid',
    employee_old_id: 'employee_old_id',
    description: 'description',
    profileTypeId: 'profileTypeId',
    profile_string_id: 'profileStringId',
    profile_department_string_id: 'profileDepartmentStringId',
    skill: 'skill',
    level: 'level',
    industry: 'industry',
    classification: 'classification',
    workinfo_unitid: 'workinfo_unitid',
    workinfo_job_title: 'workinfo_jobtitleid',
    workinfo_start_date: 'workinfo_startdate',
    workinfo_end_date: 'workinfo_enddate',
    workinfo_status: 'workinfo_status',
  },
  projected_revenue: 'Doanh thu ước đoán',
  survey_report: 'Báo cáo đánh giá chất lượng dịch vụ',
  survey_report_content: {
    star: 'sao',
    by_day: 'Đánh giá chất lượng dịch vụ trung bình theo ngày',
    review_type_need_to_impove: 'Dịch vụ cần cải thiện',
    next_promotor_score: 'Đánh giá điểm NPS (Net Promotor Score)',
    chart_by_day_label_header: '/ Trung bình các phòng khám tại khu vực',
    chart_by_day_label_footer: 'Tổng lượt đánh giá',
    excellent: 'Xuất sắc',
    good: 'Tốt',
    middle: 'Tạm ổn',
    bad: 'Kém',
    select_area: 'Tất cả khu vực',
    select_clinic: 'Tất cả phòng khám',
    review: 'Đánh giá',
    unReview: 'Không đánh giá',
    nps_average: 'Đánh giá trung bình',
    nps_choosen: 'Khu vực đã chọn',
    date: 'Ngày',
    start_no: 'Số sao',
  },
  marketing_bp: 'BP Marketing',
  operation_bp: 'BP vận hành',
  training_bp: 'BP đào tạo',
  hr_bp: 'BP nhân sự',
  accounting_bp: 'BP kế toán',
  purchasing_bp: 'BP mua hàng',
  other_expenses: 'Chi phí khác',
  other_income: 'Thu nhập khác',
  interest_on_deposits: 'Lãi tiền gửi',
  other_costs: 'Chi phí khác',
  business_management_expenses: 'Chi phí quản lý doanh nghiệp',
  other_profits: 'Lợi nhuận khác',
  download_excel_success: 'Tải file excel thành công',
  skill: 'Quản lý kỹ năng',
  skill_content: {
    title: 'Kỹ năng',
    management: 'Quản lý kỹ năng',
    description: 'Mô tả',
    industry: 'Loại',
    levelSkill: 'Cấp độ',
    rangeExperience: 'Năm kinh nghiệm',
    name: 'Tên',
    add: 'Thêm kỹ năng',
    name_create: 'Tên kỹ năng',
    are_you_want_delete: 'Bạn xác nhận xoá kỹ năng',
    exists_skill: 'Kỹ năng đã tồn tại',
    once_the_skill_is_locked_it_cannot_be_recovered:
      'Kỹ năng sau khi xoá sẽ không thể khôi phục lại',
    select_industry: 'Chọn loại',
    select_level_skill: 'Chọn vị trí',
    select_range_experience: 'Chọn số năm',
  },
  skill_field: {
    name: 'name',
    industry: 'Loại',
    levelSkill: 'Cấp độ',
    rangeExperience: 'Năm kinh nghiệm',
    status: 'status',
  },
  degree: 'Quản lý bằng cấp',
  degree_content: {
    title: 'Bằng cấp',
    management: 'Quản lý bằng cấp',
    see_degree: 'Xem bằng cấp',
    description: 'Mô tả',
    industry: 'Loại',
    start: 'Ngày cấp',
    end: 'Ngày hết hạn',
    has_end: 'Có thời hạn',
    no_end: 'Vô thời hạn',
    name: 'Tên',
    add: 'Thêm bằng cấp',
    name_create: 'Tên bằng cấp',
    placehoder_upload_document:
      'Tải lên file của bạn ( Định dạng file pdf,docx. Kích thước tối đa 20 MB )',
    placeholder_upload_document:
      'Tải lên file của bạn ( Định dạng file pdf,docx. Kích thước tối đa 20 MB )',
    are_you_want_delete: 'Bạn xác nhận xoá bằng cấp',
    once_the_degree_is_locked_it_cannot_be_recovered:
      'Bằng cấp sau khi xoá sẽ không thể khôi phục lại',
    select_industry: 'Chọn loại',
  },
  degree_field: {
    name: 'Tên',
    degree: 'Bằng cấp',
    industry: 'Loại',
    status: 'Trạng thái',
    degree_classification: 'Xếp loại',
  },
  degree_classification: {
    excellent: 'Giỏi',
    good: 'Khá',
    average_good: 'Trung bình khá',
    average: 'Trung bình',
    below_average: 'Dưới trung bình',
    weak: 'Yếu',
    unknown: 'Không rõ',
  },
  check_in_planing: 'Check in kế hoạch',
  report_group: {
    customer_care: 'Nhóm chăm sóc khách hàng',
    marketing: 'Nhóm marketing',
    operation: 'Nhóm vận hành',
    accounting: 'Nhóm kế toán',
    import_data: 'Nhập dữ liệu',
    debt_management: 'Quản lý công nợ',
    report: 'Báo cáo',
  },
  check_in_customer_plan: 'Kế hoạch số khách hàng check in',
  manage_customer_debt_contract: 'Quản lý công nợ khách hàng theo hợp đồng',
  manage_customer_debt: 'Quản lý công nợ khách hàng',
  manage_customer_debt_remain: 'Còn lại',
  manage_customer_patient_code: 'Mã khách hàng',
  manage_customer_patient_name: 'Tên',
  manage_customer_debt_payment_total: 'Tổng phải thanh toán',
  manage_customer_debt_deposit_total: 'Tổng cọc',
  manage_customer_debt_refund_total: 'Tổng hoàn tiền',
  manage_customer_debt_paid_total: 'Tổng đã thanh toán',
  manage_customer_debt_remain_total: 'Còn lại',
  manage_customer_payment_type: 'Loại thanh toán',
  manage_customer_clinic_name: 'Phòng khám',
  manage_customer_debt_treatment: 'Tên gói',
  manage_customer_debt_treatment_total: 'Số tiền của gói',
  manage_customer_debt_treatment_date: 'Ngày phát sinh gói',
  manage_customer_debt_tip_end_name: 'Tên thủ thuật kết thúc',
  manage_customer_debt_tip_end_date: 'Ngày kết thúc',
  manage_customer_choose_paid_type: 'Chọn loại thanh toán',
  manage_customer_debt_choose_treatment: 'Chọn gói',
  manage_customer_debt_choose_tip: 'Chọn Thủ thuật',
  manage_customer_debt_tip_start_date: 'Ngày bắt đầu',
  manage_customer_tip_start_date: 'Ngày bắt đầu',
  daily_invoice: 'Báo cáo khoản thu',
  deposit: 'Cọc',
  installment: 'Trả góp',
  one_time_payment: 'Thanh toán 1 lần',
  no_paid: 'Chưa phát sinh',
  booking: 'Xử lý yêu cầu đặt lịch hẹn của quản lý Call Center',
  booking_employee: 'Xử lý yêu cầu đặt lịch hẹn của nhân viên Call Center',
  booking_content: {
    title: 'Call center',
    management: 'Xử lý yêu cầu đặt lịch hẹn của quản lý Call Center',
    description: 'Mô tả',
    name: 'Tên',
    phone: 'Số điện thoại',
    treatments: 'Dịch vụ',
    area: 'Khu vực',
    clinic: 'Phòng khám',
    doctor: 'Bác sĩ',
    date: 'Ngày khám',
    time: 'Giờ khám',
    note: 'Ghi chú',
    callCenter: 'Nhân viên call center',
    calling: 'Bạn đang gọi cho {{name}}',
    add: 'Tạo lịch hẹn',
    name_create: 'Tên bệnh nhân',
    are_you_want_delete: 'Bạn xác nhận xoá lịch hẹn',
    exists_booking: 'Call center đã tồn tại',
    once_the_booking_is_locked_it_cannot_be_recovered:
      'Call center sau khi xoá sẽ không thể khôi phục lại',
    profile_call_center: 'Nhân viên call center',
    select_profile_call_center: 'Chọn nhân viên',
  },
  booking_field: {
    name: 'name',
    phone: 'Số điện thoại',
    treatments: 'Dịch vụ',
    clinic: 'Phòng khám',
    doctor: 'Bác sĩ',
    callCenter: 'Nhân viên call center',
    status: 'status',
    note: 'Ghi chú',
    area: 'Khu vực',
    date: 'Ngày khám',
    time: 'Giờ khám',
  },
  process_appointment_requests: 'Xử lý yêu cầu đặt lịch hẹn',
  create_appointment: 'Tạo lịch hẹn',
  BS: 'Bs.',
  day: 'Ngày',
  appointment_note: 'Chú thích',
  confirm: 'Xác nhận',
  guest_cancels_appointment: 'Khách hủy đặt lịch',
  confirm_guest_cancels_appointment: 'Xác nhận huỷ thông tin đặt lịch ',
  confirm_describe_guest_cancels_appointment:
    ' . Thông tin đặt lịch sẽ bị huỷ. (Hủy có thể khôi phục lại)',
  schedule_booking: 'Đặt lịch hẹn',
  patient_name: 'Tên bệnh nhân',
  patient_name_required: 'Vui lòng nhập tên bệnh nhân',
  patient_name_placeholder: 'Nhập họ tên',

  phone_label: 'Số điện thoại',
  phone_label_required: 'Vui lòng nhập số điện thoại',
  phone_label_placeholder: 'Nhập số điện thoại',

  area_label: 'Khu vực',
  area_label_required: 'Vui lòng chọn khu vực',
  area_label_placeholder: 'Chọn khu vực',

  clinic_label: 'Phòng khám',
  clinic_label_required: 'Vui lòng chọn phòng khám',
  clinic_label_placeholder: 'Chọn phòng khám',

  category_service_label: 'Danh mục dịch vụ',
  category_service_label_required: 'Vui lòng chọn danh mục dịch vụ',
  category_service_label_placeholder: 'Chọn danh mục dịch vụ',

  service_label: 'Dịch vụ',
  service_label_required: 'Vui lòng chọn dịch vụ',
  service_label_placeholder: 'Chọn dịch vụ',

  treatment_label: 'Thủ thuật',
  treatment_label_required: 'Vui lòng chọn thủ thuật',
  treatment_label_placeholder: 'Chọn thủ thuật',

  reason_label: 'Lý do',
  reason_label_required: 'Vui lòng nhập lý do',
  reason_label_placeholder: 'Nhập lý do',

  duration_label: 'Thời lượng (Phút)',
  duration_label_required: 'Vui lòng chọn thời lượng',
  duration_label_placeholder: 'Chọn thời lượng',

  note_max_length: 'Vượt quá ký tự',
  number_of_character: 'Số ký tự',

  doctor_label: 'Bác sĩ',
  doctor_label_required: 'Vui lòng chọn bác sĩ',
  doctor_label_placeholder: 'Chọn bác sĩ',

  time_label: 'Giờ khám',
  time_label_required: 'Vui lòng chọn giờ khám',
  time_label_placeholder: 'Chọn giờ khám',

  day_label: 'Ngày khám',
  day_label_required: 'Vui lòng chọn ngày khám',
  day_label_placeholder: 'Chọn ngày khám',

  status_label: 'Trạng thái',
  status_label_required: 'Vui lòng chọn trạng thái',
  status_label_placeholder: 'Chọn trạng thái',

  SA: '(Sáng)',
  CH: '(Chiều)',

  note_label: 'Ghi chú',
  note_label_placeholder: 'Nhập nội dung',
  customer_cancel: 'Khách hủy',

  created_by: 'Tạo bởi',
  schedule_info: 'Thông tin lịch hẹn',
  update_info: 'Thông tin cập nhật',
  today: 'Hôm nay',

  search_doctor: 'Tìm bác sĩ',
  all_doctor: 'Tất cả bác sĩ',
  BOOKING_EXISTS: 'Lịch hẹn đã tồn tại',
  BOOKING_DOES_NOT_EXIST: 'Lịch hẹn không tồn tại',
  schedule_appointment: 'Đặt lịch hẹn',
  manage_appointment: 'Quản lý lịch hẹn',
  limit_date: 'Giới hạn 4 tháng',
  select_range_date_required: 'Vui lòng chọn ngày bắt đầu ngày kết thúc',
  num_kpi: 'KPI',
  check_in_service_plan: 'Kế hoạch số khách hàng check in bởi dịch vụ',
  kpi_plan: 'KPI kế hoạch',
  kpi_actual: 'KPI thực tế',
  item_management: 'Quản lý Sản phẩm - Dịch vụ',
  item_management_content: {
    add_product_property: 'Thêm thuộc tính tài sản',
    add_property: 'Thêm thuộc tính',
    add_property_value: 'Thêm giá trị',
    category: 'Danh mục / Khối dịch vụ',
    enter_job_title_level_id: 'Nhập id cấp bậc',
    all_status: 'Tất cả trạng thái',
    all_item: 'Tất cả Sản phẩm/Dịch vụ',
    add_job_title_level_doctor: 'Thêm cấp bậc bác sĩ',
    service: 'Dịch vụ',
    type: 'Loại',
    request_job_title_level_doctor: 'Yêu cầu cấp bậc bác sĩ',
    job_title_doctor: 'Cấp bậc bác sĩ',
    job_title_level_doctor: 'Cấp bậc bác sĩ thực hiện',
    treatment_use: 'Thủ thuật sử dụng',
    treatment_name: 'Tên thủ thuật',
    step_perform: 'Bước thực hiện',
    time_perform: 'Thời gian thực hiện',
    condition_perform: 'Điều kiện thực hiện',
    skill: 'Kỹ năng',
    material: 'Vật tư',
    supplies: 'Vật tư',
    dental_service: 'Dịch vụ nha khoa',
    dental_product: 'Sản phẩm nha khoa',
    insert_upper_treatment: 'Chèn thủ thuật phía trên',
    insert_lower_treatment: 'Chèn thủ thuật phía dưới',
    insert_upper_step_perform: 'Chèn bước thực hiện phía trên',
    insert_lower_step_perform: 'Chèn bước thực hiện phía dưới',
    add_product_service: 'Thêm Sản phẩm/Dịch vụ',
    description_add_service:
      'Các dịch vụ chăm sóc sức khoẻ răng miệng toàn diện tại Nha Khoa PARKWAY.',
    description_add_product: 'Sản phẩm nha khoa, hỗ trợ bảo vệ răng miệng.',
    description_add_supplies: 'Vật tư Y tế, thiết bị văn phòng',
    description_add_asset:
      'Các thiết bị phục vụ công việc ( Máy tính, máy in .... )',
    description_add_tool:
      'Các công cụ, dụng cụ phục vụ khám chữa bệnh tại khối khám bệnh và phục vụ khối văn phòng',
    description_add_combo:
      'Nhóm Dịch vụ nha khoa chăm sóc sức khoẻ răng miệng toàn diện tại Nha Khoa PARKWAY',
    order_perform: 'Thứ tự thực hiện',
    are_you_want_delete_service: 'Bạn xác nhận xóa dịch vụ',
    are_you_want_delete_product: 'Bạn xác nhận xóa sản phẩm',
    are_you_want_delete_supplies: 'Bạn xác nhận xóa vật tư',
    once_the_service_is_locked_it_cannot_be_recovered:
      'Dịch vụ sau khi xóa sẽ không thể khôi phục lại',
    once_the_product_is_locked_it_cannot_be_recovered:
      'Sản phẩm sau khi xóa sẽ không thể khôi phục lại',
    once_the_supplies_is_locked_it_cannot_be_recovered:
      'Vật tư sau khi xóa sẽ không thể khôi phục lại',
    delete_service: 'Xóa dịch vụ',
    delete_product: 'Xóa sản phẩm',
    delete_supplies: 'Xóa vật tư',
    delete_asset: 'Xóa tài sản',
    delete_tool: 'Xóa công cụ dụng cụ',
    delete_treatment: 'Xóa thủ thuật',
    are_you_want_delete_treatment: 'Bạn xác nhận xóa thủ thuật',
    once_the_treatment_is_locked_it_cannot_be_recovered:
      'Thủ thuật sau khi xóa sẽ không thể khôi phục lại',
    are_you_want_delete_asset: 'Bạn xác nhận xóa tài sản',
    once_the_asset_is_locked_it_cannot_be_recovered:
      'Tài sản sau khi xóa sẽ không thể khôi phục lại',
    are_you_want_delete_tool: 'Bạn xác nhận xóa công cụ dụng cụ',
    once_the_tool_is_locked_it_cannot_be_recovered:
      'Công cụ dụng cụ sau khi xóa sẽ không thể khôi phục lại',
    add_treatment: 'Thêm thủ thuật',
    used_supplies: 'Vật tư sử dụng',
    add_supplies: 'Thêm vật tư',
    select_supplies: 'Chọn vật tư',
    select_treatment: 'Chọn thủ thuật',
    treatment: 'Thủ thuật',
    not_select_supplies: 'Chưa chọn vật tư nào',
    not_select_skill: 'Chưa chọn kỹ năng nào',
    select_job_title: 'Chọn chức danh',
    select_job_title_level: 'Chọn cấp bậc',
    info_service: 'Thông tin dịch vụ',
    info_treatment: 'Thông tin thủ thuật',
    code: 'Mã',
    name: 'Tên',
    name_service: 'Tên dịch vụ',
    enter_name_service: 'Nhập tên dịch vụ',
    condition_success_execute_step: 'Yêu cầu: Đã hoàn thành Bước {{step}}',
    service_code: 'Mã dịch vụ',
    service_code_old: 'Mã dịch vụ cũ',
    enter_service_code: 'Nhập mã dịch vụ',
    enter_service_code_old: 'Nhập mã dịch vụ cũ',
    producer: 'Nhà sản xuất / phân phối',
    select_producer: 'Chọn nhà sản xuất',
    image_type: 'Hình ảnh {{type}}',
    image: 'Hình ảnh',
    select_time_perform: 'Chọn thời gian thực hiện',
    purchase_unit: 'Đơn vị mua',
    select_purchase_unit: 'Chọn đơn vị mua',
    select_category: 'Chọn danh mục / Khối dịch vụ',
    using_unit: 'Đơn vị sử dụng',
    select_using_unit: 'Chọn đơn vị sử dụng',
    unit_used: 'Đơn vị sử dụng',
    select_unit_used: 'Chọn đơn vị sử dụng',
    inventory_management: 'Quản lý tồn kho',

    info_material: 'Thông tin vật tư',
    info_supplies: 'Thông tin vật tư',
    product_name: 'Tên sản phẩm',
    enter_product_name: 'Nhập tên sản phẩm',
    product_code: 'Mã sản phẩm',
    enter_product_code: 'Nhập mã sản phẩm',

    material_name: 'Tên vật tư',
    enter_material_name: 'Nhập tên vật tư',
    material_code: 'Mã vật tư',
    enter_material_code: 'Nhập mã vật tư',
    supplies_name: 'Tên vật tư',
    enter_supplies_name: 'Nhập tên vật tư',
    supplies_code: 'Mã vật tư',
    enter_supplies_code: 'Nhập mã vật tư',

    asset_name: 'Tên công cụ / dụng cụ',
    enter_asset_name: 'Nhập tên công cụ / dụng cụ',
    asset_code: 'Mã công cụ / dụng cụ',
    enter_asset_code: 'Nhập mã công cụ / dụng cụ',

    tools_name: 'Tên tài sản',
    enter_tools_name: 'Nhập tên tài sản',
    tools_code: 'Mã tài sản',
    enter_tools_code: 'Nhập mã tài sản',

    info_asset: 'Thông tin tài sản',
    info_tool: 'Thông tin công cụ / dụng cụ',
  },
  treatment_management: 'Quản lý thủ thuật',
  treatment_management_content: {
    request_level_skill: 'Yêu cầu kỹ năng',
    treatment_old_id: 'Mã thủ thuật cũ',
    treatment_name: 'Tên thủ thuật',
    treatment_code: 'ID',
    treatment_execute_time: 'TG báo bệnh nhân',
    treatment_doctor_time: 'TG BS thực hiện',
    treatment_chair_time: 'TG chiếm ghế',
    enter_treatment_name: 'Nhập tên thủ thuật',
    enter_treatment_code: 'Nhập mã thủ thuật',
    enter_treatment_execute_time: 'Nhập thời gian',
    enter_treatment_doctor_time: 'Nhập thời gian',
    enter_treatment_chair_time: 'Nhập thời gian',
    enter_treatment_old_id: 'Nhập mã thủ thuật cũ',
    selected_total_skill: 'Tổng cộng kỹ năng đã chọn',
    are_you_want_delete_treatment: 'Bạn xác nhận xóa dịch vụ',
    once_the_treatment_is_locked_it_cannot_be_recovered:
      'Thủ thuật sau khi xóa sẽ không thể khôi phục lại',
    delete_treatment: 'Xóa thủ thuật',
    please_enter_id_treatment: 'Nhập ID thủ thuật',
    select_type: 'Chọn loại',
  },
  YES: 'Có',
  NO: 'Không',
  material: 'Vật tư',
  tool: 'công cụ / dụng cụ',
  supplies: 'Vật tư',
  tools: 'công cụ / dụng cụ',
  asset: 'Tài sản',
  add_service: 'Thêm dịch vụ',
  create_service: 'Thêm dịch vụ',
  update_service: 'Cập nhật dịch vụ',
  add_product: 'Thêm sản phẩm',
  update_product: 'Cập nhật sản phẩm',
  add_supplies: 'Thêm vật tư',
  update_supplies: 'Cập nhật vật tư',
  add_asset: 'Thêm tài sản',
  update_asset: 'Cập nhật tài sản',
  add_tool: 'Thêm công cụ dụng cụ',
  add_combo: 'Thêm Combo sản phẩm',
  update_tool: 'Cập nhật công cụ dụng cụ',
  quantity: 'Số lượng',
  unit_of_measure: 'Đơn vị tính',
  quantity_must_be_greater_than_0: 'Số lượng phải lớn hơn 0',
  add: 'Thêm',
  empty_list: 'Danh sách trống',
  add_main_image: 'Thêm ảnh chính',
  add_image: 'Thêm ảnh',
  note_size_image: '*Ghi chú: Kích thước ảnh tải lên tối đa {{size}}MB',
  category: 'Danh mục',
  select_category: 'Chọn danh mục',
  search_appointment: 'Tìm kiếm lịch hẹn',
  search_appointment_s_label: 'Nhập họ tên, SĐT bênh nhân....',
  search_appointment_choose_clinic: 'Tất cả phòng khám',
  search_appointment_date_time: 'Ngày & Giờ',
  search_appointment_patient: 'Bệnh nhân',
  search_appointment_dob: 'Ngày sinh',
  search_appointment_doctor: 'Bác sĩ phụ trách buổi hẹn',
  search_appointment_location: 'Địa điểm',
  search_appointment_treatment: 'Điều trị',
  search_appointment_send_sms: 'Gửi SMS (lần)',
  search_appointment_in: 'In',
  add_schedule: 'Thêm lịch hẹn',
  update_schedule: 'Cập nhật lịch hẹn',
  total_schedule: 'Tổng lịch hẹn: ',
  search_appointment_send_all: 'Gửi SMS nhắc lịch cho tất cả danh sách',
  search_appointment_updated_by: 'Cập nhật bởi',
  sms_successfully: 'Gửi tin nhắn thành công',
  search_appointment_doctor_shift: 'Bác sĩ phụ trách ca',
  created_at: 'Ngày tạo',
  sorry_currently_you_cant_download_excel_file:
    'Xin lỗi bạn không thể download excel file lúc này, liên hệ với IT',
  item_category: 'Quản lý danh mục',
  item_category_content: {
    add: 'Thêm danh mục',
    add_sub: 'Tạo danh mục con',
    name: 'Danh mục',
    please_select_name: 'Nhập tên danh mục',
    are_you_want_delete: 'Bạn xác nhận xoá danh mục',
    create_profile_success: 'Thêm danh mục thành công',
    once_the_profile_is_locked_it_cannot_be_recovered:
      'Danh mục sau khi khoá sẽ không thể khôi phục lại',
    please_enter_id_item: 'Nhập ID dịch vụ',
  },
  report_14: 'Số ca/doanh thu theo SKU',
  report_14_content: {
    title: 'Số ca/doanh thu theo SKU',
    number_of_case: 'Số ca',
    all_branch: 'Tất cả chi nhánh',
    all_age_group: 'Tất cả độ tuổi',
    all_gender: 'Tất cả giới tính',
    all_area: 'Tất cả khu vực',
  },
  case: 'Số ca',
  you_dont_have_permission: `Bạn không có quyền vào chức năng "{{module}}", liên hệ Admin.`,
  not_exist_page:
    'Trang này không tồn tại hoặc bạn không có quyền truy cập, vui lòng liên hệ admin',
  role: 'Phân quyền',
  role_content: {
    enter_role: 'Nhập phân quyền',
    name_role: 'Tên phân quyền',
    permission: 'Quyền',
    update_permission: 'Cập nhật quyền',
    choose_group_permission: 'Chọn nhóm quyền',
    enter_name_permission: 'Nhập tên quyền',
    name_permission: 'Tên quyền',
    total_permission_selected: 'Tổng quyền đã chọn',
    add_role: 'Thêm phânq quyền',
    enter_name_role: 'Nhập tên phân quyền',
    add_permission: 'Thêm quyền',
    info_permission: 'Thông tin quyền',
    delete_role: 'Xoá phân quyền',
    modules: 'Nhóm quyền',
    enter_description_role: 'Nhập mô tả phân quyền',
    description_role: 'Mô tả phân quyền',
    group_permission: 'Nhóm quyền',
    update_role: 'Cập nhật phân quyền',
    not_choose_permission: 'Chưa chọn quyền',
    role_selected_count: 'Số role được chọn',
  },
  role_management: 'Quản lý phân quyền',
  all_status: 'Tất cả trạng thái',
  year_number: 'Năm {{year}}',
  tip_price: 'Giá thủ thuật',
  package_name: 'Tên gói dịch vụ',
  package_price: 'Giá trị gói',
  net_amount: 'Tổng thanh toán',
  paid: 'Đã thanh toán',
  contract_company_value: 'Giá trị ký hợp đồng theo hợp đồng công ty',
  contract_clinic_value: 'Giá trị ký hợp đồng theo hợp đồng bên phòng khám',
  contract_code: 'Mã hợp đồng',
  note_print: 'Ghi chú thông tin cho BN muốn xuất hóa đơn',
  note: 'Ghi chú',
  service_amount: 'Số dịch vụ',
  location: 'Cơ sở',
  statistical: 'Thống kê',
  list_braces_tray: 'Danh sách khách hàng bắt đầu gắn khay niềng',
  braces_tray: 'Danh sách khách hàng bắt đầu gắn khay niềng',
  created_successfully: 'Tạo thành công',
  success: 'Thành công',
  once_the_name_is_locked_it_cannot_be_recovered:
    '{{name}} sau khi khoá sẽ không thể khôi phục lại',
  are_you_want_delete_name: 'Bạn có thực sự muốn xoá {{name}}',
  roles: 'Roles',
  rights: 'Quyền',
  job_title_roles: 'Chức danh',
  day_off: 'Day off',
  day_leave_policy: 'Chính sách nghỉ phép',
  day_leave: 'Nghỉ phép',
  CONFLICT_TIME: 'Có xung đột về thời gian',
  waited: 'Đã chờ',
  inventory: 'Kho',
  dayoff: 'Day off',
  dayleave: 'Nghỉ phép',
  profile_management: 'Quản lý nhân viên',
  id: 'ID',
  select_all: 'Chọn tất cả',
  unselect_all: 'Bỏ tất cả',
  copy_success: 'Copy thành công!',
  skill_title: 'Kĩ năng',
  my_profile: 'Hồ sơ của tôi',
  group_service: 'Nhóm dịch vụ',
  select_debt_date: 'Ngày phát sinh gói',
  select_debt_date_all: 'Ngày phát sinh',
  old_password: 'Mật khẩu cũ',
  enter_old_password: 'Nhập mật khẩu cũ',
  new_password: 'Mật khẩu mới',
  enter_new_password: 'Nhập mật khẩu mới',
  confirm_new_password: 'Xác nhận mật khẩu mới',
  enter_confirm_new_password: 'Nhập xác nhận mật khẩu mới',
  the_new_password_that_you_entered_do_not_match:
    'Mật khẩu xác nhận không trùng khớp!',
  new_password_need_different_old_password:
    'Mật khẩu mới vui lòng khác mật khẩu cũ!',
  change_password_success_and_please_re_login:
    'Bạn đã đổi mật khẩu thành công, vui lòng đăng nhập lại!',
  old_password_is_incorrect: 'Mật khẩu cũ không đúng',
  wrong_username_or_password: 'Sai tài khoản hoặc mật khẩu.',
  with_error_please_contact_with_admin:
    'Đã có lỗi xảy ra, vui lòng liên hệ admin IT',
  your_session_has_expired_please_login_again:
    'Phiên đang nhập đã hết, vui lòng đăng nhập lại!',
  enter_email_or_phone: 'Nhập số điện thoại hoặc email',
  dental_treatment_management: 'Quản lý điều trị',
  patient_record_management: 'Quản lý hồ sơ bệnh nhân',
  enter_search_patient: 'Nhập tên bệnh nhân, số điện thoại, ...',
  add_patient_record: 'Thêm hồ sơ bệnh nhân',
  update_patient_record: 'Cập nhật hồ sơ bệnh nhân',
  msbn: 'MSBN',
  enter_patient_code: 'Nhập mã số bệnh nhân',
  choose_province: 'Chọn tỉnh',
  choose_district_or_city: 'Chọn quận/huyện/thành phố',
  choose_ward: 'Chọn phường/xã/thị trấn',
  enter_address: 'Nhập địa chỉ',
  customer_source: 'Nguồn khách hàng',
  choose_source: 'Chọn nguồn',
  important_note: 'Ghi chú quan trọng',
  enter_note: 'Nhập ghi chú',
  patient_info: 'Thông tin bệnh nhân',
  update_by: 'Cập nhật bởi',
  see_history_update_note: 'Xem lịch xử cập nhật ghi chú',
  history_update_patient_record: 'Lịch sử cập nhật hồ sơ bệnh nhân',
  lasted_appointment: 'Lịch hẹn gần nhất',
  attending_physician: 'Bác sĩ phụ trách ca',
  managment: 'Quản lý',
  birthday: 'Ngày sinh',
  enter_phone: 'Nhập số điện thoại',
  enter_first_name: 'Nhập họ',
  enter_last_name: 'Nhập tên',
  enter_email: 'Nhập email',
  please_select_gender: 'Vui lòng chọn giới tính',
  choose_area: 'Chọn khu vực',
  choose_clinic: 'Chọn phòng khám',
  select_birthday: 'Chọn ngày sinh',
  district: 'Quận/huyện/thành phố',
  ward: 'Phường/xã/thị trấn',
  utilities: 'Tiện ích',
  lasted_appoinment: 'Lịch hẹn gần nhất',
  qr_generate: 'Tạo mã QR',
  input_link_you_want: 'Nhập link hoặc điều gì bạn muốn',
  generate: 'Tạo',
  value_qr: 'Giá trị mã QR',
  reset_QR: 'Tạo mã khác',
  download: 'Download',
  selecting: 'Đang chọn',
  please_select_employee: 'Vui lòng chọn nhân viên',
  date_recall: 'Ngày hẹn gọi lại',
  unit_price: 'Đơn giá',
  treatment_fee: 'Phí điều trị',
  vat_code: 'Mã VAT',
  refund: 'Hoàn tiền',
  cash: 'Tiền mặt',
  card: 'Trả thẻ',
  card_type: 'Loại thẻ',
  transfer: 'Chuyển khoản',
  transfer_type: 'Loại chuyển khoản',
  app_momo: 'Momo',
  select_service_treatment_required: 'Vui lòng trọn gói hoặc thủ thuật',
  sync: 'Đồng bộ',
  debt: 'Công nợ',
  sync_for_up_to_one_day: 'Đồng bộ hóa tối đa một ngày',
  sync_successfully: 'Đồng bộ thành công',
  choose_service: 'Chọn dịch vụ',
  booking_website_report: 'Báo cáo số lượt đặt lịch từ website',
  arrivals: 'Lượt',
  number_of_booking_in_many_days: 'Số lượt đặt lịch trong {{count}} ngày',
  time_from_to: 'Thời gian: {{from}} - {{to}}',
  number_arrivals: 'Số lượt',
  export_excel_sendmail_message: 'Tệp tin sẽ được gửi vào email của bạn',
  profile_patient_record: 'Thông tin bệnh nhân',
  date_time: 'Ngày giờ',
  main_content: 'Nội dung',
  doctor_in_charge_of_the_shift: 'Bác sĩ phụ trách ca',
  prefix: 'Prefix',
  appointment: 'Lịch hẹn',
  call_reminder_schedule: 'Lịch nhắc gọi',
  purchase_dental_products_services: 'Mua sản phẩm/ dịch vụ nha khoa',
  payment_debt: 'Nợ thanh toán',
  order_id: 'Mã đơn hàng',
  monthly_installment_plan: 'Trả góp theo tháng',
  info_payment: 'Thông tin thanh toán',
  late_payment_period: 'Kỳ thanh toán trễ hạn',
  total_amount_due: 'Tổng số tiền thanh toán',
  payment_method: 'Phương thức thanh toán',
  add_payment_method: 'Thêm phương thức thanh toán',
  issue_invoice: 'Xuất hoá đơn',
  detail_payment: 'Chi tiết thanh toán',
  total_payment_required: 'Tổng tiền cần thanh toán',
  amount_paid_by_customer: 'Khách đưa',
  enter_amount_paid_by_customer: 'Nhập số tiền khách đưa',
  excess_money: 'Tiền thừa',
  total_payment_made: 'Tổng tiền đã thanh toán',
  late_payment_processing: 'Thanh toán kỳ trễ hạn',
  complete_settlement: 'Tất toán toàn bộ',
  payment_option: 'Hình thức thanh toán',
  e_wallet: 'Ví điện tử',
  choose_payment_method: 'Chọn phương thức thanh toán',
  enter_money: 'Nhấp số tiền',
  payment: 'Thanh toán',
  add_product_service: 'Thêm sản phẩm/dịch vụ',
  close: 'Đóng',
  invoice: 'Hoá đơn',
  total_invoice: 'Tổng hoá đơn',
  alternative_application_program: 'Chương trình áp dụng khác',
  installment_payment: 'Trả góp',
  payment_in_stages: 'Theo giai đoạn',
  discount: 'Giảm giá',
  product_does_not_support_installment_payment: 'Sản phẩm không hỗ trợ trả góp',
  total: 'Tổng',
  total_amount: 'Thành tiền',
  number_of_products_services: 'Số sản phẩm & dịch vụ',
  subtotal_amount: 'Số tiền tạm tính',
  promotion: 'Khuyến mãi',
  disount_code: 'Mã giảm giá',
  enter_discount_code: 'Nhập mã giảm giá',
  total_payment: 'Tổng tiền',
  program: 'Chương trình',
  add_alternative_application_program: 'Thêm chương trình áp dụng',
  select_program: 'Chọn chương trình',
  select_payment_method: 'Chọn phương thức thanh toán',
  enter_name_company: 'Nhập tên công ty',
  tax_id: 'Mã số thuế',
  enter_tax_id: 'Nhập mã số thuế',
  total_amount_payment: 'Tổng tiền thanh toán',
  image_patient: 'Hình ảnh bệnh nhân',
  add_treatment_procedure_folder: 'Thêm thư mục thủ thuật điều trị',
  exist_folder_name: 'Đã tồn tại folder tên {{name}}',
  note_maximum_image_upload_size_is_5_MB:
    'Ghi chú: Kích thước ảnh tải lên tối đa 5 MB',
  provider: 'Nhà cung cấp',
  provider_management: 'Quản lý nhà cung cấp',
  provider_add: 'Thêm nhà cung cấp',
  provider_update: 'Cập nhật nhà cung cấp',
  provider_delete: 'Xóa nhà cung cấp',
  provider_form_required: 'Vui lòng nhập {{field}}',
  name: 'Tên',
  contact: 'Liên hệ',
  bankinfo: 'Thông tin chuyển khoản',
  TIN: 'Mã số thuế',

  treatment_schedule: 'Lịch trình điều trị',
  total_amount_service: 'Tổng tiền dịch vụ',
  paid_amount: 'Tiền đã thanh toán',
  refund_amount: 'Đã hoàn tiền',
  remaining_amount: 'Còn lại',
  patient_journey: 'Hành trình bệnh nhân',
  emergency_contact: 'Liên hệ khẩn cấp',
  next_of_kin: 'Thân nhân',
  call_schedule: 'Lịch gọi khác',
  dental_health_history: 'Lịch sử khảo sát sức khoẻ răng miệng',
  laboratory_results: 'Kết quả lâm sàn',
  transaction_history: 'Lịch sử giao dịch',
  payment_history: 'Lịch sử thanh toán',
  under_treatment: 'Đang điều trị',
  service_refund: 'Hoàn trả dịch vụ',
  treatment_information: 'Thông tin điều trị',
  treating_physician: 'Bác sĩ lập phác đồ',
  no_treating_physician: 'Chưa có bác sĩ lập phác đồ',
  treatment_plan: 'Phác đồ điều trị',
  no_assigned_physician: 'Chưa có bác sĩ phụ trách',
  assigned_physician: 'Bác sĩ phụ trách',
  approved: 'Đã được duyệt',
  treatment_phase: 'Giai đoạn điều trị',
  phase_attending_physician: 'Bác sĩ phụ trách giai đoạn',
  phase_approver: 'Người duyệt giai đoạn',
  add_phase: 'Thêm giai đoạn',
  send_payment_SMS: 'Gửi SMS thanh toán',
  send_treatment_receipt_email: 'Email phiếu điều trị',
  print_treatment_receipt: 'In phiếu điều trị',
  print_contract: 'In hợp đồng',
  add_appointment: 'Thêm buổi hẹn',
  appointment_session: 'Buổi hẹn',
  symptoms_diagnosis: 'Triệu chứng/chuẩn đoán',
  treatment_direction: 'Phương hướng điều trị',
  procedures_within_appointment: 'Các thủ thuật trong buổi hẹn',
  equipment_information: 'Thông tin vật tư',
  professional_exchange_with_physician: 'Trao đổi với bác sĩ chuyên môn',
  image_size: 'Kích thước hình ảnh (JPEG, PNG)',
  document_size: 'Kích thước tài liệu (Word, Excel, PDF) tối đa 10Mb',
  upload_size_max_10_mb:
    'Kích thước hình ảnh (JPEG, PNG) và tài liệu (Word, Excel, PDF) tối đa 10Mb',
  perform_procedure: 'Thực hiện thủ thuật',
  compress: 'Thu gọn',
  cancel_approval: 'Đã huỷ duyệt',
  not_available: 'Chưa có',
  completed: 'Đã hoàn tất',
  in_treatment: 'Đã điều trị',
  cancelled: 'Đã huỷ',
  suspended: 'Tạm ngưng',
  pending_execution: 'Chờ thực hiện',
  payment_information: 'Thông tin thanh toán',
  refund_money: 'Hoàn tiền',
  code: 'Mã',
  payment_code: 'Mã thanh toán',
  payment_date: 'Thời hạn thanh toán',
  material_import: 'Nhập vật liệu',
  material_import_management: 'Quản lý nhập vật liệu',
  material_import_code: 'Mã nhập vật liệu',
  material_import_add: 'Thêm nhập vật liệu',
  material_import_update: 'Cập nhật nhập vật liệu',
  material_import_delete: 'Xóa nhập vật liệu',
  material_import_form_required: 'Vui lòng nhập {{field}}',
  material_management: 'Quản lý vật liệu',
  invoice_number: 'Số hoá đơn',
  invoice_symbol: 'Ký hiệu hóa đơn',
  invoice_date: 'Ngày hoá đơn',
  invoice_price_tax: 'Thành tiền thuế',
  unit_import: 'Đơn vị nhập',
  total_price: 'Tổng tiền',
  unit_use: 'Đơn vị sử dụng',
  unit_use_import: 'Số đơn vị sử dụng / đơn vị nhập',
  date_import: 'Ngày nhận hàng',
  select: 'Chọn',
  created_date: 'Ngày lập',
  material_category: 'Danh mục vật liệu',
  material_vat: 'Thuế suất',
  detail: 'Chi tiết',
  material_group: 'Nhóm vật tư',
  provider_contact: 'Contact nhà cung cấp',
  material_purpose: 'Công dụng',
  patient_code: 'Mã KH',
  material_export: 'Xuất vật liệu',
  material_category_delete: 'Xoá danh mục vật liệu',
  material_category_update: 'Cập nhật danh mục vật liệu',
  provider_code: 'Mã nhà cung cấp',
  doctors_and_nurses_perform: 'Bác sĩ & Y tá thực hiện',
  doctors_perform: 'Bác sĩ thực hiện',
  nurses_perform: 'Y tá thực hiện',
  stop_performing: 'Dừng thực hiện',
  pending_payment: 'Chờ thanh toán',
  not_occurred: 'Chưa diễn ra',
  occurred: 'Diễn ra',
  have_problem: 'Có vấn đề',
  update_appointment_session: 'Cập nhật buổi hẹn',
  name_appointment_session: 'Tên buổi hẹn',
  delete_appointment_session: 'Xoá buổi hẹn',
  update_treatment_phase: 'Cập nhật giai đoạn',
  delete_treatment_phase: 'Xoá giai đoạn',
  add_appointment_session: 'Thêm buổi hẹn',
  enter_content: 'Nhập nội dung',
  content: 'Nội dung',
  attached: 'Đính kèm',
  amount: 'Số tiền',
  schedule_without_paid: 'Lịch hẹn 0Đ',
  patient_arrive_at_the_clinic: 'Bệnh nhân đến phòng khám',
  the_patient_is_counseled_by_a_counselor:
    'Bệnh nhân được tư vấn bởi tư vấn viên',
  the_patient_undergoes_treatment: 'Bệnh nhân thực hiện điều trị',
  the_patient_leaves: 'Bệnh nhân ra về',
  performer: 'Người thực hiện',
  enter_phase_name: 'Thêm tên giai đoạn',
  phase_name: 'Tên giai đoạn',

  // add new
  enter_name_appointment_session: 'Nhập tên buổi hẹn',
  trade_union_members_management: 'Quản lý công đoàn viên',
  union_members_detail: 'Chi tiết công đoàn viên',
  event_management: 'Quản lý sự kiện',
  event_report: 'Báo cáo sự kiện',
  id_management: 'Mã quản lý',
  domicile: 'Quê quán',
  CCCD: 'Căn cước công dân',
  view_detail: 'Xem chi tiết',
  nations: 'Dân tộc',
  religion: 'Tôn giáo',
  Workplace: 'Đơn vị công tác',
  university_subsidiary: 'Trường/Phân hiệu',
  academic: 'Học hàm/Học vị',
  start_date_ueh: 'Ngày vào UEH',
  employment_status: 'Tình trạng việc làm',
  date_of_joining_the_party: 'Ngày vào Đảng',
  date_of_union_entry: 'Ngày vào công đoàn',
  union_card_number: 'Số thẻ đoàn viên',
  status_union_activities: 'Tình trạng sinh hoạt công đoàn',
  union_positions: 'Chức vụ công đoàn',
  enter_union_card_number: 'Nhập số thẻ đoàn viên',
  enter_union_positions: 'Nhập chức vụ công đoàn',
  admin: 'Quản trị viên',
  trade_union_member: 'Công đoàn viên',
  all_role: 'Tất cả phân quyền',
  in_active: 'Không hoạt động',
  active_content: 'Đang hoạt động',
  enter_full_name: 'Nhập họ và tên',
  create_account: 'Tạo mới tài khoản',
  create_event: 'Tạo mới sự kiện',
  update_event: 'Cập nhật sự kiện',
  not_happen: 'Chưa xảy ra',
  in_event: 'Đang diễn ra',
  number_trade_union_register: 'Số công đoàn viên đã đăng kí',
  trade_union_register_event: 'Công đoàn viên đã đăng kí sự kiện',
  check_in: 'Check in',
  checked_in: 'Đã check in',
  not_check_in: 'Chưa check in',
  event_name: 'Tên sự kiện',
  enter_event_name: 'Nhập tên sự kiện',
  estimated_number_of_union_members: 'Dự tính số lượng công đoàn viên',
  event_reporting_by_stage: 'Báo cáo sự kiện theo giai đoạn',
  general_info: 'Thông tin chung',
  education_and_team_info: 'Học vấn và thông tin đoàn đội',
  sync_data: 'Sync dữ liệu',
  permission: 'Quyền',
  name_role: 'Tên phân quyền',

  enter_description: 'Nhập mô tả',
  select_permission: 'Chọn quyền',
  add_new_role: 'Thêm mới phân quyền',
  enter_name_description_for_search: 'Nhập tên, mô tả để tìm kiếm',
  select_role: 'Chọn phân quyền',
  search_by_name_phone_email: 'Tìm theo tên, sđt, email,...',
  search_by_name_description: 'Tìm theo tên, mô tả...',

  women_workers: 'Công tác nữ công',
  propaganda_culture: 'Tuyên giáo - Văn Thể',
  union_regulations: 'Luật và điều lệ công đoàn',
  commendation_reward: 'Thi đua khen thưởng',
  policies_laws_professional_support:
    'Chính sách - Pháp luật - Hỗ trợ chuyên môn',
  union_activities_categorization: 'Phân nhóm goạt động công đoàn',
  all_union_activities_categorization: 'Tất cả phân nhóm goạt động công đoàn',
  select_union_activities_categorization: 'Chọn phân nhóm goạt động công đoàn',
  end_date_must_be_after_start_date: 'Ngày kết thúc phải sau ngày bắt đầu',
  add_address: 'Thêm địa chỉ',
  select_union_certificate: 'Chọn file chứng chỉ công đoàn',
  union_certificate: 'Chứng chỉ công đoàn',
  click_or_drag_file_to_this_area_to_upload:
    'Click hoặc kéo file vào đây để upload',
  support_for_a_single_or_bulk_upload:
    'Hỗ trợ upload từng file hoặc nhiều file',
  update_success: 'Cập nhật thành công',
  permission_content: {
    create_event: 'Tạo sự kiện',
    read_event: 'Xem sự kiện',
    update_event: 'Cập nhật sự kiện',
    delete_event: 'Xoá sự kiện',
    create_account: 'Tạo tài khoản',
    read_account: 'Xem tài khoản',
    update_account: 'Cập nhật tài khoản',
    delete_account: 'Xoá tài khoản',
    update_role_account: 'Cập nhật phân tài khoản',
    read_report: 'Xem báo cáo',
    update_trade_union_member: 'Cập nhật công đoàn viên',
    read_trade_union_member: 'Xem công đoàn viên',
    sync_trade_union_member: 'Đồng bộ công đoàn viên',
    create_role: 'Tạo phân quyền',
    read_role: 'Xem phân quyền',
    update_role: 'Cập nhật phân quyền',
    delete_role: 'Xoá phân quyền',
    read_role_permission: 'Xem quyền trong phân quyền',
    update_role_permission: 'Cập nhật quyền trong phân quyền',
    create_role_permission: 'Tạo quyền trong phân quyền',
    delete_role_permission: 'Xoá quyền trong phân quyền',
  },
  event: 'Sự kiện',
  delete_name_success: 'Xoá "{{name}}" thành công!',
  danhsachdonvi: 'Danh sách đơn vị',
  danh_sach_doan_vien: 'Danh sách đoàn viên',
  chi_tiet_doan_vien: 'Chi tiết đoàn viên',
  danhsachdangki: 'Danh sách đăng kí',
  danhsachsuukien: 'Danh sách sự kiện',
  taosukien: 'Tạo sự kiện',
  doanviendangky: 'Đoàn viên đăng kí',
  chuyendonvi: 'Chuyển đơn vị',
  khaibaodonvi: 'Khai báo đơn vị',
  khaibaosukien: 'Khai báo sự kiện',
  khaibaothongtin: 'Khai báo thông tin',
  thongtincanhan: 'Thông tin cá nhân',
  danhsachcongdoanvien: 'Danh sách công đoàn viên',
  danhgiathanhtich: 'Đánh giá thành tích',
  diemdanh: 'Điểm danh',
  themanh: 'Thêm ảnh',
  thongtinsukien: 'Đăng kí hoạt động/sự kiện',
  start_date_must_be_before_end_date: 'Ngày bắt đầu phải trước ngày kết thúc',
  end_date_register_must_be_before_start_date:
    'Ngày kết thúc đăng kí phải trước ngày bắt đầu',
  start_date_event_must_be_after_end_date_register:
    'Ngày bắt đầu sự kiện phải sau ngày kết thúc đăng kí',

  start_date_event: 'Ngày bắt đầu sự kiện',
  end_date_event: 'Ngày kết thúc sự kiện',
  start_date_register: 'Ngày bắt đầu đăng kí',
  end_date_register: 'Ngày kết thúc đăng kí',

  not_open_register: 'Chưa mở đăng kí',
  registration: 'Đang đăng kí',
  registration_completed: 'Đăng kí hoàn tất',
  finished: 'Kết thúc',
  previous: 'Trước',

  event_detail: 'Chi tiết sự kiện',

  chitietdonvi: 'Chi tiết đơn vị',
  event_management_by_unit: 'Quản lý sự kiện',
}
