import { MoreOutlined } from '@ant-design/icons'
import { BaseButton } from 'app/components/common/BaseButton'
import { BasePopover } from 'app/components/common/BasePopover'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { useMemo } from 'react'
import { useCreateEventContext } from '../../../../context'
import { ModalEditReward } from '../../../../modals'
import {
    DefineRewardType,
    IActivityEvent,
    IRewardActivity,
    RewardTypeEnum,
} from '../../../../type'

export const RewardLayout = ({
  activity,
  index,
}: {
  activity: IActivityEvent
  index: number
}) => {
  const { deleteRewardInActivityByIndex } = useCreateEventContext()

  const data = useMemo(() => {
    return activity?.rewards?.map((item, index) => {
      return {
        ...item,
        key: index,
      }
    })
  }, [activity?.rewards])

  const handleDelete = (recordIndex?: number) => {
    if (recordIndex === undefined) return

    deleteRewardInActivityByIndex?.(index, recordIndex)
  }

  return (
    <BaseTableManagement
      dataSource={data}
      columns={[
        convertColumnTable<IRewardActivity & { key: number }>({
          key: 'key',
          title: 'STT',
          fixed: 'left',
          classNameWidthColumnOverwrite: 'number-column',
          render: (index: number) => {
            return (
              <BaseText
                children={index + 1}
                fontWeight="medium"
                opacity="0.7"
              />
            )
          },
        }),
        convertColumnTable<IRewardActivity>({
          key: 'name',
          title: 'Tên giải',
          fixed: 'left',
          classNameWidthColumnOverwrite: 'big-column',
          render: (text?: string) => {
            return <BaseText children={text} fontWeight="medium" />
          },
        }),
        convertColumnTable<IRewardActivity>({
          key: 'description',
          title: 'Mô tả',
          render: (text?: string) => {
            return (
              <BaseText children={text} fontWeight="medium" opacity="0.7" />
            )
          },
        }),
        convertColumnTable<IRewardActivity>({
          key: 'gift',
          title: 'Phần quà',
          render: (text?: string) => {
            return (
              <BaseText children={text} fontWeight="medium" opacity="0.7" />
            )
          },
        }),
        convertColumnTable<IRewardActivity>({
          key: 'countPerReward',
          title: 'Số lượng giải',
          render: (text?: number) => {
            return (
              <BaseText children={text} fontWeight="medium" opacity="0.7" />
            )
          },
        }),
        convertColumnTable<IRewardActivity>({
          key: 'type',
          title: 'Đối tượng nhận giải',
          render: (type: RewardTypeEnum) => {
            return (
              <BaseText
                children={DefineRewardType[type]}
                fontWeight="medium"
                colorText="statesGreenColor"
              />
            )
          },
        }),
        {
          key: 'key',
          title: '',
          width: '5%',
          fixed: 'right',
          render: (_, record) => {
            return (
              <BasePopover
                trigger={'click'}
                content={
                  <BaseSpace>
                    <BaseButton
                      type="text"
                      onClick={() => handleDelete(record?.key)}
                      style={{ width: '100%', justifyContent:"flex-start" }}
                    >
                      {'Xoá'}
                    </BaseButton>

                    <ModalEditReward
                      index={index}
                      indexReward={record?.key}
                      reward={record as IRewardActivity}
                    />
                  </BaseSpace>
                }
              >
                <BaseButton
                  icon={<MoreOutlined rev={undefined} />}
                  type="text"
                />
              </BasePopover>
            )
          },
        },
      ]}
      scroll={{ x: true }}
      pagination={{
        pageSize: 5,
      }}
      className="row-overwrite-style"
    />
  )
}
