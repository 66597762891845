import { MoreOutlined } from '@ant-design/icons'
import { BaseButton } from 'app/components/common/BaseButton'
import { BasePopover } from 'app/components/common/BasePopover'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'

import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { UpdateJobTitle } from '../ModalUpdateCdv'
import {
  DefineJobTitleEnum,
  DoanVien,
  JobTitleEnum,
} from 'app/containers/TumManagement/type'

export const TradeUnionMemberLayout = ({
  dataTable,
  isLoadingDoanVien,
  refetch,
}: {
  dataTable?: DoanVien[]
  isLoadingDoanVien?: boolean
  refetch?: () => void
}) => {
  return (
    <BaseTableManagement
      dataSource={dataTable?.map((item, index) => ({
        ...item,
        index: index + 1,
      }))}
      pagination={{
        pageSize: 5,
        showSizeChanger: false,
        total: dataTable?.length,
      }}
      loading={isLoadingDoanVien}
      columns={[
        convertColumnTable<{ index: number }>({
          key: 'index',
          title: 'STT',
          fixed: 'left',
          classNameWidthColumnOverwrite: 'number-column',
          render: (value: number) => (
            <BaseText children={value} opacity="0.7" fontWeight="medium" />
          ),
        }),

        convertColumnTable<DoanVien>({
          key: 'hovaten',
          title: 'Họ và tên',
          fixed: 'left',
          classNameWidthColumnOverwrite: 'big-column',
          render: (value: string) => (
            <BaseText children={value} fontWeight="medium" />
          ),
        }),
        convertColumnTable<DoanVien>({
          key: 'chucvu',
          title: 'Chức vụ',
          classNameWidthColumnOverwrite: 'big-column',
          render: (value: string) => (
            <BaseText children={value ?? '-'} fontWeight="medium" />
          ),
        }),
        convertColumnTable<DoanVien>({
          key: 'chucdanh',
          title: 'Chức vụ công đoàn',
          render: (value: string) => (
            <BaseText
              children={
                value
                  ?.split('&')
                  ?.map(item => DefineJobTitleEnum[item as JobTitleEnum])
                  .join(' & ') ?? ''
              }
              fontWeight="medium"
            />
          ),
        }),
        convertColumnTable<DoanVien>({
          key: 'ngaysinh',
          title: 'Ngày sinh',
          render: (value: string) => (
            <BaseText children={value} opacity="0.7" fontWeight="medium" />
          ),
        }),
        convertColumnTable<DoanVien>({
          key: 'gioitinh',
          title: 'Giới tính',
          render: (value: string) => (
            <BaseText children={value} opacity="0.7" fontWeight="medium" />
          ),
        }),
        convertColumnTable<DoanVien>({
          key: 'quequan',
          title: 'Quê quán',
          render: (value: string) => (
            <BaseText children={value} opacity="0.7" fontWeight="medium" />
          ),
        }),
        convertColumnTable<DoanVien>({
          key: 'ngayvao_congdoan',
          title: 'Ngày vào công đoàn',
          render: (value: string) => (
            <BaseText children={value} opacity="0.7" fontWeight="medium" />
          ),
        }),
        convertColumnTable<DoanVien>({
          key: 'cmnd',
          title: 'CMND/CCCD',
          render: (value: string) => (
            <BaseText children={value} opacity="0.7" fontWeight="medium" />
          ),
        }),
        convertColumnTable<DoanVien>({
          key: 'maso_doanvien',
          title: '',
          classNameWidthColumnOverwrite: 'number-column',
          fixed: 'right',
          render: (_, record) => (
            <BasePopover
              content={
                <BaseSpace>
                  <UpdateJobTitle doanVien={record} refetch={refetch} />
                </BaseSpace>
              }
              trigger={'click'}
            >
              <BaseButton icon={<MoreOutlined rev={undefined} />} type="text" />
            </BasePopover>
          ),
        }),
      ]}
      className="row-overwrite-style"
      scroll={{ x: true }}
    />
  )
}
