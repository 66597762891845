import { HomeOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons'
import type { MenuProps } from 'antd'
import { Layout, Menu } from 'antd'
// import 'antd/dist/antd.css'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { cdvn_logo, khcn_logo } from 'app/assets'
import { DASHBOARD_PATH, EVENT_INFO_PATH, PERSONAL_INFO_PATH } from 'app/components/router/route-path'

const { Sider } = Layout

type MenuItem = Required<MenuProps>['items'][number]

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon: React.ReactNode | undefined,
  path: string | undefined,
  children?: MenuItem[] | undefined,
): MenuItem {
  return {
    key,
    icon,
    children,
    label: path ? <Link to={path}>{label}</Link> : label,
  } as MenuItem
}

const items: MenuItem[] = [
  getItem('Trang chủ', '6', <HomeOutlined rev={undefined} />, DASHBOARD_PATH),
  getItem(
    'Đăng kí hoạt động/sự kiện',
    '1',
    <TeamOutlined rev={undefined} />,
    EVENT_INFO_PATH,
  ),
  getItem(
    'Thông tin cá nhân',
    '2',
    <UserOutlined rev={undefined} />,
    PERSONAL_INFO_PATH,
  ),
]

const Sidebar: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false)

  return (
    <Sider
      width={280}
      collapsible
      collapsed={collapsed}
      onCollapse={value => setCollapsed(value)}
      style={{ background: 'light' }}
      theme="light"
    >
      {collapsed ? (
        <>
          <div className="flex gap-3 flex-col items-center my-7 justify-center w-full">
            <img
              src={cdvn_logo}
              alt="congdong_logo"
              width={35}
              className="rounded-full"
            />
            <img
              src={khcn_logo}
              alt="congdong_logo"
              width={35}
              className="rounded-full"
            />
          </div>
          <div className="border border-1 border-gray-600 mx-[16px] "></div>
        </>
      ) : (
        <>
          <div className="flex gap-3 items-center my-7 justify-center w-full">
            <img
              src={cdvn_logo}
              alt="congdong_logo"
              width="15%"
              className="rounded-full"
            />
            <img
              src={khcn_logo}
              alt="congdong_logo"
              width="15%"
              className="rounded-full"
            />
          </div>
        </>
      )}
      <Menu
        theme="light"
        mode="inline"
        items={items}
        style={{ backgroundColor: 'transparent' }}
      />
    </Sider>
  )
}

export default Sidebar
