import { EditOutlined } from '@ant-design/icons'
import { message } from 'antd'
import {
  DefineGroupEventEnum,
  DefineTypeEventEnum,
} from 'app/api/event/constant'
import { IEvent, ResourceEventEnum, TypeEventEnum } from 'app/api/event/model'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { SectionBase } from 'app/components/common/SectionBase'
import HeaderPage from 'app/components/header/components/HeaderPage'
import { EDIT_EVENT_PATH } from 'app/components/router/route-path'
import { FORMAT_DD_MM_YYYY_HH_MM } from 'app/constant'
import { UpdateModalLayout } from 'app/containers/EventManagementByUnit/layouts/UpdateWithUnitModal'
import { useGetEventById } from 'app/react-query/hook/event'
import { isEqual } from 'lodash'
import { DateUtil } from 'parkway-web-common'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router'
import { StatusEventWrapper } from '../hook'
import { ExpandedRowRender } from '../layouts'
import { DefineEventStatus, EventStatusEnum } from '../type'
import { ListMemberPrizeLayout, ListUnitPrizeLayout } from './layouts'
import { useAppSelector } from 'app/redux/hooks'
import { RoleEnum } from 'app/common/enum'

const DetailEventContainer = () => {
  const navigate = useNavigate()
  const route = useLocation()
  const { t } = useTranslation()
  const userInfo = useAppSelector(state => state.userOld)?.profile

  useEffect(() => {
    if (!route?.state?.event) {
      message.error('Không tìm thấy sự kiện')
      navigate(-1)
    }
  }, [route?.state?.event])

  const { data: dataApi } = useGetEventById(route?.state?.event?.id)

  const event = useMemo(() => {
    return dataApi
  }, [dataApi])

  const navigateToUpdate = (record?: IEvent) => {
    if (!record) return
    navigate(EDIT_EVENT_PATH, { state: { event: record } })
  }

  return (
    <BaseSpace>
      <HeaderPage
        title={`Chi tiết sự kiện "${event?.name}"`}
        rightComponent={(() => {
          if (isEqual(event?.resource, ResourceEventEnum.UNIT)) {
            return (
              <BaseCol>
                <UpdateModalLayout event={event} />
              </BaseCol>
            )
          }
          if (isEqual(userInfo?.role, RoleEnum.ADMIN)) {
            if (isEqual(event?.type, TypeEventEnum.NEWS)) {
              return (
                <BaseButton
                  onClick={() => navigateToUpdate(event)}
                  icon={<EditOutlined rev={undefined} />}
                >
                  {'Cập nhật'}
                </BaseButton>
              )
            }

            switch (event?.status) {
              case EventStatusEnum.NOT_OPEN:
              case EventStatusEnum.REGISTRATION:
              case EventStatusEnum.REGISTRATION_COMPLETED:
                return (
                  <BaseButton
                    type="text"
                    onClick={() => navigateToUpdate(event)}
                  >
                    <BaseText
                      children={'Cập nhật'}
                      fontWeight="medium"
                      fontSize="xs"
                    />
                  </BaseButton>
                )

              default:
                return null
            }
          }
        })()}
      />

      <SectionBase title="Thông tin sự kiện">
        <BaseSpace>
          <BaseRow gutter={[16, 16]} align={'top'} justify={'start'}>
            <BaseCol xl={6}>
              <BaseSpace size={4}>
                <BaseText
                  children={'Tên sự kiện'}
                  fontWeight="medium"
                  fontSize="xs"
                  opacity="0.7"
                />
                <BaseText
                  children={event?.name ?? '-'}
                  fontWeight="semibold"
                  fontSize="xs"
                />
              </BaseSpace>
            </BaseCol>
            <BaseCol xl={6}>
              <BaseSpace size={4}>
                <BaseText
                  children={'Nhóm sự kiện'}
                  fontWeight="medium"
                  fontSize="xs"
                  opacity="0.7"
                />
                <BaseText
                  children={
                    event?.group
                      ? DefineGroupEventEnum[event?.group]?.text
                      : '-'
                  }
                  fontWeight="semibold"
                  colorText="statesGreenColor"
                  fontSize="xs"
                />
              </BaseSpace>
            </BaseCol>
            <BaseCol xl={6}>
              <BaseSpace size={4}>
                <BaseText
                  children={'Loại sự kiện'}
                  fontWeight="medium"
                  fontSize="xs"
                  opacity="0.7"
                />
                <BaseText
                  children={
                    event?.type ? DefineTypeEventEnum[event?.type]?.text : '-'
                  }
                  fontWeight="semibold"
                  colorText="statesGreenColor"
                  fontSize="xs"
                />
              </BaseSpace>
            </BaseCol>
            {event?.link ? (
              <BaseCol xl={6}>
                <BaseSpace size={8}>
                  <BaseText
                    children={'Link bài viết'}
                    fontWeight="medium"
                    fontSize="xs"
                    opacity="0.7"
                  />
                  <a href={event?.link} target="_blank">
                    <BaseText
                      children={event?.link}
                      fontWeight="medium"
                      fontSize="xs"
                      colorText="collapseBackgroundColor"
                    />
                  </a>
                </BaseSpace>
              </BaseCol>
            ) : null}
          </BaseRow>
          <BaseRow gutter={[20, 20]} align={'top'} justify={'start'}>
            {event?.register_started && event?.register_end ? (
              <BaseCol xl={6}>
                <BaseSpace size={4}>
                  <BaseText
                    children={'Thời gian đăng kí'}
                    fontWeight="medium"
                    fontSize="xs"
                    opacity="0.7"
                  />
                  <BaseText
                    children={`${DateUtil?.formatDate(
                      event?.register_started ?? '',
                      FORMAT_DD_MM_YYYY_HH_MM,
                    )} - ${DateUtil?.formatDate(
                      event?.register_end ?? '',
                      FORMAT_DD_MM_YYYY_HH_MM,
                    )}`}
                    fontWeight="semibold"
                    fontSize="xs"
                  />
                </BaseSpace>
              </BaseCol>
            ) : null}
            {event?.date_started && event?.date_end ? (
              <BaseCol xl={6}>
                <BaseSpace size={4}>
                  <BaseText
                    children={'Thời gian diễn ra sự kiện'}
                    fontWeight="medium"
                    fontSize="xs"
                    opacity="0.7"
                  />
                  <BaseText
                    children={`${DateUtil?.formatDate(
                      event?.date_started ?? '',
                      FORMAT_DD_MM_YYYY_HH_MM,
                    )} - ${DateUtil?.formatDate(
                      event?.date_end ?? '',
                      FORMAT_DD_MM_YYYY_HH_MM,
                    )}`}
                    fontWeight="semibold"
                    fontSize="xs"
                  />
                </BaseSpace>
              </BaseCol>
            ) : null}

            {event?.status ? (
              <BaseCol xl={6}>
                <BaseSpace size={4}>
                  <BaseText
                    children={'Trạng thái sự kiện'}
                    fontWeight="medium"
                    fontSize="xs"
                    opacity="0.7"
                  />
                  <StatusEventWrapper $status={event?.status}>
                    <BaseText
                      children={t(DefineEventStatus[event?.status]?.keyI18n)}
                      colorText={(() => {
                        switch (event?.status) {
                          case EventStatusEnum.NOT_OPEN:
                            return 'otherBlueColor'
                          case EventStatusEnum.REGISTRATION:
                            return 'otherBlueColor'
                          case EventStatusEnum.REGISTRATION_COMPLETED:
                            return 'statesOrangeColor'
                          case EventStatusEnum.IN_PROGRESS:
                            return 'statesGreenColor'
                          case EventStatusEnum.FINISHED:
                            return 'statesRedColor'
                        }
                      })()}
                      fontWeight="medium"
                    />
                  </StatusEventWrapper>
                </BaseSpace>
              </BaseCol>
            ) : null}
          </BaseRow>

          <BaseSpace size={4}>
            <BaseText
              children={'Mô tả'}
              fontWeight="medium"
              fontSize="xs"
              opacity="0.7"
            />
            <BaseText
              children={event?.desc ?? '-'}
              fontWeight="medium"
              fontSize="xs"
            />
          </BaseSpace>
        </BaseSpace>
      </SectionBase>

      {isEqual(userInfo?.role, RoleEnum.ADMIN) ? (
        <>
          {event && !isEqual(event?.type, TypeEventEnum.NEWS) ? (
            <ExpandedRowRender event={event} />
          ) : null}

          {isEqual(event?.type, TypeEventEnum.ACTIVITY) ? (
            <SectionBase title="Thống kê">
              <BaseSpace>
                <ListMemberPrizeLayout event={event} />
                <ListUnitPrizeLayout event={event} />
              </BaseSpace>
            </SectionBase>
          ) : null}
        </>
      ) : null}
    </BaseSpace>
  )
}

export default DetailEventContainer
