/* eslint-disable @typescript-eslint/no-explicit-any */
export const DashboardInfoKeys = {
  allDashboardInfos: ['AllDashboardInfos'] as const,
  dashboardInfos: () =>
    [...DashboardInfoKeys.allDashboardInfos, 'dashboardInfos'] as const,
  dashboardInfo: (id?: string, params?: any) => [...DashboardInfoKeys.dashboardInfos(), {id, params}] as const,

  allDashboardUsers: ['AllDashboardUsers'] as const,
  dashboardUsers: () =>
    [...DashboardInfoKeys.allDashboardUsers, 'dashboardUsers'] as const,
  dashboardUser: (unit?: string) =>
    [...DashboardInfoKeys.dashboardUsers(), { unit }] as const,

  allDashboardEmployees: ['AllDashboardEmployees'] as const,
  dashboardEmployees: () =>
    [...DashboardInfoKeys.allDashboardEmployees, 'dashboardEmployees'] as const,
  dashboardEmployee: (id?: string) =>
    [...DashboardInfoKeys.dashboardEmployees(), { id }] as const,
}
