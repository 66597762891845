export const EventEndPoint = {
  GET_DASHBOARD: 'api/v1/dashboard',
  GET_TOP_10_MEMBER_PRIZE: 'api/v1/event/member-prize/top-10',
  GET_TOP_10_MEMBER_JOIN_EVENT_MOST: 'api/v1/event/member/top-10',

  GET_TOP_10_UNIT_PRIZE: 'api/v1/event/unit-prize/top-10',
  GET_TOP_10_UNIT_JOIN_EVENT_MOST: 'api/v1/event/unit/top-10',

  GET_TOTAL_EVENT_BY_TYPE: 'api/v1/event/by-type',
  GET_TOTAL_EVENT_BY_GROUP: 'api/v1/event/group/total',

  GET_MEMBER_BY_UNIT: 'api/v1/doanvien/donvi/:donvi',
  GET_TOP_10_MEMBER_PRIZE_BY_UNIT: 'api/v1/event/unit/:donvi/member/top-10',
  GET_TOP_10_MEMBER_JOIN_EVENT_MOST_BY_UNIT: 'api/v1/event/unit/:donvi/member/top-10',

  GET_UNIT_BY_MEMBER: 'api/v1/doanvien/:maso_doanvien/donvi',
  GET_MY_PRIZE: 'api/v1/event/member/:maso_doanvien/prize',
  GET_MY_ACTION: 'api/v1/event/member/:maso_doanvien/action',
  GET_MY_ACTION_REGISTER: 'api/v1/event/member/:maso_doanvien/action/register',
}
