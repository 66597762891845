import { PageTitle } from 'app/components/common/PageTitle'
import { TumManagementByUnitContainer } from 'app/containers/TumManagementByUnit'
import { isEqual } from 'lodash'
import { memo } from 'react'

const TumManagementByUnitCpn = () => {
  return (
    <>
      <PageTitle>{'Quản lý công đoàn viên'}</PageTitle>
      <TumManagementByUnitContainer />
    </>
  )
}
export const TumManagementByUnitPage = memo(TumManagementByUnitCpn, isEqual)
