import { CollapseProps, Collapse as AntdCollapse } from 'antd'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { BORDER_RADIUS } from 'parkway-web-common'
import styled from 'styled-components'

export type BaseCollapseProps = CollapseProps

interface BaseCollapseInterface extends React.FC<BaseCollapseProps> {
  Panel: typeof AntdCollapse.Panel
}

export const BaseCollapse: BaseCollapseInterface = props => {
  return <CollapseWrapper {...props} />
}

const CollapseWrapper = styled(AntdCollapse)`
  border: 1px solid ${convertedVariables.neutralBlack9Color};
  background-color: transparent !important;

  .ant-collapse-item {
    border-bottom: 0px;
    border-radius: ${BORDER_RADIUS}!important;
  }

  .ant-collapse-content {
    border-top: 1px solid ${convertedVariables.neutralBlack9Color};
    background-color: transparent !important;
  }

  .ant-collapse-header {
    background-color: ${convertedVariables.backgroundColor} !important;
    border-radius: ${BORDER_RADIUS}!important;
    align-items: center !important;
  }

  .ant-collapse-header[aria-expanded='true'] {
    border-radius: ${BORDER_RADIUS}!important;
  }
`

BaseCollapse.Panel = AntdCollapse.Panel
