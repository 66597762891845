import { BaseRow } from 'app/components/common/BaseRow'
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  PADDING,
  colors,
} from 'parkway-web-common'
import styled from 'styled-components'
import { BaseTableWrapper } from '../BaseTableReport/styles'
import { StatusTableType } from './hook'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseModal } from 'app/components/common/BaseModal'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseDivider } from 'app/components/common/BaseDivider'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { convertedVariables } from 'app/styles/themes/themeVariables'

export const BaseTableManagementWrapper = styled(BaseTableWrapper)`
  .ant-table-thead > tr > th {
    border: 0.5px solid var(--neutral-black-2-color) !important;
    text-align: left !important;
    font-size: 12px !important;
    font-weight: ${FONT_WEIGHT.bold};
  }
  & .ant-pagination-item {
    border-radius: ${BORDER_RADIUS};
    min-width: 1.75rem;
    height: 1.75rem;
    line-height: 1.75rem;
  }

  .ant-table-body {
    min-height: 480px;
    max-height: 550px !important;
  }

  .ant-table-row {
    &.ant-table-row-level-1 {
      .column-children {
        padding-left: calc(16px + 22px);
      }
    }
    &.ant-table-row-level-2 {
      .column-children {
        padding-left: calc(16px + 2 * 22px);
      }
    }
    &.ant-table-row-level-3 {
      .column-children {
        padding-left: calc(16px + 3 * 22px);
      }
    }
    &.ant-table-row-level-4 {
      .column-children {
        padding-left: calc(16px + 4 * 22px);
      }
    }
    &.ant-table-row-level-5 {
      .column-children {
        padding-left: calc(16px + 5 * 22px);
      }
    }
    &.ant-table-row-level-6 {
      .column-children {
        padding-left: calc(16px + 6 * 22px);
      }
    }
    &.ant-table-row-level-7 {
      .column-children {
        padding-left: calc(16px + 7 * 22px);
      }
    }
  }
`

export const BaseManagementWrapper = styled.div`
  padding: 0px ${PADDING.md};
  background-color: ${convertedVariables.neutralBlack9Color};
`

export const ValueTableWrapper = styled(BaseRow)``

export const TextMain = styled.div`
  color: var(--primary-color);
  font-weight: ${FONT_WEIGHT.medium};
  font-size: ${FONT_SIZE.xs};
`

export const TextLink = styled.div`
  color: #216ae2; // need change
  font-weight: ${FONT_WEIGHT.semibold};
  font-size: ${FONT_SIZE.xs};
  cursor: pointer;
`

export const BaseText = styled.div`
  color: var(--main-2-color);
  font-weight: ${FONT_WEIGHT.medium};
  font-size: ${FONT_SIZE.xs};
  display: flex;
  gap: 4px;
`

export const BaseIcon = styled.span`
  display: flex;
  align-items: center;
`

export const StatusWrapper = styled.div<{
  status?: StatusTableType | string | number
}>`
  padding: ${PADDING.xxxxs} ${PADDING.md};
  min-width: 80px;
  display: flex;
  justify-content: center;
  border-radius: ${BORDER_RADIUS};
  background-color: ${props => {
    switch (props?.status) {
      case 'active': {
        return 'var(--states-blue-light-color)'
      }
      case 'deleted': {
        return 'var(--states-red-light-color)'
      }
      case 'error': {
        return 'var(--states-orange-light-color)'
      }
      default:
        return 'var(--states-orange-light-color)'
    }
  }};
  color: ${props => {
    switch (props?.status) {
      case 'active': {
        return 'var(--other-blue-color)'
      }
      case 'deleted': {
        return 'var(--states-red-color)'
      }
      case 'error': {
        return 'var(--states-orange-color)'
      }
      default:
        return 'var(--states-orange-color)'
    }
  }};
  font-weight: ${FONT_WEIGHT.bold};
  font-size: ${FONT_SIZE.xxs};
`

export const RootWrapper = styled(BaseCol)`
  background-color: ${colors.white};
  padding: ${PADDING.md};
  border-radius: ${BORDER_RADIUS};
`

export const SearchModal = styled(BaseModal)`
  border-radius: ${BORDER_RADIUS};

  & .ant-modal-body {
    padding: 0;
  }
`

export const Btn = styled(BaseButton)`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ButtonCreateAction = styled(BaseButton)`
  background-color: var(--states-orange-color);
  color: var(--background-color);
  transition: all 0.5s ease;
  &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    background-color: var(--background-color);
    color: var(--states-orange-color);
    border-color: var(--states-orange-color);
    transition: all 0.5s ease;
  }
`
export const LabelWrapper = styled(BaseRow)`
  color: var(--main-2-color);
`

export const RequireDot = styled.div`
  color: var(--states-red-color);
`
export const DashLine = styled(BaseDivider)``

export const TitleForm = styled.div`
  color: var(--primary-color);
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.bold};
`

export const FormItem = styled(BaseForm.Item)``

export const FormInput = styled(BaseInput)``

export const SelectBaseStyled = styled(BaseSelect)``
