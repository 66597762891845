/* eslint-disable @typescript-eslint/no-explicit-any */
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCheckbox } from 'app/components/common/BaseCheckbox'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import {
  BaseModalComponent,
  IRefModal,
} from 'app/components/common/ModalComponent'
import { DoanVien } from 'app/containers/TumManagement/type'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { useGetDoanVien } from 'app/react-query/hooks-old/useGetUser'
import { useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { IDataTumActivity } from '../../type'
import { useCreateEventContext } from '../../context'
import { TypeEventEnum } from 'app/api/event/model'
import { PlusOutlined } from '@ant-design/icons'

interface IProps {
  data?: IDataTumActivity[]
  onSubmit?: (data: IDataTumActivity[]) => void
}

export const ModalSelectTum = ({ data, onSubmit }: IProps) => {
  const { eventType } = useCreateEventContext()
  const modalRef = useRef<IRefModal>({})
  const [dataSelected, setDataSelected] = useState<IDataTumActivity[]>([])
  const [search, setSearch] = useState<string>('')

  const { data: listTums, isLoading } = useGetDoanVien()

  const onSelected = (record: IDataTumActivity) => {
    const isSelected = dataSelected.some(
      item => item?.maso_doanvien === record?.maso_doanvien,
    )

    if (isSelected) {
      setDataSelected(
        dataSelected.filter(
          item => item?.maso_doanvien !== record?.maso_doanvien,
        ),
      )
    } else {
      setDataSelected([...dataSelected, record])
    }
  }

  const openModal = () => {
    modalRef?.current?.open?.()
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setDataSelected(data ?? [])
    }, 1000)

    return () => clearTimeout(timer)
  }, [data])

  const dataTable = useMemo(() => {
    return (listTums as DoanVien[])?.filter(
      item =>
        item?.hovaten?.toLowerCase().includes(search.toLowerCase()) ||
        item?.email?.toLowerCase().includes(search.toLowerCase()),
    )
  }, [search, listTums])

  const handleSubmit = () => {
    onSubmit?.(dataSelected)
    modalRef?.current?.hide?.()
    setDataSelected([])
  }

  const titleModal = useMemo(() => {
    switch (eventType) {
      case TypeEventEnum.TRAINING:
      case TypeEventEnum.ACTIVITY:
        return 'Chọn đoàn viên'
      case TypeEventEnum.CARE:
        return 'Chọn đối tượng được chăm lo'
      default:
        return 'Chọn đoàn viên'
    }
  }, [eventType])

  const textOpenModal = useMemo(() => {
    return dataSelected?.length ? `Cập nhật` : 'Thêm'
  }, [dataSelected])

  return (
    <BaseModalComponent
      titleModal={titleModal}
      ref={modalRef}
      buttonOpenModal={
        <BaseButton
          icon={data?.length ? null : <PlusOutlined rev={undefined} />}
          onClick={openModal}
        >
          {textOpenModal}
        </BaseButton>
      }
      widthModal={800}
      handleSubmit={handleSubmit}
      renderContent={
        <BaseSpace>
          <BaseFormItem label={'Tìm kiếm'}>
            <BaseInput
              value={search}
              onChange={e => setSearch(e?.target?.value)}
              placeholder="Tìm kiếm theo tên, email"
            />
          </BaseFormItem>

          <BaseText children={'Danh sách'} fontWeight="medium" fontSize="xs" />
          <BaseTableManagement
            columns={[
              convertColumnTable<IDataTumActivity>({
                key: 'hovaten',
                title: 'Họ và tên',
                render: (text: string, record) => {
                  return (
                    <BaseText
                      children={`${text} ${
                        record?.trangthai ? '' : '(Dừng công tác)'
                      }`}
                      fontWeight="medium"
                      colorText={
                        record?.trangthai ? 'primaryColor' : 'statesRedColor'
                      }
                    />
                  )
                },
              }),
              convertColumnTable<IDataTumActivity>({
                key: 'email',
                title: 'Email',
                render: (text: string, record) => {
                  return (
                    <BaseText
                      children={text}
                      fontWeight="medium"
                      colorText={
                        record?.trangthai ? 'primaryColor' : 'statesRedColor'
                      }
                    />
                  )
                },
              }),
              {
                key: 'maso_doanvien',
                title: '',
                width: '10%',

                dataIndex: 'maso_doanvien',
                render: (_, record: IDataTumActivity) => {
                  const isSelected = dataSelected.some(
                    item => item?.maso_doanvien === record?.maso_doanvien,
                  )
                  return (
                    <BaseCheckbox
                      value={isSelected}
                      checked={isSelected}
                      onClick={() => onSelected(record)}
                      disabled={!record?.trangthai}
                    />
                  )
                },
              },
            ]}
            dataSource={(dataTable as DoanVien[]) ?? []}
            className="row-overwrite-style"
            scroll={{ x: true }}
            loading={isLoading}
            pagination={{
              pageSize: 5,
            }}
          />
        </BaseSpace>
      }
    />
  )
}

const BaseFormItem = styled(BaseForm.Item)`
  margin-bottom: 0px;
`
