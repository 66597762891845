import TextArea from 'antd/es/input/TextArea'
import { StatusMemberRegisterWaiting } from 'app/api/event/model'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import {
  BaseModalComponent,
  IRefModal,
} from 'app/components/common/ModalComponent'
import { notificationController } from 'app/controllers/notification-controller'
import {
  useChangeStatusMemberRegister,
  useGetMemberRegisteredByAction,
} from 'app/react-query/hook/event'
import { useRef } from 'react'

export const ModalRejectMember = ({ id }: { id?: number }) => {
  const refModal = useRef<IRefModal>({})

  const [form] = BaseForm.useForm<{ reason?: string }>()

  const { mutateAsync: mutateAsyncUpdate, isLoading } =
    useChangeStatusMemberRegister()

  const { refetch } = useGetMemberRegisteredByAction({
    id,
    status: StatusMemberRegisterWaiting.WAITING,
  })

  const handleSubmit = async (values?: { reason?: string }) => {
    if (!id) return
    const res = await mutateAsyncUpdate({
      id,
      status: StatusMemberRegisterWaiting.REJECTED,
      reason: values?.reason,
    })
    if (res) {
      refModal?.current?.hide?.()
      notificationController?.success({
        message: 'Từ chối đăng kí thành công',
      })
      refetch?.()
    }
  }

  return (
    <BaseModalComponent
      ref={refModal}
      titleModal="Từ chối đăng kí"
      buttonOpenModal={
        <BaseButton
          children="Từ chối"
          onClick={() => refModal.current?.open?.()}
          type="text"
        />
      }
      handleSubmit={form?.submit}
      isLoadingConfirm={isLoading}
      renderContent={
        <BaseForm form={form} onFinish={handleSubmit}>
          <BaseSpace size={8}>
            <BaseForm.Item name={'reason'} required label={'Lý do'}>
              <TextArea placeholder="Vui lòng nhập lý do từ chối" />
            </BaseForm.Item>
            <BaseText
              children={`Lưu ý: Bạn cần nhập lý do từ chối để đoàn viên biết tại sao họ không được phê duyệt`}
              colorText="statesOrangeColor"
              opacity="0.8"
            />
          </BaseSpace>
        </BaseForm>
      }
    />
  )
}
