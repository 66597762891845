import { message } from 'antd'
import { requestGetActionById } from 'app/api/event'
import { IActionEvent } from 'app/api/event/model'
import { ENV_CONFIG } from 'app/common/config'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseEmpty } from 'app/components/common/BaseEmpty'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { InputPassword } from 'app/components/common/inputs/InputPassword'
import { Loading } from 'app/components/common/Loading'
import { SectionBase } from 'app/components/common/SectionBase'
import { FORMAT_DD_MM_YYYY_HH_MM } from 'app/constant'
import { useCheckInActionByMember } from 'app/react-query/hook/event'
import axios from 'axios'
import { isEqual } from 'lodash'
import { DateUtil, PADDING } from 'parkway-web-common'
import { memo, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components'

const CheckInAction = () => {
  const { id } = useParams()
  const [form] = BaseForm.useForm()
  const { mutateAsync } = useCheckInActionByMember()

  const [action, setAction] = useState<IActionEvent>({})

  const [loading, setLoading] = useState(false)

  const getAction = async () => {
    try {
      setLoading(true)
      if (!id) return
      const res = await requestGetActionById(id as unknown as number)

      if (res) {
        setAction(res)
      }
    } catch (error) {
      console.log('error', error)
      message.error('Lỗi không xác định')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getAction()
  }, [id])

  const handleSubmit = async (values: { email: string; password: string }) => {
    try {
      const { data } = await axios.post(
        `${ENV_CONFIG.API_ENDPOINT}/api/auth/login`,
        {
          email: values?.email,
          password: values?.password,
        },
      )

      if (data?.maso_doanvien) {
        await mutateAsync({
          actionEventId: action?.id,
          checkedIn: true,
          doanvienId: data?.maso_doanvien,
        })

        form?.resetFields()

        message.success('Check in thành công')
      }
    } catch (error) {
      console.log('error', error)
      message.error('Lỗi không xác định, vui lòng liên hệ ban tổ chức')
    } finally {
      setLoading(false)
    }
  }

  return (
    <RootWrapper>
      {id ? (
        <BaseForm form={form} onFinish={handleSubmit}>
          <BaseSpace>
            <SectionBase title="Thông tin hoạt động">
              <BaseSpace>
                <BaseText
                  children={action?.name}
                  fontWeight="semibold"
                  fontSize="xs"
                />
                <BaseSpace size={8}>
                  <BaseText
                    children={'Thời gian diễn ra hoạt động'}
                    fontWeight="medium"
                    opacity="0.7"
                  />
                  <BaseText
                    children={`${DateUtil.formatDate(
                      action?.event?.date_started ?? '',
                      FORMAT_DD_MM_YYYY_HH_MM,
                    )} - ${DateUtil.formatDate(
                      action?.event?.date_end ?? '',
                      FORMAT_DD_MM_YYYY_HH_MM,
                    )}`}
                    colorText="statesGreenColor"
                  />
                </BaseSpace>
                <BaseSpace size={8}>
                  <BaseText
                    children={'Hoạt động của sự kiện'}
                    fontWeight="medium"
                    opacity="0.7"
                  />
                  <BaseText
                    children={action?.event?.name}
                    fontWeight="semibold"
                    fontSize="xs"
                  />
                </BaseSpace>
              </BaseSpace>
            </SectionBase>

            <SectionBase title="Đăng nhâp để check in">
              <BaseSpace size={8}>
                <FormItem
                  name={'email'}
                  label={'Email'}
                  required
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng nhập email',
                    },
                  ]}
                >
                  <BaseInput placeholder="Vui lòng nhập email" />
                </FormItem>

                <FormItem
                  name={'password'}
                  label={'Mật khẩu'}
                  required
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng nhập mật khẩu',
                    },
                  ]}
                >
                  <InputPassword placeholder="Vui lòng nhập mật khẩu" />
                </FormItem>

                <BaseRow justify={'center'}>
                  <BaseButton
                    onClick={form.submit}
                    type="primary"
                    loading={loading}
                  >
                    {'Check in sự kiện'}
                  </BaseButton>
                </BaseRow>
              </BaseSpace>
            </SectionBase>
          </BaseSpace>
        </BaseForm>
      ) : (
        <SectionBase>
          <BaseEmpty description={'Không có hoạt động nào có thể check in'} />
        </SectionBase>
      )}
      {loading ? <Loading isAbsolute size="small" /> : null}
    </RootWrapper>
  )
}

export const CheckInActionPage = memo(CheckInAction, isEqual)

const RootWrapper = styled.div`
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: ${PADDING.xxxl};
`

const FormItem = styled(BaseForm.Item)`
  margin-bottom: 0px !important;
`
