import { IMemberActionEvent } from 'app/api/event/model'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { SectionBase } from 'app/components/common/SectionBase'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { RenderValueTable } from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { IRewardActivity } from 'app/containers/EventManagement/CreateEvent/type'
import { useGetMemberOfPrize } from 'app/react-query/hook/event'
import { useMemo } from 'react'
import { useNavigate } from 'react-router'
import { ModalSelectTum } from '../ModalSelectTum'
import { MEMBER_DETAILS_PATH_2 } from 'app/components/router/route-path'

export const ChildRow = ({
  reward,
  actionId,
}: {
  reward: IRewardActivity
  actionId?: number
}) => {
  const {
    data: dataApi,
    refetch,
    isLoading,
  } = useGetMemberOfPrize({
    id: reward?.id ?? 0,
  })
  const navigate = useNavigate()

  const data = useMemo(() => {
    return dataApi?.map((item, index) => {
      return {
        ...item,
        key: index + 1,
        index: index + 1,
      }
    })
  }, [dataApi])

  return (
    <SectionBase>
      <BaseSpace>
        <BaseRow gutter={[16, 8]} align={'middle'} justify={'space-between'}>
          <BaseCol>
            <BaseText
              children={'Danh sách đạt giải'}
              fontWeight="semibold"
              fontSize="xs"
            />
          </BaseCol>
          <BaseCol>
            <ModalSelectTum
              actionId={actionId}
              data={data}
              refetch={refetch}
              reward={reward}
            />
          </BaseCol>
        </BaseRow>

        <BaseTableManagement
          dataSource={data}
          scroll={{ x: true }}
          pagination={{
            pageSize: 5,
          }}
          rowClassName="row-overwrite-style"
          loading={isLoading}
          columns={[
            convertColumnTable<{ index: number }>({
              key: 'index',
              title: 'STT',
              fixed: 'left',
              classNameWidthColumnOverwrite: 'number-column',
              render: (value: number) => (
                <BaseText children={value} opacity="0.7" fontWeight="medium" />
              ),
            }),

            convertColumnTable<IMemberActionEvent>({
              key: 'info',
              title: 'Họ và tên',
              fixed: 'left',
              classNameWidthColumnOverwrite: 'big-column',
              render: (_, record) => (
                <RenderValueTable
                  onClick={() =>
                    navigate(MEMBER_DETAILS_PATH_2, {
                      state: {
                        tumId: record?.maso_doanvien,
                      },
                    })
                  }
                  value={record?.info?.hovaten}
                  type="Link"
                />
              ),
            }),
            convertColumnTable<IMemberActionEvent>({
              key: 'info',
              title: 'Đơn vị',
              render: (_, record) => (
                <BaseText
                  children={record?.info?.donvi}
                  fontWeight="medium"
                />
              ),
            }),

            convertColumnTable<IMemberActionEvent>({
              key: 'info',
              title: 'CMND/CCCD',
              render: (_, record) => (
                <BaseText
                  children={record?.info?.email}
                  opacity="0.7"
                  fontWeight="medium"
                />
              ),
            }),
          ]}
        />
      </BaseSpace>
    </SectionBase>
  )
}
