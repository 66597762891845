import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { useGetDashboardEmployee } from 'app/react-query/hook/dashboard'
import { InfoUnitLayout, MyActionRegisterLayout } from './layouts'
import { MyActionLayout, MyPrizeLayout } from 'app/containers/Profile/layouts'

export const DashboardMemberLayout = () => {
  const { data, isLoading } = useGetDashboardEmployee()
  return (
    <BaseSpace>
      <BaseRow gutter={[16, 16]} align={'top'} justify={'space-between'}>
        <BaseCol xl={12}>
          <BaseSpace>
            <InfoUnitLayout unit={data?.donvi} isLoading={isLoading} />
            <MyPrizeLayout
              listPrize={data?.myPrize ?? []}
              isLoading={isLoading}
            />
          </BaseSpace>
        </BaseCol>
        <BaseCol xl={12}>
          <BaseSpace>
            <MyActionRegisterLayout
              listActionRegister={data?.myActionRegister ?? []}
              isLoading={isLoading}
            />
            <MyActionLayout
              listAction={data?.myAction ?? []}
              isLoading={isLoading}
            />
          </BaseSpace>
        </BaseCol>
      </BaseRow>
    </BaseSpace>
  )
}
