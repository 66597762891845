import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  AuthActionKey,
  LoginRequest,
  ResetPasswordRequest,
  SecurityCodePayload,
  SignUpRequest,
  requestPostLogin,
  requestPostResetPassword,
  requestPostSignUp,
  requestPostVerifySecurityCode,
} from 'app/api/auth'
import { IUser } from 'app/model/user.model'
import {
  getToken,
  refetchToken,
  setToken,
} from 'app/service/storage/storage-service'
import { ResponseType } from 'parkway-web-common'
import { getUserInfoOld } from './userOldSlice'

export interface AuthSlice {
  token?: string | null
}

const initialState: AuthSlice = {
  token: getToken(),
}

export const doLogin = createAsyncThunk(
  AuthActionKey.AUTH_ACTION_KEY,
  async (loginPayload: LoginRequest, { dispatch }) => {
    const response: ResponseType<IUser> = await requestPostLogin(loginPayload)
    const token = response?.data?.token
    setToken(token)
    dispatch(getUserInfoOld())
    return token
  },
)

export const doSignUp = createAsyncThunk(
  'auth/doSignUp',
  async (signUpPayload: SignUpRequest) => requestPostSignUp(signUpPayload),
)

export const doResetPassword = createAsyncThunk(
  'auth/doResetPassword',
  async (resetPassPayload: ResetPasswordRequest) =>
    requestPostResetPassword(resetPassPayload),
)

export const doVerifySecurityCode = createAsyncThunk(
  'auth/doVerifySecurityCode',
  async (securityCodePayload: SecurityCodePayload) =>
    requestPostVerifySecurityCode(securityCodePayload),
)

export const doLogout = createAsyncThunk('auth/doLogout', () => {
  refetchToken()
})

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(doLogin.fulfilled, (state, action) => {
      state.token = action.payload
    })
    builder.addCase(doLogout.fulfilled, state => {
      state.token = ''
    })
  },
})

export default authSlice.reducer
