// Message code define
export const AuthMessageCode = {
  AUTH_SUCCESS: '10001',
  AUTH_WRONG_USERNAME_PASSWORD: '10002',
}

// Action key
export const AuthActionKey = {
  AUTH_ACTION_KEY: 'auth/doLogin',
}

// Endpoint
export const AuthEndPoint = {
  LOGIN: 'account/login',
  SIGN_UP: 'auth/signUp',
  FORGOT_PASSWORD: 'auth/forgot-password',
  VERITY_CODE: 'auth/verify-security-code',
  NEW_PASSWORD: 'account/v1/user/change-password',
}
