/* eslint-disable @typescript-eslint/no-explicit-any */
import { ENV_CONFIG } from 'app/common/config'
import axios from 'axios'
import Cookies from 'js-cookie'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { Navigate, Outlet, useNavigate } from 'react-router-dom'

type Props = {
  handleData: (data: any) => void
}

const PrivateRoute = ({ handleData }: Props) => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)
  const [user, setUser] = useState(null)
  const checkAccessTokenInCookies = () => {
    const accessToken = Cookies.get('access_token') // Use your cookie name
    // TODO: Get check if the current access token matches the access token in the database.
    return !!accessToken // Check if access token exists
  }

  useEffect(() => {
    if (checkAccessTokenInCookies()) {
      axios
        .get(`${ENV_CONFIG.API_ENDPOINT}/api/v1/doanvien/info`, {
          headers: {
            token: Cookies.get('access_token') ?? '',
          },
        })
        .then(res => {
          const userData = res?.data
          if (userData) {
            handleData(userData)
            setUser(userData)
          } else {
            Cookies.remove('access_token')
          }
        })
        .catch(() => {
          toast.error('Xin Vui Lòng Đăng Nhập Lại')
          navigate('/login')
          Cookies.remove('access_token')
        })
        .finally(() => {
          setIsLoading(false)
        })
    } else {
      setIsLoading(false)
    }
  }, [])

  return (
    <>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <>{user ? <Outlet /> : <Navigate to={'/login'} />}</>
      )}
    </>
  )
}

export default PrivateRoute
