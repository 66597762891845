import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { Loading } from 'app/components/common/Loading'
import { SectionBase } from 'app/components/common/SectionBase'
import { DoanVien, JobTitleEnum } from 'app/containers/TumManagement/type'
import { useGetDoanVien } from 'app/react-query/hooks-old/useGetUser'
import { useMemo } from 'react'

export const JobTitleLayout = () => {
  const { data: doanvienData, isLoading: isLoadingDoanVien } = useGetDoanVien()
  const quanLy = useMemo(() => {
    const chuTichCongDoan: DoanVien[] = doanvienData?.filter((dv: DoanVien) => {
      return dv?.chucdanh
        ?.split('&')
        ?.includes(JobTitleEnum.CHAIRMAN_OF_THE_TRADE_UNION)
    })

    const phoChuTichCongDoan: DoanVien[] = doanvienData?.filter(
      (dv: DoanVien) => {
        return dv?.chucdanh
          ?.split('&')
          ?.includes(JobTitleEnum.VICE_CHAIRMAN_OF_THE_TRADE_UNION)
      },
    )

    const uyVienBanChapHanh: DoanVien[] = doanvienData?.filter(
      (dv: DoanVien) => {
        return dv?.chucdanh
          ?.split('&')
          ?.includes(JobTitleEnum.EXECUTIVE_COMMITTEE_MEMBER)
      },
    )

    const uyVienBanChapHanhDoanSo: DoanVien[] = doanvienData?.filter(
      (dv: DoanVien) => {
        return dv?.chucdanh
          ?.split('&')
          ?.includes(
            JobTitleEnum.EXECUTIVE_COMMITTEE_MEMBER_OF_TRADE_UNION_DEPARTMENT,
          )
      },
    )

    // Chủ nhiệm Ủy ban Kiểm tra Công đoàn Sở
    const chuNhiemUyBanKiemTraCongDoanSo: DoanVien[] = doanvienData?.filter(
      (dv: DoanVien) => {
        return dv?.chucdanh
          ?.split('&')
          ?.includes(JobTitleEnum.CHAIRMAN_OF_TRADE_UNION_INSPECTION_COMMITTEE)
      },
    )

    // Ủy viên Ủy ban Kiểm tra Công đoàn Sở
    const uyVienUyBanKiemTraCongDoan: DoanVien[] = doanvienData?.filter(
      (dv: DoanVien) => {
        return dv?.chucdanh
          ?.split('&')
          ?.includes(JobTitleEnum.MEMBER_OF_TRADE_UNION_INSPECTION_COMMITTEE)
      },
    )

    return {
      chuTichCongDoan,
      phoChuTichCongDoan,
      uyVienBanChapHanh,
      uyVienBanChapHanhDoanSo,
      chuNhiemUyBanKiemTraCongDoanSo,
      uyVienUyBanKiemTraCongDoan,
    }
  }, [doanvienData])

  return (
    <SectionBase>
      <BaseSpace>
        <BaseRow gutter={[16, 8]} align={'top'}>
          <BaseCol xl={8}>
            <BaseSpace size={8}>
              <BaseText
                children={'Chủ tịch công đoàn'}
                fontWeight="medium"
                fontSize="xs"
                opacity="0.7"
              />
              <BaseRow gutter={[16, 8]} align={'middle'}>
                {quanLy.chuTichCongDoan?.length ? (
                  quanLy.chuTichCongDoan?.map((dv: DoanVien, index: number) => (
                    <>
                      <BaseCol key={index}>
                        <BaseText
                          children={dv?.hovaten ?? '-'}
                          fontWeight="semibold"
                          fontSize="xs"
                        />
                      </BaseCol>

                      {index < quanLy.chuTichCongDoan.length - 1 ? (
                        <BaseCol>{'|'}</BaseCol>
                      ) : null}
                    </>
                  ))
                ) : (
                  <BaseCol>
                    <BaseText
                      children={'-'}
                      fontWeight="semibold"
                      fontSize="xs"
                    />
                  </BaseCol>
                )}
              </BaseRow>
            </BaseSpace>
          </BaseCol>
          <BaseCol xl={8}>
            <BaseSpace size={8}>
              <BaseText
                children={'Phó chủ tịch công đoàn'}
                fontWeight="medium"
                fontSize="xs"
                opacity="0.7"
              />
              <BaseRow gutter={[16, 8]} align={'middle'}>
                {quanLy.phoChuTichCongDoan?.length ? (
                  quanLy.phoChuTichCongDoan?.map(
                    (dv: DoanVien, index: number) => (
                      <>
                        <BaseCol key={index}>
                          <BaseText
                            children={dv?.hovaten ?? '-'}
                            fontWeight="semibold"
                            fontSize="xs"
                          />
                        </BaseCol>

                        {index < quanLy.phoChuTichCongDoan.length - 1 ? (
                          <BaseCol>{'|'}</BaseCol>
                        ) : null}
                      </>
                    ),
                  )
                ) : (
                  <BaseCol>
                    <BaseText
                      children={'-'}
                      fontWeight="semibold"
                      fontSize="xs"
                    />
                  </BaseCol>
                )}
              </BaseRow>
            </BaseSpace>
          </BaseCol>
          <BaseCol xl={8}>
            <BaseSpace size={8}>
              <BaseText
                children={'Uỷ viên ban chấp hành'}
                fontWeight="medium"
                fontSize="xs"
                opacity="0.7"
              />
              <BaseRow gutter={[16, 8]} align={'middle'}>
                {quanLy.uyVienBanChapHanh?.length ? (
                  quanLy.uyVienBanChapHanh?.map(
                    (dv: DoanVien, index: number) => (
                      <>
                        <BaseCol key={index}>
                          <BaseText
                            children={dv?.hovaten ?? '-'}
                            fontWeight="semibold"
                            fontSize="xs"
                          />
                        </BaseCol>

                        {index < quanLy.uyVienBanChapHanh.length - 1 ? (
                          <BaseCol>{'|'}</BaseCol>
                        ) : null}
                      </>
                    ),
                  )
                ) : (
                  <BaseCol>
                    <BaseText
                      children={'-'}
                      fontWeight="semibold"
                      fontSize="xs"
                    />
                  </BaseCol>
                )}
              </BaseRow>
            </BaseSpace>
          </BaseCol>
        </BaseRow>

        <BaseRow gutter={[16, 8]} align={'top'}>
          <BaseCol xl={8}>
            <BaseSpace size={8}>
              <BaseText
                children={'Ủy viên Ban chấp hành Công đoàn Sở'}
                fontWeight="medium"
                fontSize="xs"
                opacity="0.7"
              />
              <BaseRow gutter={[16, 8]} align={'middle'}>
                {quanLy.uyVienBanChapHanhDoanSo?.length ? (
                  quanLy.uyVienBanChapHanhDoanSo?.map(
                    (dv: DoanVien, index: number) => (
                      <>
                        <BaseCol key={index}>
                          <BaseText
                            children={dv?.hovaten ?? '-'}
                            fontWeight="semibold"
                            fontSize="xs"
                          />
                        </BaseCol>

                        {index < quanLy.uyVienBanChapHanhDoanSo.length - 1 ? (
                          <BaseCol>{'|'}</BaseCol>
                        ) : null}
                      </>
                    ),
                  )
                ) : (
                  <BaseCol>
                    <BaseText
                      children={'-'}
                      fontWeight="semibold"
                      fontSize="xs"
                    />
                  </BaseCol>
                )}
              </BaseRow>
            </BaseSpace>
          </BaseCol>
          <BaseCol xl={8}>
            <BaseSpace size={8}>
              <BaseText
                children={'Chủ nhiệm Ủy ban Kiểm tra Công đoàn Sở'}
                fontWeight="medium"
                fontSize="xs"
                opacity="0.7"
              />
              <BaseRow gutter={[16, 8]} align={'middle'}>
                {quanLy.chuNhiemUyBanKiemTraCongDoanSo?.length ? (
                  quanLy.chuNhiemUyBanKiemTraCongDoanSo?.map(
                    (dv: DoanVien, index: number) => (
                      <>
                        <BaseCol key={index}>
                          <BaseText
                            children={dv?.hovaten ?? '-'}
                            fontWeight="semibold"
                            fontSize="xs"
                          />
                        </BaseCol>

                        {index <
                        quanLy.chuNhiemUyBanKiemTraCongDoanSo.length - 1 ? (
                          <BaseCol>{'|'}</BaseCol>
                        ) : null}
                      </>
                    ),
                  )
                ) : (
                  <BaseCol>
                    <BaseText
                      children={'-'}
                      fontWeight="semibold"
                      fontSize="xs"
                    />
                  </BaseCol>
                )}
              </BaseRow>
            </BaseSpace>
          </BaseCol>
          <BaseCol xl={8}>
            <BaseSpace size={8}>
              <BaseText
                children={'Ủy viên Ủy ban Kiểm tra Công đoàn Sở'}
                fontWeight="medium"
                fontSize="xs"
                opacity="0.7"
              />
              <BaseRow gutter={[16, 8]} align={'middle'}>
                {quanLy.uyVienUyBanKiemTraCongDoan?.length ? (
                  quanLy.uyVienUyBanKiemTraCongDoan?.map(
                    (dv: DoanVien, index: number) => (
                      <>
                        <BaseCol key={index}>
                          <BaseText
                            children={dv?.hovaten ?? '-'}
                            fontWeight="semibold"
                            fontSize="xs"
                          />
                        </BaseCol>

                        {index <
                        quanLy.uyVienUyBanKiemTraCongDoan.length - 1 ? (
                          <BaseCol>{'|'}</BaseCol>
                        ) : null}
                      </>
                    ),
                  )
                ) : (
                  <BaseCol>
                    <BaseText
                      children={'-'}
                      fontWeight="semibold"
                      fontSize="xs"
                    />
                  </BaseCol>
                )}
              </BaseRow>
            </BaseSpace>
          </BaseCol>
        </BaseRow>
      </BaseSpace>

      {isLoadingDoanVien ? <Loading isAbsolute /> : null}
    </SectionBase>
  )
}
