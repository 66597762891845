import { MoreOutlined } from '@ant-design/icons'
import { StatusMemberRegisterWaiting } from 'app/api/event/model'
import { BaseButton } from 'app/components/common/BaseButton'
import { BasePopover } from 'app/components/common/BasePopover'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { DoanVien } from 'app/containers/TumManagement/type'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { useGetMemberRegisteredByAction } from 'app/react-query/hook/event'
import { isEqual } from 'lodash'
import { useMemo } from 'react'
import { ModalApproveMember } from '../ModalApproveMember'
import { ModalRejectMember } from '../ModalRejectMember'

interface IData {
  key?: number
  id?: number
  info?: DoanVien
  reason?: string
}

export const ActionRegisteredByStatusLayout = ({
  status,
  actionId,
}: {
  status: StatusMemberRegisterWaiting
  actionId?: number
}) => {
  const { data: response, isLoading } = useGetMemberRegisteredByAction({
    id: actionId,
    status,
  })

  const data = useMemo(() => {
    return response?.data?.map((item, index) => {
      return {
        ...item,
        key: index + 1,
        index: index + 1,
      }
    })
  }, [response])

  return (
    <BaseSpace>
      <BaseTableManagement
        dataSource={data}
        columns={[
          convertColumnTable<IData>({
            title: 'STT',
            key: 'key',
            classNameWidthColumnOverwrite: 'number-column',
            render: value => {
              return (
                <BaseText children={value} fontWeight="medium" opacity="0.7" />
              )
            },
          }),
          convertColumnTable<IData>({
            title: 'Tên đoàn viên',
            key: 'info',
            classNameWidthColumnOverwrite: 'big-column',
            render: (_, record) => {
              return (
                <BaseText
                  children={record?.info?.hovaten}
                  fontWeight="semibold"
                />
              )
            },
          }),
          convertColumnTable<IData>({
            title: 'Email',
            key: 'info',
            render: (_, record) => {
              return (
                <BaseText
                  children={record?.info?.email}
                  fontWeight="medium"
                  opacity="0.7"
                />
              )
            },
          }),
          convertColumnTable<IData>({
            title: 'Đơn vị',
            key: 'info',
            render: (_, record) => {
              return (
                <BaseText
                  children={record?.info?.donvi}
                  fontWeight="medium"
                  opacity="0.7"
                />
              )
            },
          }),

          ...(isEqual(status, StatusMemberRegisterWaiting.WAITING)
            ? [
                convertColumnTable<IData>({
                  title: '',
                  key: 'key',
                  width: '10%',
                  classNameWidthColumnOverwrite: 'number-column',
                  render: (_, record) => {
                    return (
                      <BasePopover
                        content={
                          <BaseSpace>
                            <ModalApproveMember id={record?.id} />
                            <ModalRejectMember id={record?.id} />
                          </BaseSpace>
                        }
                        trigger={'click'}
                      >
                        <BaseButton
                          type="text"
                          icon={<MoreOutlined rev={undefined} />}
                        />
                      </BasePopover>
                    )
                  },
                }),
              ]
            : []),

          ...(isEqual(status, StatusMemberRegisterWaiting.REJECTED)
            ? [
                convertColumnTable<IData>({
                  key: 'key',
                  title: 'Lý do từ chối',
                  render: (_, record) => {
                    return (
                      <BaseText
                        children={record?.reason ?? ''}
                        colorText="statesRedColor"
                        fontWeight="medium"
                      />
                    )
                  },
                }),
              ]
            : []),
        ]}
        loading={isLoading}
        scroll={{
          x: true,
        }}
      />
    </BaseSpace>
  )
}
