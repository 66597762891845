import styled, { css } from 'styled-components'
import { Form, FormItemProps } from 'antd'
import { FONT_SIZE, FONT_WEIGHT, PADDING, media } from 'parkway-web-common'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'

interface InternalFormItemProps {
  $isSuccess?: boolean
  $successText?: string
}

export type BaseFormItemProps = FormItemProps & {
  hiddenLabel?: boolean
}

export const FormItem = styled(Form.Item)<InternalFormItemProps>`
  .ant-form-item-label > label {
    color: var(--primary-color);
  }

  .ant-form-item-label > label > span.ant-form-item-optional {
    display: none;
  }

  .ant-input-group-addon:first-of-type {
    font-weight: 600;
    width: 5rem;

    color: var(--primary-color);

    .anticon,
    svg {
      font-size: 1.25rem;
    }

    @media only screen and (${media.md}) {
      width: 5.5rem;
      font-size: 1.125rem;
    }

    @media only screen and (${media.xl}) {
      font-size: 1.5rem;
    }
  }

  .ant-input-suffix .ant-btn {
    padding: 0;
    width: unset;
    height: unset;
    line-height: 1;
  }

  .ant-form-item-explain-error {
    display: flex;
    margin: 0px;
    padding-bottom: ${PADDING.xxxxs};
    line-height: 1;
    font-weight: ${FONT_WEIGHT.regular};

    // &:before {
    //   content: 'X';
    //   display: inline-flex;
    //   flex-shrink: 0;
    //   align-items: center;
    //   justify-content: center;
    //   margin: 0 0.25rem;
    //   color: var(--text-secondary-color);
    //   background: var(--error-color);
    //   border-radius: 50%;
    //   width: 1rem;
    //   height: 1rem;
    //   font-size: 0.5rem;
    // }

    &:before {
      display: none;
    }

    &:not(:first-of-type) {
      display: none;
    }
  }

  ${props =>
    props.$isSuccess &&
    css`
      .ant-input {
        &,
        &:hover {
          border-color: var(--success-color);
        }
      }

      .ant-form-item-control-input {
        display: block;

        &::after {
          content: '✓ ${props.$successText}';
          color: var(--success-color);
        }
      }
    `}

  &.ant-form-item-has-feedback .ant-form-item-children-icon {
    display: none;
  }

  .ant-picker-suffix {
    font-size: 1rem;
  }

  .ant-select-arrow {
    font-size: 1rem;
    width: unset;
    height: unset;
    top: 50%;
  }

  &.ant-form-item-has-error .ant-input,
  &.ant-form-item-has-error .ant-input-affix-wrapper,
  &.ant-form-item-has-error .ant-input:hover,
  &.ant-form-item-has-error .ant-input-affix-wrapper:hover {
    border-color: var(--error-color);
  }

  &.ant-form-item-has-success.ant-form-item-has-feedback .ant-input,
  &.ant-form-item-has-success.ant-form-item-has-feedback
    .ant-input-affix-wrapper,
  &.ant-form-item-has-success.ant-form-item-has-feedback .ant-input:hover,
  &.ant-form-item-has-success.ant-form-item-has-feedback
    .ant-input-affix-wrapper:hover {
    border-color: var(--success-color);
  }

  margin-bottom: 0.75rem;
  & .ant-form-item-control-input {
    min-height: 3rem;
  }

  & .ant-form-item-label {
    padding: 0px;
  }

  & label {
    color: var(--primary-color);
    font-size: ${FONT_SIZE.xs};
    line-height: 1.25rem;
  }

  &.ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
    padding-right: 1.5rem;
  }

  &.ant-form-item .ant-form-item-label > label::after {
    display: none !important;
  }

  .ant-form-item-label > label.ant-form-item-required::before {
    display: none !important;
  }
`

export const LabelWrapper = styled(BaseRow)`
  color: var(--main-2-color);
`

export const RequireDot = styled.div`
  color: var(--states-red-color);
`

export const BaseFormItem: React.FC<BaseFormItemProps> = ({
  label,
  hiddenLabel,
  required,
  ...props
}) => {
  return (
    <FormItem
      {...props}
      label={
        !hiddenLabel && (
          <LabelWrapper gutter={2} align={'top'}>
            <BaseCol>{label}</BaseCol>
            {!!required && (
              <BaseCol>
                <RequireDot>*</RequireDot>
              </BaseCol>
            )}
          </LabelWrapper>
        )
      }
    />
  )
}
