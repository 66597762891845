import { ApiClient } from 'app/service/api-service'
import { DoanVienRewardCreationAttributes } from './model'
import { RewardTumEndPoint } from './constant'

export const requestCreateRewardTum = (
  body?: DoanVienRewardCreationAttributes,
) =>
  ApiClient.Post({
    url: RewardTumEndPoint.CREATE_REWARD_TUM,
    body,
  })

export const requestGetRewardTumByDoanVien = (maso_doanvien: string) =>
  ApiClient.Get({
    url: RewardTumEndPoint.GET_REWARD_TUM_BY_DOAN_VIEN.replace(
      ':maso_doanvien',
      maso_doanvien,
    ),
    params: {},
  })

export const requestDeleteRewardTum = (id: string) =>
  ApiClient.Delete({
    url: RewardTumEndPoint.DELETE_REWARD_TUM.replace(':id', id.toString()),
    body: {},
  })
