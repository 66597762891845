import { PlusOutlined } from '@ant-design/icons'
import { message } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { SvgDeleteDesignIcon } from 'app/assets/svg-assets'
import { ENV_CONFIG } from 'app/common/config'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCheckbox } from 'app/components/common/BaseCheckbox'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseCollapse } from 'app/components/common/BaseCollapse'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import {
  BaseModalComponent,
  IRefModal,
} from 'app/components/common/ModalComponent'
import { BaseDatePicker } from 'app/components/common/pickers/BaseDatePicker'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { useGetAllDonVi } from 'app/react-query/hooks-old/useDonVi'
import { useGetDoanVienByUnitName } from 'app/react-query/hooks-old/useGetUser'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import axios from 'axios'
import { Dayjs } from 'dayjs'
import { isEmpty, isEqual } from 'lodash'
import { BORDER_RADIUS, FORMAT_DD_MM_YYYY, PADDING } from 'parkway-web-common'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { DonVi } from '../..'
import { DoanVien } from '../../../TumManagement/type'

export enum ActionTypeEnum {
  NHAP = 'NHẬP',
  TACH = 'TÁCH',
}

export const DefineActionTypeEnum = {
  [ActionTypeEnum.NHAP]: {
    title: 'Nhập',
    color: convertedVariables.otherBlueColor,
    backgroundColor: convertedVariables.statesBlueLightColor,
  },
  [ActionTypeEnum.TACH]: {
    title: 'Tách',
    color: convertedVariables.statesGreenColor,
    backgroundColor: convertedVariables.statesGreenLightColor,
  },
}

interface IDonviCustom {
  ten?: string
  diachi?: string
  ngaythanhlap?: Dayjs
  thongtindonvi?: string
  cdvIds?: string[]
}
interface IPayloadConsolidationOfUnits {
  tendonvi?: string
  status?: ActionTypeEnum
  diachi?: string
  ngaythanhlap?: string
  thongtindonvi?: string
  arrayData?: number[]
}
// layout sát nhập đơn vị
const ConsolidationOfUnitsLayout = () => {
  const [form] = BaseForm.useForm<IDonviCustom>()
  const [formOther] = BaseForm.useForm<IDonviCustom>()
  const modalRef = React.useRef<IRefModal>(null)
  const modalConfirmRef = React.useRef<IRefModal>(null)
  const [donviSelected, setDonviSelected] = React.useState<DonVi[]>([])
  const [loading, setLoading] = React.useState(false)

  const { data: donvi, isLoading, refetch } = useGetAllDonVi()

  const handleCreateUnit = async (values?: IDonviCustom) => {
    const payload: IPayloadConsolidationOfUnits = {
      diachi: values?.diachi,
      status: ActionTypeEnum.NHAP,
      ngaythanhlap: values?.ngaythanhlap?.format('DD-MM-YYYY'),
      tendonvi: values?.ten,
      thongtindonvi: values?.thongtindonvi ?? '',
      arrayData: donviSelected?.map(item => item.id ?? 0),
    }

    try {
      setLoading(true)
      const res = await axios.post(
        `${ENV_CONFIG.API_ENDPOINT}/api/v1/donvimoi/create`,
        payload,
      )
      if (res) {
        message.success('Nhập đơn vị thành công!')
        refetch()
        modalRef.current?.hide?.()
        modalConfirmRef?.current?.hide?.()
        form?.resetFields()
        formOther?.resetFields()
        setDonviSelected([])
      }
    } catch (error) {
      message.error('Nhập đơn vị thất bại!')
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const actionClickUnit = (record: DonVi) => {
    const index = donviSelected.findIndex(item => item?.id === record?.id)
    if (index === -1) {
      setDonviSelected([...donviSelected, record])
    } else {
      setDonviSelected(donviSelected.filter(item => item?.id !== record?.id))
    }
  }

  const data = useMemo(() => {
    return donvi?.map((item: DonVi, index: number) => ({
      ...item,
      index: index + 1,
      key: index + 1,
    }))
  }, [donvi])

  const isDisableSubmit = useMemo(() => {
    if (!donviSelected?.length) {
      return true
    }

    if (donviSelected?.length < 2) {
      return true
    }

    return false
  }, [donviSelected])

  const onFinishFirstModal = (values?: IDonviCustom) => {
    if (!values) return
    modalConfirmRef.current?.open?.()
    formOther.setFieldsValue(values)
  }

  return (
    <BaseModalComponent
      titleModal="Hoạt động hợp nhất đơn vị"
      titleOpenModal="Hoạt động hợp nhất đơn vị"
      ref={modalRef}
      borderButtonOpen={`1px solid ${convertedVariables.neutralBlack2Color}`}
      borderRadiusButtonOpen={BORDER_RADIUS}
      widthModal={1100}
      styleButtonOpen={{
        backgroundColor: convertedVariables.backgroundColor,
      }}
      showConfirmButton={false}
      otherButtonFooter={
        <BaseModalComponent
          ref={modalConfirmRef}
          titleModal="Xác nhận hợp nhất đơn vị"
          buttonOpenModal={
            <BaseButton onClick={form.submit}>{'Hợp nhất đơn vị'}</BaseButton>
          }
          isLoadingConfirm={loading}
          handleSubmit={formOther.submit}
          isDisableSubmit={isDisableSubmit}
          widthModal={800}
          renderContent={
            <BaseForm form={formOther} onFinish={handleCreateUnit}>
              <BaseSpace>
                <BaseRow gutter={[16, 8]} align={'middle'}>
                  <BaseCol>
                    <BaseText
                      children={'Bạn có thực sự muốn hợp nhất các đơn vị: '}
                      fontWeight="medium"
                      opacity="0.7"
                    />
                  </BaseCol>
                  {donviSelected?.map((item, index) => {
                    return (
                      <BaseCol key={index}>
                        <BaseText
                          children={item?.ten}
                          fontWeight="semibold"
                          fontSize="md"
                          colorText="statesGreenColor"
                          key={index}
                        />
                      </BaseCol>
                    )
                  })}
                </BaseRow>

                <BaseText
                  fontWeight="medium"
                  opacity="0.7"
                  children={'Thành đơn vị sau?: '}
                  style={{
                    textAlign: 'left',
                  }}
                />
                <BaseSpace size={8} className="content">
                  <BaseFormItem name={'ten'} label={'Tên đơn vị'} required>
                    <BaseInput disabled />
                  </BaseFormItem>
                  <BaseFormItem
                    name={'diachi'}
                    label={'Địa chỉ đơn vị'}
                    required
                  >
                    <BaseInput disabled />
                  </BaseFormItem>

                  <BaseFormItem
                    name={'ngaythanhlap'}
                    label={'Ngày thành lập đơn vị'}
                    required
                  >
                    <BaseDatePicker
                      format="DD-MM-YYYY"
                      style={{ width: '100%' }}
                      disabled
                    />
                  </BaseFormItem>

                  <BaseFormItem
                    name={'thongtindonvi'}
                    label={'Thông tin đơn vị'}
                  >
                    <TextArea rows={5} disabled />
                  </BaseFormItem>
                </BaseSpace>

                <BaseText
                  children={
                    'Cảnh báo: Sau khi hợp nhất, việc revert lại hành động vừa rồi sẽ phức tạp, bạn hãy chắc chắn thông tin hợp nhất đơn vị!'
                  }
                  colorText="statesOrangeColor"
                  style={{
                    fontStyle: 'italic',
                  }}
                  fontWeight="medium"
                />
              </BaseSpace>
            </BaseForm>
          }
        />
      }
      renderContent={
        <BaseForm onFinish={onFinishFirstModal} form={form}>
          <BaseRow align={'top'} gutter={[16, 8]} justify={'space-between'}>
            <BaseCol xl={12}>
              <BaseSpace>
                <BaseText
                  children={'Chọn đơn vị'}
                  fontWeight="medium"
                  fontSize="xs"
                />
                <BaseRow gutter={[8, 8]} align={'middle'}>
                  <BaseCol>
                    <BaseText
                      children={'Đã chọn:'}
                      fontWeight="semibold"
                      opacity="0.7"
                    />
                  </BaseCol>
                  {(donviSelected as DonVi[])?.map((item, index) => {
                    return (
                      <BaseCol key={index}>
                        <BaseText
                          children={item?.ten}
                          fontWeight="semibold"
                          colorText="statesGreenColor"
                        />
                      </BaseCol>
                    )
                  })}
                </BaseRow>
                <BaseTableManagement
                  loading={isLoading}
                  dataSource={data}
                  columns={[
                    {
                      key: 'index',
                      title: 'STT',
                      width: '15%',
                      render: (_, record) => {
                        return (
                          <BaseText
                            children={record?.index}
                            fontWeight="medium"
                            opacity="0.7"
                          />
                        )
                      },
                    },
                    convertColumnTable({
                      key: 'ten',
                      title: 'Tên đơn vị',
                      render: (_, record) => {
                        return (
                          <BaseText
                            children={record?.ten}
                            fontWeight="medium"
                            opacity="0.7"
                          />
                        )
                      },
                    }),

                    {
                      key: 'index',
                      title: '',
                      width: '10%',
                      render: (_, record) => {
                        return (
                          <BaseCheckbox
                            checked={record?.selected}
                            onChange={() => actionClickUnit(record)}
                          />
                        )
                      },
                    },
                  ]}
                  pagination={{
                    pageSize: 5,
                    total: data?.length,
                    showSizeChanger: false,
                  }}
                  scroll={{ x: true }}
                />
              </BaseSpace>
            </BaseCol>
            <BaseCol xl={12}>
              <NewInfoInUnit>
                <BaseSpace size={8} className="content">
                  <BaseText
                    children={'Thông tin đơn vị mới'}
                    fontWeight="medium"
                    fontSize="md"
                    opacity="0.7"
                  />
                  <Line />
                  <BaseFormItem
                    name={'ten'}
                    label={'Tên đơn vị'}
                    required
                    rules={[
                      {
                        required: true,
                        message: 'Vui lòng nhập tên đơn vị',
                      },
                    ]}
                  >
                    <BaseInput placeholder="Nhập tên đơn vị" />
                  </BaseFormItem>
                  <BaseFormItem
                    name={'diachi'}
                    label={'Địa chỉ đơn vị'}
                    required
                    rules={[
                      {
                        required: true,
                        message: 'Vui lòng nhập địa chỉ',
                      },
                    ]}
                  >
                    <BaseInput placeholder="Nhập địa chỉ" />
                  </BaseFormItem>

                  <BaseFormItem
                    name={'ngaythanhlap'}
                    label={'Ngày thành lập đơn vị'}
                    required
                    rules={[
                      {
                        required: true,
                        message: 'Vui lòng chọn ngày thành lập',
                      },
                    ]}
                  >
                    <BaseDatePicker
                      format="DD-MM-YYYY"
                      placeholder="Chọn ngày thành lập"
                      style={{ width: '100%' }}
                    />
                  </BaseFormItem>

                  <BaseFormItem
                    name={'thongtindonvi'}
                    label={'Thông tin đơn vị'}
                  >
                    <TextArea placeholder="Nhập thông tin đơn vị" rows={4} />
                  </BaseFormItem>
                </BaseSpace>
              </NewInfoInUnit>
            </BaseCol>
          </BaseRow>
        </BaseForm>
      }
    />
  )
}

interface IPayloadUnitSeparation {
  id?: number
  status?: ActionTypeEnum
  arrayData: {
    ten?: string
    diachi?: string
    ngaythanhlap?: string
    thongtindonvi?: string
    cdvids?: string[]
  }[]
}

// Unit separation activity
const UnitSeparationActivity = () => {
  const modalRef = React.useRef<IRefModal>(null)
  const modalConfirmRef = React.useRef<IRefModal>(null)
  const [donviSelected, setDonviSelected] = React.useState<DonVi | undefined>(
    undefined,
  )

  const { data: dataDoanVien, isLoading: isLoadingDoanVien } =
    useGetDoanVienByUnitName(donviSelected?.ten)

  const [newUnit, setNewUnit] = React.useState<IDonviCustom[]>([])
  const [loading, setLoading] = React.useState(false)

  const { data: donvi, isLoading, refetch } = useGetAllDonVi()

  const handleCreateUnit = async () => {
    const payload: IPayloadUnitSeparation = {
      id: donviSelected?.id,
      status: ActionTypeEnum.TACH,
      arrayData: newUnit?.map(item => ({
        ten: item?.ten,
        cdvids: item?.cdvIds ?? [],
        diachi: item?.diachi,
        ngaythanhlap: item?.ngaythanhlap?.format(FORMAT_DD_MM_YYYY),
        thongtindonvi: item?.thongtindonvi ?? '',
      })),
    }

    try {
      setLoading(true)
      const res = await axios.post(
        `${ENV_CONFIG.API_ENDPOINT}/api/v1/donvimoi/tach`,
        payload,
      )
      if (res) {
        message.success('Tách đơn vị thành công!')
        refetch()
        modalRef.current?.hide?.()
        modalConfirmRef.current?.hide?.()
        setDonviSelected(undefined)
        setNewUnit([])
      }
    } catch (error) {
      console.log(error)
      message.error('Tách đơn vị thất bại!')
    } finally {
      setLoading(false)
    }
  }

  const actionClickUnit = (record: DonVi) => {
    if (donviSelected?.id === record.id) {
      setDonviSelected(undefined)
    } else {
      setDonviSelected(record)
    }
  }

  const data = useMemo(() => {
    return donvi?.map((item: DonVi, index: number) => ({
      ...item,
      index: index + 1,
      key: index + 1,
    }))
  }, [donvi])

  const onChangeNameUnit = (value: string, index: number) => {
    const nameExist = newUnit.some(item => item.ten === value)

    if (nameExist) {
      return
    }

    const newUnitTemp = [...newUnit]
    newUnitTemp[index].ten = value
    setNewUnit(newUnitTemp)
  }

  const onChangeAddressUnit = (value: string, index: number) => {
    const newUnitTemp = [...newUnit]
    newUnitTemp[index].diachi = value
    setNewUnit(newUnitTemp)
  }

  const onChangeDateUnit = (value: Dayjs | null, index: number) => {
    if (!value) return

    const newUnitTemp = [...newUnit]
    newUnitTemp[index].ngaythanhlap = value
    setNewUnit(newUnitTemp)
  }

  const onChangeInfoUnit = (value: string, index: number) => {
    const newUnitTemp = [...newUnit]
    newUnitTemp[index].thongtindonvi = value
    setNewUnit(newUnitTemp)
  }

  const onDeleteUnit = (index: number) => {
    const newUnitTemp = [...newUnit]
    newUnitTemp.splice(index, 1)
    setNewUnit(newUnitTemp)
  }

  const addNewUnit = () => {
    setNewUnit([
      ...newUnit,
      {
        ten: '',
        diachi: '',
        ngaythanhlap: undefined,
        thongtindonvi: '',
      },
    ])
  }

  const onChangeCdvIds = (value: string[], index: number) => {
    // xoá nhưng ids mới được thêm này ra khỏi các đơn vị khác nếu có
    const newData = newUnit.map((item, idx) => {
      if (idx === index) {
        return { ...item, cdvIds: value }
      }
      return {
        ...item,
        cdvIds: item?.cdvIds?.filter(item => !value.includes(item)),
      }
    })
    setNewUnit(newData)
  }

  const isDisableAddNewUnit = useMemo(() => {
    return newUnit?.some(
      item => !item.ten || !item.diachi || !item.ngaythanhlap,
    )
  }, [newUnit])

  const isDisableSubmit = useMemo(() => {
    if (!donviSelected) {
      return true
    }

    if (!newUnit?.length) {
      return true
    }

    if (
      newUnit?.some(item => !item.ten || !item.diachi || !item.ngaythanhlap)
    ) {
      return true
    }

    // kiểm tra nếu danh sách công đoàn viên lớn hơn 0 và số công đoàn viên được chọn không bằng số công đoàn viên ban đầu thì disable
    if (dataDoanVien?.length) {
      const totalCdv = newUnit.reduce(
        (total, item) => total + (item?.cdvIds?.length ?? 0),
        0,
      )

      if (totalCdv !== dataDoanVien?.length) {
        return true
      }
    }

    return false
  }, [donviSelected, newUnit])

  const getListDoanVienNotUsing = (currentUnit?: IDonviCustom) => {
    const listWithoutCurrentUnit = newUnit?.filter(
      item => !isEqual(item?.ten, currentUnit?.ten),
    )

    const listCdvIdsUsed = listWithoutCurrentUnit.reduce(
      (total, item) => total.concat(item?.cdvIds ?? []),
      [] as string[],
    )

    return (dataDoanVien as DoanVien[])?.filter(
      item => !listCdvIdsUsed.includes(item?.maso_doanvien),
    )
  }

  return (
    <BaseModalComponent
      titleModal="Hoạt động tách đơn vị thành các đơn vị khác"
      titleOpenModal="Hoạt động tách đơn vị"
      ref={modalRef}
      borderButtonOpen={`1px solid ${convertedVariables.neutralBlack2Color}`}
      borderRadiusButtonOpen={BORDER_RADIUS}
      widthModal={1300}
      showConfirmButton={false}
      otherButtonFooter={
        <BaseModalComponent
          ref={modalConfirmRef}
          titleModal="Xác nhận tách đơn vị"
          buttonOpenModal={
            <BaseButton onClick={() => modalConfirmRef.current?.open?.()}>
              {'Tách đơn vị'}
            </BaseButton>
          }
          isDisableSubmit={isDisableSubmit}
          handleSubmit={handleCreateUnit}
          isLoadingConfirm={loading}
          widthModal={1200}
          renderContent={
            <BaseForm>
              <BaseSpace>
                <BaseText
                  children={'Bạn có thực sự muốn tách đơn vị: '}
                  fontWeight="medium"
                  opacity="0.7"
                  style={{
                    textAlign: 'left',
                  }}
                />
                <BaseText
                  children={donviSelected?.ten}
                  fontWeight="semibold"
                  fontSize="md"
                  colorText="statesGreenColor"
                  style={{
                    textAlign: 'left',
                  }}
                />

                <BaseText
                  fontWeight="medium"
                  opacity="0.7"
                  children={'Sang danh sách đơn vị sau?: '}
                  style={{
                    textAlign: 'left',
                  }}
                />
                {newUnit?.map((item, index) => {
                  return (
                    <BaseCollapse
                      key={index}
                      items={[
                        {
                          key: index + 1,
                          label: (
                            <BaseText
                              children={`Đơn vị: ${item?.ten}`}
                              fontWeight="semibold"
                              fontSize="xs"
                              style={{
                                textAlign: 'left',
                              }}
                            />
                          ),
                          children: (
                            <BaseSpace>
                              <BaseFormItem label={'Tên đơn vị'} required>
                                <BaseInput value={item.ten} disabled />
                              </BaseFormItem>
                              <BaseFormItem label={'Địa chỉ đơn vị'} required>
                                <BaseInput value={item.diachi} disabled />
                              </BaseFormItem>

                              <BaseFormItem
                                label={'Ngày thành lập đơn vị'}
                                required
                              >
                                <BaseDatePicker
                                  format="DD-MM-YYYY"
                                  placeholder="Chọn ngày thành lập"
                                  style={{ width: '100%' }}
                                  disabled
                                  value={item?.ngaythanhlap}
                                />
                              </BaseFormItem>

                              <BaseFormItem label={'Thông tin đơn vị'}>
                                <TextArea
                                  placeholder="Nhập thông tin đơn vị"
                                  value={item?.thongtindonvi ?? ''}
                                  rows={5}
                                  disabled
                                />
                              </BaseFormItem>

                              <BaseForm.Item
                                label={'Danh sách công đoàn viên'}
                                required
                              >
                                <BaseSelect
                                  mode="multiple"
                                  loading={isLoadingDoanVien}
                                  value={item?.cdvIds}
                                  options={getListDoanVienNotUsing(item)?.map(
                                    (item: DoanVien) => {
                                      return {
                                        label: item?.hovaten,
                                        value: item?.maso_doanvien,
                                      }
                                    },
                                  )}
                                  disabled
                                />
                              </BaseForm.Item>
                            </BaseSpace>
                          ),
                        },
                      ]}
                    />
                  )
                })}

                <BaseText
                  children={
                    'Cảnh báo: Sau khi tách, việc revert lại hành động vừa rồi sẽ phức tạp, bạn hãy chắc chắn thông tin tách đơn vị!'
                  }
                  colorText="statesOrangeColor"
                  style={{
                    fontStyle: 'italic',
                  }}
                  fontWeight="medium"
                />
              </BaseSpace>
            </BaseForm>
          }
        />
      }
      renderContent={
        <BaseForm>
          <RootWrapperModal
            align={'top'}
            gutter={[16, 8]}
            justify={'space-between'}
          >
            <BaseCol xl={12}>
              <BaseSpace>
                <BaseText
                  children={'Chọn đơn vị'}
                  fontSize="md"
                  fontWeight="medium"
                />
                <BaseTableManagement
                  loading={isLoading}
                  dataSource={data}
                  columns={[
                    {
                      key: 'index',
                      title: 'STT',
                      width: '15%',
                      render: (_, record) => {
                        return (
                          <BaseText
                            children={record?.index}
                            fontWeight="medium"
                            opacity="0.7"
                          />
                        )
                      },
                    },
                    convertColumnTable({
                      key: 'ten',
                      title: 'Tên đơn vị',
                      render: (_, record) => {
                        return (
                          <BaseText
                            children={record?.ten}
                            fontWeight="medium"
                            opacity="0.7"
                          />
                        )
                      },
                    }),
                    {
                      key: 'index',
                      title: '',
                      width: '10%',
                      render: (_, record) => {
                        const isSelected = donviSelected?.id === record.id
                        return (
                          <BaseCheckbox
                            checked={isSelected}
                            onChange={() => actionClickUnit(record)}
                            value={isSelected}
                          />
                        )
                      },
                    },
                  ]}
                  pagination={{
                    pageSize: 5,
                    total: data?.length,
                    showSizeChanger: false,
                  }}
                  scroll={{ x: true }}
                  className="row-overwrite-style"
                />
              </BaseSpace>
            </BaseCol>
            <BaseCol xl={12}>
              <BaseSpace>
                <BaseRow justify={'space-between'} align={'middle'} gutter={8}>
                  <BaseCol>
                    <BaseText
                      children={'Danh sách đơn vị mới'}
                      fontSize="md"
                      fontWeight="medium"
                    />
                  </BaseCol>
                  <BaseCol>
                    <BaseButton
                      icon={<PlusOutlined rev={undefined} />}
                      onClick={addNewUnit}
                      disabled={isDisableAddNewUnit}
                    >
                      {'Thêm đơn vị mới'}
                    </BaseButton>
                  </BaseCol>
                </BaseRow>
                <NewInfoInUnit>
                  {newUnit?.length ? null : (
                    <BaseText
                      children={'Chưa có đơn vị mới'}
                      fontWeight="medium"
                    />
                  )}
                  <BaseSpace>
                    {newUnit?.map((item, index) => {
                      return (
                        <BaseCollapse
                          key={index}
                          items={[
                            {
                              key: index + 1,
                              label: !isEmpty(item?.ten)
                                ? `Đơn vị: ${item?.ten}`
                                : `Thông tin đơn vị mới ${index + 1}`,
                              extra: (
                                <BaseRow>
                                  <BaseCol>
                                    <BaseButton
                                      icon={<SvgDeleteDesignIcon />}
                                      onClick={() => onDeleteUnit(index)}
                                    />
                                  </BaseCol>
                                </BaseRow>
                              ),

                              children: (
                                <BaseSpace>
                                  <BaseFormItem
                                    label={`Tên đơn vị ${
                                      !isEmpty(item?.ten)
                                        ? item?.ten
                                        : index + 1
                                    }`}
                                    required
                                  >
                                    <BaseInput
                                      placeholder={`Nhập tên đơn vị ${
                                        isEmpty(item?.ten)
                                          ? item?.ten
                                          : index + 1
                                      }`}
                                      onChange={e =>
                                        onChangeNameUnit(e.target.value, index)
                                      }
                                      value={item.ten}
                                    />
                                  </BaseFormItem>
                                  <BaseFormItem
                                    label={`Địa chỉ đơn vị ${
                                      !isEmpty(item?.ten)
                                        ? item?.ten
                                        : index + 1
                                    }`}
                                    required
                                  >
                                    <BaseInput
                                      placeholder={`Nhập địa chỉ ${
                                        isEmpty(item?.ten)
                                          ? item?.ten
                                          : index + 1
                                      }`}
                                      value={item.diachi}
                                      onChange={e =>
                                        onChangeAddressUnit(
                                          e.target.value,
                                          index,
                                        )
                                      }
                                    />
                                  </BaseFormItem>

                                  <BaseFormItem
                                    label={`Ngày thành lập đơn vị ${
                                      !isEmpty(item?.ten)
                                        ? item?.ten
                                        : index + 1
                                    }`}
                                    required
                                  >
                                    <BaseDatePicker
                                      format="DD-MM-YYYY"
                                      placeholder={`Chọn ngày thành lập ${
                                        isEmpty(item?.ten)
                                          ? item?.ten
                                          : index + 1
                                      }`}
                                      style={{ width: '100%' }}
                                      onChange={value =>
                                        onChangeDateUnit(value, index)
                                      }
                                      value={item?.ngaythanhlap}
                                    />
                                  </BaseFormItem>

                                  <BaseFormItem
                                    label={`Thông tin đơn vị ${
                                      !isEmpty(item?.ten)
                                        ? item?.ten
                                        : index + 1
                                    }`}
                                  >
                                    <TextArea
                                      placeholder={`Nhập thông tin đơn vị ${
                                        isEmpty(item?.ten)
                                          ? item?.ten
                                          : index + 1
                                      }`}
                                      onChange={e =>
                                        onChangeInfoUnit(e.target.value, index)
                                      }
                                      value={item?.thongtindonvi ?? ''}
                                      rows={4}
                                    />
                                  </BaseFormItem>

                                  <BaseForm.Item
                                    label={`Chọn công đoàn viên đc thuyên chuyển qua đơn vị ${
                                      !isEmpty(item?.ten)
                                        ? item?.ten
                                        : index + 1
                                    }`}
                                    required
                                  >
                                    <BaseSelect
                                      maxTagCount={1}
                                      mode="multiple"
                                      loading={isLoadingDoanVien}
                                      value={item?.cdvIds}
                                      options={getListDoanVienNotUsing(
                                        item,
                                      )?.map((item: DoanVien) => {
                                        return {
                                          label: item?.hovaten,
                                          value: item?.maso_doanvien,
                                        }
                                      })}
                                      onChange={value =>
                                        onChangeCdvIds(value as string[], index)
                                      }
                                      placeholder="Chọn danh sách công đoàn viên"
                                    />
                                  </BaseForm.Item>
                                </BaseSpace>
                              ),
                            },
                          ]}
                        />
                      )
                    })}
                  </BaseSpace>
                </NewInfoInUnit>
              </BaseSpace>
            </BaseCol>
          </RootWrapperModal>
        </BaseForm>
      }
    />
  )
}

const NewInfoInUnit = styled.div`
  padding: ${PADDING.md};
  border-radius: ${BORDER_RADIUS};
  background-color: ${convertedVariables.neutralBlack9Color};
  max-height: 500px;
  overflow-y: auto;
  .content {
    background-color: ${convertedVariables.backgroundColor};
    padding: ${PADDING.md};
    border-radius: ${BORDER_RADIUS};
  }
`

const ModalActionUnit = () => {
  const modalRef = React.useRef<IRefModal>(null)

  return (
    <BaseModalComponent
      titleModal="Hoạt động nhập/ tách đơn vị"
      titleOpenModal="Nhập/ tách đơn vị"
      ref={modalRef}
      borderButtonOpen={`1px solid ${convertedVariables.neutralBlack2Color}`}
      borderRadiusButtonOpen={BORDER_RADIUS}
      showConfirmButton={false}
      styleButtonOpen={{
        backgroundColor: convertedVariables.backgroundColor,
      }}
      renderContent={
        <BaseSpace>
          <BaseText
            children={'Vui lòng loại hoạt động nhập/tách'}
            opacity="0.7"
          />

          <BaseRow justify={'start'} align={'middle'} gutter={20}>
            <BaseCol>
              <ConsolidationOfUnitsLayout />
            </BaseCol>
            <BaseCol>
              <UnitSeparationActivity />
            </BaseCol>
          </BaseRow>
        </BaseSpace>
      }
    />
  )
}

export default ModalActionUnit

const Line = styled.div`
  height: 1px;
  background-color: ${convertedVariables.neutralBlack2Color};
  width: 100%;
`

const BaseFormItem = styled(BaseForm.Item)`
  margin-bottom: 0px;
`

const RootWrapperModal = styled(BaseRow)``
