/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApiClient } from 'app/service/api-service'
import { EventEndPoint } from './constant'

export const requestGetDashboardInfo = (params?: any) =>
  ApiClient.Get({
    url: EventEndPoint.GET_DASHBOARD,
    params: { params },
  })

export const requestGetTop10MemberPrize = (params?: any) =>
  ApiClient.Get({
    url: EventEndPoint.GET_TOP_10_MEMBER_PRIZE,
    params: { params },
  })

export const requestGetEventByType = (params?: any) =>
  ApiClient.Get({
    url: EventEndPoint.GET_TOTAL_EVENT_BY_TYPE,
    params: { params },
  })

export const requestGetEventByGroup = (params?: any) =>
  ApiClient.Get({
    url: EventEndPoint.GET_TOTAL_EVENT_BY_GROUP,
    params: { params },
  })

export const requestGetTop10MemberJoinEvent = (params?: any) =>
  ApiClient.Get({
    url: EventEndPoint.GET_TOP_10_MEMBER_JOIN_EVENT_MOST,
    params: { params },
  })

export const requestGetTop10UnitPrize = (params?: any) =>
  ApiClient.Get({
    url: EventEndPoint.GET_TOP_10_UNIT_PRIZE,
    params: { params },
  })

export const requestGetTop10UnitJoinEvent = (params?: any) =>
  ApiClient.Get({
    url: EventEndPoint.GET_TOP_10_UNIT_JOIN_EVENT_MOST,
    params: { params },
  })

export const requestGetMemberByUnit = (unit: string, params?: any) =>
  ApiClient.Get({
    url: EventEndPoint.GET_MEMBER_BY_UNIT?.replace(':donvi', unit),
    params: { params },
  })

export const requestGetTop10MemberPrizeByUnit = (unit: string, params?: any) =>
  ApiClient.Get({
    url: EventEndPoint.GET_TOP_10_MEMBER_PRIZE_BY_UNIT?.replace(':donvi', unit),
    params: { params },
  })

export const requestGetTop10MemberJoinEventByUnit = (
  unit: string,
  params?: any,
) =>
  ApiClient.Get({
    url: EventEndPoint.GET_TOP_10_MEMBER_JOIN_EVENT_MOST_BY_UNIT?.replace(
      ':donvi',
      unit,
    ),
    params: { params },
  })

// get my prize

export const requestGetUnitByMember = (id: string) =>
  ApiClient.Get({
    url: EventEndPoint.GET_UNIT_BY_MEMBER?.replace(':maso_doanvien', id),
    params: { id },
  })

export const requestGetMyPrize = (id: string) =>
  ApiClient.Get({
    url: EventEndPoint.GET_MY_PRIZE?.replace(':maso_doanvien', id),
    params: { id },
  })

export const requestGetMyAction = (id: string) =>
  ApiClient.Get({
    url: EventEndPoint.GET_MY_ACTION.replace(':maso_doanvien', id),
    params: { id },
  })

export const requestGetMyActionRegister = (id: string) =>
  ApiClient.Get({
    url: EventEndPoint.GET_MY_ACTION_REGISTER.replace(':maso_doanvien', id),
    params: { id },
  })
